import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { process } from "@progress/kendo-data-query";
import { useQueryGetModelErrors } from "../../data/services/clientService";

const ClientRateModelErrorDialog = ({
  clientId,
  oePeriodId,
  versionId,
  toggleDialog,
}) => {
  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50],
    previousNext: true,
  };

  const [errorList, setErrorList] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const { isLoading: errorListLoading, data: errorListSrc } =
    useQueryGetModelErrors(oePeriodId, clientId, versionId);

  React.useEffect(() => {
    if (!errorListLoading && errorListSrc && errorListSrc.data) {
      setErrorList(process(errorListSrc.data.slice(0), gridDataState));
    }
  }, [errorListLoading, errorListSrc, gridDataState]);

  const DialogBody = () => {
    const dataStateChange = (event) => {
      setErrorList(process(errorListSrc.data.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={errorListSrc.data}
            expanded={true}
          />
        </div>
      );
    };

    if (errorListLoading) {
      return <div>Loading...</div>;
    }

    if (!errorListSrc || !errorListSrc.data) {
      return <div>No error details found</div>;
    }

    return (
      <div>
        <Grid
          sortable={true}
          pageable={pagerSetting}
          pageSize={20}
          style={{ maxHeight: "400px" }}
          data={errorList}
          {...gridDataState}
          onDataStateChange={dataStateChange}
        >
          <Column
            field="planId"
            width={"150"}
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("planId")}
            title="Plan Id"
          />
          <Column
            field="groupId"
            width={"150"}
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("groupId")}
            title="Group Id"
          />
          <Column field="message" title="Error Message" />
        </Grid>
      </div>
    );
  };

  return (
    <Dialog
      title={"Rate Publish Error Details"}
      onClose={toggleDialog}
      width={800}
    >
      <DialogBody />
      <DialogActionsBar layout={"end"}>
        <Button
          onClick={toggleDialog}
          themeColor={"info"}
          style={{ marginRight: "8px" }}
        >
          {"Close"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ClientRateModelErrorDialog;
