export const handleServiceError = (queryError) => {
  let statusCode =
    queryError && queryError.response && queryError.response.status
      ? queryError.response.status
      : 0;

  if (statusCode === 400 || statusCode === 404) return;

  if (statusCode === 401 || statusCode === 403) {
    throw new Error(statusCode);
  }
};

export const parseServiceValidationError = (error) => {
  let errors = [];
  let errorTitle = "Sorry, something went wrong.  Please try again later.";
  if (error && error.response && error.response.status === 400) {
    let data = error.response.data;
    if (data && data.errors) {
      Object.values(data.errors).forEach((val) => {
        errors.push(val[0]);
      });
      errorTitle = "One or more validation errors occurred.";
    } else if (data && data.Source) {
      Object.values(data.Source).forEach((val) => {
        errors.push(val);
      });
      errorTitle = "One or more errors occurred.";
    } else {
      errorTitle = "Sorry, something went wrong.  Please try again later.";
    }
  }

  return { errors, errorTitle };
};
