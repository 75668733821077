import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { getPanelSubtitle } from "./panelHelper";

import {
  useMutateDataSync,
  useMutateStartWorkflow,
  useMutateContinueWorkflow,
} from "../../../data/services/workflowService";

const IntroductionPanel = ({
  expanded,
  setExpanded,
  oePeriod,
  status,
  workflowSetupId,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isNotStarted = stepStatus === "NotStarted" || !stepStatus;
  const isCompleted = stepStatus === "Completed";
  const isInProgress = stepStatus === "InProgress";
  const isError = stepStatus === "Error";
  const isSkipped = status?.isSkipped || false;

  const useMutateStartWorkflowHandler = useMutateStartWorkflow();
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();
  const useMutateDataSyncHandler = useMutateDataSync();

  const handleStartWorkflowEvent = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
    };

    await useMutateStartWorkflowHandler.mutateAsync(data);
  };

  const handleRetry = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "AllBenefitPlans",
      workflowId: status.oeWorkflowId,
      isRetry: true,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleSkipAndContinue = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      isSkipped: !isError,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  const RenderStatusBanner = () => {
    if (isNotStarted) {
      return null;
    } else if (isCompleted) {
      if (isSkipped) {
        return (
          <div
            style={{
              backgroundColor: "#fff3cd",
              padding: 10,
              borderRadius: ".25rem",
              marginTop: "20px",
            }}
          >
            <span style={{ color: "#721c24" }}>Data sync was skipped.</span>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "#d4edda",
              padding: 10,
              borderRadius: ".25rem",
              marginTop: "20px",
            }}
          >
            <span style={{ color: "#155724" }}>
              Successfully completed data sync.
            </span>
          </div>
        );
      }
    } else if (isInProgress) {
      return (
        <div
          style={{
            backgroundColor: "#fff3cd",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Data sync In Progress. Please wait ...
          </span>
        </div>
      );
    } else if (isError) {
      return (
        <div
          style={{
            backgroundColor: "#f8d7da",
            padding: 10,
            marginBottom: 10,
            borderRadius: ".25rem",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Error syncing data. Please try again
          </span>
        </div>
      );
    }
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) System Plan Data Sync`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              Clicking on the button will initiates system plan data sync. Make
              sure to setup any plans in PrismHR before you start the workflow.
            </p>
            <p>The data sync will take a few minutes to complete.</p>
            <RenderStatusBanner />
            <div
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!isError && (
                <Button
                  themeColor={"success"}
                  onClick={handleStartWorkflowEvent}
                  disabled={isCompleted || isInProgress}
                >
                  Start Data Sync{" "}
                  {isInProgress && <span className="k-icon k-i-loading"></span>}
                  {isCompleted && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "darkgreen", marginLeft: "10px" }}
                    />
                  )}
                </Button>
              )}
              {isError && (
                <Button themeColor={"error"} onClick={handleRetry}>
                  Retry Step
                </Button>
              )}
              <Button
                themeColor={"warning"}
                onClick={handleSkipAndContinue}
                style={{ marginLeft: "10px" }}
                disabled={isCompleted || isInProgress}
              >
                Skip & Continue
              </Button>
            </div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default IntroductionPanel;
