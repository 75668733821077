import * as React from "react";
import { useParams } from "react-router-dom";
import { Stepper } from "@progress/kendo-react-layout";

import LoadingIndicator from "../../components/LoadingIndicator";
import ImportRatesGrid from "./ImportRatesGrid";
import ImportPlanMapping from "./ImportPlanMapping";
import ImportBillingRateMapping from "./ImportBillingRateMapping";
import ImportApplyCost from "./ImportApplyCost";
import ImportRateReview from "./ImportRateReview";
import ImportRateSubmit from "./ImportRateSubmit";

import { ImportStatus } from "./ImportStatusHelper";

import { useQueryGetImportItem } from "../../data/services/importService";

const ImportRateFileLayout = () => {
  const { id } = useParams();

  const [step, setStep] = React.useState(0);
  const [steps, setSteps] = React.useState([
    {
      label: "Import File",
      isValid: undefined,
      disabled: false,
      id: 1,
    },
    {
      label: "Plan Mapping",
      isValid: undefined,
      disabled: false,
      id: 2,
    },
    {
      label: "Rate Group Mapping",
      isValid: undefined,
      disabled: false,
      id: 3,
    },
    {
      label: "Apply Costs",
      isValid: undefined,
      disabled: false,
      id: 4,
    },
    {
      label: "Review",
      isValid: undefined,
      disabled: false,
      id: 5,
    },
    {
      label: "Submit",
      isValid: undefined,
      disabled: false,
      id: 6,
    },
  ]);

  const { isLoading: importItemLoading, data: importItem } =
    useQueryGetImportItem(id);

  React.useEffect(() => {
    if (!importItemLoading && importItem && importItem.data) {
      setCurrentStep();
    }
  }, [importItemLoading, importItem]);

  const setCurrentStep = () => {
    switch (importItem.data.importStatus) {
      case ImportStatus.ParsingQueued:
      case ImportStatus.ParsingRunning: {
        setStep(0);
        setDisableStatus(1, true);
        break;
      }
      case ImportStatus.ParsingError: {
        setStep(0);
        setDisableStatus(1, false);
        break;
      }
      case ImportStatus.ParsingComplete:
      case ImportStatus.PlanMappingQueued:
      case ImportStatus.PlanMappingRunning: {
        setStep(1);
        setDisableStatus(2, true);
        break;
      }
      case ImportStatus.PlanMappingError: {
        setStep(1);
        setDisableStatus(2, false);
        break;
      }
      case ImportStatus.PlanMappingComplete:
      case ImportStatus.RateMappingQueued:
      case ImportStatus.RateMappingRunning: {
        setStep(2);
        setDisableStatus(3, true);
        break;
      }
      case ImportStatus.RateMappingError: {
        setStep(2);
        setDisableStatus(3, false);
        break;
      }
      case ImportStatus.RateMappingComplete: {
        setStep(3);
        setDisableStatus(4, true);
        break;
      }
      case ImportStatus.ApplyCostComplete: {
        setStep(4);
        setDisableStatus(5, true);
        break;
      }
      case ImportStatus.ReviewCompleted:
      case ImportStatus.SubmitQueued:
      case ImportStatus.SubmitRunning: {
        setStep(5);
        setDisableStatus(6, true);
        break;
      }
      case ImportStatus.SubmitComplete: {
        setStep(5);
        setDisableStatus(6, true, true);
        break;
      }
      case ImportStatus.SubmitError: {
        setStep(5);
        setDisableStatus(6, false);
        break;
      }
      default: {
        setStep(0);
        break;
      }
    }
  };

  const setDisableStatus = (id, isValid, allComplete) => {
    setSteps(
      steps.map((step) => {
        if (step.id > id) {
          return {
            ...step,
            disabled: true,
          };
        } else if (step.id < id) {
          return {
            ...step,
            disabled: false,
            isValid: true,
          };
        } else if (!isValid && step.id === id) {
          return {
            ...step,
            disabled: false,
            isValid: false,
          };
        } else if (isValid && step.id === id) {
          return {
            ...step,
            disabled: false,
            isValid: allComplete ? true : undefined,
          };
        }
        return step;
      })
    );
  };

  const handleChange = (e) => {
    setStep(e.value);
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  if (importItemLoading) {
    return <LoadingIndicator />;
  }

  if (!importItem || !importItem.data || !importItem.data.importStatus) {
    return <h3>Import item not found</h3>;
  }

  return (
    <>
      <h3>
        {importItem.data.carrier.carrierName}{" "}
        {importItem.data.carrierType.carrierTypeDesc} Rate file import -{" "}
        {importItem.data.fileName}
      </h3>
      <Stepper
        value={step}
        onChange={handleChange}
        items={steps}
        linear={false}
      />
      <div style={{ height: 20 }}></div>
      {step === 0 && (
        <ImportRatesGrid
          id={id}
          onNextClick={handleNext}
          importItem={importItem.data}
        />
      )}
      {step === 1 && (
        <ImportPlanMapping
          id={id}
          onPrevClick={handleBack}
          onNextClick={handleNext}
          importItem={importItem.data}
        />
      )}
      {step === 2 && (
        <ImportBillingRateMapping
          id={id}
          onPrevClick={handleBack}
          onNextClick={handleNext}
          importItem={importItem.data}
        />
      )}
      {step === 3 && (
        <ImportApplyCost
          id={id}
          onPrevClick={handleBack}
          onNextClick={handleNext}
          importItem={importItem.data}
        />
      )}
      {step === 4 && (
        <ImportRateReview
          id={id}
          onPrevClick={handleBack}
          onNextClick={handleNext}
          importItem={importItem.data}
        />
      )}
      {step === 5 && (
        <ImportRateSubmit
          id={id}
          onPrevClick={handleBack}
          importItem={importItem.data}
        />
      )}
    </>
  );
};

export default ImportRateFileLayout;
