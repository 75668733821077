import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosPut,
  reactQueryDefaultOptions,
  axiosGet,
  axiosPost,
  axiosDelete,
  axiosFormPost,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetNotificationWaves = (oePeriodId) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.NOTIFICATION_WAVES_LIST(oePeriodId));
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 300000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_WAVES_LIST, oePeriodId],
    getData,
    option
  );
};

export const useMutateCreateNotificationWave = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.NOTIFICATION_WAVES_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVES_LIST,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateDeleteNotificationWave = () => {
  const postData = async (data) => {
    return await axiosDelete(SERVICE_URLS.NOTIFICATION_WAVES_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVES_LIST,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useQueryGetNotificationWaveClients = (oePeriodId, waveId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.NOTIFICATION_WAVE_CLIENTS(oePeriodId, waveId)
    );
  };

  let enabled = false;
  if (oePeriodId && waveId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_WAVE_CLIENTS, oePeriodId, waveId],
    getData,
    option
  );
};

export const useQueryGetNotificationWaveMailDetails = (
  oePeriodId,
  waveId,
  isBroker
) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.NOTIFICATION_WAVE_MAIL_DETAILS(oePeriodId, waveId, isBroker)
    );
  };

  let enabled = false;
  if (oePeriodId && waveId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_WAVE_MAIL_DETAILS, oePeriodId, waveId, isBroker],
    getData,
    option
  );
};

export const useMutateCreateNotificationWaveClient = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.NOTIFICATION_WAVE_CLIENTS_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVE_CLIENTS,
        variables.oePeriodId,
        variables.notificationWaveId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVES_LIST,
        variables.oePeriodId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVE_AVAILABLE_CLIENTS,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateDeleteNotificationWaveClient = () => {
  const postData = async (data) => {
    return await axiosDelete(
      SERVICE_URLS.NOTIFICATION_WAVE_CLIENTS_DELETE,
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVE_CLIENTS,
        variables.oePeriodId,
        variables.notificationWaveId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVES_LIST,
        variables.oePeriodId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVE_AVAILABLE_CLIENTS,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useQueryGetNotificationWaveAvailableClients = (oePeriodId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.NOTIFICATION_WAVE_AVAILABLE_CLIENTS(oePeriodId)
    );
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_WAVE_AVAILABLE_CLIENTS, oePeriodId],
    getData,
    option
  );
};

export const useQueryGetNotificationWaveBenefitGroups = (waveId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.NOTIFICATION_WAVE_BENEFIT_GROUPS(waveId)
    );
  };

  let enabled = false;
  if (waveId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_WAVE_BENEFIT_GROUPS, waveId],
    getData,
    option
  );
};

export const useMutateContinueNotificationWaveStep = () => {
  const postData = async (data) => {
    return await axiosPut(SERVICE_URLS.NOTIFICATION_WAVES_UPDATE_STATUS, data);
  };

  return useMutation(postData, {
    onSuccess: (data, variables, context) => { },
  });
};

export const useMutateResendNotification = () => {
  const postData = async (data) => {
    return await axiosPut(SERVICE_URLS.NOTIFICATION_WAVES_RESEND, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVES_LIST,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateUploadWelcomeLetter = () => {
  const postData = async (data) => {
    var formData = new FormData();
    formData.append("data", data.file, data.fileName);

    return await axiosFormPost(
      SERVICE_URLS.NOTIFICATION_UPLOAD_WELCOME_LETTER(
        data.waveId,
        data.bgLookupId
      ),
      formData
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.NOTIFICATION_WAVE_BENEFIT_GROUPS,
        variables.waveId,
      ]);
    },
  });
};

export const useQueryGetNotificationRedirects = () => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.NOTIFICATION_REDIRECT);
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_REDIRECT_LIST],
    getData,
    reactQueryDefaultOptions
  );
};

export const useQueryGetNotificationReminder = () => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.NOTIFICATION_REMINDER);
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_REDIRECT_LIST],
    getData,
    reactQueryDefaultOptions
  );
};

export const useMutatePostNotificationReminder = () => {
  debugger;
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.NOTIFICATION_REMINDER, data);
  };

  return useMutation(postData, {
    onSuccess: () => {},
  });
};

export const useMutatePostNotificationRedirect = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.NOTIFICATION_REDIRECT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.NOTIFICATION_REDIRECT_LIST]);
    },
  });
};

export const useQueryGetNotificationWaveErrors = (
  waveId,
  brokerNotifications
) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.NOTIFICATION_WAVE_ERRORS(waveId, brokerNotifications)
    );
  };

  return useQuery(
    [QUERY_KEYS.NOTIFICATION_WAVE_ERRORS, waveId, brokerNotifications],
    getData,
    reactQueryDefaultOptions
  );
};
