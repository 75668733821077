import { Loader } from "@progress/kendo-react-indicators";

const LoadingIndicator = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "85vh",
        alignItems: "center",
      }}
    >
      <div>
        <Loader size="large" type={"infinite-spinner"} />
      </div>
    </div>
  );
};

export default LoadingIndicator;
