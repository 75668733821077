import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { formatDate } from "@telerik/kendo-intl";

import { useQueryGetClientBenefitRules } from "../../data/services/clientAdminService";

const CLientBPRulesModel = ({ clientId, planId, groupId, onClose }) => {
  const [ratesListSrc, setRatesListSrc] = React.useState([]);
  const [gridState, setGridState] = React.useState({});

  const { isLoading: ratesLoading, data: ratesList } =
    useQueryGetClientBenefitRules(clientId, planId);

  React.useEffect(() => {
    if (!ratesLoading && ratesList && ratesList.data) {
      let mapped = ratesList.data
        .filter((e) => e.groupPlanId === groupId && e.planType)
        .map((m) => {
          m.methodDesc = m.method === "F" ? "Fixed" : "Percent";
          return m;
        });
      setRatesListSrc(mapped);
    }
  }, [ratesLoading, ratesList, groupId]);

  const gridDataStateChange = (event) => {
    setGridState(event.dataState);
  };

  const dateCell = (props) => {
    return <td>{formatDate(new Date(props.dataItem[props.field]), "d")}</td>;
  };

  return (
    <Dialog title={"Plan Current Rates"} onClose={onClose} width={"70vw"}>
      {ratesLoading && <div>Loading...</div>}
      {!ratesLoading &&
        ratesList &&
        ratesList.data &&
        ratesList.data.length === 0 && <div>No data found</div>}
      {!ratesLoading &&
        ratesList &&
        ratesList.data &&
        ratesList.data.length > 0 && (
          <Grid
            data={process(ratesListSrc, gridState)}
            style={{ maxHeight: "50vh" }}
            sortable={true}
            onDataStateChange={gridDataStateChange}
            {...gridState}
          >
            <GridColumn
              field="effectiveDate"
              title="Effective Date"
              cell={dateCell}
            />
            <GridColumn field="planType" title="Plan Type" />
            <GridColumn
              field="totalBilled"
              title="Rate"
              className="k-text-right"
              format="{0:c}"
            />
            <GridColumn field="methodDesc" title="Contribution Method" />
            <GridColumn field="companyEEBill" title="Contribution Value (EE)" />
            <GridColumn
              field="companyDepBill"
              title="Contribution Value (Dep)"
            />
            <GridColumn
              field="companyContribution"
              title="Company Contribution"
              className="k-text-right"
              format="{0:c}"
            />
            <GridColumn
              field="employeeTotalContribution"
              title="Employee Contribution"
              className="k-text-right"
              format="{0:c}"
            />
          </Grid>
        )}
      <DialogActionsBar layout={"end"}>
        <div style={{ paddingRight: "8px", paddingBottom: "8px" }}>
          <Button onClick={onClose} style={{ marginRight: "10px" }}>
            {"Close"}
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default CLientBPRulesModel;
