import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ErrorBanner from "../../components/ErrorBanner";
import { FormRadioGroup } from "../../components/FormRadioGroup";

import { useMutateCopyContribution } from "../../data/services/clientService";

const ClientCopyContributionDialog = (props) => {
  const [submitted, setSubmitted] = React.useState(false);

  const useMutateCopyContributionHandler = useMutateCopyContribution();

  const handleSubmit = async (item) => {
    var data = {
      oePeriodId: props.oePeriodId,
      clientId: props.clientId,
      modelVersionId: props.versionId,
      groupId: props.groupId,
      contributionType: item.contributionType,
    };

    setSubmitted(true);

    await useMutateCopyContributionHandler.mutateAsync(data);

    setSubmitted(false);
    props.onClose();
  };

  return (
    <Dialog
      title={"Contribution Shortcuts"}
      onClose={props.onClose}
      width={400}
    >
      <ErrorBanner
        handler={useMutateCopyContributionHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement
            horizontal={false}
            style={{
              maxWidth: 600,
              paddingLeft: 10,
            }}
          >
            <Field
              id={"contributionType"}
              name={"contributionType"}
              layout={"vertical"}
              component={FormRadioGroup}
              style={{ paddingTop: 20 }}
              data={[
                {
                  label: "Keep same contribution % as previous year",
                  value: "SamePercentageAsPreviousYear",
                },
                {
                  label: "Keep same contribution $ as previous year",
                  value: "SameAmountAsPreviousYear",
                },
                {
                  label: "Split increase 50/50 between employer and employee",
                  value: "FiftyFiftyIncreaseBetweenERAndEE",
                },
                {
                  label: "Assign 100% of increase to employer",
                  value: "FullIncreaseBetweenERAndEE",
                },
              ]}
              required={true}
              validator={(value) =>
                value ? "" : "Please select a contribution type"
              }
            />
            <div style={{ paddingTop: 30 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={props.onClose}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {"Update Contribution"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default ClientCopyContributionDialog;
