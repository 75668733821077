import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Link } from "react-router-dom";

import { getPanelSubtitle } from "./panelHelper";
import DataSyncErrorModel from "./DataSyncErrorModel";
import {
  useMutateDataSync,
  useMutateContinueWorkflow,
} from "../../../data/services/workflowService";

const BuildClientModelPanel = ({
  expanded,
  setExpanded,
  currentStep,
  status,
  oePeriod,
  workflowSetupId,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isSyncInProgress = stepStatus === "SyncInProgress";
  const isSyncError = stepStatus === "SyncError";
  const isSyncCompleted =
    stepStatus === "SyncCompleted" || stepStatus === "Completed";
  const isInProgress = stepStatus === "InProgress";
  const isStepCompleted = stepStatus === "Completed";

  const [showErrorModel, setShowErrorModel] = React.useState(false);

  const useMutateDataSyncHandler = useMutateDataSync();
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();

  const handleSubmit = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "AllClientBenefits",
      workflowId: status.oeWorkflowId,
      isRetry: false,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleRetry = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "AllClientBenefits",
      workflowId: status.oeWorkflowId,
      isRetry: true,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleContinue = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  const RenderStatusBanner = () => {
    if (isSyncCompleted) {
      return (
        <div
          style={{
            backgroundColor: "#d4edda",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#155724" }}>
            Successfully synced client details. Please review the data in{" "}
            <Link to="/admin/clientadminsummary"> Assigned Client Screen</Link>
          </span>
        </div>
      );
    } else if (isSyncInProgress) {
      return (
        <div
          style={{
            backgroundColor: "#fff3cd",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Client data sync In Progress. Please wait ...
          </span>
        </div>
      );
    } else if (isSyncError) {
      return (
        <div
          style={{
            backgroundColor: "#f8d7da",
            padding: 10,
            marginBottom: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Data sync failed for few clients. Review errors and continue if
            those are invalid clients.{" "}
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setShowErrorModel(true)}
            >
              View Error Details
            </span>
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Load client details and build model`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              Clicking on the <b>Sync Client & Build Model</b> button below will
              trigger data sync for selected clients from PrismHR to OE Portal.
              (clients Benefit Plans, EE Enrollments and Contacts)
            </p>
            <p>
              {" "}
              Once the data is synced, client menus and Base Rate Grid Model is
              created for the selected clients.
            </p>
            <p>Note: The step takes few hours to complete once queued.</p>
            <RenderStatusBanner />
            {isInProgress && (
              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button themeColor={"success"} onClick={handleSubmit}>
                  Sync Client & Build Model
                </Button>
              </div>
            )}
            {isSyncInProgress && (
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  themeColor={"success"}
                  onClick={handleSubmit}
                  disabled={true}
                >
                  Sync Client & Build Model{" "}
                  <span className="k-icon k-i-loading"></span>
                </Button>
              </div>
            )}
            {isSyncError && (
              <>
                <p>
                  Please review the model in{" "}
                  <Link to="/admin/clientadminsummary">
                    Assigned Clients Screen
                  </Link>
                </p>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button themeColor={"error"} onClick={handleRetry}>
                    Retry Failed Clients
                  </Button>
                  <Button
                    themeColor={"warning"}
                    onClick={handleContinue}
                    style={{ marginLeft: "10px" }}
                  >
                    Skip & Continue to Next Step
                  </Button>
                </div>
              </>
            )}
            {isSyncCompleted && (
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  themeColor={"success"}
                  onClick={handleContinue}
                  disabled={isStepCompleted}
                >
                  Continue to Next Step
                </Button>
              </div>
            )}
            {showErrorModel && (
              <DataSyncErrorModel
                oePeriodId={oePeriod.oePeriodId}
                workflowId={status.oeWorkflowId}
                onClose={() => setShowErrorModel(false)}
              />
            )}
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default BuildClientModelPanel;
