import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const ConfirmationDialog = (props) => {
  return (
    <Dialog title={props.title} onClose={props.onCancel}>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        {props.message}
      </p>
      <DialogActionsBar>
        <Button onClick={props.onCancel}>{"Cancel"}</Button>
        <Button
          type={"submit"}
          themeColor={"primary"}
          onClick={() => props.onConfirm(props.item)}
        >
          {"Confirm"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ConfirmationDialog;
