import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosPut,
  reactQueryDefaultOptions,
  axiosGet,
  axiosPost,
  axiosDelete,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetActiveOEPeriod = () => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.CLIENT_ACTIVE_OE);
  };
  return useQuery(
    [QUERY_KEYS.GET_CLIENTS_ACTIVE_OE],
    getData,
    reactQueryDefaultOptions
  );
};

export const useQueryGetClients = (oePeriodId, showAllClients) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.CLIENT_LIST(oePeriodId, showAllClients));
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 2,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: false,
    refetchInterval: 120000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.GET_CLIENTS, oePeriodId, showAllClients],
    getData,
    option
  );
};

export const useQueryGetClientBenefitRep = (clientId) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.GET_CLIENT_BENEFIT_REP(clientId));
  };

  let enabled = false;
  if (clientId) {
    enabled = true;
  }

  let option = {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.GET_CLIENT_BENEFIT_REP, clientId],
    getData,
    option
  );
};

export const useQueryGetClientSalesRep = (clientId) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.GET_CLIENT_SALES_REP(clientId));
  };

  let enabled = false;
  if (clientId) {
    enabled = true;
  }

  let option = {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.GET_CLIENT_SALES_REP, clientId],
    getData,
    option
  );
};

export const useQueryGetModelVersions = (oePeriodId, clientId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_MODEL_VERSIONS(oePeriodId, clientId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: enabled,
  };
  return useQuery(
    [QUERY_KEYS.GET_CLIENT_MODEL_VERSIONS, oePeriodId, clientId],
    getData,
    option
  );
};

export const useQueryGetModelSummary = (oePeriodId, clientId, versionId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_MODEL_SUMMARY_GET(oePeriodId, clientId, versionId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId && versionId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: enabled,
  };
  return useQuery(
    [QUERY_KEYS.GET_CLIENT_MODEL_SUMMARY, oePeriodId, clientId, versionId],
    getData,
    option
  );
};

export const useQueryGetModelAvailablePlans = (
  oePeriodId,
  clientId,
  versionId,
  groupId
) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_MODEL_AVAILABLE_PLANS(
        oePeriodId,
        clientId,
        versionId,
        groupId
      )
    );
  };

  let enabled = false;
  if (oePeriodId && clientId && versionId && groupId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 1,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: enabled,
  };
  return useQuery(
    [
      QUERY_KEYS.GET_CLIENT_MODEL_AVAILABLE_PLANS,
      oePeriodId,
      clientId,
      versionId,
      groupId,
    ],
    getData,
    option
  );
};

export const useQueryGetModelDetail = (oePeriodId, clientId, versionId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_MODEL_GET(oePeriodId, clientId, versionId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId && versionId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: enabled,
  };
  return useQuery(
    [QUERY_KEYS.GET_CLIENT_MODEL_DETAIL, oePeriodId, clientId, versionId],
    getData,
    option
  );
};

export const useQueryGetModelMaxContributions = (
  oePeriodId,
  clientId,
  versionId
) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_MODEL_MAXCONTRIBS(oePeriodId, clientId, versionId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId && versionId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: enabled,
  };
  return useQuery(
    [QUERY_KEYS.GET_CLIENT_MODEL_MAXCONTRIBS, oePeriodId, clientId, versionId],
    getData,
    option
  );
};

export const useQueryGetModelErrors = (oePeriodId, clientId, versionId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_MODEL_ERRORS_GET(oePeriodId, clientId, versionId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId && versionId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 3,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: false,
    enabled: enabled,
  };
  return useQuery(
    [QUERY_KEYS.GET_CLIENT_MODEL_ERRORS, oePeriodId, clientId, versionId],
    getData,
    option
  );
};

export const useMutateSetMaxContribution = () => {
  const postData = async (data) => {
    return await axiosPut(SERVICE_URLS.CLIENT_MODEL_SET_MAX_CONTRIBUTION, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_DETAIL,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_MAXCONTRIBS,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
    },
  });
};

export const useMutateCopyContribution = () => {
  const postData = async (data) => {
    return await axiosPut(SERVICE_URLS.CLIENT_MODEL_COPY_CONTRIBUTION, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_DETAIL,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
    },
  });
};

export const useMutatePostOESummaryReadStatus = () => {
  const postData = async (data) => {
    return await axiosPut(SERVICE_URLS.CLIENT_UPDATE_OESUMMARY_STATAS, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENTS,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateCreateModelVersion = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.CLIENT_CREATE_MODEL, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_VERSIONS,
        variables.oePeriodId,
        variables.clientId,
      ]);
    },
  });
};

export const useMutateUpdateModelRates = () => {
  const postData = async (data) => {
    return await axiosPut(
      SERVICE_URLS.CLIENT_MODEL_UPDATE_RATE(
        data.oePeriodId,
        data.clientId,
        data.versionId
      ),
      data.rates
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_SUMMARY,
        variables.oePeriodId,
        variables.clientId,
        variables.versionId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_DETAIL,
        variables.oePeriodId,
        variables.clientId,
        variables.versionId,
      ]);
    },
  });
};

export const useMutateModelAddPlan = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.CLIENT_MODEL_ADD_PLAN, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_AVAILABLE_PLANS,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
        variables.groupId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_SUMMARY,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_DETAIL,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
    },
  });
};

export const useMutateSubmitModel = () => {
  const putData = async (data) => {
    return await axiosPut(
      SERVICE_URLS.CLIENT_MODEL_SUMIT_RATE(
        data.oePeriodId,
        data.clientId,
        data.versionId
      ),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(putData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENTS,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateUnlockModel = () => {
  const putData = async (data) => {
    return await axiosPut(
      SERVICE_URLS.CLIENT_MODEL_UNLOCK_RATE(
        data.oePeriodId,
        data.clientId,
        data.versionId
      ),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(putData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENTS,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutatePublishModel = () => {
  const putData = async (data) => {
    return await axiosPut(
      SERVICE_URLS.CLIENT_MODEL_PUBLISH_RATE(
        data.oePeriodId,
        data.clientId,
        data.versionId,
        data.isRetry
      ),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(putData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENTS,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateModelDeletePlan = () => {
  const postData = async (data) => {
    return await axiosDelete(SERVICE_URLS.CLIENT_MODEL_DELETE_PLAN, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_AVAILABLE_PLANS,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
        variables.groupId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_SUMMARY,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_CLIENT_MODEL_DETAIL,
        variables.oePeriodId,
        variables.clientId,
        variables.modelVersionId,
      ]);
    },
  });
};

export const useQueryGetClientModelAuditRecords = (clientId) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.CLIENT_MODEL_AUDIT_RECORD(clientId));
  };

  let enabled = false;
  if (clientId) {
    enabled = true;
  }

  let option = {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: false,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.GET_CLIENT_AUDIT_RECORDS, clientId],
    getData,
    option
  );
};