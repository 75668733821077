import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";

import { process } from "@progress/kendo-data-query";
import {
  useQueryGetOEWorkflowClients,
  useMutateAddOEWorkflowClient,
} from "../../../data/services/workflowService";
import OEWorkflowAddClientsModel from "./OEWorkflowAddClientsModel";

const OEWorkflowClientsGrid = ({
  oePeriodId,
  workflowSetupId,
  isStepCompleted,
}) => {
  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50],
    previousNext: true,
  };

  const [showAddClientsModel, setShowAddClientsModel] = React.useState(false);
  const [workflowClientsList, setWorkflowClientsList] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const {
    isLoading: workflowClientsLoading,
    data: workflowClientsSrc,
    refetch,
  } = useQueryGetOEWorkflowClients(oePeriodId, workflowSetupId);
  const useMutateAddOEWorkflowClientHandler = useMutateAddOEWorkflowClient();

  React.useEffect(() => {
    if (
      !workflowClientsLoading &&
      workflowClientsSrc &&
      workflowClientsSrc.data
    ) {
      setWorkflowClientsList(
        process(workflowClientsSrc.data.slice(0), gridDataState)
      );
    }
  }, [workflowClientsLoading, workflowClientsSrc, gridDataState]);

  const ClientsGrid = () => {
    const dataStateChange = (event) => {
      setWorkflowClientsList(
        process(workflowClientsSrc.data.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={workflowClientsSrc.data}
            expanded={true}
          />
        </div>
      );
    };

    const onRemove = async (dataItem) => {
      const updatedData = {
        ...dataItem,
        included: false,
      };

      await useMutateAddOEWorkflowClientHandler.mutateAsync(updatedData);

      refetch();
    };

    const onAddBack = async (dataItem) => {
      const updatedData = {
        ...dataItem,
        included: true,
      };

      await useMutateAddOEWorkflowClientHandler.mutateAsync(updatedData);

      refetch();
    };

    const includedCell = (props) => {
      return (
        <td style={{ color: !props.dataItem.included ? "red" : "" }}>
          {props.dataItem.included ? "Included" : "Removed"}
        </td>
      );
    };

    const clientModelExistsCell = (props) => {
      return (
        <td style={{ color: !props.dataItem.hasClientModel ? "" : "red" }}>
          {props.dataItem.hasClientModel ? "Yes (Will get overridden)" : "No"}
        </td>
      );
    };

    const actionCell = (props) => {
      return (
        <td>
          {props.dataItem.included && (
            <Button
              themeColor="info"
              onClick={() => onRemove(props.dataItem)}
              disabled={
                useMutateAddOEWorkflowClientHandler.isLoading || isStepCompleted
              }
            >
              Remove{" "}
              {useMutateAddOEWorkflowClientHandler.isLoading && (
                <span className="k-icon k-i-loading"></span>
              )}
            </Button>
          )}
          {!props.dataItem.included && (
            <Button
              themeColor="info"
              onClick={() => onAddBack(props.dataItem)}
              disabled={
                useMutateAddOEWorkflowClientHandler.isLoading || isStepCompleted
              }
            >
              Add Back{" "}
              {useMutateAddOEWorkflowClientHandler.isLoading && (
                <span className="k-icon k-i-loading"></span>
              )}
            </Button>
          )}
        </td>
      );
    };

    return (
      <div>
        <Grid
          sortable={true}
          pageable={pagerSetting}
          pageSize={20}
          style={{ maxHeight: "400px" }}
          data={workflowClientsList}
          {...gridDataState}
          onDataStateChange={dataStateChange}
        >
          <Column
            field="clientId"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("clientId")}
            title="Client Id"
          />
          <Column
            field="clientName"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("clientName")}
            title="Client Name"
          />
          <Column
            field="included"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("included")}
            title="Status"
            cell={includedCell}
          />
          <Column
            field="hasClientModel"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("hasClientModel")}
            title="Client Model Exists"
            cell={clientModelExistsCell}
          />
          <Column
            field="Action"
            title="Action"
            width={200}
            cell={actionCell}
            sortable={false}
          />
        </Grid>
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: 40,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3>
            Below clients will be included in the workflow. Add or Modify
            clients if required
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setShowAddClientsModel(true)}
            themeColor={"success"}
            disabled={isStepCompleted}
          >
            Add Clients
          </Button>
        </div>
      </div>
      <ClientsGrid />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          color: "red",
        }}
      >
        {workflowClientsSrc &&
          workflowClientsSrc.data &&
          workflowClientsSrc.data.find(
            (x) => x.hasClientModel && x.included
          ) && (
            <h3>
              Note:{" "}
              {
                workflowClientsSrc.data.filter(
                  (x) => x.hasClientModel && x.included
                ).length
              }{" "}
              clients have Rate Grid update InProgress. Proceeding with this
              workflow will override any progress. You can remove the clients
              from the workflow if you want to avoid this.
            </h3>
          )}
      </div>
      {showAddClientsModel && (
        <OEWorkflowAddClientsModel
          oePeriodId={oePeriodId}
          workflowSetupId={workflowSetupId}
          onClose={() => setShowAddClientsModel(false)}
          onSubmit={() => {
            setShowAddClientsModel(false);
            refetch();
          }}
        />
      )}
    </div>
  );
};

export default OEWorkflowClientsGrid;
