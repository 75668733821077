import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Upload as KendoUpload } from "@progress/kendo-react-upload";
import Papa from "papaparse";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { useMutateImportRateGroupMapping } from "../../data/services/benefitPlanService";

const ImportRateGroupMappingModel = ({
  existingMappings,
  carrierList,
  onClose,
}) => {
  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50],
    previousNext: true,
  };
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const [files, setFiles] = React.useState([]);
  const [parsedRateGroupData, setParsedRateGroupData] = React.useState([]);
  const [parsedRateGroupDataPaginated, setParsedRateGroupDataPaginated] =
    React.useState([]);

  const useMutateImportRateGroupMappingHandler =
    useMutateImportRateGroupMapping();

  const onDownloadTemplate = async (event) => {
    const csvData = Papa.unparse([
      {
        Carrier: "",
        PlanGroup: "",
        RateFactorName: "",
        PrismBillingRateGroup: "",
      },
    ]);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a download link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Rate Group Mapping Upload Template.csv";

    // Simulate a click on the download link to trigger the download
    link.click();
  };

  const onAddFileHandler = (event) => {
    setFiles(event.newState);
    Papa.parse(event.newState[0].getRawFile(), {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const filteredData = results.data.map((item) => {
          if (
            !item.Carrier ||
            !item.PlanGroup ||
            !item.RateFactorName ||
            !item.PrismBillingRateGroup
          ) {
            item.Status = "Missing required fields";
            item.isValid = false;
            return item;
          }

          const carrier = carrierList.find(
            (c) => c.carrierName === item.Carrier
          );
          if (!carrier) {
            item.Status = "Carrier not found";
            item.isValid = false;
            return item;
          } else {
            item.CarrierId = carrier.carrierId;
          }

          const existingMapping = existingMappings.find(
            (m) =>
              m.carrierName === item.Carrier &&
              m.planGroup === item.PlanGroup &&
              m.rateFactorName === item.RateFactorName
          );

          item.Status = existingMapping ? "Updating" : "Adding";
          item.isValid = true;
          item.RateGroup = item.PrismBillingRateGroup;

          return item;
        });
        setParsedRateGroupData(filteredData);
        setParsedRateGroupDataPaginated(
          process(filteredData.slice(0), gridDataState)
        );
      },
    });
  };

  const onRemoveHandler = (event) => {
    setFiles(event.newState);
    setParsedRateGroupData([]);
    setParsedRateGroupDataPaginated([]);
  };

  const handleSubmit = async (event) => {
    const data = parsedRateGroupData.filter((item) => item.isValid);
    await useMutateImportRateGroupMappingHandler.mutateAsync(data);
    onClose();
  };

  const ClientsGrid = () => {
    const dataStateChange = (event) => {
      setParsedRateGroupDataPaginated(
        process(parsedRateGroupData.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={parsedRateGroupData}
            expanded={true}
          />
        </div>
      );
    };

    return (
      <div>
        <Grid
          sortable={true}
          pageable={pagerSetting}
          pageSize={20}
          style={{ maxHeight: "400px" }}
          data={parsedRateGroupDataPaginated}
          {...gridDataState}
          onDataStateChange={dataStateChange}
        >
          <Column
            field="Carrier"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("Carrier")}
            title="Carrier"
            width={150}
          />
          <Column
            field="PlanGroup"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("PlanGroup")}
            title="Plan Group"
          />
          <Column
            field="RateFactorName"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("RateFactorName")}
            title="Rate Factor Name"
          />
          <Column
            field="PrismBillingRateGroup"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("PrismBillingRateGroup")}
            title="Prism Billing Rate Group"
          />
          <Column
            field="Status"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("Status")}
            title="Status"
          />
        </Grid>
      </div>
    );
  };

  return (
    <Dialog title={"Import Rate Group Mapping"} onClose={onClose} width={1000}>
      <p>
        On import, system will either add new rate group mapping or update
        existing rate group mapping.
      </p>
      <p>
        Please use this template to upload rate group mapping.{" "}
        <Button
          themeColor={"info"}
          fillMode="flat"
          onClick={onDownloadTemplate}
        >
          Download Template
        </Button>
      </p>
      <KendoUpload
        autoUpload={false}
        showActionButtons={false}
        files={files}
        multiple={false}
        onAdd={onAddFileHandler}
        onRemove={onRemoveHandler}
        restrictions={{
          allowedExtensions: [".csv"],
        }}
      />
      <ClientsGrid />
      <div style={{ paddingTop: 20 }}></div>
      <DialogActionsBar layout={"end"}>
        <Button onClick={onClose}>{"Cancel"}</Button>
        <Button
          type={"submit"}
          themeColor={"success"}
          disabled={
            !parsedRateGroupData ||
            parsedRateGroupData.filter((e) => e.isValid).length === 0
          }
          onClick={handleSubmit}
        >
          {"Import"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ImportRateGroupMappingModel;
