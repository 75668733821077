import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Link } from "react-router-dom";

import { FormUploadWithoutDZ } from "../../../components/FormUploadWithoutDZ";

import { getPanelSubtitle } from "./panelHelper";
import { useMutateContinueWorkflow } from "../../../data/services/workflowService";
import { useMutatePostPlanDetailImport } from "../../../data/services/benefitPlanService";

const UploadPlanDetailsPanel = ({
  expanded,
  setExpanded,
  currentStep,
  status,
  oePeriod,
  workflowSetupId,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isSyncInProgress = stepStatus === "SyncInProgress";
  const isSyncError = stepStatus === "SyncError";
  const isSyncCompleted =
    stepStatus === "SyncCompleted" || stepStatus === "Completed";
  const isInProgress = stepStatus === "InProgress";
  const isStepCompleted = stepStatus === "Completed";

  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();
  const useMutatePostPlanDetailImportHandler = useMutatePostPlanDetailImport();

  const onSubmit = async (event) => {
    var data = {
      oePeriodId: oePeriod.oePeriodId,
      oeWorkflowId: status.oeWorkflowId,
      medFileName: event.medicalFile[0].name,
      medFile: event.medicalFile[0].getRawFile(),
      denFileName: event.dentalFile[0].name,
      denFile: event.dentalFile[0].getRawFile(),
    };

    await useMutatePostPlanDetailImportHandler.mutateAsync(data);
  };

  const handleContinue = async (isSkipped) => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      isSkipped: isSkipped,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  const RenderStatusBanner = () => {
    if (isSyncCompleted) {
      return (
        <div
          style={{
            backgroundColor: "#d4edda",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#155724" }}>
            Successfully imported Plan Details. You can now generate menu to
            review plan details and rates in{" "}
            <Link to="/admin/systemmenu">System Menu Screen</Link>
          </span>
        </div>
      );
    } else if (isSyncInProgress) {
      return (
        <div
          style={{
            backgroundColor: "#fff3cd",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Data import In Progress. Please wait ...
          </span>
        </div>
      );
    } else if (isSyncError) {
      return (
        <div
          style={{
            backgroundColor: "#f8d7da",
            padding: 10,
            marginBottom: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Error importing data. Please try again
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Upload Benefit Plan Details`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              Please upload plan details file from PrismHR. NOTE: .xls files are
              not supported. Please save the file as .xlsx before uploading.
            </p>
            <Form
              onSubmit={onSubmit}
              render={(formRenderProps) => (
                <FormElement horizontal={false}>
                  <Field
                    id={"medicalFile"}
                    name={"medicalFile"}
                    label={"Select Medical File"}
                    component={FormUploadWithoutDZ}
                    disabled={isSyncInProgress || isSyncCompleted}
                    restrictions={{
                      allowedExtensions: [".xlsx"],
                    }}
                    validator={(value) =>
                      value && value.length > 0 ? "" : "Please select a file"
                    }
                  />
                  <Field
                    id={"dentalFile"}
                    name={"dentalFile"}
                    label={"Select Dental and Vision File"}
                    component={FormUploadWithoutDZ}
                    disabled={isSyncInProgress || isSyncCompleted}
                    restrictions={{
                      allowedExtensions: [".xlsx"],
                    }}
                    validator={(value) =>
                      value && value.length > 0 ? "" : "Please select a file"
                    }
                  />
                  <RenderStatusBanner />
                  <div
                    style={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {(isInProgress || isSyncInProgress) && (
                      <>
                        <Button
                          type={"submit"}
                          themeColor={"success"}
                          disabled={
                            !formRenderProps.allowSubmit || isSyncInProgress
                          }
                        >
                          Upload Files{" "}
                          {isSyncInProgress && (
                            <span className="k-icon k-i-loading"></span>
                          )}
                        </Button>
                        <Button
                          themeColor={"warning"}
                          type={"button"}
                          onClick={() => handleContinue(true)}
                          style={{ marginLeft: "10px" }}
                        >
                          Skip & Continue
                        </Button>
                      </>
                    )}
                    {isSyncError && (
                      <>
                        <Button
                          type={"submit"}
                          themeColor={"error"}
                          disabled={!formRenderProps.allowSubmit}
                        >
                          Retry Upload
                        </Button>
                        <Button
                          themeColor={"warning"}
                          type={"button"}
                          onClick={() => handleContinue(false)}
                          style={{ marginLeft: "10px" }}
                        >
                          Skip & Continue
                        </Button>
                      </>
                    )}
                    {isSyncCompleted && (
                      <Button
                        themeColor={"success"}
                        type={"button"}
                        onClick={() => handleContinue(false)}
                        disabled={isStepCompleted}
                      >
                        Continue to Next Step
                      </Button>
                    )}
                  </div>
                </FormElement>
              )}
            />
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default UploadPlanDetailsPanel;
