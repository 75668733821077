import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { formatDate } from "@telerik/kendo-intl";
import { Switch } from "@progress/kendo-react-inputs";

import {
  useQueryGetOEPeriodList,
  useMutateDeleteOEPeriod,
} from "../../data/services/openEnrollmentService";

import DeleteDialog from "../../components/DeleteDialog";
import ErrorBanner from "../../components/ErrorBanner";
import AddEditOEModel from "./AddEditOEModel";
import ConfirmPublishOEModel from "./ConfirmPublishOEModel";

import {
  useMutatePostEnableMaintenance,
} from "../../data/services/clientAdminService";

import ConfirmationDialog from "../../components/ConfirmationDialog";

const OpenEnrollmentList = () => {
  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [editItem, setEditItem] = React.useState();

  const [showPublishModel, setShowPublishModel] = React.useState(false);
  const [publishItem, setPublishItem] = React.useState();
  const [isPublishClicked, setIsPublishClicked] = React.useState(false);

  const [oePeriodListSrc, setOEPeriodListSrc] = React.useState([]);
  const [oePeriodMasterList, setOEPeriodMasterList] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: oePeriodListLoading, data: oePeriodList } =
    useQueryGetOEPeriodList();
  const useMutateDeleteOEPeriodHandler = useMutateDeleteOEPeriod();

  React.useEffect(() => {
    if (!oePeriodListLoading && oePeriodList && oePeriodList.data) {
      let masterList = oePeriodList.data.map((e) => {
        e.effectiveDate = new Date(e.effectiveDate);
        return e;
      });

      setOEPeriodMasterList(masterList);
      setOEPeriodListSrc(process(masterList.slice(0), gridDataState));
    }
  }, [oePeriodListLoading, oePeriodList, gridDataState]);

  const handleCancelEdit = () => {
    setOpenEditForm(false);
  };

  const handleSubmit = () => {
    setOpenEditForm(false);
  };

  const handleOpenEditWindow = (item) => {
    setEditItem(item);
    setOpenEditForm(true);
  };

  const handleOpenDeleteWindow = (item) => {
    setEditItem(item);
    setShowDeleteDialog(true);
  };

  const handleOpenAddWindow = (item) => {
    setEditItem({});
    setOpenEditForm(true);
  };

  const handleDelete = async (item) => {
    await useMutateDeleteOEPeriodHandler.mutateAsync(item);

    setShowDeleteDialog(false);
  };

  const handlePublishClick = (item) => {
    setShowPublishModel(true);
    setPublishItem(item);
    setIsPublishClicked(true);
  };

  const handleUnPublishClick = (item) => {
    setShowPublishModel(true);
    setPublishItem(item);
    setIsPublishClicked(false);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="primary"
            onClick={() => handleOpenEditWindow(props.dataItem)}
          >
            Edit
          </Button>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            onClick={() => handleOpenDeleteWindow(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const publishCell = (props) => {
      return (
        <td>
          <span>{props.dataItem.status}</span>
          {props.dataItem.status === "Unpublished" && (
            <Button
              themeColor="success"
              style={{ marginLeft: 10 }}
              onClick={() => handlePublishClick(props.dataItem)}
            >
              Publish
            </Button>
          )}
          {props.dataItem.status === "Published" && (
            <Button
              themeColor="warning"
              style={{ marginLeft: 10 }}
              onClick={() => handleUnPublishClick(props.dataItem)}
            >
              Unpublish
            </Button>
          )}
        </td>
      );
    };

    const dateCell = (props) => {
      return <td>{formatDate(new Date(props.dataItem[props.field]), "d")}</td>;
    };

    const dataStateChange = (event) => {
      setOEPeriodListSrc(process(oePeriodMasterList.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={oePeriodListSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="oePeriodName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("oePeriodName")}
          title="Name"
        />
        <Column
          field="effectiveDate"
          filter="date"
          cell={dateCell}
          columnMenu={ColumnMenu}
          headerClassName={columnMenuHeader("effectiveDate")}
          title="Effective Date"
        />
        <Column
          field="status"
          filter="text"
          cell={publishCell}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("status")}
          title="Publish Status"
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          hideSecondFilter={true}
        />
      </div>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={oePeriodMasterList}
          expanded={true}
        />
      </div>
    );
  };


  const [showSubmitConfirmModel, setShowSubmitConfirmModel] =
    React.useState(false);
  const [toggleMaintenanceWindow, setToggleMaintenanceWindow] =
    React.useState(true);
  const useMutatePostEnableMaintenanceHandler =
    useMutatePostEnableMaintenance();

  const toggleSwitchHandler = (event) => {
    var blockedFlag = event.target.value;
    setShowSubmitConfirmModel(true);
    setToggleMaintenanceWindow(blockedFlag);
  };

  const temporaryPortalBlockHandler = async () => {
    var entity = { isPortalBlocked: !toggleMaintenanceWindow };
    await useMutatePostEnableMaintenanceHandler.mutateAsync(entity);
    setShowSubmitConfirmModel(false);
  };

  const onCancelHandler = () => {
    setShowSubmitConfirmModel(false);
    setToggleMaintenanceWindow(!toggleMaintenanceWindow);
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px", marginLeft: 0 }}>Open Enrollment Periods</h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button onClick={handleOpenAddWindow} themeColor={"primary"}>
            Add New
          </Button>
        </div>
      </div>
      <div style={{ paddingBottom: "20px" }}>
        When the Open Enrollment Period is published, the client contact will be
        able to see that Open Enrollment Renewal Grid. Only one Open Enrollment
        Period can be published at a time. System will automatically publish the
        Open Enrollment Period when sending the email notification to the client
        contact.
      </div>
      <div style={{ paddingBottom: 5 }}>
          <span>
            <span style={{ paddingTop: "6px", paddingRight: "10px", paddingBottom: 5 }}>
              Public Access:
            </span>
            <Switch
              style={{ paddingRight: 20 }}
              onChange={toggleSwitchHandler}
              checked={toggleMaintenanceWindow}
            />
          </span>

        </div>
      <ErrorBanner
        handler={useMutateDeleteOEPeriodHandler}
        onError={() => setShowDeleteDialog(false)}
      />

      {showSubmitConfirmModel && (
        <ConfirmationDialog
          title={"Please confirm"}
          message={toggleMaintenanceWindow ? "Are you sure you want to disable maintenance window?" : "Are you sure you want to enable maintenance window?"}
          onCancel={() => onCancelHandler()}
          onConfirm={() => temporaryPortalBlockHandler()}
        />
      )}

      <RenderGrid />
      {openEditForm && (
        <AddEditOEModel
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onDelete={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
          item={editItem}
        />
      )}
      {showPublishModel && (
        <ConfirmPublishOEModel
          onClose={() => setShowPublishModel(false)}
          item={publishItem}
          isPublish={isPublishClicked}
        />
      )}
    </div>
  );
};

export default OpenEnrollmentList;
