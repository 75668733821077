import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ErrorBanner from "../../components/ErrorBanner";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import {
  useMutateCreateNotificationWaveClient,
  useQueryGetNotificationWaveAvailableClients,
} from "../../data/services/notificationService";

const AddWaveClientModel = ({ onSubmit, onClose, oePeriod, currentWave }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [clientsDataSource, setClientsDataSource] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);

  const { isLoading: availableClientsLoading, data: availableClients } =
    useQueryGetNotificationWaveAvailableClients(oePeriod?.oePeriodId);
  const useMutateCreateNotificationWaveClientHandler =
    useMutateCreateNotificationWaveClient();

  React.useEffect(() => {
    if (!availableClientsLoading && availableClients && availableClients.data) {
      let tempAvailableClients = availableClients.data.map((client) => {
        client.clientDispName = `${client.clientId} - ${client.clientName}`;
        return client;
      });

      if (tempAvailableClients.length > 0) {
        let clients = [
          { clientId: "@All", clientDispName: "All Clients" },
          ...tempAvailableClients,
        ];
        setClients(clients);
        setClientsDataSource(clients.slice());
      } else {
        setClients([]);
        setClientsDataSource([]);
      }
    }
  }, [availableClientsLoading, availableClients]);

  const handleSubmit = async () => {
    var data = {
      oePeriodId: oePeriod.oePeriodId,
      notificationWaveId: currentWave.notificationWaveId,
      clientIds: selectedValues.map((e) => e.clientId),
    };

    setSubmitted(true);

    await useMutateCreateNotificationWaveClientHandler.mutateAsync(data);

    setSubmitted(false);
    onSubmit();
  };

  const handleChange = (event) => {
    const currentSelectAll = selectedValues.some((i) => i.clientId === "@All");
    const nextSelectAll = event.value.some((i) => i.clientId === "@All");
    let value = event.value;
    const currentCount = selectedValues.length;
    const nextCount = value.length;

    if (
      nextCount > currentCount &&
      !currentSelectAll &&
      !nextSelectAll &&
      clients.length - 1 === nextCount
    ) {
      value = clients;
    } else if (
      nextCount < currentCount &&
      currentCount === clients.length &&
      currentSelectAll &&
      nextSelectAll
    ) {
      value = value.filter((v) => v.clientId !== "@All");
    } else if (!currentSelectAll && nextSelectAll) {
      value = clients;
    } else if (currentSelectAll && !nextSelectAll) {
      value = [];
    }

    setSelectedValues(value);
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const filterChange = (event) => {
    setClientsDataSource(filterBy(clients.slice(), event.filter));
  };

  const selected = selectedValues.length;

  return (
    <Dialog
      title={"Select clients to add to Wave"}
      onClose={onClose}
      width={600}
    >
      <ErrorBanner
        handler={useMutateCreateNotificationWaveClientHandler}
        onError={() => setSubmitted(false)}
      />
      {clients && clients.length > 0 && (
        <>
          <p>
            Select clients to be added to the <b>{currentWave.name}</b>
          </p>
          <MultiSelect
            data={clientsDataSource}
            itemRender={itemRender}
            textField={"clientDispName"}
            dataItemKey={"clientId"}
            autoClose={false}
            value={selectedValues}
            onChange={handleChange}
            filterable={true}
            onFilterChange={filterChange}
            tags={
              selected > 0
                ? [
                    {
                      text: `${selected} clients selected`,
                      data: [...selectedValues],
                    },
                  ]
                : []
            }
          />
        </>
      )}
      {(!clients || clients.length === 0) && !availableClientsLoading && (
        <p>
          No clients available to add to the <b>{currentWave.name}</b>. Please
          make sure Open Enrollment workflow is in Notification step if you are
          not seeing clients
        </p>
      )}
      <div style={{ paddingTop: 20 }}></div>
      <DialogActionsBar layout={"end"}>
        <Button onClick={onClose}>{"Cancel"}</Button>
        <Button
          type={"submit"}
          themeColor={"success"}
          disabled={
            submitted ||
            !clients ||
            clients.length === 0 ||
            selectedValues.length === 0
          }
          onClick={handleSubmit}
        >
          {"Add Clients"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default AddWaveClientModel;
