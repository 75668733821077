import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosGet,
  axiosFormPost,
  axiosDelete,
  axiosPost,
  axiosDownload,
  reactQueryDefaultOptions,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetImportList = (oePeriodId) => {
  const getList = async (oePeriodId) => {
    if (oePeriodId) {
      return await axiosGet(SERVICE_URLS.IMPORT_LIST(oePeriodId));
    } else {
      return await axiosGet(SERVICE_URLS.IMPORT_LIST_All);
    }
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_LIST, oePeriodId],
    () => getList(oePeriodId),
    {
      refetchInterval: 30000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
};

export const useQueryGetImportSummaryList = (oePeriodId) => {
  const getList = async (oePeriodId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_SUMMARY_LIST(oePeriodId));
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 60000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.IMPORT_SUMMARY_LIST, oePeriodId],
    () => getList(oePeriodId),
    option
  );
};

export const useQueryGetImportItem = (importId) => {
  const getItem = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_ITEM(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_ITEM, importId],
    () => getItem(importId),
    reactQueryDefaultOptions
  );
};

export const useMutatePostRetryMapping = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.IMPORT_RETRY_MAPPING_POST(data.importId),
      data.content
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_ITEM,
        variables.importId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_PLANMAPPING_LIST,
        variables.importId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_RATEGROUPMAPPING_LIST,
        variables.importId,
      ]);
    },
  });
};

export const useMutatePostApproveImportRate = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.IMPORT_APPROVE_RATES_POST(data.importId),
      data.content
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_ITEM,
        variables.importId,
      ]);
    },
  });
};

export const useMutatePostSubmitImportRate = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.IMPORT_SUBMIT_RATES_POST(data.importId),
      data.content
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_ITEM,
        variables.importId,
      ]);
    },
  });
};

export const useQueryGetImportErrors = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_ERRORS(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_ERRORS, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportSubmitErrors = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_SUBMIT_ERRORS(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_SUBMIT_ERRORS, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportRates = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_RATES(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_RATES, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportFinalRates = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_FINAL_RATES_LIST(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_FINAL_RATES, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryImportFinalRatesExport = (data) => {
  const getList = async (data) => {
    return await axiosDownload(
      SERVICE_URLS.IMPORT_FINAL_RATES_EXPORT(data.importId),
      data.fileName
    );
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_FINAL_RATES_EXPORT, data.importId],
    () => getList(data),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportPlanMapping = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_PLANMAPPING_LIST(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_PLANMAPPING_LIST, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportRateGroupMapping = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_RATEGROUPMAPPING_LIST(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_RATEGROUPMAPPING_LIST, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportCosts = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_COSTS_LIST(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_COSTS_LIST, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useQueryGetImportPlanGroups = (importId) => {
  const getList = async (importId) => {
    return await axiosGet(SERVICE_URLS.IMPORT_PLANGROUPS_LIST(importId));
  };
  return useQuery(
    [QUERY_KEYS.IMPORT_PLANGROUPS_LIST, importId],
    () => getList(importId),
    reactQueryDefaultOptions
  );
};

export const useMutatePostImportCost = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.IMPORT_COSTS_POST(data.importId),
      data.content
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_COSTS_LIST,
        variables.importId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_ITEM,
        variables.importId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_FINAL_RATES,
        variables.importId,
      ]);
    },
  });
};

export const useMutateDeleteImportCost = () => {
  const deleteData = async (data) => {
    return await axiosDelete(
      SERVICE_URLS.IMPORT_COSTS_DELETE(data.importId),
      data.content
    );
  };

  var queryClient = useQueryClient();

  return useMutation(deleteData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_COSTS_LIST,
        variables.importId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_ITEM,
        variables.importId,
      ]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_FINAL_RATES,
        variables.importId,
      ]);
    },
  });
};

export const useMutateQueueImport = () => {
  const queueImport = async (data) => {
    var formData = new FormData();
    formData.append("data", data.file, data.fileName);
    formData.append("OEPeriodId", data.oePeriodId);
    formData.append("CarrierMappingId", data.carrierMappingId);
    formData.append("FileType", data.fileType);

    return await axiosFormPost(SERVICE_URLS.IMPORT_QUEUE, formData);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_LIST,
        variables.oePeriodId,
      ]);
      queryClient.invalidateQueries([QUERY_KEYS.IMPORT_LIST]);
      queryClient.invalidateQueries([
        QUERY_KEYS.IMPORT_SUMMARY_LIST,
        variables.oePeriodId,
      ]);
    },
  });
};
