import * as React from "react";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartLegend,
  ChartSeriesLabels,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Button } from "@progress/kendo-react-buttons";
import "hammerjs";
import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import {
  ImportStatusColorMapping,
  NotificationStatusColorMapping,
  SubmissionStatusColorMapping,
} from "./chartColorMapper";
import { Loader } from "@progress/kendo-react-indicators";
import OEContext from "../../layouts/OEContext";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";
import { axiosDownload } from "../../data/helpers/baseApi";
import { useQueryGetDashboardSummary } from "../../data/services/workflowService";
import { useNavigate } from "react-router-dom"
import { useQueryGetUser } from "../../data/services/authService";

const AdminDashboard = () => {
  const oePeriod = React.useContext(OEContext);
  const navigate = useNavigate();

  const [dashSummary, setDashSummary] = React.useState({});
  const [downloading, setIsDownloading] = React.useState(false);
  const { data: userData, isLoading: userDataLoading } = useQueryGetUser();

  const { isLoading: summaryLoading, data: summaryData } =
    useQueryGetDashboardSummary(oePeriod?.oePeriodId);

    const handleExportButton = async () => {
      setIsDownloading(true);
      let fileName = `PrestigePEO Client Summery.xlsx`;
      await axiosDownload(
        SERVICE_URLS.OE_WORKFLOW_DAHSBAORD_EXPORT(oePeriod?.oePeriodId),
        fileName
      );
      setIsDownloading(false);
    }

  React.useEffect(() => {
    if (!summaryLoading && summaryData && summaryData.data) {
      setDashSummary(summaryData.data);
    }
  }, [summaryLoading, summaryData]);

  React.useEffect(() => {
    if (userData && !userData.data.isWhiteListed) {
      navigate("/under-maintenance");
    }
  }, [userData]);
  

  const RenderChart = ({ data, colorCodes }) => {
    const labelContent = (e) => `${e.category}: ${e.value}`;

    const renderTooltip = (context) => {
      const { category, value } = context.point || context;
      return (
        <div>
          {category} : {value}
        </div>
      );
    };

    return (
      <Chart>
        <ChartTooltip render={renderTooltip} />
        <ChartSeries>
          <ChartSeriesItem
            type="donut"
            data={data}
            categoryField="label"
            field="count"
            color={(data) => colorCodes[data?.category]}
          >
            <ChartSeriesLabels
              position="outsideEnd"
              background="none"
              content={labelContent}
            />
          </ChartSeriesItem>
        </ChartSeries>
        <ChartLegend position="bottom" orientation="horizontal" />
      </Chart>
    );
  };

  if (
    !dashSummary.notificationStatus ||
    dashSummary.notificationStatus.length === 0
  ) {
    return (
      <div>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <Card>
            <CardBody>
              <CardTitle style={{ textAlign: "center" }}>
                <b>Carrier Import Status</b>
              </CardTitle>
              <RenderChart
                data={dashSummary.importStatus}
                colorCodes={ImportStatusColorMapping}
              />
            </CardBody>
          </Card>
        </div>
        <div style={{ padding: "10px", fontStyle: "italic" }}>
          Once Client data sync is completed, notification and model submission
          status will be shown.
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row', display: 'flex', margin: '20px' }}>
        <Button onClick={handleExportButton} disabled={downloading} themeColor={"primary"}  >Export to Excel
        {downloading ?  <Loader style={{marginBottom:2,marginLeft:5}} size="small" type={'infinite-spinner'} themeColor="light"/> : null}
       
        </Button>
      </div>
      <div style={{ display: "flex", flexFlow: "row", marginTop: "10px" }}>

        <div style={{ width: "100%", margin: "10px" }}>
          <Card>
            <CardBody>
              <CardTitle style={{ textAlign: "center" }}>
                <b>Carrier Import Status</b>
              </CardTitle>
              <RenderChart
                data={dashSummary.importStatus}
                colorCodes={ImportStatusColorMapping}
              />
            </CardBody>
          </Card>
        </div>
        <div style={{ width: "100%", margin: "10px" }}>
          <Card>
            <CardBody>
              <CardTitle style={{ textAlign: "center" }}>
                <b>Notification Progress</b>
              </CardTitle>
              <RenderChart
                data={dashSummary.notificationStatus}
                colorCodes={NotificationStatusColorMapping}
              />
            </CardBody>
          </Card>
        </div>
        <div style={{ width: "100%", margin: "10px" }}>
          <Card>
            <CardBody>
              <CardTitle style={{ textAlign: "center" }}>
                <b>Model Submission Status</b>
              </CardTitle>
              <RenderChart
                data={dashSummary.sumbissionStatus}
                colorCodes={SubmissionStatusColorMapping}
              />
            </CardBody>
          </Card>
        </div>
      </div></>
  );
};

export default AdminDashboard;
