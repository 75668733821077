import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleDot } from "@fortawesome/free-solid-svg-icons";

export const getPanelSubtitle = (stepStatus) => {
  if (!stepStatus) {
    return "Not Started";
  }

  if (stepStatus.status === "NotStarted") {
    return "Not Started";
  } else if (stepStatus.status === "Completed") {
    if (stepStatus.isSkipped) {
      return (
        <span>
          Skipped{" "}
          <FontAwesomeIcon
            icon={faCircleDot}
            style={{ color: "#721c24", marginLeft: "10px" }}
          />
        </span>
      );
    } else {
      return (
        <span>
          Completed{" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "darkgreen", marginLeft: "10px" }}
          />
        </span>
      );
    }
  } else {
    return "In Progress";
  }
};
