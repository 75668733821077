import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import LoadingIndicator from "../../components/LoadingIndicator";
import { process } from "@progress/kendo-data-query";

import { ImportSuccess } from "./ImportStatusHelper";

import { useQueryGetImportRates } from "../../data/services/importService";

const ImportRatesGrid = ({ id, importItem, onNextClick }) => {
  const [importRatesSrc, setimportRatesSrc] = React.useState([]);
  const [importGridDataState, setImportGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const [nextEnabled, setNextEnabled] = React.useState(false);

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: importRatesLoading, data: importRates } =
    useQueryGetImportRates(id);

  React.useEffect(() => {
    if (!importRatesLoading && importRates && importRates.data) {
      setimportRatesSrc(
        process(importRates.data.slice(0), importGridDataState)
      );
    }

    if (importItem && ImportSuccess(importItem.importStatus)) {
      setNextEnabled(true);
    }
  }, [importRatesLoading, importRates, importGridDataState, importItem]);

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setimportRatesSrc(process(importRates.data.slice(0), event.dataState));
      setImportGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(
        field,
        importGridDataState.filter
      );
      return active ? "active" : "";
    };

    return (
      <Grid
        pageable={pagerSetting}
        dataItemKey={"importBPRatesId"}
        pageSize={20}
        sortable={true}
        data={importRatesSrc}
        {...importGridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="planGroup"
          title="Benefit Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planGroup")}
        />
        <Column
          field="planName"
          title="Plan Name"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planName")}
        />
        <Column
          field="planTypeCode"
          title="Plan Type"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planTypeCode")}
        />
        <Column
          field="rateFactorName"
          title="Rate Factor Name"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("rateFactorName")}
        />
        <Column
          field="rateFactor"
          title="Rate Factor"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("rateFactor")}
        />
        <Column
          field="rate"
          title="Rate"
          format="{0:c}"
          className="k-text-right"
        />
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={importRates.data}
          expanded={true}
        />
      </div>
    );
  };

  if (importRatesLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div></div>
        <div>
          <h3 style={{ marginTop: 30 }}>Rates from Import File</h3>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"primary"}
            onClick={onNextClick}
            iconClass="k-icon k-i-arrow-right"
            disabled={!nextEnabled}
          >
            Plan Mapping
          </Button>
        </div>
      </div>
      <RenderGrid />
    </div>
  );
};

export default ImportRatesGrid;
