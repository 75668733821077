import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  reactQueryDefaultOptions,
  axiosGet,
  axiosFormPost,
  axiosPost,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetClientContacts = (clientId) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.CLIENT_ADMIN_CONTACT_LIST(clientId));
  };
  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_CONTACT_LIST, clientId],
    getData,
    reactQueryDefaultOptions
  );
};

export const useQueryGetClientsMasterList = () => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.CLIENT_MASTER_LIST);
  };
  return useQuery(
    [QUERY_KEYS.CLIENT_MASTER_LIST],
    getData,
    reactQueryDefaultOptions
  );
};

export const useQueryGetClientBenefitGroups = (clientId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_BENEFITGROUOP_LIST(clientId)
    );
  };
  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_BENEFITGROUP_LIST, clientId],
    getData,
    reactQueryDefaultOptions
  );
};

export const useQueryGetClientBenefitPlans = (clientId, oePeriodId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_BENEFITPLANS_LIST(clientId, oePeriodId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_BENEFITPLAN_LIST, clientId, oePeriodId],
    getData,
    option
  );
};

export const useQueryGetClientBenefitRules = (clientId, planId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_BENEFITRULES_LIST(clientId, planId)
    );
  };
  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_BENEFITRULES_GET, clientId, planId],
    getData,
    reactQueryDefaultOptions
  );
};

export const useQueryGetClientNotificationHistory = (oePeriodId, clientId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_NOTIFICATION_HISTORY(oePeriodId, clientId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_NOTIFICATION_HISTORY, oePeriodId, clientId],
    getData,
    option
  );
};

export const useQueryGetClientMenuDocuments = (oePeriodId, clientId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_MENU_DOCUMENT(oePeriodId, clientId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_MENU_DOCUMENT, oePeriodId, clientId],
    getData,
    option
  );
};

export const useQueryGetAllClientMenuAll = (oePeriodId, clientId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_MENU_ALL(oePeriodId, clientId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_MENU_All, oePeriodId, clientId],
    getData,
    option
  );
};

export const useQueryGetEmpClientMenuDocuments = (oePeriodId, clientId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_EMP_MENU_DOCUMENT(oePeriodId, clientId)
    );
  };

  let enabled = false;
  if (oePeriodId && clientId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_EMP_MENU_DOCUMENT, oePeriodId, clientId],
    getData,
    option
  );
};

export const useMutateUploadUserGuide = () => {
  const postData = async (data) => {
    var formData = new FormData();
    formData.append("data", data.file, data.fileName);

    return await axiosFormPost(SERVICE_URLS.USER_GUIDE_DOWNLOAD, formData);
  };

  return useMutation(postData, {
    onSuccess: () => {},
  });
};

export const useMutatePostEnableMaintenance = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.CLIENT_ADMIN_MAINTENANCE(), data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CLIENT_ADMIN_MAINTENANCE]);
    },
  });
};


export const useQueryGetLatestMaintenanceLog = () => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.CLIENT_ADMIN_GET_LATEST_MAINTENANCE()
    );
  };

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: true,
  };

  return useQuery(
    [QUERY_KEYS.CLIENT_ADMIN_GET_LATEST_MAINTENANCE],
    getData,
    option
  );
};