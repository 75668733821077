import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const AlertDialog = (props) => {
  return (
    <Dialog title={props.title} onClose={props.onClose}>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        {props.message}
      </p>
      <DialogActionsBar layout={"end"}>
        <Button type="submit" themeColor={"primary"} onClick={props.onClose}>
          {"Close"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default AlertDialog;
