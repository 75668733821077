import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useQueryGetOEPeriodList } from "../data/services/openEnrollmentService";

const OEPeriodSelector = (props) => {
  const [oePeriodListSrc, setOePeriodListSrc] = React.useState([]);
  const [selectedOEPeriod, setSelectedOEPeriod] = React.useState(null);

  const { isLoading: oePeriodsLoading, data: oePeriods } =
    useQueryGetOEPeriodList();

  React.useEffect(() => {
    if (
      !oePeriodsLoading &&
      oePeriods &&
      oePeriods.data &&
      oePeriods.data.length > 0
    ) {
      setOePeriodListSrc(oePeriods.data);

      let oePeriod = localStorage.getItem("OEPeriod");

      let found = oePeriods.data.find((e) => e.oePeriodId == oePeriod);
      if (found) {
        setSelectedOEPeriod(found);
        props.oePeriodChange(found);
      } else {
        let first = oePeriods.data[0];
        setSelectedOEPeriod(first);
        props.oePeriodChange(first);
        localStorage.setItem("OEPeriod", first.oePeriodId);
      }
    }
  }, [oePeriodsLoading, oePeriods, props]);

  const handleChange = (event) => {
    setSelectedOEPeriod(event.target.value);
    props.oePeriodChange(event.target.value);
    localStorage.setItem("OEPeriod", event.target.value.oePeriodId);
  };

  return (
    <div
      style={{
        height: "30px",
        backgroundColor: "#E3E5EA",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <span
        className="k-icon k-i-menu"
        style={{ marginLeft: 10, fontSize: 20, cursor: "pointer" }}
        onClick={props.menuIconCLick}
      />
      <span style={{ float: "right", paddingRight: "20px" }}>
        OE Period:{" "}
        <DropDownList
          style={{
            width: "250px",
          }}
          fillMode={"outline"}
          data={oePeriodListSrc}
          textField="oePeriodName"
          dataItemKey="oePeriodId"
          value={selectedOEPeriod}
          onChange={handleChange}
          loading={oePeriodsLoading}
        />
      </span>
    </div>
  );
};

export default OEPeriodSelector;
