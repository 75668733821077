import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";

import { useQueryGetImportList } from "../../data/services/importService";
import AddImportModel from "./AddImportModel";
import ImportErrorModel from "./ImportErrorModel";
import OEContext from "../../layouts/OEContext";
import { IsImportAutoStepsComplete, ImportStatus } from "./ImportStatusHelper";

const RateImport = () => {
  const navigate = useNavigate();
  const oePeriod = React.useContext(OEContext);

  const [visible, setVisible] = React.useState(false);
  const [errorModelVisible, setErrorModelVisible] = React.useState(false);
  const [importId, setImportId] = React.useState(0);
  const [importListSrc, setImportListSrc] = React.useState([]);
  const [importGridDataState, setImportGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200],
    previousNext: true,
  };

  const { isLoading: importListLoading, data: importList } =
    useQueryGetImportList(oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (!importListLoading && importList && importList.data) {
      setImportListSrc(process(importList.data.slice(0), importGridDataState));
    }
  }, [importListLoading, importList, importGridDataState]);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const toggleErrorDialog = (importFileQueueId) => {
    setErrorModelVisible(!errorModelVisible);
    setImportId(importFileQueueId);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      let isAutoComplete = IsImportAutoStepsComplete(
        props.dataItem.importStatus
      );
      if (props.dataItem.importStatus === ImportStatus.ParsingError) {
        return (
          <td>
            <Button
              onClick={() => {
                toggleErrorDialog(props.dataItem.importFileQueueId);
              }}
              fillMode="flat"
              themeColor="error"
            >
              View Error Details
            </Button>
          </td>
        );
      } else if (!isAutoComplete) {
        return (
          <td style={{ paddingLeft: "40px" }}>
            <span className="k-icon k-i-loading"></span>
          </td>
        );
      } else {
        return (
          <td>
            <Button
              onClick={() => {
                navigate(
                  `/admin/rateimportsummary/${props.dataItem.importFileQueueId}/grid`
                );
              }}
              fillMode="flat"
              themeColor="success"
            >
              View Data
            </Button>
          </td>
        );
      }
    };

    const dateCell = (props) => {
      //https://github.com/telerik/kendo-intl/blob/develop/docs/date-formatting/index.md
      return (
        <td>{formatDate(new Date(props.dataItem.dateCreated + "Z"), "G")}</td>
      );
    };

    const dataStateChange = (event) => {
      setImportListSrc(process(importList.data.slice(0), event.dataState));
      setImportGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(
        field,
        importGridDataState.filter
      );
      return active ? "active" : "";
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={importListSrc}
        {...importGridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="carrier.carrierName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("carrier.carrierName")}
          title="Carrier Name"
          width={130}
        />
        <Column
          field="carrierType.carrierTypeDesc"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("carrier.carrierTypeDesc")}
          title="Type"
          width={130}
        />
        <Column
          field="fileName"
          filter="text"
          columnMenu={ColumnMenu}
          headerClassName={columnMenuHeader("fileName")}
          title="Filename"
        />
        <Column
          field="dateCreated"
          filter="date"
          cell={dateCell}
          columnMenu={ColumnMenu}
          headerClassName={columnMenuHeader("dateCreated")}
          title="Date Created"
          width={180}
        />
        <Column
          field="importStatusDesc"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("importStatusDesc")}
          title="Status"
          width={180}
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          width={180}
          sortable={false}
        />
      </Grid>
    );
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          hideSecondFilter={true}
        />
      </div>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={importList.data}
          expanded={true}
        />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Carrier Rate Import</h2>
        </div>
      </div>
      <div
        style={{
          justifyContent: "space-around",
          paddingTop: "40px",
          paddingBottom: "60px",
          display: "flex",
        }}
      >
        <Button
          onClick={toggleDialog}
          themeColor={"primary"}
          disabled={!oePeriod}
          size={"large"}
          iconClass="k-icon k-i-upload"
        >
          Import New File
        </Button>
      </div>
      <RenderGrid />
      {visible && (
        <AddImportModel
          visible={visible}
          toggleDialog={toggleDialog}
          oePeriod={oePeriod}
        />
      )}
      {errorModelVisible && (
        <ImportErrorModel
          visible={errorModelVisible}
          toggleDialog={toggleErrorDialog}
          importId={importId}
        />
      )}
    </div>
  );
};

export default RateImport;
