export const ImportStatusColorMapping = {
  "Not Started": "#B5B5AC",
  "In Progress": "#FFA500",
  Submitted: "#008000",
};

export const NotificationStatusColorMapping = {
  "Not Sent": "#B5B5AC",
  Sent: "#008000",
};

export const SubmissionStatusColorMapping = {
  NotStarted: "#B5B5AC",
  InProgress: "#FFA500",
  Submitted: "#008000",
  PublishInProgress: "#77ddaa",
  PublishError: "#FF0000",
  Published: "#023020",
};
