import * as React from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { Switch } from "@progress/kendo-react-inputs";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import OEPeriodSelector from "./OEPeriodSelector";
import { Button } from "@progress/kendo-react-buttons";
import ErrorBoundary from "../components/ErrorBoundary";
import UserContext from "./UserContext";

import { useQueryGetUser, useMutateLogout } from "../data/services/authService";

import OEContext from "./OEContext";
import LoadingIndicator from "../components/LoadingIndicator";
import { PRIVILEGE_TYPE } from "../constants/index";
import {
  useMutatePostEnableMaintenance,
  useQueryGetLatestMaintenanceLog,
} from "../data/services/clientAdminService";

const AdminLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoggedInUser } = React.useContext(UserContext);
  const { isLoading: maintenanceLogLoading, data: maintenanceLog } =
    useQueryGetLatestMaintenanceLog();

  const avatarCompRef = React.useRef(null);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [toggleMaintenanceWindow, setToggleMaintenanceWindow] =
    React.useState(true);
  const [items, setItems] = React.useState([
    {
      text: "Dashboard",
      id: 1,
      selected: true,
      route: "/admin/dashboard",
    },
    {
      separator: true,
    },
    {
      text: "Workflows",
      id: 6,
      route: "/admin/workflows",
    },
    {
      separator: true,
    },
    {
      text: "Carrier Administration",
      id: 2,
      isExpanded: false,
    },
    {
      text: "Rate Import Summary",
      id: 21,
      parentId: 2,
      route: "/admin/rateimportsummary",
    },
    {
      text: "Import Rate File",
      id: 22,
      parentId: 2,
      route: "/admin/importrate",
    },
    {
      text: "Benefit Plans",
      id: 23,
      parentId: 2,
      route: "/admin/benefitplans",
    },
    {
      text: "System Menu",
      id: 24,
      parentId: 2,
      route: "/admin/systemmenu",
    },
    {
      separator: true,
    },
    {
      text: "Send Notifications",
      isExpanded: false,
      id: 3,
    },
    {
      text: "Waves",
      id: 31,
      parentId: 3,
      route: "/admin/notificationwaves",
    },
    {
      separator: true,
    },
    {
      text: "Client Administration",
      isExpanded: false,
      id: 4,
    },
    {
      text: "Assigned Clients",
      id: 41,
      parentId: 4,
      route: "/admin/clientadminsummary",
    },
    {
      text: "Data Sync",
      id: 42,
      parentId: 4,
      route: "/admin/datasync",
    },
    {
      separator: true,
    },
    {
      text: "Brokers",
      isExpanded: false,
      id: 7,
    },
    {
      text: "Broker Users",
      id: 71,
      parentId: 7,
      route: "/admin/brokers",
    },
    {
      separator: true,
    },
    {
      text: "Setup",
      isExpanded: false,
      id: 5,
    },
    {
      text: "Open Enrollment",
      id: 51,
      parentId: 5,
      route: "/admin/openenrollment",
    },
    {
      text: "PrismHR Mapping",
      id: 52,
      parentId: 5,
      route: "/admin/planmapping",
    },
    {
      text: "Benefit Group Mapping",
      id: 53,
      parentId: 5,
      route: "/admin/benefitgroupmapping",
    },
    {
      text: "Carrier Setup",
      id: 54,
      parentId: 5,
      route: "/admin/carriersetup",
    },
    {
      text: "Notification Redirects",
      id: 55,
      parentId: 5,
      route: "/admin/notificationredirect",
    },
    {
      text: "Notification Reminders",
      id: 57,
      parentId: 5,
      route: "/admin/notificationreminders",
    },
    {
      text: "User Guide Setup",
      id: 56,
      parentId: 5,
      route: "/admin/userguidesetup",
    },
  ]);
  const [selectedOEPeriod, setSelectedOEPeriod] = React.useState(null);

  const { data: userData, isLoading: userDataLoading } = useQueryGetUser();
  const useMutateLogoutHandler = useMutateLogout();

  React.useEffect(() => {
    if (!userDataLoading) {
      if (!userData || !userData.data || !userData.data.hasAdminAppPrivilege) {
        navigate("/autherror");
      } else {
        if (!userData.data.isWhiteListed) {
          navigate("/under-maintenance");
        } else {
          let userEntity = { ...userData.data };
          if (userEntity.privileges && userEntity.privileges.length > 0) {
            userEntity.hasDataSyncPriv = userEntity.privileges.some(
              (e) => e === PRIVILEGE_TYPE.HasDataSyncPrivilege
            );
            userEntity.hasUnlockPriv = userEntity.privileges.some(
              (e) => e === PRIVILEGE_TYPE.HasGridUnlockPrivilege
            );
            userEntity.submitContributionModalPrivilege =
              userEntity.privileges.some(
                (e) => e === PRIVILEGE_TYPE.SubmitContributionModalPrivilege
              );
            userEntity.benefitAdminPrivilege = userEntity.privileges.some(
              (e) => e === PRIVILEGE_TYPE.BenefitAdminPrivilege
            );
          }

          setUser(userEntity);
          setLoggedInUser(userEntity);
        }
      }
    }
  }, [userData, userDataLoading, navigate, setLoggedInUser]);

  React.useEffect(() => {
    if (!maintenanceLogLoading && maintenanceLog) {
      setToggleMaintenanceWindow(!maintenanceLog.data.isMaintenanceWindowEnabled);
    }
  }, [maintenanceLog, maintenanceLogLoading]);

  if (userDataLoading) {
    return <LoadingIndicator />;
  }

  const oePeriodChange = (oePeriod) => {
    setSelectedOEPeriod(oePeriod);
  };

  const CustomItem = (props) => {
    const { visible, ...others } = props;
    const arrowDir = props.isExpanded
      ? "k-i-arrow-chevron-down"
      : "k-i-arrow-chevron-right";
    const isParent = props.isExpanded !== undefined;
    const isSingle = isParent || props.parentId === undefined;

    return (
      <React.Fragment>
        {props.visible === false ? null : (
          <DrawerItem {...others}>
            {isSingle && (
              <span
                className={"k-item-text"}
                style={{ fontSize: "15px", fontWeight: "500" }}
              >
                {props.text}
              </span>
            )}
            {!isSingle && (
              <span
                className={"k-item-text"}
                style={{ paddingLeft: "20px", fontSize: "14px" }}
              >
                {props.text}
              </span>
            )}
            {isParent && (
              <span
                className={"k-icon " + arrowDir}
                style={{
                  position: "absolute",
                  right: 10,
                }}
              />
            )}
          </DrawerItem>
        )}
      </React.Fragment>
    );
  };

  const onSelect = (ev) => {
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem.isExpanded !== undefined;
    const nextExpanded = !currentItem.isExpanded;
    const newData = items.map((item) => {
      const { selected, isExpanded: currentExpanded, id, ...others } = item;
      const isCurrentItem = currentItem.id === id;
      return {
        selected: isParent ? selected : isCurrentItem,
        isExpanded: isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others,
      };
    });

    if (ev.itemTarget.props.route) {
      navigate(ev.itemTarget.props.route);
    }

    setItems(newData);
  };

  const setSelectedItemByRoute = () => {
    const pathName = location.pathname;
    var selectedItem = items.find(
      (e) => e.route === pathName || pathName.indexOf(e.route) > -1
    );
    if (selectedItem) {
      items.forEach((e) => {
        if (e.id === selectedItem.id) {
          e.selected = true;
        } else {
          e.selected = false;
        }

        return e;
      });

      if (selectedItem.parentId) {
        var parentItem = items.find((e) => e.id === selectedItem.parentId);
        if (parentItem && !parentItem.isExpanded) {
          items.forEach((e) => {
            if (e.isExpanded !== undefined) {
              e.isExpanded = false;
            }
          });

          parentItem.isExpanded = true;
        }
      }
    }
  };

  const onLogoutClick = async () => {
    await useMutateLogoutHandler.mutateAsync();
    navigate("/logout");
  };

  setSelectedItemByRoute();

  const data = items.map((item) => {
    const { parentId, ...others } = item;

    if (parentId !== undefined) {
      const parent = items.find((parent) => parent.id === parentId);
      return {
        parentId,
        ...others,
        visible: parent.isExpanded,
      };
    }

    return item;
  });

  return (
    <React.Fragment>
      <AppBar
        themeColor={"light"}
        style={{ backgroundColor: "#192853", padding: 0 }}
      >
        <AppBarSection style={{ padding: "8px" }}>
          <img
            src={"/Logo_white.svg"}
            alt={"PrestigePEO logo"}
            style={{ height: 35 }}
          />
        </AppBarSection>

        <AppBarSpacer />

        <AppBarSection>
          <div
            onClick={() => setIsProfilePopupOpen(!isProfilePopupOpen)}
            ref={avatarCompRef}
            className={
              isProfilePopupOpen ? "profile-avatar" : "profile-avatar-default"
            }
          >
            <span
              style={{
                paddingRight: "10px",
                paddingLeft: "8px",
                color: "#fff",
              }}
            >
              Welcome {user.userName}
            </span>
            <Avatar type="text" style={{ marginRight: "8px" }}>
              <span>{user.userInitial}</span>
            </Avatar>
          </div>
          <Popup
            anchor={avatarCompRef.current}
            show={isProfilePopupOpen}
            style={{ width: "180px" }}
          >
            <div style={{ padding: "10px" }}>
              <Button
                themeColor={"primary"}
                style={{ width: "100%" }}
                fillMode={"outline"}
                onClick={onLogoutClick}
              >
                {"Logout"}
              </Button>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>

      <OEPeriodSelector
        oePeriodChange={oePeriodChange}
        menuIconCLick={() => setDrawerOpen(!drawerOpen)}
      />
      <OEContext.Provider value={selectedOEPeriod}>
        <Drawer
          expanded={drawerOpen}
          mode="push"
          width={220}
          items={data}
          item={CustomItem}
          onSelect={onSelect}
        >
          <DrawerContent style={{ overflow: "auto" }}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
            <div style={{ height: "10px" }}></div>
          </DrawerContent>
        </Drawer>
      </OEContext.Provider>
    </React.Fragment>
  );
};

export default AdminLayout;
