import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { formatDate } from "@telerik/kendo-intl";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Link } from "react-router-dom";

import { getPanelSubtitle } from "./panelHelper";
import DataSyncErrorModel from "./DataSyncErrorModel";

import {
  useMutateDataSync,
  useMutateContinueWorkflow,
} from "../../../data/services/workflowService";

const SyncSystemPlanRatesPanel = ({
  expanded,
  setExpanded,
  currentStep,
  oePeriod,
  status,
  workflowSetupId,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isCompleted =
    stepStatus === "Completed" ||
    stepStatus === "SyncCompleted" ||
    stepStatus === "SyncError" ||
    stepStatus === "SyncInProgress";
  const isSyncInProgress = stepStatus === "SyncInProgress";
  const isSyncError = stepStatus === "SyncError";
  const isSyncCompleted =
    stepStatus === "SyncCompleted" || stepStatus === "Completed";
  const isInProgress = stepStatus === "InProgress";
  const isStepCompleted = stepStatus === "Completed";
  const isSkipped = status?.isSkipped || false;

  const [dentalChecked, setDentalChecked] = React.useState(false);
  const [visionChecked, setVisionChecked] = React.useState(false);

  const [showErrorModel, setShowErrorModel] = React.useState(false);

  const useMutateDataSyncHandler = useMutateDataSync();
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();

  const handleSubmit = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "AllBenefitPlans",
      workflowId: status.oeWorkflowId,
      isRetry: false,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleRetry = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "AllBenefitPlans",
      workflowId: status.oeWorkflowId,
      isRetry: true,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleContinue = async (isSkipped) => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      isSkipped: isSkipped,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  const RenderStatusBanner = () => {
    if (isSyncCompleted) {
      if (isSkipped) {
        return (
          <div
            style={{
              backgroundColor: "#fff3cd",
              padding: 10,
              borderRadius: ".25rem",
              marginTop: "20px",
            }}
          >
            <span style={{ color: "#721c24" }}>
              Data sync skipped. Please review existing billing rates in{" "}
              <Link to="/admin/benefitplans">Benefit Plans Screen</Link>
            </span>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "#d4edda",
              padding: 10,
              borderRadius: ".25rem",
              marginTop: "20px",
            }}
          >
            <span style={{ color: "#155724" }}>
              Successfully completed data sync. Please review the billing rates
              in <Link to="/admin/benefitplans">Benefit Plans Screen</Link>
            </span>
          </div>
        );
      }
    } else if (isSyncInProgress) {
      return (
        <div
          style={{
            backgroundColor: "#fff3cd",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Data sync In Progress. Please wait ...
          </span>
        </div>
      );
    } else if (isSyncError) {
      return (
        <div
          style={{
            backgroundColor: "#f8d7da",
            padding: 10,
            marginBottom: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Error syncing data.{" "}
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setShowErrorModel(true)}
            >
              View Error Details
            </span>
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Sync & Validate System Plan Rates`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              Please setup Dental and Vision plan billing rates in PrismHR.{" "}
            </p>

            <div>
              <Checkbox
                defaultChecked={isCompleted}
                disabled={isCompleted}
                label={`Verified that all Dental Plan ${formatDate(
                  new Date(oePeriod.effectiveDate),
                  "d"
                )} billing rates are setup in PrismHR`}
                onChange={(checked) => setDentalChecked(checked.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <Checkbox
                defaultChecked={isCompleted}
                disabled={isCompleted}
                label={`Verified that all Vision Plan ${formatDate(
                  new Date(oePeriod.effectiveDate),
                  "d"
                )} billing rates are setup in PrismHR`}
                onChange={(checked) => setVisionChecked(checked.value)}
              />
            </div>
            <RenderStatusBanner />
            {(isInProgress || isSyncInProgress) && (
              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  themeColor={"success"}
                  onClick={handleSubmit}
                  disabled={
                    !dentalChecked || !visionChecked || isSyncInProgress
                  }
                >
                  Sync Rates{" "}
                  {isSyncInProgress && (
                    <span className="k-icon k-i-loading"></span>
                  )}
                </Button>
                <Button
                  themeColor={"warning"}
                  onClick={() => handleContinue(true)}
                  style={{ marginLeft: "10px" }}
                  disabled={isSyncInProgress}
                >
                  Skip & Continue
                </Button>
              </div>
            )}
            {isSyncError && (
              <>
                <p>
                  Please review the synced billing rates in{" "}
                  <Link to="/admin/benefitplans">Benefit Plans Screen</Link>
                </p>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button themeColor={"error"} onClick={handleRetry}>
                    Retry Sync
                  </Button>
                  <Button
                    themeColor={"warning"}
                    onClick={() => handleContinue(false)}
                    style={{ marginLeft: "10px" }}
                  >
                    Skip & Continue
                  </Button>
                </div>
              </>
            )}
            {isSyncCompleted && (
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  themeColor={"success"}
                  onClick={() => handleContinue(false)}
                  disabled={isStepCompleted}
                >
                  Continue to Next Step
                </Button>
              </div>
            )}
            {showErrorModel && (
              <DataSyncErrorModel
                oePeriodId={oePeriod.oePeriodId}
                workflowId={status.oeWorkflowId}
                onClose={() => setShowErrorModel(false)}
              />
            )}
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default SyncSystemPlanRatesPanel;
