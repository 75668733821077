import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";

import {
  useQueryGetBenefitGroupMapping,
  useMutateDeleteBenefitGroupMapping,
} from "../../data/services/benefitPlanService";

import DeleteDialog from "../../components/DeleteDialog";
import ErrorBanner from "../../components/ErrorBanner";
import AddEditBenefitGroupModel from "./AddEditBenefitGroupModel";

const BenefitGroupMapGrid = ({ title, offerType }) => {
  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [editItem, setEditItem] = React.useState();

  const [benefitGroupMappingsList, SetBenefitGroupMappings] = React.useState(
    []
  );
  const [benefitGroupMappingsMasterData, SetBenefitGroupMappingsMaster] =
    React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: benefitGroupMappingLoading, data: benefitGroupMappings } =
    useQueryGetBenefitGroupMapping(offerType);
  const useMutateDeleteBenefitGroupMappingHandler =
    useMutateDeleteBenefitGroupMapping();

  React.useEffect(() => {
    if (
      !benefitGroupMappingLoading &&
      benefitGroupMappings &&
      benefitGroupMappings.data
    ) {
      SetBenefitGroupMappingsMaster(benefitGroupMappings.data);
      SetBenefitGroupMappings(
        process(benefitGroupMappings.data.slice(0), gridDataState)
      );
    }
  }, [benefitGroupMappingLoading, benefitGroupMappings, gridDataState]);

  const handleCancelEdit = () => {
    setOpenEditForm(false);
  };

  const handleSubmit = () => {
    setOpenEditForm(false);
  };

  const handleOpenEditWindow = (item) => {
    setEditItem(item);
    setOpenEditForm(true);
  };

  const handleOpenDeleteWindow = (item) => {
    setEditItem(item);
    setShowDeleteDialog(true);
  };

  const handleOpenAddWindow = (item) => {
    setEditItem({});
    setOpenEditForm(true);
  };

  const handleDelete = async (item) => {
    let data = {
      bpBenefitGroupMappingId: item.bpBenefitGroupMappingId,
      offerType: offerType,
    };

    await useMutateDeleteBenefitGroupMappingHandler.mutateAsync(data);

    setShowDeleteDialog(false);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="primary"
            onClick={() => handleOpenEditWindow(props.dataItem)}
          >
            Edit
          </Button>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            onClick={() => handleOpenDeleteWindow(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const dataStateChange = (event) => {
      SetBenefitGroupMappings(
        process(benefitGroupMappingsMasterData.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={benefitGroupMappingsList}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="planId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planId")}
          title="Plan Id"
        />
        <Column
          field="planName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planName")}
          title="Plan"
        />
        <Column
          field="benefitGroup"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("benefitGroup")}
          title="Benefit Group"
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={benefitGroupMappingsMasterData}
          expanded={true}
        />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>{title}</h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button onClick={handleOpenAddWindow} themeColor={"primary"}>
            Add New
          </Button>
        </div>
      </div>
      <ErrorBanner
        handler={useMutateDeleteBenefitGroupMappingHandler}
        onError={() => setShowDeleteDialog(false)}
      />
      <RenderGrid />
      {openEditForm && (
        <AddEditBenefitGroupModel
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
          offerType={offerType}
          lookup={benefitGroupMappingsMasterData}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onDelete={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
          item={editItem}
        />
      )}
    </div>
  );
};

export default BenefitGroupMapGrid;
