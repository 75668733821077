import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridToolbar,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import LoadingIndicator from "../../components/LoadingIndicator";
import { process } from "@progress/kendo-data-query";

import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

import {
  useQueryGetImportFinalRates,
  useMutatePostApproveImportRate,
} from "../../data/services/importService";
import { RateImportInApplyCostSuccess } from "./ImportStatusHelper";

const ImportRateReview = ({ id, importItem, onNextClick, onPrevClick }) => {
  const [importFinalRatesSrc, setimportFinalRatesSrc] = React.useState([]);
  const [importGridDataState, setImportGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  let isApplyCostStatus =
    importItem && RateImportInApplyCostSuccess(importItem.importStatus);

  const { isLoading: importFinalRatesLoading, data: importFinalRates } =
    useQueryGetImportFinalRates(id);
  const useMutatePostApproveImportRateHandler =
    useMutatePostApproveImportRate();

  React.useEffect(() => {
    if (!importFinalRatesLoading && importFinalRates && importFinalRates.data) {
      setimportFinalRatesSrc(
        process(importFinalRates.data.slice(0), importGridDataState)
      );
    }
  }, [importFinalRatesLoading, importFinalRates, importGridDataState]);

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setimportFinalRatesSrc(
        process(importFinalRates.data.slice(0), event.dataState)
      );
      setImportGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(
        field,
        importGridDataState.filter
      );
      return active ? "active" : "";
    };

    const onExportClick = async () => {
      await axiosDownload(
        SERVICE_URLS.IMPORT_FINAL_RATES_EXPORT(id),
        "Rates Export.csv"
      );
    };

    return (
      <Grid
        pageable={pagerSetting}
        dataItemKey={"importBPRatesId"}
        pageSize={20}
        sortable={true}
        data={importFinalRatesSrc}
        {...importGridDataState}
        onDataStateChange={dataStateChange}
      >
        <GridToolbar>
          <Button themeColor="info" onClick={onExportClick}>
            Export to csv
          </Button>
        </GridToolbar>
        <Column
          field="planGroup"
          title="Benefit Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planGroup")}
        />
        <Column
          field="prismPlanName"
          width={"300"}
          title="Prism Plan Name"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("prismPlanName")}
        />
        <Column
          field="rateGroup"
          title="Rate Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("rateGroup")}
        />
        <Column
          field="planTypeCode"
          title="Plan Type Code"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planTypeCode")}
        />
        <Column
          field="rate"
          title="Rate"
          format="{0:c}"
          className="k-text-right"
        />
        <Column
          field="mlrCredit"
          title="MLR Credit"
          format="{0:c}"
          className="k-text-right"
        />
        <Column
          field="adminFee"
          title="Admin Fee"
          format="{0:c}"
          className="k-text-right"
        />
        <Column
          field="employeeAmount"
          title="Employee Amount"
          format="{0:c}"
          className="k-text-right"
        />
        <Column
          field="dependentAmount"
          title="Dependent Amount"
          format="{0:c}"
          className="k-text-right"
        />
        <Column
          field="totalAmount"
          title="Total Amount"
          format="{0:c}"
          className="k-text-right"
        />
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={importFinalRates.data}
          expanded={true}
        />
      </div>
    );
  };

  const OnApprove = async () => {
    if (isApplyCostStatus) {
      var data = {
        importId: id,
        content: {
          importFileQueueId: id,
        },
      };

      await useMutatePostApproveImportRateHandler.mutateAsync(data);
    }

    onNextClick();
  };

  if (importFinalRatesLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"light"}
            onClick={onPrevClick}
            iconClass="k-icon k-i-arrow-left"
          >
            Apply Cost
          </Button>
        </div>
        <div style={{ alignSelf: "center" }}>
          <h3 style={{ marginTop: 30 }}>Review Data</h3>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"primary"}
            onClick={OnApprove}
            iconClass="k-icon k-i-arrow-right"
          >
            {isApplyCostStatus ? "Approve and Submit" : "View Submit Status"}
          </Button>
        </div>
      </div>
      <RenderGrid />
    </div>
  );
};

export default ImportRateReview;
