import { useMutation, useQuery } from "react-query";
import {
  axiosPost,
  reactQueryDefaultOptions,
  axiosGet,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useMutateSSOLogin = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.USER_AUTHENTICATE, data);
  };

  return useMutation(postData, {
    onSuccess: () => {},
  });
};

export const useQueryGetUser = () => {
  const getUser = async () => {
    return await axiosGet(SERVICE_URLS.USER_GET);
  };
  return useQuery([QUERY_KEYS.GET_USER], getUser, reactQueryDefaultOptions);
};

export const useMutateLogout = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.USER_LOGOUT, data);
  };
  return useMutation(postData, {
    onSuccess: () => {},
  });
};
