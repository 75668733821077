import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../../components/FormInput";
import ErrorBanner from "../../components/ErrorBanner";

import { useMutateCreateModelVersion } from "../../data/services/clientService";
import UserContext from "../../layouts/UserContext";

const AddModelVersion = ({ onModelClose }) => {
  const { clientId, oePeriodId } = useParams();
  const navigate = useNavigate();
  const { loggedInUser } = React.useContext(UserContext);

  const [addModelVersionSubmitted, setAddModelVersionSubmitted] =
    React.useState(false);

  const useMutateCreateModelVersionHandler = useMutateCreateModelVersion();

  const handleConfirmCreateModel = async (item) => {
    let data = {
      clientId: clientId,
      oePeriodId: oePeriodId,
      modelName: item.modelVersionName,
    };

    setAddModelVersionSubmitted(true);

    let response = await useMutateCreateModelVersionHandler.mutateAsync(data);

    setAddModelVersionSubmitted(false);

    if (loggedInUser.hasAdminAppPrivilege) {
      navigate(
        `/admin/clients/${clientId}/oe/${oePeriodId}/ratemodel/${response.data.modelVersionId}`
      );
    } else {
      navigate(
        `/clients/${clientId}/oe/${oePeriodId}/ratemodel/${response.data.modelVersionId}`
      );
    }
  };

  const cancelCreateModel = () => {
    onModelClose();
  };

  return (
    <Dialog
      title={"Create Contribution Model"}
      onClose={cancelCreateModel}
      width={400}
    >
      <ErrorBanner
        handler={useMutateCreateModelVersionHandler}
        onError={() => setAddModelVersionSubmitted(false)}
      />
      <Form
        onSubmit={handleConfirmCreateModel}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"modelVersionName"}
              name={"modelVersionName"}
              label={"Name"}
              autoFocus={true}
              component={FormInput}
              Placeholder={"Enter version name"}
              required={true}
              autoComplete={"off"}
              validator={(value) =>
                value ? "" : "Please enter a model version name"
              }
            />
            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button
                onClick={cancelCreateModel}
                disabled={addModelVersionSubmitted}
              >
                {"Cancel"}
              </Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={
                  !formRenderProps.allowSubmit || addModelVersionSubmitted
                }
              >
                {"Create"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddModelVersion;
