import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormNumericTextbox } from "../../components/FormNumericTextbox";
import ErrorBanner from "../../components/ErrorBanner";

import { useMutateSetMaxContribution } from "../../data/services/clientService";

const ClientMaxContributionModel = (props) => {
  const [submitted, setSubmitted] = React.useState(false);

  const useMutateSetMaxContributionHandler = useMutateSetMaxContribution();

  const handleSubmit = async (item) => {
    var data = {
      oePeriodId: props.oePeriodId,
      clientId: props.clientId,
      modelVersionId: props.versionId,
      groupId: props.maxContribution.groupId,
      eeContrib: item.eeContrib,
      esContrib: item.esContrib,
      ecContrib: item.ecContrib,
      famContrib: item.famContrib,
    };

    setSubmitted(true);

    await useMutateSetMaxContributionHandler.mutateAsync(data);

    setSubmitted(false);
    props.onClose();
  };

  return (
    <Dialog
      title={"Set Max Monthly Contribution"}
      onClose={props.onClose}
      width={400}
    >
      <ErrorBanner
        handler={useMutateSetMaxContributionHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={props.maxContribution}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"eeContrib"}
              name={"eeContrib"}
              label={"Single Max Contribution"}
              autoFocus={true}
              component={FormNumericTextbox}
              required={false}
              format={"c2"}
              min={0}
            />
            <Field
              id={"esContrib"}
              name={"esContrib"}
              label={"Couple Max Contribution"}
              component={FormNumericTextbox}
              required={false}
              format={"c2"}
              min={0}
            />
            <Field
              id={"ecContrib"}
              name={"ecContrib"}
              label={"Single Parent Max Contribution"}
              component={FormNumericTextbox}
              required={false}
              format={"c2"}
              min={0}
            />
            <Field
              id={"famContrib"}
              name={"famContrib"}
              label={"Family Max Contribution"}
              component={FormNumericTextbox}
              required={false}
              format={"c2"}
              min={0}
            />
            <div style={{ paddingTop: 30 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={props.onClose}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {"Set Max Contribution"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default ClientMaxContributionModel;
