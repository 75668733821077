import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";

import { FormUpload } from "../../components/FormUpload";
import { useMutateUploadWelcomeLetter } from "../../data/services/notificationService";

const UploadLetterModel = ({ onClose, waveId, bgLookupId }) => {
  const [submitted, setSubmitted] = React.useState(false);

  const useMutateUploadWelcomeLetterHandler = useMutateUploadWelcomeLetter();

  const onSubmit = async (event) => {
    if (
      event.letterfile &&
      event.letterfile.length > 0 &&
      event.letterfile[0].name
    ) {
      setSubmitted(true);

      var data = {
        waveId: waveId,
        bgLookupId: bgLookupId,
        fileName: event.letterfile[0].name,
        file: event.letterfile[0].getRawFile(),
      };

      await useMutateUploadWelcomeLetterHandler.mutateAsync(data);

      onClose();
    } else {
      alert("Please select all required fields");
    }
  };

  return (
    <Dialog title={"Select Welcome Letter"} onClose={onClose} width={600}>
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"letterfile"}
              name={"letterfile"}
              label={"Select Welcome Letter (.pdf)"}
              component={FormUpload}
              restrictions={{
                allowedExtensions: [".pdf"],
              }}
              validator={(value) =>
                value && value.length > 0 ? "" : "Please select a file"
              }
            />
            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={onClose}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {"Import File"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default UploadLetterModel;
