import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Breadcrumb } from "@progress/kendo-react-layout";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../data/helpers/queryKeys";

import {
  useQueryGetClients,
  useQueryGetModelMaxContributions,
  useQueryGetModelDetail,
  useMutateUpdateModelRates,
  useMutatePublishModel,
  useMutateUnlockModel,
} from "../../data/services/clientService";

import ClientRateModelSummary from "./ClientRateModelSummary";
import ClientRateModelGrid from "./ClientRateModelGrid";
import { setRateGridFields } from "./ClientRateModelHelper";

import LoadingIndicator from "../../components/LoadingIndicator";
import ErrorBanner from "../../components/ErrorBanner";
import SuccessBanner from "../../components/SuccessBanner";
import AlertDialog from "../../components/AlertDialog";
import ClientRateModelAddPlan from "./ClientRateModelAddPlan";
import ClientRateModelErrorDialog from "./ClientRateModelErrorDialog";
import ClientMaxContributionModel from "./ClientMaxContributionModel";
import ConfirmSubmitClientRateModel from "./ConfirmSubmitClientRateModel";
import ClientCopyContributionDialog from "./ClientCopyContributionDialog";
import UserContext from "../../layouts/UserContext";
import OEContext from "../../layouts/OEContext";
import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

const ClientRateModelLayout = () => {
  const { clientId, oePeriodId, versionId } = useParams();
  const navigate = useNavigate();
  const { loggedInUser } = React.useContext(UserContext);
  const oePeriod = React.useContext(OEContext);
  const queryClient = useQueryClient();

  const [client, setClient] = React.useState({});
  const [selected, setSelected] = React.useState(0);
  const [newModelDetailData, setNewModelDetailData] = React.useState();
  const [isSavingDraft, setIsSavingDraft] = React.useState(false);
  const [showAddPlanModel, setShowAddPlanModel] = React.useState(false);
  const [showCopyContribModel, setShowCopyContribModel] = React.useState(false);
  const [showPublishErrorModel, setShowPublishErrorModel] =
    React.useState(false);
  const [currentGroupId, setCurrentGroupId] = React.useState("");
  const [lastRefreshTime, setLastRefreshTime] = React.useState(null);

  const [showMaxContributionModel, setShowMaxContributionModel] =
    React.useState(false);
  const [currentGroupMaxContrib, setCurrentGroupMaxContrib] =
    React.useState(null);
  const [maxContributionData, setMaxContributionData] = React.useState([]);

  const [showSubmitErrorModel, setShowSubmitErrorModel] = React.useState(false);
  const [showSubmitConfirmModel, setShowSubmitConfirmModel] =
    React.useState(false);

  const { isLoading: clientListLoading, data: clientList } =
    useQueryGetClients(oePeriodId);
  const { isLoading: newModelDetailLoading, data: newModelDetail } =
    useQueryGetModelDetail(oePeriodId, clientId, versionId);
  const { isLoading: maxContribLoading, data: maxContrib } =
    useQueryGetModelMaxContributions(oePeriodId, clientId, versionId);

  const useMutateUpdateModelRatesHandler = useMutateUpdateModelRates();
  const useMutatePublishModelHandler = useMutatePublishModel();
  const useMutateUnlockModelHandler = useMutateUnlockModel();

  const isRateSubmitted =
    client.oeStatus === "Submitted" ||
    client.oeStatus === "PublishInProgress" ||
    client.oeStatus === "PublishError" ||
    client.oeStatus === "Published" ||
    client.oeStatus === "PublishedDefault";
  const isClientRateSubmitted = client.oeStatus === "Submitted";
  const isClientRatePublishError = client.oeStatus === "PublishError";
  const isClientRatePublishSuccess = client.oeStatus === "Published";
  const isClientRatePublishDefaultSuccess = client.oeStatus === "PublishedDefault";
  
  React.useEffect(() => {
    if (!newModelDetailLoading && newModelDetail && newModelDetail.data) {
      setRateGridFields(newModelDetail);
      setNewModelDetailData(newModelDetail.data);
    }
  }, [newModelDetailLoading, newModelDetail]);

  React.useEffect(() => {
    if (!maxContribLoading && maxContrib && maxContrib.data) {
      setMaxContributionData(maxContrib.data ?? []);
    }
  }, [maxContribLoading, maxContrib]);

  React.useEffect(() => {
    if (!clientListLoading && clientList && clientList.data) {
      let client = clientList.data.find((e) => e.clientId === clientId);
      if (!client) {
        if (loggedInUser.hasAdminAppPrivilege) {
          navigate("/admin/clientadminsummary");
        } else {
          navigate("/dashboard");
        }
      }

      setClient(client);
    }
  }, [clientListLoading, clientList, clientId, navigate, loggedInUser]);

  if (newModelDetailLoading) {
    return <LoadingIndicator />;
  }

  if (!client) {
    return <div>Client not found</div>;
  }

  const RenderNav = () => {
    let data = [
      {
        id: "home",
        text: loggedInUser.hasAdminAppPrivilege
          ? `${client?.clientId} - ${client?.clientName}`
          : "Home",
        iconClass: "k-i-home",
      },
      {
        id: "Model",
        text: `${newModelDetailData?.modelName}`,
      },
    ];

    const handleItemSelect = (event) => {
      if (event.id === "home") {
        if (loggedInUser.hasAdminAppPrivilege) {
          navigate(`/admin/clients/${clientId}/oe/${oePeriodId}/summary`);
        } else {
          navigate(`/clients/${clientId}/oe/${oePeriodId}/summary`);
        }
      }
    };

    return (
      <div>
        <Breadcrumb
          data={data}
          className={"oe-breadcrumb"}
          onItemSelect={handleItemSelect}
        />
      </div>
    );
  };

  const RenderTab = () => {
    const handleSelect = (e) => {
      setSelected(e.selected);
    };

    const formatTitle = (title) => {
      if (title && title.length > 20) {
        return title.substring(0, 20) + "...";
      } else {
        return title;
      }
    };

    const onRateDataChange = (rates, groupId) => {
      const group = newModelDetailData.groups.find(
        (g) => g.groupId === groupId
      );
      if (group) {
        group.rates = rates;
        setNewModelDetailData(newModelDetailData);
      }
    };

    const onSubmitClick = () => {
      let hasMissingData = newModelDetailData.groups.some((group) =>
        group.rates.some((e) => !e.percentSelected && !e.fixedValueSelected)
      );

      if (hasMissingData) {
        setShowSubmitErrorModel(true);
      } else {
        setShowSubmitConfirmModel(true);
      }
    };

    const onPublishClick = async () => {
      let inputData = {
        oePeriodId: oePeriodId,
        clientId: clientId,
        versionId: versionId,
        isRetry: false,
      };

      await useMutatePublishModelHandler.mutateAsync(inputData);
    };

    const onRetryPublishClick = async () => {
      let inputData = {
        oePeriodId: oePeriodId,
        clientId: clientId,
        versionId: versionId,
        isRetry: true,
      };

      await useMutatePublishModelHandler.mutateAsync(inputData);
    };

    const onUnlockClick = async () => {
      let inputData = {
        oePeriodId: oePeriodId,
        clientId: clientId,
        versionId: versionId,
      };

      await useMutateUnlockModelHandler.mutateAsync(inputData);
    };

    const onSaveDraft = async () => {
      setIsSavingDraft(true);

      let ratesToUpdate = newModelDetailData.groups.flatMap((group) => {
        return group.rates
          .filter((e) => e.percentSelected || e.fixedValueSelected)
          .map((rate) => {
            return {
              clientRateModelId: rate.clientRateModelId,
              method: rate.percentSelected ? "P" : "F",
              contributionValue: rate.percentSelected
                ? (rate.percentValue ? rate.percentValue : 0) * 100
                : rate.fixedValue
                ? rate.fixedValue
                : 0,
            };
          });
      });

      if (ratesToUpdate.length > 0) {
        let data = {
          clientId: clientId,
          oePeriodId: oePeriodId,
          versionId: versionId,
          rates: ratesToUpdate,
        };

        await useMutateUpdateModelRatesHandler.mutateAsync(data);
      }

      setIsSavingDraft(false);
    };

    const onManagePlanClick = (groupId) => {
      setCurrentGroupId(groupId);
      setShowAddPlanModel(true);
    };

    const onCopyContributionClick = (groupId) => {
      setCurrentGroupId(groupId);
      setShowCopyContribModel(true);
    };

    const handleSetMaxContribution = (groupMaxContrib, groupId) => {
      if (!groupMaxContrib) {
        groupMaxContrib = {
          groupId: groupId,
        };
      }

      setCurrentGroupMaxContrib(groupMaxContrib);
      setShowMaxContributionModel(true);
    };

    const onExcelExportClick = async () => {
      await axiosDownload(
        SERVICE_URLS.CLIENT_MODEL_EXCEL_DOWNLOAD(
          oePeriodId,
          clientId,
          versionId
        ),
        `${clientId} - ${newModelDetailData?.modelName} - Contribution Model.xlsx`
      );
    };

    return (
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        dir={""}
        tabPosition={"left"}
      >
        <TabStripTab title={"Summary"} key={"Summary"}>
          <div className="ratesummary">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ marginTop: 0, marginBottom: 0 }}>{"Summary"}</h2>
              <div>
                <Button themeColor={"success"} onClick={onExcelExportClick}>
                  Export
                </Button>
              </div>
            </div>
            {ClientRateModelSummary(newModelDetailData)}
            <NewOfferingPlanDetailsMessage modelData={newModelDetailData} />
            {!isRateSubmitted &&
              loggedInUser.submitContributionModalPrivilege && (
                <div style={{ float: "right", marginTop: "30px" }}>
                  <Button
                    themeColor={"success"}
                    onClick={onSubmitClick}
                    disabled={isSavingDraft}
                  >
                    {" "}
                    Submit Contribution Model{" "}
                  </Button>
                </div>
              )}
            {isClientRateSubmitted && loggedInUser.hasAdminAppPrivilege && (
              <div style={{ float: "right", marginTop: "30px" }}>
                <Button
                  themeColor={"secondary"}
                  onClick={onUnlockClick}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  Unlock Grid
                </Button>
                <Button themeColor={"success"} onClick={onPublishClick}>
                  {" "}
                  Publish Rates To PrismHR
                </Button>
              </div>
            )}
            {isClientRatePublishError && loggedInUser.hasAdminAppPrivilege && (
              <div style={{ float: "right", marginTop: "30px" }}>
                <Button
                  themeColor={"secondary"}
                  onClick={onUnlockClick}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  Unlock Grid
                </Button>
                <Button themeColor={"primary"} onClick={onRetryPublishClick}>
                  {" "}
                  Retry Publish Rates To PrismHR
                </Button>
              </div>
            )}
            {isClientRatePublishSuccess && loggedInUser.hasUnlockPriv && (
              <div style={{ float: "right", marginTop: "30px" }}>
                <Button
                  themeColor={"secondary"}
                  onClick={onUnlockClick}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  Unlock Grid
                </Button>
              </div>
            )}
          {isClientRatePublishDefaultSuccess && loggedInUser.hasUnlockPriv && (
              <div style={{ float: "right", marginTop: "30px" }}>
                <Button
                  themeColor={"secondary"}
                  onClick={onUnlockClick}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  Unlock Grid
                </Button>
              </div>
            )}
          </div>
        </TabStripTab>
        {newModelDetailData &&
          newModelDetailData.groups &&
          newModelDetailData.groups.map((group, index) => {
            const groupMaxContrib = maxContributionData.find(
              (e) => e.groupId === group.groupId
            );
            const hasGroupMaxContrib =
              groupMaxContrib &&
              (groupMaxContrib.eeContrib > 0 ||
                groupMaxContrib.ecContrib > 0 ||
                groupMaxContrib.esContrib > 0 ||
                groupMaxContrib.famContrib > 0);

            return (
              <TabStripTab title={formatTitle(group.groupName)} key={index}>
                <div className="ratemodel">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h2 style={{ marginTop: 0 }}>
                      Class: {group.groupId} - {group.groupName}
                    </h2>
                    {!isRateSubmitted && (
                      <div>
                        <Button
                          themeColor={"info"}
                          onClick={() => onManagePlanClick(group.groupId)}
                          style={{ marginRight: "20px" }}
                        >
                          {" "}
                          Add or Terminate Plans
                        </Button>
                        <Button
                          themeColor="info"
                          onClick={() =>
                            handleSetMaxContribution(
                              groupMaxContrib,
                              group.groupId
                            )
                          }
                          style={{ marginRight: "20px" }}
                        >
                          {hasGroupMaxContrib > 0
                            ? "Set Max Medical Plan Contribution"
                            : "Set Max Medical Plan Contribution"}
                        </Button>
                        <Button
                          themeColor="info"
                          onClick={() => onCopyContributionClick(group.groupId)}
                          style={{ marginRight: "20px" }}
                        >
                          {"Contribution Shortcuts"}
                        </Button>
                        <Button
                          themeColor={"success"}
                          onClick={onSaveDraft}
                          disabled={isSavingDraft}
                        >
                          {" "}
                          Save Draft{" "}
                        </Button>
                      </div>
                    )}
                  </div>
                  <ClientRateModelGrid
                    data={group}
                    maxContribution={groupMaxContrib}
                    dataChange={onRateDataChange}
                    isRateSubmitted={isRateSubmitted}
                    hasDentalNewOffering={
                      newModelDetailData?.hasDentalNewOffering
                    }
                    hasVisionNewOffering={
                      newModelDetailData?.hasVisionNewOffering
                    }
                  ></ClientRateModelGrid>
                  {!isRateSubmitted && (
                    <div
                      style={{
                        float: "right",
                        marginTop: "30px",
                        paddingBottom: "30px",
                      }}
                    >
                      <Button
                        themeColor={"success"}
                        onClick={onSaveDraft}
                        disabled={isSavingDraft}
                      >
                        {" "}
                        Save Draft{" "}
                      </Button>
                    </div>
                  )}
                </div>
              </TabStripTab>
            );
          })}
      </TabStrip>
    );
  };

  const RenderStatusBanner = () => {
    const onRefreshClick = async () => {
      setLastRefreshTime(new Date());
      queryClient.invalidateQueries([QUERY_KEYS.GET_CLIENTS]);
    };

    const GetLastRefreshString = () => {
      if (lastRefreshTime) {
        return `(Last refreshed: ${lastRefreshTime.toLocaleTimeString()})`;
      } else {
        return "";
      }
    };

    if (client.oeStatus === "Submitted") {
      if (loggedInUser.hasAdminAppPrivilege) {
        return (
          <div
            style={{
              backgroundColor: "#d4edda",
              padding: 10,
              marginBottom: 20,
              borderRadius: ".25rem",
            }}
          >
            <span style={{ color: "#155724" }}>
              Client submitted rate model for review. Please review and publish
              the rates to PrismHR
            </span>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "#d4edda",
              padding: 10,
              marginBottom: 20,
              borderRadius: ".25rem",
            }}
          >
            <span style={{ color: "#155724" }}>
              Successfully submitted rate model for review. Benefit Specialist
              will review and publish the Rates. Please reach out to Specialist
              to make any changes
            </span>
          </div>
        );
      }
    } else if (client.oeStatus === "PublishInProgress") {
      if (loggedInUser.hasAdminAppPrivilege) {
        return (
          <div
            style={{
              backgroundColor: "#fff3cd",
              padding: 10,
              marginBottom: 20,
              borderRadius: ".25rem",
            }}
          >
            <span style={{ color: "#721c24" }}>
              Rate publishing is in progress. Please check status after few mins{" "}
              <span
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  paddingLeft: "20px",
                }}
                onClick={onRefreshClick}
              >
                Refresh
              </span>
              <span style={{ fontStyle: "italic", paddingLeft: "10px" }}>
                {GetLastRefreshString()}
              </span>
            </span>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "#d4edda",
              padding: 10,
              marginBottom: 20,
              borderRadius: ".25rem",
            }}
          >
            <span style={{ color: "#155724" }}>
              Successfully submitted rate model for review. Benefit Specialist
              will review and publish the Rates. Please reach out to Specialist
              to make any changes
            </span>
          </div>
        );
      }
    } else if (client.oeStatus === "PublishError") {
      if (loggedInUser.hasAdminAppPrivilege) {
        return (
          <div
            style={{
              backgroundColor: "#f8d7da",
              padding: 10,
              marginBottom: 20,
              borderRadius: ".25rem",
            }}
          >
            <span style={{ color: "#721c24" }}>
              Error publishing rates.{" "}
              <span
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => setShowPublishErrorModel(true)}
              >
                View error details
              </span>
            </span>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "#d4edda",
              padding: 10,
              marginBottom: 20,
              borderRadius: ".25rem",
            }}
          >
            <span style={{ color: "#155724" }}>
              Successfully submitted rate model for review. Benefit Specialist
              will review and publish the Rates. Please reach out to Specialist
              to make any changes
            </span>
          </div>
        );
      }
    } else if (client.oeStatus === "Published") {
      return (
        <div
          style={{
            backgroundColor: "#d4edda",
            padding: 10,
            marginBottom: 20,
            borderRadius: ".25rem",
          }}
        >
          <span style={{ color: "#155724" }}>
            Successfully published rates.
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <div>
          <RenderNav />
        </div>
      </div>
      <SuccessBanner
        handler={useMutateUpdateModelRatesHandler}
        message={"Rates draft saved successfully."}
      />
      <ErrorBanner
        handler={useMutateUpdateModelRatesHandler}
        onError={() => setIsSavingDraft(false)}
      />
      {RenderStatusBanner()}
      {RenderTab()}
      {showAddPlanModel && (
        <ClientRateModelAddPlan
          clientId={clientId}
          oePeriodId={oePeriodId}
          versionId={versionId}
          groupId={currentGroupId}
          onClose={() => setShowAddPlanModel(false)}
        />
      )}
      {showPublishErrorModel && (
        <ClientRateModelErrorDialog
          clientId={clientId}
          oePeriodId={oePeriodId}
          versionId={versionId}
          toggleDialog={() => setShowPublishErrorModel(false)}
        />
      )}
      {showMaxContributionModel && (
        <ClientMaxContributionModel
          clientId={clientId}
          oePeriodId={oePeriodId}
          versionId={versionId}
          maxContribution={currentGroupMaxContrib}
          onClose={() => setShowMaxContributionModel(false)}
        />
      )}
      {showCopyContribModel && (
        <ClientCopyContributionDialog
          clientId={clientId}
          oePeriodId={oePeriodId}
          versionId={versionId}
          groupId={currentGroupId}
          onClose={() => setShowCopyContribModel(false)}
        />
      )}
      {showSubmitErrorModel && (
        <AlertDialog
          title={"Incomplete Model"}
          message={
            "Please enter rate contribution for all plans and classes before submitting."
          }
          onClose={() => setShowSubmitErrorModel(false)}
        />
      )}
      {showSubmitConfirmModel && (
        <ConfirmSubmitClientRateModel
          clientId={clientId}
          oePeriodId={oePeriodId}
          versionId={versionId}
          oePeriod={oePeriod}
          onClose={() => setShowSubmitConfirmModel(false)}
        />
      )}
    </div>
  );
};

const NewOfferingPlanDetailsMessage = ({ modelData }) => {
  const memoizedPlanIds = React.useMemo(() => {
    if (!modelData?.hasDentalNewOffering && !modelData?.hasVisionNewOffering) {
      return [];
    }

    const plans = modelData.groups.flatMap((group) => {
      return group.rates
        .filter((e) => e.planOfferType === "DEN" || e.planOfferType === "VIS")
        .map((rate) => rate.planId);
    });

    return [...new Set(plans)];
  }, [modelData]);

  const memoizedPlanIdsString = React.useMemo(() => {
    if (!memoizedPlanIds || memoizedPlanIds.length === 0) {
      return "";
    }

    return memoizedPlanIds.join(", ");
  }, [memoizedPlanIds]);

  const addedPlansString = React.useMemo(() => {
    if (!modelData?.newlyAddedPlans) {
      return null;
    }

    const addedPlans = modelData.newlyAddedPlans.filter(
      (e) => !memoizedPlanIds.some((id) => id === e)
    );

    return addedPlans.join(", ");
  }, [modelData?.newlyAddedPlans, memoizedPlanIds]);

  const deletedPlansString = React.useMemo(() => {
    if (!modelData?.deletedPlans) {
      return null;
    }

    return modelData?.deletedPlans.join(", ");
  }, [modelData?.deletedPlans]);

  const newOfferingDescription = React.useMemo(() => {
    if (modelData?.hasDentalNewOffering && modelData?.hasVisionNewOffering) {
      return "This client has new dental and vision offerings. Please add the below plans in PrismHR before publishing the rates.";
    } else if (modelData?.hasDentalNewOffering) {
      return "This client has new dental offerings. Please add the below plans in PrismHR before publishing the rates.";
    } else if (modelData?.hasVisionNewOffering) {
      return "This client has new vision offerings. Please add the below plans in PrismHR before publishing the rates.";
    }

    return null;
  }, [modelData?.hasDentalNewOffering, modelData?.hasVisionNewOffering]);

  return (
    <div style={{ paddingTop: 20, color: "red", fontSize: 18 }}>
      NOTE: <br />
      {newOfferingDescription && (
        <>
          {newOfferingDescription}
          <br />
          <span style={{ fontWeight: "bold" }}>{memoizedPlanIdsString}</span>
          <br />
        </>
      )}
      {addedPlansString && (
        <>
          <br />
          The following new plans are added to this client:
          <br />
          <span style={{ fontWeight: "bold" }}>{addedPlansString}</span>
          <br />
        </>
      )}
      {deletedPlansString && (
        <>
          <br />
          The following plans are removed from this client:
          <br />
          <span style={{ fontWeight: "bold" }}>{deletedPlansString}</span>
          <br />
        </>
      )}
    </div>
  );
};

export default ClientRateModelLayout;
