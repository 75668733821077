import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useQueryGetClientModelAuditRecords } from "../../data/services/clientService";
import { Loader } from "@progress/kendo-react-indicators";
import { process } from "@progress/kendo-data-query";

const AuditTrailModal = ({ onModelClose, client }) => {
  const { isLoading: clientAuditRecordsLoading, data: clientAuditRecords } =
    useQueryGetClientModelAuditRecords(client.clientId);
  const [auditRecordsListSrc, setAuditRecordsListSrc] = React.useState([]);
  const [auditRecordsMasterList, setAuditRecordsMasterList] = React.useState(
    []
  );
  const [gridDataState, setGridDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20],
    previousNext: true,
  };

  React.useEffect(() => {
    if (
      !clientAuditRecordsLoading &&
      clientAuditRecords &&
      clientAuditRecords.data
    ) {
      setAuditRecordsMasterList(clientAuditRecords.data);
      setAuditRecordsListSrc(
        process(clientAuditRecords.data.slice(0), gridDataState)
      );
    }
  }, [clientAuditRecordsLoading, clientAuditRecords, gridDataState]);

  const dataStateChange = (event) => {
    setAuditRecordsListSrc(
      process(auditRecordsMasterList.slice(0), event.dataState)
    );
    setGridDataState(event.dataState);
  };

  const handleCloseAuditTrailModal = () => {
    onModelClose();
  };

  const DateCell = (props) => {
    return (
      <td>
        {props.dataItem[props.field] &&
          new Date(props.dataItem[props.field] + "Z").toLocaleString("en-US")}
      </td>
    );
  };

  return (
    <Dialog
      title={`Audit Trail - ${client.clientName}`}
      onClose={handleCloseAuditTrailModal}
      width={800}
    >
      {clientAuditRecordsLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Loader size="medium" type={"infinite-spinner"} />
          </div>
        </div>
      )}
      {!clientAuditRecordsLoading && (
        <Grid
          sortable={true}
          pageable={pagerSetting}
          pageSize={10}
          data={auditRecordsListSrc}
          {...gridDataState}
          onDataStateChange={dataStateChange}
        >
          <Column field="userName" title="Name" sortable={true} />
          <Column field="userAction" title="Action" sortable={true} />
          <Column field="clientRateModelName" title="Model Name" sortable={true} />
          <Column
            field="actionTime"
            title="Date"
            sortable={true}
            cell={DateCell}
          />
        </Grid>
      )}
    </Dialog>
  );
};

export default AuditTrailModal;
