import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { useParams } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";
import OEContext from "../../layouts/OEContext";

import { useQueryGetClientNotificationHistory } from "../../data/services/clientAdminService";

const ClientNotificationHistoryGrid = () => {
  const { clientId } = useParams();
  const oePeriod = React.useContext(OEContext);

  const [gridDataSrc, setGridDataSrc] = React.useState([]);
  const [gridDataMasterSrc, SetGridDataMasterSrc] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: isHistoryLoading, data: historyData } =
    useQueryGetClientNotificationHistory(oePeriod?.oePeriodId, clientId);

  React.useEffect(() => {
    if (!isHistoryLoading && historyData && historyData.data) {
      SetGridDataMasterSrc(historyData.data);
      setGridDataSrc(process(historyData.data.slice(0), gridDataState));
    }
  }, [isHistoryLoading, historyData, gridDataState]);

  if (isHistoryLoading) {
    return "Loading ...";
  }

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setGridDataSrc(process(gridDataMasterSrc.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={gridDataMasterSrc}
            expanded={true}
          />
        </div>
      );
    };

    const dateCell = (props) => {
      return (
        <td>
          {props.dataItem.notificationDate
            ? formatDate(new Date(props.dataItem.notificationDate + "Z"), "G")
            : null}
        </td>
      );
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={gridDataSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="contactName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("contactName")}
          title="Contact Name"
        />
        <Column
          field="contactEmail"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("contactEmail")}
          title="Contact Email"
        />
        <Column
          field="status"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("status")}
          title="Status"
        />
        <Column
          field="notificationDate"
          title="Notification Date"
          cell={dateCell}
        />
      </Grid>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Notification History</h2>
        </div>
      </div>
      <RenderGrid />
    </div>
  );
};

export default ClientNotificationHistoryGrid;
