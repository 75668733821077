import * as React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import OEContext from "./OEContext";
import UserContext from "./UserContext";

import { useQueryGetClients } from "../data/services/clientService";

const ClientHeader = (props) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { loggedInUser } = React.useContext(UserContext);
  const oePeriod = React.useContext(OEContext);

  const [client, setClient] = React.useState({});

  const { isLoading: clientListLoading, data: clientList } = useQueryGetClients(
    oePeriod?.oePeriodId
  );

  React.useEffect(() => {
    if (!clientListLoading && clientList && clientList.data) {
      let client = clientList.data.find((e) => e.clientId === clientId);
      if (!client) {
        if (loggedInUser.hasAdminAppPrivilege) {
          navigate("/admin/clientadminsummary");
        } else {
          navigate("/dashboard");
        }
      }

      setClient(client);
    }
  }, [clientListLoading, clientList, clientId, loggedInUser, navigate]);

  return (
    <div
      style={{
        height: "25px",
        backgroundColor: "#E3E5EA",
        paddingTop: "5px",
        paddingBottom: "5px",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span
        className="k-icon k-i-menu"
        style={{ marginLeft: 10, fontSize: 20, cursor: "pointer" }}
        onClick={props.menuIconCLick}
      />
      <Link
        to="/dashboard"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        {client?.clientId} - {client?.clientName} (change client)
      </Link>
      <span></span>
    </div>
  );
};

export default ClientHeader;
