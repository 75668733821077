import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faUserGroup,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartLegend,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import "hammerjs";
import { Button } from "@progress/kendo-react-buttons";
import LoadingIndicator from "../../components/LoadingIndicator";
import { formatNumber } from "@telerik/kendo-intl";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Breadcrumb } from "@progress/kendo-react-layout";
import AddModelVersion from "./AddModelVersion";
import ClientRateModelSummary from "./ClientRateModelSummary";
import ConfirmSubmitClientRateModel from "./ConfirmSubmitClientRateModel";
import { setRateGridFields } from "./ClientRateModelHelper";
import { Loader } from "@progress/kendo-react-indicators";

import {
  useQueryGetClients,
  useQueryGetModelVersions,
  useQueryGetModelSummary,
  useQueryGetModelDetail,
  useQueryGetClientBenefitRep,
  useQueryGetClientSalesRep
} from "../../data/services/clientService";
import UserContext from "../../layouts/UserContext";
import OEContext from "../../layouts/OEContext";
import { PRIVILEGE_TYPE } from "../../constants/index";

const ClientSummary = () => {
  const { clientId, oePeriodId } = useParams();
  const navigate = useNavigate();
  const { loggedInUser } = React.useContext(UserContext);
  const oePeriod = React.useContext(OEContext);

  const [clientBenefitRepLocal, setClientBenefitRepLocal] = React.useState();
  const [clientSalesRepLocal, setClientSalesRepLocal] = React.useState();
  const [client, setClient] = React.useState({});
  const [currentModelVersionId, setCurrentModelVersionId] =
    React.useState(null);
  const [newModelVersionId, setNewModelVersionId] = React.useState(null);
  const [showSubmitConfirmModel, setShowSubmitConfirmModel] =
    React.useState(false);
  const [isWelcomeMessage, setIsWelcomeMessage] =
    React.useState(false);

  const [currentModelSummaryData, setCurrentModelSummaryData] =
    React.useState();
  const [newModelSummaryData, setNewModelSummaryData] = React.useState();

  const [showNewModelVersionDialog, setShowNewModelVersionDialog] =
    React.useState(false);
  const [canCreateNewModelVersion, setCanCreateNewModelVersion] =
    React.useState(false);
  const [modelVersionsSrc, setModelVersionsSrc] = React.useState([]);

  const [newModelDetailData, setNewModelDetailData] = React.useState();

  const { isLoading: clientListLoading, data: clientList } =
    useQueryGetClients(oePeriodId);
  const { isLoading: modelVersionsLoading, data: modelVersions } =
    useQueryGetModelVersions(oePeriodId, clientId);
  const { isLoading: currentModelSummaryLoading, data: currentModelSummary } =
    useQueryGetModelSummary(oePeriodId, clientId, currentModelVersionId);
  const { isLoading: newModelSummaryLoading, data: newModelSummary, refetch: refetchModelSummery } =
    useQueryGetModelSummary(oePeriodId, clientId, newModelVersionId);
  const { isLoading: newModelDetailLoading, data: newModelDetail } =
    useQueryGetModelDetail(oePeriodId, clientId, newModelVersionId);
  const { isLoading: clientBenefitRepLoading, data: clientBenefitRep } =
    useQueryGetClientBenefitRep(clientId);
  const { isLoading: clientSalesRepLoading, data: clientSalesRep } =
    useQueryGetClientSalesRep(clientId);


  const isRateSubmitted = React.useMemo(
    () =>
      client.oeStatus === "Submitted" ||
      client.oeStatus === "PublishInProgress" ||
      client.oeStatus === "PublishError" ||
      client.oeStatus === "Published" ||
      client.oeStatus === "PublishedDefault",
    [client]
  );

  React.useEffect(() => {
    if (!modelVersionsLoading && modelVersions && modelVersions.data) {
      setModelVersionsSrc(
        modelVersions.data.filter((e) => e.isSystem !== true)
      );
      if (modelVersions.data.length > 0) {
        let current = modelVersions.data.find((e) => e.isSystem === true);
        if (current) {
          setCurrentModelVersionId(current.modelVersionId);
        }

        if (isRateSubmitted) {
          setNewModelVersionId(client.submittedVersionId);
        } else if (!newModelVersionId) {
          let newModel = modelVersions.data.find(
            (e) => e.isClientDefault === true
          );
          if (newModel) {
            setNewModelVersionId(newModel.modelVersionId);
          } else {
            let newModel = modelVersions.data.find((e) => !e.isSystem);
            if (newModel) {
              setNewModelVersionId(newModel.modelVersionId);
            }
          }
        }
      }

      setCanCreateNewModelVersion(modelVersions.data.length < 4);
    }

    if (
      !currentModelSummaryLoading &&
      currentModelSummary &&
      currentModelSummary.data
    ) {
      setCurrentModelSummaryData(currentModelSummary.data);
    }

    if (!newModelSummaryLoading && newModelSummary && newModelSummary.data) {
      setNewModelSummaryData(newModelSummary.data);
    }

    if (!newModelDetailLoading && newModelDetail && newModelDetail.data) {
      setRateGridFields(newModelDetail);
      setNewModelDetailData(newModelDetail.data);
    }
  }, [
    modelVersionsLoading,
    modelVersions,
    currentModelSummaryLoading,
    currentModelSummary,
    newModelSummaryLoading,
    newModelSummary,
    newModelVersionId,
    newModelDetailLoading,
    newModelDetail,
    client.submittedVersionId,
    isRateSubmitted,
  ]);

  function hasCreatedByBroker(arr) {
    if (arr) {
      return arr.some(item => item.isBroker === true);
    }
  }
  function hasCreatedByClientAdmin(arr) {
    if (arr) {
      return arr.some(item => item.isAdmin === true);
    }
  }

  React.useEffect(() => {
    if (!!modelVersionsSrc && !!loggedInUser) {
      // Check if the user has the BrokerUserPrivilege
      const BrokerUserPrivilege = loggedInUser?.privileges.includes(PRIVILEGE_TYPE.BrokerUserPrivilege);

      // Check for broker and client messages based on user privileges and source data
      if (BrokerUserPrivilege) {
        if (hasCreatedByBroker(modelVersionsSrc)) {
          setIsWelcomeMessage(false);
        } else if (hasCreatedByClientAdmin(modelVersionsSrc)) {
          setIsWelcomeMessage(true);
        }
      } else if (loggedInUser?.hasClientAppPrivilege) {
        if (hasCreatedByBroker(modelVersionsSrc)) {
          setIsWelcomeMessage(true);
        } else if (hasCreatedByClientAdmin(modelVersionsSrc)) {
          setIsWelcomeMessage(false);
        }
      }

    } else {
      console.log('Skipping the if block because modelVersionsSrc is undefined or loggedInUser is falsy');
    }
  }, [loggedInUser, modelVersionsSrc]);




  React.useEffect(() => {
    if (!clientListLoading && clientList && clientList.data) {
      let client = clientList.data.find((e) => e.clientId === clientId);
      if (!client) {
        if (loggedInUser.hasAdminAppPrivilege) {
          navigate("/admin/clientadminsummary");
        } else {
          navigate("/dashboard");
        }
      }

      setClient(client);
    }
  }, [clientListLoading, clientList, clientId, navigate, loggedInUser]);

  React.useEffect(() => {
    if (clientBenefitRepLoading || !clientBenefitRep) {
      return;
    }
    setClientBenefitRepLocal(clientBenefitRep.data);
  }, [clientBenefitRepLoading, clientBenefitRep]);

  React.useEffect(() => {
    if (clientSalesRepLoading || !clientSalesRep) {
      return;
    }
    setClientSalesRepLocal(clientSalesRep.data);
  }, [clientSalesRepLoading, clientSalesRep]);

  if (modelVersionsLoading || currentModelSummaryLoading || clientListLoading) {
    return <LoadingIndicator />;
  }

  if (!client) {
    return <div>Client not found</div>;
  }

  const handleCreateModel = async () => {
    setShowNewModelVersionDialog(true);
  };

  const handleClose = () => {
    setIsWelcomeMessage(false)
  }

  const handleModelVersionChange = (e) => {
    setNewModelVersionId(e.target.value.modelVersionId);
    setIsWelcomeMessage(false)
    refetchModelSummery()
  };

  const RenderNav = () => {
    let data = [
      {
        id: "home",
        text: loggedInUser.hasAdminAppPrivilege
          ? `${client?.clientId} - ${client?.clientName}`
          : "Home",
        iconClass: "k-i-home",
      },
    ];

    return (
      <div>
        <Breadcrumb data={data} className={"oe-breadcrumb"} />
      </div>
    );
  };

  const RenderVersionBar = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          marginBottom: 0,
        }}
      >
        <DropDownList
          style={{
            width: "250px",
            marginRight: 20,
          }}
          data={modelVersionsSrc}
          fillMode={"flat"}
          textField="modelName"
          dataItemKey="modelVersionId"
          value={modelVersionsSrc.find(
            (x) => x.modelVersionId === newModelVersionId
          )}
          onChange={handleModelVersionChange}
          loading={modelVersionsLoading}
        />

        <div style={{ marginTop: 2, marginRight: 20 }}>
          {newModelDetailLoading && <Loader size="small" type={"pulsing"} />}
          {!newModelDetailLoading && newModelDetailData && newModelDetailData.creatorUserName && (
            <span
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "1px",
                paddingBottom: "1px",
                backgroundColor: "grey",
                borderRadius: "15px",
                color: "white",
                fontSize: "12px",
              }}
            >
              Created by {newModelDetailData.creatorUserName}
            </span>
          )}
        </div>

        <Button
          themeColor="primary"
          onClick={handleCreateModel}
          disabled={!canCreateNewModelVersion}
        >
          Create New Contribution Model
        </Button>
      </div>
    );
  };

  const RenderClientSummary = ({
    title,
    data,
    showViewButton,
    isCurrent,
    currentData,
    isWelcomeMessage
  }) => {
    if (isCurrent && !data) {
      return (
        <div style={{ margin: 20 }}>
          No Benefit Group or Plan details found for the client. Please contact
          Specialist.
        </div>
      );
    } else if (isWelcomeMessage || (!data && currentData)) {
      return (
        <div style={{ paddingBottom: 30 }}>
          <div
            style={{
              textAlign: "center",
              marginTop: 100,
              backgroundColor: "#f6f6f6",
              paddingBottom: 20,
              position: "relative",
            }}
          >
            {modelVersionsSrc.length > 0 ? 
            <button
              onClick={handleClose}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button> : null
    }
            <div
              style={{
                fontSize: "15px",
                fontWeight: "500",
                marginBottom: "20px",
                padding: 30,
              }}
            >
              <h2>Welcome to {client.oePeriodName}</h2>
              To start modeling, click on the create model button below. You can create multiple versions of the model to compare before submitting the rates.
            </div>
            <Button themeColor="primary" onClick={handleCreateModel}>
              Create New Contribution Model
            </Button>
          </div>
        </div>);
    } else if (!data) {
      return <div style={{ margin: 20 }}>NA</div>;
    }

    const renderSummary = (
      icon,
      contribution,
      label,
      percentageIncreaseText
    ) => {
      return (
        <>
          <div
            style={{
              float: "left",
              height: "40px",
              padding: "10px",
              fontSize: "1.5rem",
            }}
          >
            <FontAwesomeIcon icon={icon} style={{ verticalAlign: "middle" }} />
          </div>
          <div style={{ marginTop: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "20px" }}>
                {formatNumber(contribution, "c")}
              </div>
              {percentageIncreaseText}
            </div>
            <div style={{ fontSize: "10px", color: "#7D807D" }}>{label}</div>
          </div>
        </>
      );
    };

    const renderChartAxisLabel = (data) => {
      return `${data.value.planOfferTypeDesc}\n(${data.value.eeCount} members)`;
    };

    const renderLabelContent = (data) => {
      return formatNumber(data.value, "c");
    };

    const renderChart = (data, header, companyField, empField) => {
      return (
        <div style={{ marginBottom: "20px" }}>
          <fieldset
            style={{
              border: "1px solid lightgrey",
              borderRadius: "3px",
              padding: 0,
            }}
          >
            <legend>
              <b style={{ fontSize: 13 }}>{header}</b>
            </legend>

            <div style={{ margin: "5px" }}>
              <Chart style={{ height: "220px", width: "100%" }}>
                <ChartLegend position="bottom" orientation="horizontal" />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    categories={data.totalCosts}
                    line={{ visible: false }}
                    majorGridLines={{ visible: false }}
                    minorGridLines={{ visible: false }}
                    labels={{ content: renderChartAxisLabel }}
                  ></ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    majorGridLines={{ visible: false }}
                    visible={false}
                    minorGridLines={{ visible: false }}
                  ></ChartValueAxisItem>
                </ChartValueAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    name={"Company Contribution"}
                    type="bar"
                    gap={1}
                    spacing={0.5}
                    data={data.totalCosts}
                    field={companyField}
                    color={"#40D555"}
                    border={{ color: "#40D555" }}
                    autoFit={true}
                    labels={{ visible: true }}
                  >
                    <ChartSeriesLabels content={renderLabelContent} />
                  </ChartSeriesItem>
                  <ChartSeriesItem
                    name={"Employee Contribution"}
                    type="bar"
                    data={data.totalCosts}
                    field={empField}
                    color={"#339DFF"}
                    border={{ color: "#339DFF" }}
                    autoFit={true}
                    labels={{ visible: true }}
                  >
                    <ChartSeriesLabels content={renderLabelContent} />
                  </ChartSeriesItem>
                </ChartSeries>
              </Chart>
            </div>
          </fieldset>
        </div>
      );
    };

    const onViewEditModelClick = () => {
      if (loggedInUser.hasAdminAppPrivilege) {
        navigate(
          `/admin/clients/${clientId}/oe/${oePeriodId}/ratemodel/${newModelVersionId}`
        );
      } else {
        navigate(
          `/clients/${clientId}/oe/${oePeriodId}/ratemodel/${newModelVersionId}`
        );
      }
    };

    const getPercentageIncreaseText = (show, previous, current) => {
      if (!show) {
        return null;
      }

      previous = previous || 0;
      current = current || 0;

      if (previous === current) {
        return (
          <div
            style={{ fontSize: "12px", paddingRight: "5px", color: "black" }}
          >
            0%
          </div>
        );
      } else if (previous === 0 && current !== 0) {
        return (
          <div
            style={{ fontSize: "12px", paddingRight: "5px", color: "black" }}
          >
            100%
          </div>
        );
      }

      const change = (current - previous) / previous;
      const formattedChange = change.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        style: "percent",
      });
      const iconClass = change > 0 ? "red-icon" : "green-icon";
      const arrowIcon = change > 0 ? "▲" : "▼";

      return (
        <div style={{ fontSize: "12px", paddingRight: "5px" }}>
          <span className={iconClass}>
            {arrowIcon}
            {formattedChange}
          </span>
        </div>
      );
    };

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h5 style={{ marginTop: "15px" }}>{title}</h5>
          </div>
          <div style={{ marginTop: "10px" }}>
            {showViewButton && (
              <Button themeColor="info" onClick={onViewEditModelClick}>
                {isRateSubmitted
                  ? "View Contribution Model"
                  : "Edit Contribution Model"}
              </Button>
            )}
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              width: "33%",
              backgroundColor: "#f6f6f6",
              color: "#40D555",
              borderRadius: "5px",
              marginRight: "10px",
              height: "60px",
            }}
          >
            {renderSummary(
              faBuilding,
              data.companyContribution,
              "Company Contribution",
              getPercentageIncreaseText(
                !isCurrent && currentData,
                currentData?.companyContribution,
                data.companyContribution
              )
            )}
          </div>
          <div
            style={{
              width: "33%",
              backgroundColor: "#f6f6f6",
              color: "#339DFF",
              borderRadius: "5px",
              marginRight: "10px",
              height: "60px",
            }}
          >
            {renderSummary(
              faUserGroup,
              data.employeeContribution,
              "Employee Contribution",
              getPercentageIncreaseText(
                !isCurrent && currentData,
                currentData?.employeeContribution,
                data.employeeContribution
              )
            )}
          </div>
          <div
            style={{
              width: "33%",
              backgroundColor: "#f6f6f6",
              borderRadius: "5px",
              height: "60px",
            }}
          >
            {renderSummary(
              faFileInvoiceDollar,
              data.totalContribution,
              "Total Contribution",
              getPercentageIncreaseText(
                !isCurrent && currentData,
                currentData?.totalContribution,
                data.totalContribution
              )
            )}
          </div>
        </div>
        {renderChart(
          data,
          "Total costs",
          "companyContribution",
          "employeeContribution"
        )}
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <div>
          <RenderNav />
        </div>
        <div>
          {!isRateSubmitted && modelVersionsSrc.length > 0 && (
            <RenderVersionBar />
          )}
        </div>
      </div>
      {clientBenefitRepLocal && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            marginBottom: 10,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div>
            <span style={{ marginLeft: 10 }}>
              <b>Benefit Rep:</b> {clientBenefitRepLocal.userName}
            </span>
            <span style={{ marginLeft: 30 }}>
              <b>Email:</b> {clientBenefitRepLocal.email || "N/A"}
            </span>
            <span style={{ marginLeft: 30 }}>
              <b>Ph:</b> {clientBenefitRepLocal.phone || "N/A"}
            </span>
          </div>
          <div></div>
        </div>
      )}
      {clientSalesRepLocal && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            marginBottom: 10,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div>
            <span style={{ marginLeft: 10 }}>
              <b>Sales Rep:</b> {clientSalesRepLocal?.userName}
            </span>
            <span style={{ marginLeft: 30 }}>
              <b>Email:</b> {clientSalesRepLocal?.email || "N/A"}
            </span>
            <span style={{ marginLeft: 30 }}>
              <b>Ph:</b> {clientSalesRepLocal?.phone || "N/A"}
            </span>
          </div>
          <div></div>
        </div>
      )}
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div
          style={{
            width: "50%",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
            marginRight: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <RenderClientSummary
            title={"Current Benefit Cost Per Month"}
            data={currentModelSummaryData}
            isWelcomeMessage={false}
            isCurrent={true}
          />
        </div>
        <div
          style={{
            width: "50%",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
            marginLeft: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <RenderClientSummary
            title={"Projected Benefit Cost Per Month"}
            data={newModelSummaryData}
            currentData={currentModelSummaryData}
            showViewButton={true}
            isWelcomeMessage={isWelcomeMessage}
          />
        </div>
      </div>
      <div
        className="ratesummary"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        {ClientRateModelSummary(newModelDetailData)}
        {!isRateSubmitted && loggedInUser.submitContributionModalPrivilege && (
          <div
            style={{ float: "right", marginTop: "20px", marginBottom: "30px" }}
          >
            <Button
              themeColor={"success"}
              onClick={() => setShowSubmitConfirmModel(true)}
              disabled={false}
            >
              {" "}
              Submit Contribution Model{" "}
            </Button>
          </div>
        )}
      </div>

      {showNewModelVersionDialog && (
        <AddModelVersion
          onModelClose={() => setShowNewModelVersionDialog(false)}
        />
      )}
      {showSubmitConfirmModel && (
        <ConfirmSubmitClientRateModel
          clientId={clientId}
          oePeriodId={oePeriodId}
          versionId={newModelVersionId}
          oePeriod={oePeriod}
          onClose={() => setShowSubmitConfirmModel(false)}
        />
      )}
    </div>
  );
};

export default ClientSummary;
