import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const HideDialog = (props) => {
  return (
    <Dialog title={"Please confirm"} onClose={props.onCancel}>
      <p
        style={{
          margin: "25px",
          textAlign: "center",
        }}
      >
        Are you sure you want to Hide?
      </p>
      <DialogActionsBar>
        <Button onClick={props.onCancel}>{"Cancel"}</Button>
        <Button
          type={"submit"}
          themeColor={"primary"}
          onClick={() => props.onHide(props.item)}
        >
          {"Hide"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default HideDialog;
