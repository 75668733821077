import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";

import { FormUpload } from "../../components/FormUpload";
import { FormDropDownList } from "../../components/FormDropDownList";
import { useQueryGetCarrierList } from "../../data/services/carrierService";
import { useMutateQueueImport } from "../../data/services/importService";

const AddImportModel = (props) => {
  const [carrierListSrc, setCarrierListSrc] = React.useState([]);
  const [carrierTypeListSrc, setCarrierTypeListSrc] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);

  const { isLoading: carrierListLoading, data: carrierList } =
    useQueryGetCarrierList();
  const queueImportHandler = useMutateQueueImport();

  const defaultCarrier = {
    carrierName: "Select Carrier",
  };

  const defaultCarrierType = {
    carrierTypeDesc: "Select Carrier Type",
  };

  React.useEffect(() => {
    if (!carrierListLoading && carrierList && carrierList.data) {
      setCarrierListSrc(carrierList.data);
    }
  }, [carrierListLoading, carrierList]);

  const onSubmit = async (event) => {
    if (
      event.carrierTypeId &&
      event.carrierTypeId.carrierMappingId &&
      event.rateFileId &&
      event.rateFileId.length > 0 &&
      event.rateFileId[0].name
    ) {
      setSubmitted(true);

      var data = {
        carrierMappingId: event.carrierTypeId.carrierMappingId,
        fileType: "R",
        oePeriodId: props.oePeriod.oePeriodId,
        fileName: event.rateFileId[0].name,
        file: event.rateFileId[0].getRawFile(),
      };

      await queueImportHandler.mutateAsync(data);

      props.toggleDialog();
    } else {
      alert("Please select all required fields");
    }
  };

  const onCarrierChange = (event, formRenderProps) => {
    let carrier = carrierList.data.find(
      (carrier) => carrier.carrierId === event.value.carrierId
    );
    setCarrierTypeListSrc(carrier ? carrier.carrierTypes : []);

    formRenderProps.onChange("carrierTypeId", { value: defaultCarrierType });
  };

  return (
    props.visible && (
      <Dialog title={"Import File"} onClose={props.toggleDialog} width={600}>
        <Form
          onSubmit={onSubmit}
          render={(formRenderProps) => (
            <FormElement horizontal={false} style={{ maxWidth: 600 }}>
              <Field
                id={"carrierId"}
                name={"carrierId"}
                label={"Carrier"}
                data={carrierListSrc}
                component={FormDropDownList}
                required={true}
                textField={"carrierName"}
                dataItemKey={"carrierId"}
                defaultItem={defaultCarrier}
                onChange={(event) => onCarrierChange(event, formRenderProps)}
                loading={carrierListLoading}
                validator={(value) =>
                  value?.carrierId ? "" : "Please select a carrier type"
                }
              />
              <Field
                id={"carrierTypeId"}
                name={"carrierTypeId"}
                label={"Carrier Type"}
                data={carrierTypeListSrc}
                required={true}
                textField={"carrierTypeDesc"}
                dataItemKey={"carrierMappingId"}
                defaultItem={defaultCarrierType}
                disabled={carrierTypeListSrc.length === 0}
                component={FormDropDownList}
                validator={(value) =>
                  value?.carrierMappingId ? "" : "Please select a carrier type"
                }
              />
              <Field
                id={"rateFileId"}
                name={"rateFileId"}
                label={"Select Rate File"}
                component={FormUpload}
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                validator={(value) =>
                  value && value.length > 0 ? "" : "Please select a file"
                }
              />
              <div style={{ paddingTop: 40 }}></div>
              <DialogActionsBar layout={"end"}>
                <Button onClick={props.toggleDialog}>{"Cancel"}</Button>
                <Button
                  type={"submit"}
                  themeColor={"primary"}
                  disabled={!formRenderProps.allowSubmit || submitted}
                >
                  {"Import File"}
                </Button>
              </DialogActionsBar>
            </FormElement>
          )}
        />
      </Dialog>
    )
  );
};

export default AddImportModel;
