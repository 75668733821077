import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ paddingLeft: "5%", paddingTop: "5vh", width: "600px" }}>
          <div>
            <h1>Something went wrong &#128542;</h1>
            <h4>
              Please try again later. If error continues please reach out to
              Specialist
            </h4>
          </div>
          <div>
            <Button
              themeColor="primary"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
