import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

import { getPanelSubtitle } from "./panelHelper";
import DataSyncErrorModel from "./DataSyncErrorModel";
import {
  useMutateDataSync,
  useMutateContinueWorkflow,
} from "../../../data/services/workflowService";
import OEWorkflowClientsGrid from "./OEWorkflowClientsGrid";

const SyncClientDetailsPanel = ({
  expanded,
  setExpanded,
  currentStep,
  status,
  oePeriod,
  workflowSetupId,
  currentWorkflowSetup,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isSyncInProgress = stepStatus === "SyncInProgress";
  const isSyncError = stepStatus === "SyncError";
  const isSyncCompleted =
    stepStatus === "SyncCompleted" || stepStatus === "Completed";
  const isInProgress = stepStatus === "InProgress";
  const isStepCompleted = stepStatus === "Completed";

  const [showErrorModel, setShowErrorModel] = React.useState(false);

  const useMutateDataSyncHandler = useMutateDataSync();
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();

  const handleSubmit = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "BuildOEWorkflowClients",
      workflowId: status.oeWorkflowId,
      isRetry: false,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleRetry = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "BuildOEWorkflowClients",
      workflowId: status.oeWorkflowId,
      isRetry: true,
    };

    await useMutateDataSyncHandler.mutateAsync(data);
  };

  const handleContinue = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  const RenderStatusBanner = () => {
    if (isSyncInProgress) {
      return (
        <div
          style={{
            backgroundColor: "#fff3cd",
            padding: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Client data sync and mapping In Progress. Please wait ...
          </span>
        </div>
      );
    } else if (isSyncError) {
      return (
        <div
          style={{
            backgroundColor: "#f8d7da",
            padding: 10,
            marginBottom: 10,
            borderRadius: ".25rem",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "#721c24" }}>
            Data sync failed for few clients. Review errors and continue if
            those are invalid clients.{" "}
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => setShowErrorModel(true)}
            >
              View Error Details
            </span>
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Add clients to workflow`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              Clicking on the <b>Load Clients</b> button below will identity and
              load clients with <b>{currentWorkflowSetup?.carrierName}</b> plans
              to the workflow.
            </p>
            <p>
              Once the clients are loaded, you can review the client and make
              any changes if required.
            </p>
            <RenderStatusBanner />
            {(isInProgress || isSyncInProgress) && (
              <div
                style={{
                  marginTop: "40px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  themeColor={"success"}
                  onClick={handleSubmit}
                  disabled={isSyncInProgress}
                >
                  Load Clients{" "}
                  {isSyncInProgress && (
                    <span className="k-icon k-i-loading"></span>
                  )}
                </Button>
              </div>
            )}
            {(isSyncCompleted || isStepCompleted || isSyncError) && (
              <OEWorkflowClientsGrid
                oePeriodId={oePeriod.oePeriodId}
                workflowSetupId={workflowSetupId}
                isStepCompleted={isStepCompleted}
              />
            )}
            {isSyncError && (
              <>
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button themeColor={"error"} onClick={handleRetry}>
                    Retry Loading Clients
                  </Button>
                  <Button
                    themeColor={"success"}
                    onClick={handleContinue}
                    disabled={isStepCompleted}
                    style={{ marginLeft: 10 }}
                  >
                    Continue to Next Step
                  </Button>
                </div>
              </>
            )}
            {isSyncCompleted && (
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "30px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  themeColor={"success"}
                  onClick={handleContinue}
                  disabled={isStepCompleted}
                >
                  Continue to Next Step
                </Button>
              </div>
            )}
            {showErrorModel && (
              <DataSyncErrorModel
                oePeriodId={oePeriod.oePeriodId}
                workflowId={status.oeWorkflowId}
                onClose={() => setShowErrorModel(false)}
              />
            )}
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default SyncClientDetailsPanel;
