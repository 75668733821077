import * as React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { formatDate } from "@telerik/kendo-intl";
import ErrorBanner from "../../components/ErrorBanner";
import {
    useMutatePostNotificationReminder,
    useQueryGetNotificationReminder
} from "../../data/services/notificationService";
import SuccessBanner from "../../components/SuccessBanner";
import { FormNumericTextbox } from "../../components/FormNumericTextbox";

const NotificationReminders = () => {
    const [initialValues, setInitialValues] = React.useState("");

    const {
        isLoading: notificationReminderLoading,
        data: notificationReminderData
    } = useQueryGetNotificationReminder();

    const useMutatePostNotificationReminderHandler =
        useMutatePostNotificationReminder();

    React.useEffect(() => {
        if (
            !notificationReminderLoading &&
            notificationReminderData &&
            notificationReminderData.data
        ) {
            setInitialValues({
                isValueSat: true,
                sendFirstNotificationInDays: notificationReminderData?.data?.sendFirstNotificationInDays ?? 1,
                sendDefaultingNotificationInDays: notificationReminderData?.data?.sendDefaultingNotificationInDays ?? 15,
                enableNotificationReminder: notificationReminderData?.data?.enableNotificationReminder ?? true
            })
        }
    }, [notificationReminderLoading, notificationReminderData]);

    if (!initialValues.isValueSat) {
        return <LoadingIndicator />;
    }

    const handleSubmit = async (item) => {
        await useMutatePostNotificationReminderHandler.mutateAsync(
            item
        );
    }

    return (
        <div>
            <h2 style={{ marginBlockEnd: 0 }}>Notification Reminders</h2>
            {(notificationReminderData.data.lastDateUpdated && notificationReminderData.data.lastUpdateBy) && <span
                style={{
                    color: "rgb(152 160 167)",
                    fontSize: "0.9em"
                }}
            >Last updated: {formatDate(new Date(notificationReminderData.data.lastDateUpdated + "Z"), "G")} by {notificationReminderData.data.lastUpdateBy} </span>
            }<ErrorBanner
                handler={useMutatePostNotificationReminderHandler}
                onError={() => { }}
            />
            <SuccessBanner
                handler={useMutatePostNotificationReminderHandler}
                message={"Successfully saved."}
            />
            <div style={{ marginTop: "30px" }}>
                <Form
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    render={(formRenderProps) => (
                        <FormElement horizontal={false} style={{ maxWidth: 600 }}>
                            <FieldWrapper>
                                <Field
                                    id={"enableNotificationReminder"}
                                    name={"enableNotificationReminder"}
                                    label={"Enable Notification Reminder"}
                                    component={Checkbox}
                                />
                            </FieldWrapper>
                            <Field
                                id={"sendFirstNotificationInDays"}
                                name={"sendFirstNotificationInDays"}
                                label={"Send first notification in days:"}
                                format={"n0"}
                                component={FormNumericTextbox}
                                required={true}
                                validator={(value) =>
                                    value >= 0 ? "" : "Please enter the number of days"
                                }
                            />
                            <Field
                                id={"sendDefaultingNotificationInDays"}
                                name={"sendDefaultingNotificationInDays"}
                                label={"Send defaulting notification in days:"}
                                format={"n0"}
                                component={FormNumericTextbox}
                                required={true}
                                validator={(value) =>
                                    value >= 0 ? "" : "Please enter the number of days"
                                }
                            />
                            <div style={{ marginTop: "30px" }}>
                                <Button type="submit" themeColor={"success"}>
                                    {"Save"}
                                </Button>
                            </div>
                        </FormElement>
                    )}
                />
            </div>
        </div>
    );
};

export default NotificationReminders;
