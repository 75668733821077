import * as React from "react";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { filterBy, process } from "@progress/kendo-data-query";

import ErrorBanner from "../../components/ErrorBanner";
import DeleteDialog from "../../components/DeleteDialog";

import {
  useQueryGetPlanMappingList,
  useQueryGetPlanListForMapping,
  useMutatePostPlanMapping,
  useMutatePutPlanMapping,
  useMutateDeletePlanMapping,
} from "../../data/services/benefitPlanService";
import { useQueryGetCarrierList } from "../../data/services/carrierService";

const PlanMapping = () => {
  const editField = "inEdit";
  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const [gridDataState, setGridDataState] = React.useState({
    take: 10,
    skip: 0,
  });
  const [data, setData] = React.useState([]);
  const [masterMappingData, setMasterMappingData] = React.useState([]);
  const [carrierList, setCarrierList] = React.useState([]);
  const [planList, setPlanList] = React.useState([]);
  const [isInAddMode, setIsInAddMode] = React.useState(false);

  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();

  const { isLoading: planMappingLoading, data: planMappings } =
    useQueryGetPlanMappingList();
  const { isLoading: carrierListLoading, data: carrierListResp } =
    useQueryGetCarrierList();
  const { isLoading: planListLoading, data: planListResp } =
    useQueryGetPlanListForMapping("MED");

  const useMutatePostPlanMappingHandler = useMutatePostPlanMapping();
  const useMutatePutPlanMappingHandler = useMutatePutPlanMapping();
  const useMutateDeletePlanMappingHandler = useMutateDeletePlanMapping();

  React.useEffect(() => {
    if (!planMappingLoading && planMappings && planMappings.data) {
      setData(planMappings.data);
      setMasterMappingData(planMappings.data);
    }

    if (!carrierListLoading && carrierListResp && carrierListResp.data) {
      setCarrierList(carrierListResp.data);
    }

    if (!planListLoading && planListResp && planListResp.data) {
      let mapped = planListResp.data.map((e) => {
        e.PlanDisName = e.planId + "-" + e.planDesc;
        return e;
      });
      setPlanList(mapped);
    }
  }, [
    planMappingLoading,
    planMappings,
    carrierListLoading,
    carrierListResp,
    planListLoading,
    planListResp,
  ]);

  const CarrierCell = (props) => {
    const handleChange = (e) => {
      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value.carrierName,
        });
      }
    };

    const { dataItem } = props;

    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const dataEntity = carrierList.find((x) => x.carrierName === dataValue);
    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            onChange={handleChange}
            value={dataEntity}
            data={carrierList}
            textField="carrierName"
          />
        ) : (
          dataValue
        )}
      </td>
    );
  };

  const PlanListCell = (props) => {
    const { dataItem } = props;

    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const dataEntity = planList.find((x) => x.planId === dataValue);

    let filteredPlanList = planList.filter(
      (x) =>
        !masterMappingData.some(
          (y) =>
            y.benefitPlanMappingId !== dataItem?.benefitPlanMappingId &&
            y.prismPlanId === x.planId
        ) || x.planId === dataItem?.prismPlanId
    );

    const [planIntData, setPlanIntData] = React.useState(filteredPlanList);

    const filterData = (filter) => {
      const data = filteredPlanList;
      return filterBy(data, filter);
    };

    const filterChange = (event) => {
      setPlanIntData(filterData(event.filter));
    };

    const handleChange = (e) => {
      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value.planId,
        });
      }
    };

    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            onChange={handleChange}
            value={dataEntity}
            filterable={true}
            onFilterChange={filterChange}
            data={planIntData}
            textField="PlanDisName"
          />
        ) : (
          dataEntity?.PlanDisName
        )}
      </td>
    );
  };

  const CommandCell = (props) => {
    const { dataItem } = props;

    const inEdit = dataItem[editField];
    const isNewItem = dataItem.benefitPlanMappingId === undefined;

    return inEdit ? (
      <td className="k-command-cell">
        <Button onClick={() => (isNewItem ? add(dataItem) : update(dataItem))}>
          {isNewItem ? "Add" : "Update"}
        </Button>
        <Button
          onClick={() => (isNewItem ? discard(dataItem) : cancel(dataItem))}
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button onClick={() => enterEdit(dataItem)}>Edit</Button>
        <Button onClick={() => remove(dataItem)}>Remove</Button>
      </td>
    );
  };

  const remove = async (dataItem) => {
    setDeleteItem(dataItem);
    setShowDeleteModel(true);
  };

  const handleDeleteConfirm = async (data) => {
    var entity = {
      benefitPlanMappingId: data.benefitPlanMappingId,
    };

    await useMutateDeletePlanMappingHandler.mutateAsync(entity);

    setShowDeleteModel(false);
    setDeleteItem(null);
    setIsInAddMode(false);
  };

  const add = async (dataItem) => {
    dataItem.inEdit = true;

    let carrierId = carrierList.find(
      (e) => e.carrierName === dataItem.carrierName
    )?.carrierId;

    var entity = {
      carrierId: carrierId,
      planGroup: dataItem.planGroup,
      planName: dataItem.planName,
      prismPlanId: dataItem.prismPlanId,
    };

    await useMutatePostPlanMappingHandler.mutateAsync(entity);

    setIsInAddMode(false);
  };

  const update = async (dataItem) => {
    let carrierId = carrierList.find(
      (e) => e.carrierName === dataItem.carrierName
    ).carrierId;

    var entity = {
      benefitPlanMappingId: dataItem.benefitPlanMappingId,
      carrierId: carrierId,
      planGroup: dataItem.planGroup,
      planName: dataItem.planName,
      prismPlanId: dataItem.prismPlanId,
    };

    await useMutatePutPlanMappingHandler.mutateAsync(entity);

    dataItem.inEdit = false;
    setIsInAddMode(false);
  };

  const discard = (dataItem) => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
    setIsInAddMode(false);
  };

  const cancel = (dataItem) => {
    const originalItem = masterMappingData.find(
      (p) => p.benefitPlanMappingId === dataItem.benefitPlanMappingId
    );
    const newData = data.map((item) =>
      item.benefitPlanMappingId === originalItem.benefitPlanMappingId
        ? originalItem
        : item
    );
    setData(newData);
  };

  const enterEdit = (dataItem) => {
    let newData = data.map((item) =>
      item.benefitPlanMappingId === dataItem.benefitPlanMappingId
        ? {
            ...item,
            inEdit: true,
          }
        : item
    );
    setData(newData);
  };

  const itemChange = (event) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item.benefitPlanMappingId === event.dataItem.benefitPlanMappingId
        ? {
            ...item,
            [field]: event.value,
          }
        : item
    );
    setData(newData);
  };

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
    };
    setData([newDataItem, ...data]);
    setIsInAddMode(true);
    setGridDataState({
      take: 10,
      skip: 0,
    });
  };

  const dataStateChange = (event) => {
    setGridDataState(event.dataState);
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={masterMappingData}
          expanded={true}
        />
      </div>
    );
  };

  const columnMenuHeader = (field) => {
    var active = GridColumnMenuFilter.active(field, gridDataState.filter);
    return active ? "active" : "";
  };

  const rowRender = (trElement, props) => {
    const inEdit = props.dataItem.inEdit;
    const green = {
      backgroundColor: "#fffdaf",
    };
    const trProps = {
      style: inEdit ? green : {},
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <>
      <ErrorBanner handler={useMutatePostPlanMappingHandler} />
      <ErrorBanner handler={useMutatePutPlanMappingHandler} />
      <ErrorBanner handler={useMutateDeletePlanMappingHandler} />
      <Grid
        data={process(data.slice(0), gridDataState)}
        onItemChange={itemChange}
        sortable={true}
        pageable={pagerSetting}
        pageSize={10}
        editField={editField}
        dataItemKey={"benefitPlanMappingId"}
        rowRender={rowRender}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <GridToolbar>
          <Button themeColor="primary" onClick={addNew} disabled={isInAddMode}>
            Add new
          </Button>
        </GridToolbar>
        <Column
          field="carrierName"
          title="Carrier"
          width={"150px"}
          cell={CarrierCell}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("carrierName")}
        />
        <Column
          field="planGroup"
          title="Plan Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planGroup")}
        />
        <Column
          field="planName"
          title="Plan Name"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planName")}
        />
        <Column
          field="prismPlanId"
          title="Prism Plan"
          cell={PlanListCell}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("prismPlanId")}
        />
        <Column cell={CommandCell} width="160px" />
      </Grid>
      {showDeleteModel && (
        <DeleteDialog
          onDelete={handleDeleteConfirm}
          onCancel={() => setShowDeleteModel(false)}
          item={deleteItem}
        />
      )}
    </>
  );
};

export default PlanMapping;
