import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";

import {
  useQueryGetNotificationWaveMailDetails,
  useMutateContinueNotificationWaveStep,
  useMutateResendNotification,
} from "../../data/services/notificationService";

import ErrorBanner from "../../components/ErrorBanner";
import LoadingIndicator from "../../components/LoadingIndicator";
import NotificationErrorDialog from "./NotificationErrorDialog";

import {
  SendClientNotificationInProgress,
  SendClientNotificationError,
  SendClientNotificationCompleted,
} from "./NotificationWaveDetailHelper";

const NotificationClientMails = ({
  waveId,
  oePeriod,
  currentNotificationWave,
  currentStep,
}) => {
  const isSendNotificationInProgress = React.useMemo(
    () => currentNotificationWave?.status === SendClientNotificationInProgress,
    [currentNotificationWave]
  );

  const isSendNotificationCompleted = React.useMemo(
    () => currentNotificationWave?.status === SendClientNotificationCompleted,
    [currentNotificationWave]
  );

  const isSendNotificationError = React.useMemo(
    () => currentNotificationWave?.status === SendClientNotificationError,
    [currentNotificationWave]
  );

  const isNotificationProcessStarted = React.useMemo(
    () =>
      isSendNotificationInProgress ||
      isSendNotificationCompleted ||
      isSendNotificationError,
    [
      isSendNotificationInProgress,
      isSendNotificationCompleted,
      isSendNotificationError,
    ]
  );

  const [notificationWaveClientsList, SetNotificationWaveClients] =
    React.useState([]);
  const [notificationWaveClientsMasterData, SetNotificationWaveClientsMaster] =
    React.useState([]);

  const [showNotificationErrorDialog, setShowNotificationErrorDialog] =
    React.useState(false);
  const [currentNotificationWaveId, setCurrentNotificationWaveId] =
    React.useState();

  const [gridDataState, setGridDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const {
    isLoading: notificationWaveClientsLoading,
    data: notificationWaveClients,
    refetch,
  } = useQueryGetNotificationWaveMailDetails(
    oePeriod?.oePeriodId,
    waveId,
    false
  );
  const useMutateContinueNotificationWaveStepHandler =
    useMutateContinueNotificationWaveStep();
  const useMutateResendNotificationHandler = useMutateResendNotification();

  React.useEffect(() => {
    if (
      !notificationWaveClientsLoading &&
      notificationWaveClients &&
      notificationWaveClients.data
    ) {
      SetNotificationWaveClientsMaster(notificationWaveClients.data);
      SetNotificationWaveClients(
        process(notificationWaveClients.data.slice(0), gridDataState)
      );
    }
  }, [notificationWaveClientsLoading, notificationWaveClients, gridDataState]);

  const handleSendNotification = async () => {
    let reqBody = {
      notificationWaveId: waveId,
      status: SendClientNotificationInProgress,
      oePeriodId: oePeriod?.oePeriodId,
    };

    await useMutateContinueNotificationWaveStepHandler.mutateAsync(reqBody);
  };

  const handleViewNotificationError = (data) => {
    setCurrentNotificationWaveId(data);
    setShowNotificationErrorDialog(true);
  };

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      SetNotificationWaveClients(
        process(notificationWaveClientsMasterData.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={notificationWaveClientsMasterData}
            expanded={true}
          />
        </div>
      );
    };

    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="info"
            onClick={async () => {
              const data = {
                oePeriodId: oePeriod?.oePeriodId,
                notificationWaveId: waveId,
                clientId: props.dataItem.clientId,
                notificationWaveClientId:
                  props.dataItem.notificationWaveClientId,
                userId: props.dataItem.notificationToUserId,
                isBrokerNotification: false,
              };

              await useMutateResendNotificationHandler.mutateAsync(data);
              await refetch();
            }}
            disabled={
              props.dataItem.isResendInProgress ||
              useMutateResendNotificationHandler.isLoading
            }
          >
            Resend{" "}
            {props.dataItem.isResendInProgress && (
              <span className="k-icon k-i-loading"></span>
            )}
          </Button>
        </td>
      );
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={notificationWaveClientsList}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="clientId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("clientId")}
          title="Client Id"
        />
        <Column
          field="clientName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("clientName")}
          title="Client Name"
        />
        <Column
          field="notificationToName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("notificationToName")}
          title="Contact Name"
        />
        <Column
          field="notificationToEmail"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("notificationToEmail")}
          title="Contact Email"
        />
        <Column
          field="notificationDate"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("notificationDate")}
          title="Notification Date"
        />
        <Column
          field="status"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("status")}
          title="Message"
        />
        {(isSendNotificationCompleted || isSendNotificationError) && (
          <Column
            field="Action"
            title="Action"
            width={200}
            cell={actionCell}
            sortable={false}
          />
        )}
      </Grid>
    );
  };

  const StatusCard = ({ message, color, isError, waveId }) => {
    let customStyle = {
      marginLeft: "10px",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "3px",
      paddingBottom: "3px",
      backgroundColor: color,
      borderRadius: "15px",
      color: "white",
      fontSize: "12px",
    };

    if (isError) {
      return (
        <p>
          <span style={customStyle}>
            {message}
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              onClick={() => handleViewNotificationError(waveId)}
            >
              View error details
            </span>
          </span>
        </p>
      );
    } else {
      return (
        <p>
          <span style={customStyle}>{message}</span>
        </p>
      );
    }
  };

  const getNotificationStatus = () => {
    if (currentNotificationWave?.status === SendClientNotificationInProgress) {
      return (
        <StatusCard
          message={"Notification are being sent. Please wait..."}
          color={"orange"}
        />
      );
    } else if (
      currentNotificationWave?.status === SendClientNotificationError
    ) {
      return (
        <StatusCard
          message={"Error sending notification."}
          color={"red"}
          isError={true}
          waveId={waveId}
        />
      );
    } else if (currentNotificationWave?.status === "Completed") {
      return (
        <StatusCard
          message={"Notification successfully sent"}
          color={"darkgreen"}
        />
      );
    } else {
      return null;
    }
  };

  const noOfClientsMissingContact = React.useMemo(() => {
    if (notificationWaveClientsMasterData) {
      return notificationWaveClientsMasterData
        .filter((x) => x.notificationToEmail === null)
        .reduce((acc, curr) => {
          if (acc.indexOf(curr.clientId) === -1) {
            acc.push(curr.clientId);
          }
          return acc;
        }, []).length;
    }
    return 0;
  }, [notificationWaveClientsMasterData]);

  const totalClients = React.useMemo(() => {
    if (notificationWaveClientsMasterData) {
      return notificationWaveClientsMasterData.reduce((acc, curr) => {
        if (acc.indexOf(curr.clientId) === -1) {
          acc.push(curr.clientId);
        }
        return acc;
      }, []).length;
    }
    return 0;
  }, [notificationWaveClientsMasterData]);

  if (notificationWaveClientsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h3 style={{ margin: "10px" }}>
            {noOfClientsMissingContact === 0 &&
              !isNotificationProcessStarted && (
                <div>
                  Below clients will receive notification. Please review and
                  click on Send Notification button to send notification.
                </div>
              )}
            {noOfClientsMissingContact > 0 && !isNotificationProcessStarted && (
              <div style={{ color: "red" }}>
                {noOfClientsMissingContact} of {totalClients} Clients don't have
                contact setup. Please add $OE comment for required contacts in
                PRISM and resync data for those clients before sending
                notification.
              </div>
            )}
            {isNotificationProcessStarted && (
              <div>{getNotificationStatus()}</div>
            )}
          </h3>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button
            onClick={() => handleSendNotification()}
            themeColor={"success"}
            disabled={isNotificationProcessStarted}
          >
            {"Send Notification"}{" "}
            {isSendNotificationInProgress && (
              <span className="k-icon k-i-loading"></span>
            )}
          </Button>
        </div>
      </div>
      <ErrorBanner handler={useMutateContinueNotificationWaveStepHandler} />
      <RenderGrid />
      {showNotificationErrorDialog && (
        <NotificationErrorDialog
          notificationWaveId={currentNotificationWaveId}
          toggleDialog={() => setShowNotificationErrorDialog(false)}
          brokerNotifications={false}
        />
      )}
    </div>
  );
};

export default NotificationClientMails;
