import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { Stepper } from "@progress/kendo-react-layout";
import { Card } from "@progress/kendo-react-layout";

import LoadingIndicator from "../../components/LoadingIndicator";
import AddNotificationWaveModel from "./AddNotificationWaveModel";
import OEContext from "../../layouts/OEContext";

import { useQueryGetNotificationWaves } from "../../data/services/notificationService";
import { getSummaryCurrentSteps } from "./NotificationWaveDetailHelper";

const NotificationWaveGrid = () => {
  const navigate = useNavigate();
  const oePeriod = React.useContext(OEContext);

  const [isAddWaveModelOpen, setIsAddWaveModelOpen] = React.useState(false);
  const [notificationWavesSrc, setNotificationWavesSrc] = React.useState([]);

  const { isLoading: notificationWavesLoading, data: notificationWaves } =
    useQueryGetNotificationWaves(oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (
      !notificationWavesLoading &&
      notificationWaves &&
      notificationWaves.data
    ) {
      setNotificationWavesSrc(notificationWaves.data);
    }
  }, [notificationWavesLoading, notificationWaves]);

  if (notificationWavesLoading) {
    return <LoadingIndicator />;
  }

  const handleOpenAddWindow = () => {
    setIsAddWaveModelOpen(true);
  };

  const MyItemRender = (props) => {
    let data = props.dataItem;
    let stepData = getSummaryCurrentSteps(data);

    return (
      <Card
        style={{
          width: "100%",
          boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
          marginTop: "15px",
          padding: "15px",
        }}
      >
        <div className="stepper-view-container">
          <div className="stepper-view-text">
            <span style={{ fontSize: 16, fontWeight: 600 }}>{data.name}</span>
          </div>
          <div className="stepper-view-control">
            <Stepper
              value={stepData.currentStep}
              items={stepData.steps}
              linear={false}
              mode={"labels"}
              successIcon="k-icon k-i-check-circle"
              errorIcon="k-icon k-i-close-outline"
            />
          </div>
          <div className="stepper-view-action">
            <Button
              onClick={() => {
                navigate(`/admin/notificationwaves/${data.notificationWaveId}`);
              }}
            >
              View
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
          marginTop: 20,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Notification Waves</h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button onClick={handleOpenAddWindow} themeColor={"success"}>
            Add Wave
          </Button>
        </div>
      </div>
      {(!notificationWavesSrc || notificationWavesSrc.length === 0) && (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <h3>No Notification Waves Found. Click "Add Wave" to add new Wave</h3>
        </div>
      )}
      {notificationWavesSrc && notificationWavesSrc.length > 0 && (
        <ListView
          data={notificationWavesSrc}
          item={MyItemRender}
          style={{
            width: "100%",
          }}
        />
      )}
      {isAddWaveModelOpen && (
        <AddNotificationWaveModel
          onSubmit={() => setIsAddWaveModelOpen(false)}
          onClose={() => setIsAddWaveModelOpen(false)}
          oePeriod={oePeriod}
        />
      )}
    </div>
  );
};

export default NotificationWaveGrid;
