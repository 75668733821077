import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Link } from "react-router-dom";

import { getPanelSubtitle } from "./panelHelper";
import { useQueryGetImportSummaryList } from "../../../data/services/importService";
import { ImportStatus } from "../../carrieradmin/ImportStatusHelper";
import { useMutateContinueWorkflow } from "../../../data/services/workflowService";

const ImportRateFilePanel = ({
  expanded,
  setExpanded,
  currentStep,
  oePeriod,
  status,
  workflowSetupId,
  currentWorkflowSetup,
  panelStepId,
}) => {
  const [currentCarrierImportStatus, setCurrentCarrierImportStatus] =
    React.useState();

  const { isLoading: importSummaryListLoading, data: importSummaryList } =
    useQueryGetImportSummaryList(oePeriod?.oePeriodId);
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();

  React.useEffect(() => {
    if (!importSummaryListLoading && importSummaryList?.data) {
      const currentCarrier = importSummaryList.data.find(
        (e) => e.carrierId === currentWorkflowSetup?.carrierId
      );
      setCurrentCarrierImportStatus(currentCarrier);
    }
  }, [importSummaryListLoading, importSummaryList, currentWorkflowSetup]);

  const handleSubmit = async (isSkipped) => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      isSkipped: isSkipped,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Import Rate Files`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              You are ready to start <b>{currentWorkflowSetup?.carrierName}</b>{" "}
              Rate File import. Please navigate to{" "}
              <Link to="/admin/rateimportsummary">Import Screen</Link> to import
              and submit rate files.
            </p>

            <h3>
              Import Status:{" "}
              <b>
                <Link to="/admin/rateimportsummary">
                  {currentCarrierImportStatus?.importStatusDesc ??
                    "Not Started"}
                </Link>
              </b>
            </h3>
            <p
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Note: If rate files are already imported as part of different
              workflow please skip and continue
            </p>
            {status?.isSkipped && (
              <div
                style={{
                  backgroundColor: "#fff3cd",
                  padding: 10,
                  borderRadius: ".25rem",
                  marginTop: "20px",
                }}
              >
                <span style={{ color: "#721c24" }}>
                  Rate import was skipped.
                </span>
              </div>
            )}
            <div
              style={{
                marginTop: "30px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                themeColor={"success"}
                onClick={() => handleSubmit(false)}
                style={{ marginLeft: "10px" }}
                disabled={
                  currentCarrierImportStatus?.importStatus !==
                    ImportStatus.SubmitComplete || currentStep > panelStepId
                }
              >
                Continue to Next Step
              </Button>
              <Button
                themeColor={"warning"}
                onClick={() => handleSubmit(true)}
                style={{ marginLeft: "10px" }}
                disabled={currentStep > panelStepId}
              >
                Skip & Continue
              </Button>
            </div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default ImportRateFilePanel;
