import axios from "axios";
import { HTTP_HEADERS } from "./apiHeader";
import fileDownload from "js-file-download";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/sessiontimeout";
    } else {
      return Promise.reject(error);
    }
  }
);

export const axiosGet = async (url) => {
  return axios.get(url, HTTP_HEADERS().GET);
};

export const axiosPost = async (url, data) => {
  return axios.post(url, JSON.stringify(data), HTTP_HEADERS().POST);
};

export const axiosPut = async (url, data) => {
  return axios.put(url, JSON.stringify(data), HTTP_HEADERS().PUT);
};

export const axiosDelete = async (url, data) => {
  var option = HTTP_HEADERS().DELETE;
  option.data = JSON.stringify(data);
  return axios.delete(url, option);
};

export const axiosFormPost = async (url, formData) => {
  return axios.post(url, formData, HTTP_HEADERS("form").POST);
};

export const axiosFormPut = async (url, formData) => {
  return axios.put(url, formData, HTTP_HEADERS("form").PUT);
};

export const axiosDownload = async (url, fileName) => {
  return axios.get(url, HTTP_HEADERS().GET_FILE).then((res) => {
    let comFileName = fileName;
    try {
      let headerLine = res.headers["Content-Disposition"];
      if (headerLine) {
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        comFileName = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex
        );
      }
    } catch (e) {
      console.log(e);
    }

    fileDownload(res.data, comFileName);
  });
};

export const reactQueryDefaultOptions = {
  staleTime: 1000 * 60 * 5,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true,
  retry: false,
};
