import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormDropDownList } from "../../components/FormDropDownList";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import OEContext from "../../layouts/OEContext";
import { formatDate } from "@telerik/kendo-intl";

const ClientRMDeletePlanModal = ({ onClose, handleSubmit, plans }) => {
  const oePeriod = React.useContext(OEContext);

  const handleSubmitInternal = (item) => {
    handleSubmit(item.plan);
  };

  return (
    <Dialog
      title={"Move employees to different plan"}
      onClose={onClose}
      width={"400px"}
    >
      <Form
        onSubmit={handleSubmitInternal}
        //  initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <p>
              Please select Plan to move the employees to estimate projected
              cost
            </p>

            <Field
              id={"plan"}
              name={"plan"}
              label={"Plan"}
              data={plans}
              component={FormDropDownList}
              required={true}
              autoFocus={true}
              textField={"planName"}
              dataItemKey={"planId"}
              validator={(value) =>
                value?.planId ? "" : "Please select a plan"
              }
            />
            <p style={{ textAlign: "justify", paddingTop: "30px" }}>
              <b>
                Note: Employees moved to another plan only for modeling
                purposes. Employees on this plan will need to elect a new plan
                through the employee open enrollment portal before{" "}
                {formatDate(new Date(oePeriod.effectiveDate), "d")}
              </b>
            </p>
            <div style={{ paddingTop: 30 }}></div>
            <DialogActionsBar layout={"end"}>
              <div style={{ paddingBottom: "8px" }}>
                <Button onClick={onClose} style={{ marginRight: "10px" }}>
                  {"Cancel"}
                </Button>
                <Button
                  type={"submit"}
                  themeColor={"primary"}
                  disabled={!formRenderProps.allowSubmit}
                >
                  {"Save"}
                </Button>
              </div>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default ClientRMDeletePlanModal;
