import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ErrorBanner from "../../components/ErrorBanner";

import { useMutateSubmitModel } from "../../data/services/clientService";

const ConfirmSubmitClientRateModel = ({
  clientId,
  oePeriodId,
  versionId,
  onClose,
  oePeriod,
}) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const useMutateSubmitModelHandler = useMutateSubmitModel();

  let year = new Date(oePeriod.effectiveDate).getFullYear();

  const onSubmitConfirmClick = async () => {
    setIsSubmitted(true);

    let inputData = {
      oePeriodId: oePeriodId,
      clientId: clientId,
      versionId: versionId,
    };

    await useMutateSubmitModelHandler.mutateAsync(inputData);

    setIsSubmitted(false);
    onClose();
  };

  return (
    <Dialog title={"Confirm Contribution Model Submission"} onClose={onClose}>
      <ErrorBanner
        handler={useMutateSubmitModelHandler}
        onError={() => setIsSubmitted(false)}
      />
      <p
        style={{
          margin: "20px",
          textAlign: "center",
        }}
      >
        {`Is this your final decision for plans offered and rate contribution for the ${year} renewal ?`}
      </p>
      <DialogActionsBar layout={"end"}>
        <Button
          type="submit"
          themeColor={"light"}
          disabled={isSubmitted}
          onClick={onClose}
        >
          {"Cancel"}
        </Button>
        <Button
          type="submit"
          themeColor={"primary"}
          disabled={isSubmitted}
          onClick={onSubmitConfirmClick}
        >
          {"Confirm & Submit"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ConfirmSubmitClientRateModel;
