import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ClientMasterSyncGrid from "./ClientMasterSyncGrid";
import ClientBenefitSyncGrid from "./ClientBenefitSyncGrid";

const DataSyncLayout = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleTabSelect = (e) => {
    setSelectedTabIndex(e.selected);
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "start",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <h2>Data Sync</h2>
      </div>
      <TabStrip selected={selectedTabIndex} onSelect={handleTabSelect}>
        <TabStripTab title="Client Master Sync">
          <ClientMasterSyncGrid />
        </TabStripTab>
        <TabStripTab title="Sync Client Benefits">
          <ClientBenefitSyncGrid />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default DataSyncLayout;
