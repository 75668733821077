import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import BenefitGroupMapGrid from "./BenefitGroupMapGrid";

const BenefitGroupMapLayout = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleTabSelect = (e) => {
    setSelectedTabIndex(e.selected);
  };

  return (
    <TabStrip
      selected={selectedTabIndex}
      onSelect={handleTabSelect}
      style={{ paddingTop: 20 }}
    >
      <TabStripTab title="Medical Plan Mapping">
        <BenefitGroupMapGrid
          title={"Medical Plan Benefit Group Mapping"}
          offerType={"MED"}
        />
      </TabStripTab>
      <TabStripTab title="Dental Plan Mapping">
        <BenefitGroupMapGrid
          title={"Dental Plan Benefit Group Mapping"}
          offerType={"DEN"}
        />
      </TabStripTab>
      <TabStripTab title="Vision Plan Mapping">
        <BenefitGroupMapGrid
          title={"Vision Plan Benefit Group Mapping"}
          offerType={"VIS"}
        />
      </TabStripTab>
    </TabStrip>
  );
};

export default BenefitGroupMapLayout;
