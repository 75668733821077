import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Link } from "react-router-dom";
import { Checkbox } from "@progress/kendo-react-inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { getPanelSubtitle } from "./panelHelper";

import { useQueryGetPlanMappingStatus } from "../../../data/services/benefitPlanService";
import { useMutateContinueWorkflow } from "../../../data/services/workflowService";

const MappingPanel = ({
  expanded,
  setExpanded,
  currentStep,
  oePeriod,
  status,
  workflowSetupId,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isCompleted = stepStatus === "Completed";

  const [mappingStatusSrc, setMappingStatusSrc] = React.useState({});
  const [isChecked, setIsChecked] = React.useState(false);

  const { isLoading: mappingStatusLoading, data: mappingStatusData } =
    useQueryGetPlanMappingStatus();
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();

  React.useEffect(() => {
    if (!mappingStatusLoading && mappingStatusData && mappingStatusData.data) {
      setMappingStatusSrc(mappingStatusData.data);
    }
  }, [mappingStatusLoading, mappingStatusData]);

  const handleSubmit = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Prism HR Benefit Plan and Rate Group Mapping`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              Please make sure all required plans have Plan Import Mapping,
              Billing Rate Group Mapping and Benefit Group Mapping setup.
            </p>
            <h3>Plan Mapping</h3>
            <div style={{ paddingLeft: "15px" }}>
              {mappingStatusSrc.importMappingPlanCount ===
                mappingStatusSrc.totalPlans && (
                <p>
                  All <b>{mappingStatusSrc.totalPlans}</b> benefit plans have
                  mapping setup. You can review setup in{" "}
                  <Link to="/admin/planmapping">Mapping Setup Screen</Link>
                </p>
              )}
              {mappingStatusSrc.importMappingPlanCount !==
                mappingStatusSrc.totalPlans && (
                <>
                  <p>
                    <b>
                      {mappingStatusSrc.importMappingPlanCount} of{" "}
                      {mappingStatusSrc.totalPlans}
                    </b>{" "}
                    benefit plans have mapping defined. Please navigate to{" "}
                    <Link to="/admin/planmapping">Mapping Setup Screen</Link> to
                    complete the setup
                  </p>
                </>
              )}
            </div>
            <h3>Benefit Group Mapping</h3>
            <div style={{ paddingLeft: "15px" }}>
              <p>
                Please navigate to{" "}
                <Link to="/admin/benefitgroupmapping">
                  Benefit Group Mapping Screen
                </Link>{" "}
                to complete the setup
              </p>
              <p>
                <b>{mappingStatusSrc.medBGMappingCount}</b> medical benefit
                plans have benefit group setup{" "}
                {mappingStatusSrc.missingMedBGMappingPlanIds &&
                  mappingStatusSrc.missingMedBGMappingPlanIds.length > 0 && (
                    <>
                      and{" "}
                      <b>
                        {mappingStatusSrc.missingMedBGMappingPlanIds.length}{" "}
                        plans
                      </b>{" "}
                      is missing setup
                    </>
                  )}
              </p>
              <p>
                <b>{mappingStatusSrc.denBGMappingCount}</b> dental benefit plans
                have benefit group setup{" "}
                {mappingStatusSrc.missingDenBGMappingPlanIds &&
                  mappingStatusSrc.missingDenBGMappingPlanIds.length > 0 && (
                    <>
                      and{" "}
                      <b>
                        {mappingStatusSrc.missingDenBGMappingPlanIds.length}{" "}
                        plans
                      </b>{" "}
                      is missing setup
                    </>
                  )}
              </p>
              <p>
                <b>{mappingStatusSrc.visBGMappingCount}</b> vision benefit plans
                have benefit group setup{" "}
                {mappingStatusSrc.missingVisBGMappingPlanIds &&
                  mappingStatusSrc.missingVisBGMappingPlanIds.length > 0 && (
                    <>
                      and{" "}
                      <b>
                        {mappingStatusSrc.missingVisBGMappingPlanIds.length}{" "}
                        plans
                      </b>{" "}
                      is missing setup
                    </>
                  )}
              </p>
            </div>
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Checkbox
                defaultChecked={isCompleted}
                disabled={isCompleted}
                label={
                  "Verified that all required benefit plans mapping setup are complete"
                }
                onChange={(checked) => setIsChecked(checked.value)}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                themeColor={"success"}
                onClick={handleSubmit}
                disabled={isCompleted || !isChecked}
              >
                Validate and Continue
              </Button>
              {isCompleted && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    color: "darkgreen",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                />
              )}
            </div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default MappingPanel;
