import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";

import {
  useQueryGetCarrierTypeList,
  useQueryGetCarrierList,
} from "../../data/services/carrierService";
import {
  useMutateDeleteCarrierType,
  useMutateDeleteCarrier,
} from "../../data/services/carrierService";

import DeleteDialog from "../../components/DeleteDialog";
import ErrorBanner from "../../components/ErrorBanner";
import CarrierTypeAddModel from "./CarrierTypeAddModel";
import CarrierAddModel from "./CarrierAddModel";

const CarrierSetupLayout = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const [showCarrierTypeEditDialog, setShowCarrierTypeEditDialog] =
    React.useState(false);
  const [showCarrierTypeDeleteDialog, setShowCarrierTypeDeleteDialog] =
    React.useState(false);
  const [carrierTypeEditItem, setCarrierTypeEditItem] = React.useState();

  const [showCarrierEditDialog, setShowCarrierEditDialog] =
    React.useState(false);
  const [showCarrierDeleteDialog, setShowCarrierDeleteDialog] =
    React.useState(false);
  const [carrierEditItem, setCarrierEditItem] = React.useState();

  const [carrierTypeListSrc, setCarrierTypeListSrc] = React.useState([]);
  const [carrierTypeMasterListSrc, setCarrierTypeMasterListSrc] =
    React.useState([]);
  const [carrierTypeGridDataState, setCarrierTypeGridDataState] =
    React.useState({ take: 20, skip: 0 });

  const [carrierListSrc, setCarrierListSrc] = React.useState([]);
  const [carrierMasterListSrc, setCarrierMasterListSrc] = React.useState([]);
  const [carrierGridDataState, setCarrierGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const { isLoading: carrierTypeLoading, data: carrierTypes } =
    useQueryGetCarrierTypeList();
  const { isLoading: carrierLoading, data: carriers } =
    useQueryGetCarrierList();

  const useMutateDeleteCarrierTypeHandler = useMutateDeleteCarrierType();
  const useMutateDeleteCarrierHandler = useMutateDeleteCarrier();

  React.useEffect(() => {
    if (!carrierTypeLoading && carrierTypes && carrierTypes.data) {
      setCarrierTypeMasterListSrc(carrierTypes.data.slice(0));
      setCarrierTypeListSrc(
        process(carrierTypes.data.slice(0), carrierTypeGridDataState)
      );
    }

    if (!carrierLoading && carriers && carriers.data) {
      setCarrierMasterListSrc(carriers.data.slice(0));
      setCarrierListSrc(process(carriers.data.slice(0), carrierGridDataState));
    }
  }, [
    carrierTypeLoading,
    carrierTypes,
    carrierTypeGridDataState,
    carrierLoading,
    carriers,
    carrierGridDataState,
  ]);

  const handleTabSelect = (e) => {
    setSelectedTabIndex(e.selected);
  };

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const handleOpenCarrierTypeEditwindow = (item) => {
    setCarrierTypeEditItem(item);
    setShowCarrierTypeEditDialog(true);
  };

  const handleOpenCarrierTypeDeletewindow = (item) => {
    setCarrierTypeEditItem(item);
    setShowCarrierTypeDeleteDialog(true);
  };

  const handleOpenCarrierTypeAddWindow = (item) => {
    setCarrierTypeEditItem({});
    setShowCarrierTypeEditDialog(true);
  };

  const handleCarrierTypeDelete = async (item) => {
    await useMutateDeleteCarrierTypeHandler.mutateAsync(item);

    setShowCarrierTypeDeleteDialog(false);
  };

  const handleOpenCarrierEditwindow = (item) => {
    setCarrierEditItem(item);
    setShowCarrierEditDialog(true);
  };

  const handleOpenCarrierDeletewindow = (item) => {
    setCarrierEditItem(item);
    setShowCarrierDeleteDialog(true);
  };

  const handleOpenCarrierAddWindow = (item) => {
    setCarrierEditItem({});
    setShowCarrierEditDialog(true);
  };

  const handleCarrierDelete = async (item) => {
    await useMutateDeleteCarrierHandler.mutateAsync(item);

    setShowCarrierDeleteDialog(false);
  };

  const CarrierRenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="primary"
            onClick={() => handleOpenCarrierEditwindow(props.dataItem)}
          >
            Edit
          </Button>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            onClick={() => handleOpenCarrierDeletewindow(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const carrierTypesColumn = (props) => {
      var data = props.dataItem.carrierTypes
        .map((e) => e.carrierTypeDesc)
        .join();
      return <td>{data}</td>;
    };

    const carrierDataStateChange = (event) => {
      setCarrierListSrc(
        process(carrierMasterListSrc.slice(0), event.dataState)
      );
      setCarrierGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(
        field,
        carrierGridDataState.filter
      );
      return active ? "active" : "";
    };

    const DetailComponent = (props) => {
      const data = props.dataItem.carrierTypes;

      if (data) {
        return (
          <Grid data={data}>
            <Column field="carrierTypeDesc" title="Type" />
            <Column field="rateFileParser" title="Rate File Parser" />
            <Column field="planDetailParser" title="Plan Detail Parser" />
          </Grid>
        );
      }

      return (
        <div
          style={{
            height: "50px",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
            }}
          >
            <div className="k-loading-image" />
          </div>
        </div>
      );
    };

    const expandChange = (event) => {
      event.dataItem.expanded = event.value;

      var item = carrierMasterListSrc.find(
        (e) => e.carrierId === event.dataItem.carrierId
      );
      if (item) {
        item.expanded = event.value;
        setCarrierMasterListSrc([...carrierMasterListSrc]);
      }
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={carrierListSrc}
        {...carrierGridDataState}
        onDataStateChange={carrierDataStateChange}
        detail={DetailComponent}
        expandField="expanded"
        onExpandChange={expandChange}
      >
        <Column
          field="carrierName"
          filter="text"
          columnMenu={ColumnCarrierCheckboxMenu}
          headerClassName={columnMenuHeader("carrierName")}
          title="Carrier Name"
        />
        <Column
          field="CarrierTypes"
          title="Carrier Types"
          cell={carrierTypesColumn}
          sortable={false}
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const CarrierTypeRenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="primary"
            onClick={() => handleOpenCarrierTypeEditwindow(props.dataItem)}
          >
            Edit
          </Button>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            onClick={() => handleOpenCarrierTypeDeletewindow(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const dataStateChange = (event) => {
      setCarrierTypeListSrc(
        process(carrierTypes.data.slice(0), event.dataState)
      );
      setCarrierTypeGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(
        field,
        carrierTypeGridDataState.filter
      );
      return active ? "active" : "";
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={carrierTypeListSrc}
        {...carrierTypeGridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="carrierTypeCode"
          filter="text"
          columnMenu={ColumnCarrierTypeCheckboxMenu}
          headerClassName={columnMenuHeader("carrierTypeCode")}
          title="Code"
        />
        <Column
          field="carrierTypeDesc"
          filter="text"
          columnMenu={ColumnMenu}
          headerClassName={columnMenuHeader("carrierTypeDesc")}
          title="Description"
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          hideSecondFilter={true}
        />
      </div>
    );
  };

  const ColumnCarrierTypeCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={carrierTypes.data}
          expanded={true}
        />
      </div>
    );
  };

  const ColumnCarrierCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={carrierMasterListSrc}
          expanded={true}
        />
      </div>
    );
  };

  return (
    <TabStrip
      selected={selectedTabIndex}
      onSelect={handleTabSelect}
      style={{ paddingTop: 20 }}
    >
      <TabStripTab title="Carrier">
        <div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h2 style={{ margin: "10px", marginBottom: 20 }}>Carrier</h2>
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button
                onClick={handleOpenCarrierAddWindow}
                themeColor={"primary"}
              >
                Add New
              </Button>
            </div>
          </div>
          <ErrorBanner
            handler={useMutateDeleteCarrierHandler}
            onError={() => setShowCarrierDeleteDialog(false)}
          />
          <CarrierRenderGrid />
          {showCarrierEditDialog && (
            <CarrierAddModel
              carrierTypes={carrierTypeMasterListSrc}
              cancelEdit={() => setShowCarrierEditDialog(false)}
              onSubmit={() => setShowCarrierEditDialog(false)}
              item={carrierEditItem}
            />
          )}
          {showCarrierDeleteDialog && (
            <DeleteDialog
              onDelete={handleCarrierDelete}
              onCancel={() => setShowCarrierDeleteDialog(false)}
              item={carrierEditItem}
            />
          )}
        </div>
      </TabStripTab>
      <TabStripTab title="Carrier Type">
        <div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h2 style={{ margin: "10px", marginBottom: 20 }}>Carrier Type</h2>
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button
                onClick={handleOpenCarrierTypeAddWindow}
                themeColor={"primary"}
              >
                Add New
              </Button>
            </div>
          </div>
          <ErrorBanner
            handler={useMutateDeleteCarrierTypeHandler}
            onError={() => setShowCarrierTypeDeleteDialog(false)}
          />
          <CarrierTypeRenderGrid />
          {showCarrierTypeEditDialog && (
            <CarrierTypeAddModel
              cancelEdit={() => setShowCarrierTypeEditDialog(false)}
              onSubmit={() => setShowCarrierTypeEditDialog(false)}
              item={carrierTypeEditItem}
            />
          )}
          {showCarrierTypeDeleteDialog && (
            <DeleteDialog
              onDelete={handleCarrierTypeDelete}
              onCancel={() => setShowCarrierTypeDeleteDialog(false)}
              item={carrierTypeEditItem}
            />
          )}
        </div>
      </TabStripTab>
    </TabStrip>
  );
};

export default CarrierSetupLayout;
