import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormInput } from "../../components/FormInput";
import ErrorBanner from "../../components/ErrorBanner";

import { useMutatePostCarrierType } from "../../data/services/carrierService";

const CarrierTypeAddModel = (props) => {
  const [submitted, setSubmitted] = React.useState(false);

  let isEdit = props.item.carrierTypeId ? true : false;

  const useMutatePostCarrierTypeHandler = useMutatePostCarrierType();

  const handleSubmit = async (item) => {
    var data = {
      carrierTypeCode: item.carrierTypeCode,
      carrierTypeDesc: item.carrierTypeDesc,
    };

    setSubmitted(true);

    if (isEdit) {
      data.carrierTypeId = props.item.carrierTypeId;
      await useMutatePostCarrierTypeHandler.mutateAsync(data);
    } else {
      await useMutatePostCarrierTypeHandler.mutateAsync(data);
    }

    setSubmitted(false);
    props.onSubmit();
  };

  return (
    <Dialog
      title={isEdit ? `Edit ${props.item.carrierTypeDesc}` : "Add"}
      onClose={props.cancelEdit}
      width={400}
    >
      <ErrorBanner
        handler={useMutatePostCarrierTypeHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"carrierTypeCode"}
              name={"carrierTypeCode"}
              label={"Carrier Type Code"}
              autoFocus={true}
              component={FormInput}
              required={true}
              disabled={isEdit}
              validator={(value) =>
                value ? "" : "Please enter carrier type code"
              }
            />
            <Field
              id={"carrierTypeDesc"}
              name={"carrierTypeDesc"}
              label={"Carrier Type Desc"}
              required={true}
              component={FormInput}
              validator={(value) => (value ? "" : "Please enter description")}
            />
            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={props.cancelEdit}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default CarrierTypeAddModel;
