import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";

import {
  useQueryGetBrokerUsersList,
  useMutateDeleteBrokerUser,
} from "../../data/services/brokerUserService";

import DeleteDialog from "../../components/DeleteDialog";
import ErrorBanner from "../../components/ErrorBanner";
import LoadingIndicator from "../../components/LoadingIndicator";
import AddBrokerUser from "./AddBrokerUser";

const BrokerUserList = () => {
  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [editItem, setEditItem] = React.useState();

  const [brokerUsers, SetBrokerUsers] = React.useState([]);
  const [brokerUserMasterData, SetBrokerUsersMaster] = React.useState([]);

  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: brokerUserApiLoading, data: brokerUserApiResponse } =
    useQueryGetBrokerUsersList();
  const useMutateDeleteBrokerUserHandler = useMutateDeleteBrokerUser();

  React.useEffect(() => {
    if (
      !brokerUserApiLoading &&
      brokerUserApiResponse &&
      brokerUserApiResponse.data
    ) {
      SetBrokerUsersMaster(brokerUserApiResponse.data);
      SetBrokerUsers(
        process(brokerUserApiResponse.data.slice(0), gridDataState)
      );
    }
  }, [brokerUserApiLoading, brokerUserApiResponse, gridDataState]);

  if (brokerUserApiLoading) {
    return <LoadingIndicator />;
  }

  const handleCancelEdit = () => {
    setOpenEditForm(false);
  };

  const handleSubmit = () => {
    setOpenEditForm(false);
  };

  const handleOpenDeleteWindow = (item) => {
    setEditItem(item);
    setShowDeleteDialog(true);
  };

  const handleOpenEditWindow = (item) => {
    setEditItem(item);
    setOpenEditForm(true);
  };

  const handleOpenAddWindow = (item) => {
    setEditItem({});
    setOpenEditForm(true);
  };

  const handleDelete = async (item) => {
    let data = {
      userId: item.userId,
    };

    await useMutateDeleteBrokerUserHandler.mutateAsync(data);

    setShowDeleteDialog(false);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="primary"
            style={{ marginLeft: 10 }}
            onClick={() => handleOpenEditWindow(props.dataItem)}
          >
            Edit
          </Button>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            onClick={() => handleOpenDeleteWindow(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const clientsCell = (props) => {
      return (
        <td
          title={`${props.dataItem.clients.map((e) => e.clientId).join(", ")}`}
        >
          {`${props.dataItem.clients.length} Clients`}
        </td>
      );
    };

    const dataStateChange = (event) => {
      SetBrokerUsers(process(brokerUserMasterData.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={brokerUsers}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="userId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("userId")}
          title="User Id"
        />
        <Column
          field="firstName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("firstName")}
          title="First Name"
        />
        <Column
          field="lastName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("lastName")}
          title="Last Name"
        />
        <Column
          field="userEmail"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("userEmail")}
          title="Email"
        />
        <Column
          field="clients"
          title="Clients"
          cell={clientsCell}
          sortable={false}
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={brokerUserMasterData}
          expanded={true}
        />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Manage Broker Users</h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button onClick={handleOpenAddWindow} themeColor={"success"}>
            Add User
          </Button>
        </div>
      </div>
      <ErrorBanner
        handler={useMutateDeleteBrokerUser}
        onError={() => setShowDeleteDialog(false)}
      />
      <RenderGrid />
      {openEditForm && (
        <AddBrokerUser
          onClose={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}
      {showDeleteDialog && (
        <DeleteDialog
          onDelete={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
          item={editItem}
        />
      )}
    </div>
  );
};

export default BrokerUserList;
