import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";

import { FormUpload } from "../../components/FormUpload";

import { SERVICE_URLS } from "../../data/helpers/serviceUrls";
import { useMutateUploadUserGuide } from "../../data/services/clientAdminService";
import SuccessBanner from "../../components/SuccessBanner";

const UserGuideSetup = () => {
  const [previewUrl, setPreviewUrl] = React.useState(
    SERVICE_URLS.USER_GUIDE_DOWNLOAD
  );
  const [submitted, setSubmitted] = React.useState(false);

  const useMutateUploadUserGuideHandler = useMutateUploadUserGuide();

  const onSubmit = async (event) => {
    if (
      event.userguidefile &&
      event.userguidefile.length > 0 &&
      event.userguidefile[0].name
    ) {
      setSubmitted(true);

      var data = {
        fileName: event.userguidefile[0].name,
        file: event.userguidefile[0].getRawFile(),
      };

      await useMutateUploadUserGuideHandler.mutateAsync(data);

      setSubmitted(false);
      setPreviewUrl(SERVICE_URLS.USER_GUIDE_DOWNLOAD);
    } else {
      alert("Please select all required fields");
    }
  };

  return (
    <div>
      <h2>Upload User Guide</h2>
      <SuccessBanner
        message="User Guide uploaded successfully"
        handler={useMutateUploadUserGuideHandler}
      />
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps) => (
          <FormElement horizontal={false}>
            <Field
              id={"userguidefile"}
              name={"userguidefile"}
              component={FormUpload}
              restrictions={{
                allowedExtensions: [".pdf"],
              }}
              validator={(value) =>
                value && value.length > 0 ? "" : "Please select a file"
              }
            />
            <div style={{ paddingTop: 20 }}></div>
            <div>
              <Button
                type={"submit"}
                themeColor={"success"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {"Upload User Guide"}
              </Button>
            </div>
          </FormElement>
        )}
      />
      <p style={{ marginTop: "50px" }}>
        <hr />
        <h2 style={{ textAlign: "center" }}>Preview</h2>
        {!submitted && (
          <iframe
            src={previewUrl}
            style={{ border: "none" }}
            width={"100%"}
            height={"800px"}
            title="User Guide Preview"
          ></iframe>
        )}
      </p>
    </div>
  );
};

export default UserGuideSetup;
