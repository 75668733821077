import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { getPanelSubtitle } from "./panelHelper";
import { useMutateContinueWorkflow } from "../../../data/services/workflowService";

const SendNotificationPanel = ({
  expanded,
  setExpanded,
  currentStep,
  status,
  oePeriod,
  workflowSetupId,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isStepCompleted = stepStatus === "Completed";

  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();

  const handleContinue = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      stepId: panelStepId,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  return (
    <ExpansionPanel
      title={`${panelStepId}) Ready to send Notification`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>Workflow is ready to send notification to client Contacts</p>
            <p>
              Please setup notification waves in{" "}
              <Link to="/admin/notificationwaves">Waves Setup Screen</Link>.
              When ready trigger notification process
            </p>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                themeColor={"success"}
                onClick={handleContinue}
                disabled={isStepCompleted}
              >
                Complete Workflow
              </Button>
              {isStepCompleted && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    color: "darkgreen",
                    marginLeft: "10px",
                    marginTop: "8px",
                  }}
                />
              )}
            </div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default SendNotificationPanel;
