import * as React from "react";
import { useParams } from "react-router-dom";
import OEContext from "../../layouts/OEContext";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

import { useQueryGetEmpClientMenuDocuments } from "../../data/services/clientAdminService";

const ClientEmpMenuGrid = () => {
  const { clientId } = useParams();
  const oePeriod = React.useContext(OEContext);

  const [gridDataSrc, setGridDataSrc] = React.useState([]);
  const [previousGridDataSrc, setPreviousGridDataSrc] = React.useState([]);

  const { isLoading: menuDocumentsLoading, data: menuDocuments } =
    useQueryGetEmpClientMenuDocuments(oePeriod?.oePeriodId, clientId);

  React.useEffect(() => {
    if (!menuDocumentsLoading && menuDocuments && menuDocuments.data) {
      const currentMenus = menuDocuments.data.filter(
        (data) => data.menus && data.menus.length > 0
      );
      const previousMenus = menuDocuments.data
        .filter((data) => data.previousMenus && data.previousMenus.length > 0)
        .map((data) => {
          return {
            ...data,
            menus: data.previousMenus,
          };
        });

      setGridDataSrc([...currentMenus]);
      setPreviousGridDataSrc([...previousMenus]);
    }
  }, [menuDocumentsLoading, menuDocuments]);

  if (menuDocumentsLoading) {
    return "Loading ...";
  }

  return (
    <div>
      <div style={{ paddingBottom: 10 }}>
        <h2 style={{ margin: "10px" }}>Benefit Employee Menus</h2>
      </div>
      <div style={{ minWidth: "75vw" }}>
        <RenderDocs data={gridDataSrc} />
      </div>
      <div style={{ paddingBottom: 10 }}>
        <h2 style={{ margin: "10px", marginTop: "40px" }}>
          Previous Year Benefit Employee Menus
        </h2>
      </div>
      <div style={{ minWidth: "75vw" }}>
        <RenderDocs previousYear={true} data={previousGridDataSrc} />
      </div>
    </div>
  );
};

const RenderDocs = ({ data, previousYear }) => {
  const { clientId } = useParams();

  const [expanded, setExpanded] = React.useState();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [downloadId, setDownloadId] = React.useState(null);
  const [docType, setDocType] = React.useState(null);

  const downloadFile = async (item, docType) => {
    setIsDownloading(true);
    setDownloadId(item.clientEmpMenuDocumentId);
    setDocType(docType);
    await axiosDownload(
      SERVICE_URLS.CLIENT_ADMIN_EMP_MENU_DOCUMENT_DOWNLOAD(
        item.oePeriodId,
        clientId,
        item.clientEmpMenuDocumentId,
        docType
      ),
      docType === "pdf" ? item.fileName : item.excelFileName
    );
    setIsDownloading(false);
  };

  const MenuCards = ({ menuCardData }) => {
    return menuCardData.map((doc, index) => {
      return (
        <Card style={{ width: 300, margin: "10px" }} key={index} type="info">
          <CardBody>
            <CardTitle>
              <b>{doc.documentType} Benefit Menu</b>
            </CardTitle>
            <p>
              Please select the desired file format below to initiate the
              download
            </p>
            <p>
              <Button
                themeColor={"primary"}
                className={
                  "oe-multiline-button k-button-flat k-button-flat-primary"
                }
                onClick={() => downloadFile(doc, "pdf")}
                disabled={isDownloading}
              >
                {doc.fileName}{" "}
                {isDownloading &&
                downloadId === doc.clientEmpMenuDocumentId &&
                docType === "pdf" ? (
                  <span className="k-icon k-i-loading"></span>
                ) : (
                  ""
                )}
              </Button>
            </p>
            <p>
              {doc.excelFileName && (
                <Button
                  themeColor={"primary"}
                  className={
                    "oe-multiline-button k-button-flat k-button-flat-primary"
                  }
                  onClick={() => downloadFile(doc, "excel")}
                  disabled={isDownloading}
                >
                  {doc.excelFileName}{" "}
                  {isDownloading &&
                  downloadId === doc.clientEmpMenuDocumentId &&
                  docType === "excel" ? (
                    <span className="k-icon k-i-loading"></span>
                  ) : (
                    ""
                  )}
                </Button>
              )}
            </p>
          </CardBody>
        </Card>
      );
    });
  };

  if (data.length === 0) {
    if (previousYear) {
      return (
        <div style={{ paddingLeft: "20px" }}>
          No documents found for previous year
        </div>
      );
    } else {
      return (
        <div style={{ paddingLeft: "20px" }}>
          No employee benefit menu documents found. Employee benefit menu will
          be available once the rates are submitted and published
        </div>
      );
    }
  }

  return data.map((data) => {
    return (
      <ExpansionPanel
        title={data.groupName}
        expanded={expanded === data.groupId}
        tabIndex={0}
        key={data.groupId}
        onAction={(event) => {
          setExpanded(event.expanded ? "" : data.groupId);
        }}
      >
        <Reveal>
          {expanded === data.groupId && (
            <ExpansionPanelContent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <MenuCards menuCardData={data.menus} />
              </div>
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
    );
  });
};

export default ClientEmpMenuGrid;
