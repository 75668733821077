import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { process, filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ListView } from "@progress/kendo-react-listview";

import UserContext from "../../layouts/UserContext";
import OEContext from "../../layouts/OEContext";
import ErrorBanner from "../../components/ErrorBanner";
import { FormMultiSelect } from "../../components/FormMultiSelect";

import {
  useQueryGetDataSyncHistory,
  useMutateBulkDataSync,
} from "../../data/services/workflowService";
import { useQueryGetClientsMasterList } from "../../data/services/clientAdminService";

const pagerSetting = {
  buttonCount: 5,
  info: true,
  type: "numeric",
  pageSizes: [10, 20, 50, 100, 200, 500],
  previousNext: true,
};

const ClientBenefitSyncGrid = () => {
  const oePeriod = React.useContext(OEContext);
  const { loggedInUser } = React.useContext(UserContext);

  const [gridDataSrc, setGridDataSrc] = React.useState([]);
  const [gridDataMasterSrc, SetGridDataMasterSrc] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });
  const [showSyncDialog, setShowSyncDialog] = React.useState(false);

  const { isLoading: historyLoading, data: historyList } =
    useQueryGetDataSyncHistory(
      "IndividualClientBenefits",
      oePeriod?.oePeriodId
    );

  React.useEffect(() => {
    if (!historyLoading && historyList && historyList.data) {
      SetGridDataMasterSrc(historyList.data);
      setGridDataSrc(process(historyList.data.slice(0), gridDataState));
    }
  }, [historyLoading, historyList, gridDataState]);

  if (historyLoading) {
    return "Loading ...";
  }

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setGridDataSrc(process(gridDataMasterSrc.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={gridDataMasterSrc}
            expanded={true}
          />
        </div>
      );
    };

    const DateCell = (props) => {
      return (
        <td>
          {props.dataItem[props.field] &&
            new Date(props.dataItem[props.field] + "Z").toLocaleString("en-US")}
        </td>
      );
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={gridDataSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="clientId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("clientId")}
          title="Client Id"
        />
        <Column
          field="startDate"
          filter="text"
          title="Start Date"
          cell={DateCell}
        />
        <Column
          field="endDate"
          filter="text"
          title="End Date"
          cell={DateCell}
        />
        <Column
          field="syncStatusDesc"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("syncStatusDesc")}
          title="Sync Status"
        />
        <Column field="errorMessage" filter="text" title="Error Message" />
      </Grid>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>
            Individual Client Benefits Sync History
          </h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          {loggedInUser && loggedInUser.hasDataSyncPriv && (
            <Button
              onClick={() => setShowSyncDialog(true)}
              themeColor={"success"}
            >
              Sync Client & Rebuild Model
            </Button>
          )}
        </div>
      </div>
      <RenderGrid />
      {showSyncDialog && (
        <ClientBenefitSyncModal
          oePeriod={oePeriod}
          onClose={() => {
            setShowSyncDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default ClientBenefitSyncGrid;

const ClientBenefitSyncModal = ({ oePeriod, onClose }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [clientsDataSource, setClientsDataSource] = React.useState([]);
  const [selectedClients, setSelectedClients] = React.useState([]);

  const { isLoading: availableClientsLoading, data: availableClients } =
    useQueryGetClientsMasterList();
  const useMutateDataSyncHandler = useMutateBulkDataSync();

  React.useEffect(() => {
    if (!availableClientsLoading && availableClients && availableClients.data) {
      let tempAvailableClients = availableClients.data.map((client) => {
        client.clientDispName = `${client.clientId} - ${client.clientName}`;
        return client;
      });

      if (tempAvailableClients.length > 0) {
        setClients(tempAvailableClients);
        setClientsDataSource(tempAvailableClients.slice());
      } else {
        setClients([]);
        setClientsDataSource([]);
      }
    }
  }, [availableClientsLoading, availableClients]);

  const handleSubmit = async () => {
    let data = selectedClients.map((client) => {
      return {
        oePeriodId: oePeriod.oePeriodId,
        dataType: "IndividualClientBenefits",
        clientId: client.clientId,
      };
    });

    await useMutateDataSyncHandler.mutateAsync(data);

    onClose();
  };

  const handleChange = (event) => {
    setSelectedClients(event.value);
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const filterChange = (event) => {
    setClientsDataSource(filterBy(clients.slice(), event.filter));
  };

  const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="k-listview-item"
        style={{
          padding: 10,
          borderBottom: "1px solid lightgrey",
        }}
      >
        {item.clientDispName}
      </div>
    );
  };

  const selectedClientsValidator = (value) =>
    selectedClients.length > 0 ? "" : "Please select at least one client.";

  const selected = selectedClients.length;

  return (
    <Dialog title={"Sync Client & Rebuild Model"} onClose={onClose} width={400}>
      <ErrorBanner
        handler={useMutateDataSyncHandler}
        onError={() => setSubmitted(false)}
      />
      {clients && clients.length > 0 && (
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement horizontal={false} style={{ maxWidth: 400 }}>
              <Field
                id={"clientIds"}
                name={"clientIds"}
                label={"Select clients to sync"}
                component={FormMultiSelect}
                required={true}
                validator={selectedClientsValidator}
                data={clientsDataSource}
                itemRender={itemRender}
                textField={"clientDispName"}
                dataItemKey={"clientId"}
                onChange={handleChange}
                autoClose={false}
                value={selectedClients}
                filterable={true}
                onFilterChange={filterChange}
                tags={
                  selected > 0
                    ? [
                        {
                          text: `${selected} clients selected`,
                          data: [...selectedClients],
                        },
                      ]
                    : []
                }
              />
              <ListView
                data={selectedClients}
                item={MyItemRender}
                style={{
                  width: "100%",
                  maxHeight: 300,
                }}
              />
              <p>
                Note: This will sync the selected clients and rebuild the model.
                Any existing model data will be overwritten.
              </p>
              <div style={{ paddingTop: 20 }}></div>
              <DialogActionsBar layout={"end"}>
                <Button onClick={onClose}>{"Cancel"}</Button>
                <Button
                  type={"submit"}
                  themeColor={"primary"}
                  disabled={!formRenderProps.allowSubmit || submitted}
                >
                  {"Start Sync"}
                </Button>
              </DialogActionsBar>
            </FormElement>
          )}
        />
      )}
      {(!clients || clients.length === 0) && <span>Loading... </span>}
    </Dialog>
  );
};
