import * as React from "react";
import { useParams } from "react-router-dom";
import OEContext from "../../layouts/OEContext";
import { Card, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useQueryGetClientMenuDocuments } from "../../data/services/clientAdminService";
import { useQueryGetAllClientMenuAll } from "../../data/services/clientAdminService";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { Hint } from "@progress/kendo-react-labels";

const ClientRateMenuGrid = () => {
  const { clientId } = useParams();
  const oePeriod = React.useContext(OEContext);

  const [prevOEPeriod, setPrevOEPeriod] = React.useState([]);

  const [gridDataSrc, setGridDataSrc] = React.useState([]);
  const [previousGridDataSrc, setPreviousGridDataSrc] = React.useState([]);

  const { isLoading: menuDocumentsLoading, data: menuDocuments } =
    useQueryGetClientMenuDocuments(oePeriod?.oePeriodId, clientId);

    const { isLoading: menusAllLoading, data: allMenus } =
    useQueryGetAllClientMenuAll(oePeriod?.oePeriodId, clientId);

    const { isLoading: prevMenusAllLoading, data: prevMenus } =
    useQueryGetAllClientMenuAll(prevOEPeriod, clientId);
  

  React.useEffect(() => {
    if (!menuDocumentsLoading && menuDocuments && menuDocuments.data) {
      const currentMenus = menuDocuments.data.filter((e) => !e.isPreviousYear);
      const previousMenus = menuDocuments.data.filter((e) => e.isPreviousYear);
      if (previousMenus.length > 0) {
        setPrevOEPeriod(previousMenus[0].oePeriodId)
      }
      setGridDataSrc([...currentMenus]);
      setPreviousGridDataSrc([...previousMenus]);
    }
  }, [menuDocumentsLoading, menuDocuments]);

  if (menuDocumentsLoading ) {
    return "Loading ...";
  }

  return (
    <div>
      <div style={{ paddingBottom: 10 }}>
        <h2 style={{ margin: "10px" }}>Full Rate Benefit Menus</h2>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <RenderDocs data={gridDataSrc} menusAllLoading={menusAllLoading} menus={allMenus?.data} />
      </div>
      <div style={{ paddingBottom: 10 }}>
        <h2 style={{ margin: "10px", marginTop: "40px" }}>
          Previous Year Full Rate Benefit Menus
        </h2>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <RenderDocs data={previousGridDataSrc} previousYear={true}  menusAllLoading={prevMenusAllLoading} menus={prevMenus?.data} />
      </div>
    </div>
  );
};

const RenderDocs = ({ data, previousYear,menusAllLoading ,menus}) => {
  const { clientId } = useParams();

  const [isDownloading, setIsDownloading] = React.useState(false);
  const [downloadId, setDownloadId] = React.useState(null);
  const [docType, setDocType] = React.useState(null);
  const [selectedDoc, setSelectedDoc] = React.useState(null);
  const [ratemenuFilterVisible, setRatemenuFilterVisible] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState([]);
  const [planwithType, setPlanwithType] = React.useState([]);

  React.useEffect(() => {
    setPlanwithType(menus?.filter((plan) => plan.planOfferType == getOfferType(selectedDoc?.documentType)))
  },[menus,selectedDoc])



  const onChange = (event) => {
    setSelectedPlan([...event.target.value].slice(0, 5));
  };

  const getOfferType = (type) =>{
    switch (type) {
      case "Medical":
        return "MED"
      case "Dental":
        return "DEN"
      case "Vision":
        return "VIS"
      default:
        break;
    }

  }

  const downloadFile = async (item, docType, isAllMenus) => {
    setIsDownloading(true);
    setDocType(docType);
    setDownloadId(isAllMenus ? item.clientMenuDocumentId : null);

    const fileName = docType === "pdf" ? item.fileName : item.excelFileName;
    const planIds = isAllMenus ? planwithType.map((plan) => plan.planId) : selectedPlan.map((plan) => plan.planId);
    // const planIds = isAllMenus ? null : selectedPlan.map((plan) => plan.planId);
    // const offerType = isAllMenus ? null : getOfferType(selectedDoc?.documentType);
    const offerType = getOfferType(selectedDoc?.documentType)
    await axiosDownload(
      SERVICE_URLS.CLIENT_ADMIN_MENU_DOCUMENT_DOWNLOAD(
        item.oePeriodId,
        clientId,
        item.clientMenuDocumentId,
        docType,
        planIds,
        offerType
      ),
      fileName
    );
  
    setRatemenuFilterVisible(false);
    setIsDownloading(false);
  };

  if (data.length === 0) {
    if (previousYear) {
      return <div>No benefit menu documents found for previous year.</div>;
    } else {
      return <div>No benefit menu documents found.</div>;
    }
  }

  return data.map((doc, index) => {
    return (
      <Card style={{ width: 300, margin: "10px" }} key={index} type="info">
        {ratemenuFilterVisible &&
        <Dialog  title={`${selectedDoc.documentType} Benefit Filter Menus`}   onClose={() => setRatemenuFilterVisible(false)} width={"60vw"} >
          <div>
            {planwithType?.length !=0 ?
            <>
          <MultiSelect
                    data={planwithType}
                    onChange={onChange}
                    loading={menusAllLoading}
                    dataItemKey="planId"
                    max
                    textField="planName"
                    value={selectedPlan}
                    placeholder="Please select ..."
                  /> 
                  <Hint id="sport">You can choose upto 5 plans </Hint>
                  </>: <div>There are no multiple plans to be select.
                    Please click download button to get plan details </div>
            }
                  
          </div>
          <DialogActionsBar layout={"end"}>
            <div style={{ paddingRight: "8px", paddingBottom: "8px" }}>
              {(!selectedPlan.length >0 || planwithType?.length ==0) ?
            <Button   disabled ={isDownloading} themeColor={"primary"} onClick={() =>  downloadFile(selectedDoc,docType, true)} style={{ marginRight: "10px" }}>
            {isDownloading ?  <Loader style={{marginBottom:2,marginLeft:5}} size="small" type={'infinite-spinner'} themeColor="light"/> : null} {"Download All"}
              </Button> :
            <Button   disabled ={isDownloading} themeColor={"primary"} onClick={() =>  downloadFile(selectedDoc,docType,false)} style={{ marginRight: "10px" }}>
            {isDownloading ?  <Loader style={{marginBottom:2,marginLeft:5}} size="small" type={'infinite-spinner'} themeColor="light"/> : null} {"Download Selected"}
              </Button>
            }
              <Button onClick={() => setRatemenuFilterVisible(false)} style={{ marginRight: "10px" }} >
                {"Close"}
              </Button>
            </div>
          </DialogActionsBar>
        </Dialog>
        }
        <CardBody>
          <CardTitle>
            <b>{doc.documentType} Benefit Menu</b>
          </CardTitle>
          <p>
            Please select the desired file format below to initiate the download
          </p>
          <p>
            <Button
              themeColor={"primary"}
              className={
                "oe-multiline-button k-button-flat k-button-flat-primary"
              }
              onClick={() =>  {
                setRatemenuFilterVisible(true);
                setSelectedDoc(doc);
                setDocType("pdf");
              }}
              
              disabled={isDownloading}
            >
              {doc.fileName}{" "}
              {isDownloading &&
                downloadId === doc.clientMenuDocumentId &&
                docType === "pdf" ? (
                <span className="k-icon k-i-loading"></span>
              ) : (
                ""
              )}
            </Button>
          </p>
          <p>
            {doc.excelFileName && (
              <Button
                themeColor={"primary"}
                className={
                  "oe-multiline-button k-button-flat k-button-flat-primary"
                }
                 onClick={() =>  {
                  setRatemenuFilterVisible(true);
                  setSelectedDoc(doc);
                  setDocType("excel");
                }}
                disabled={isDownloading}
              >
                {doc.excelFileName}{" "}
                {isDownloading &&
                  downloadId === doc.clientMenuDocumentId &&
                  docType === "excel" ? (
                  <span className="k-icon k-i-loading"></span>
                ) : (
                  ""
                )}
              </Button>
            )}
          </p>
        </CardBody>
      </Card>
    );
  });
};

export default ClientRateMenuGrid;
