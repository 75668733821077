import * as React from "react";
import { useParams } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import NotificationWaveClients from "./NotifictionWaveClients";
import NotificationWelcomeLetter from "./NotificationWelcomeLetter";
import NotificationBrokerMails from "./NotificationBrokerMails";
import NotificationClientMails from "./NotificationClientMails";
import LoadingIndicator from "../../components/LoadingIndicator";
import OEContext from "../../layouts/OEContext";

import { useQueryGetNotificationWaves } from "../../data/services/notificationService";
import {
  getCurrentStep,
  getPanelSubtitle,
} from "./NotificationWaveDetailHelper";

const NotificationWaveDetail = () => {
  const { waveId } = useParams();
  const oePeriod = React.useContext(OEContext);

  const [currentNotificationWave, setCurrentNotificationWave] =
    React.useState();
  const [expanded, setExpanded] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [userClicked, setUserClicked] = React.useState(false);

  const { isLoading: notificationWavesLoading, data: notificationWaves } =
    useQueryGetNotificationWaves(oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (
      !notificationWavesLoading &&
      notificationWaves &&
      notificationWaves.data
    ) {
      let currentWave = notificationWaves.data.find(
        (e) => e.notificationWaveId === Number(waveId)
      );
      setCurrentNotificationWave(currentWave);
      if (!userClicked) {
        const currentStep = getCurrentStep(currentWave);
        setCurrentStep(currentStep);
        setExpanded(currentStep);
      }
    }
  }, [notificationWavesLoading, notificationWaves, waveId, userClicked]);

  const updateExpanded = (panelId) => {
    setUserClicked(true);
    setExpanded(panelId);
  };

  if (notificationWavesLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <h2>
        Notification Wave - <b>{currentNotificationWave?.name}</b>
      </h2>
      <p>{currentNotificationWave?.description}</p>

      <ExpansionPanel
        title={"1) Select Clients to Notify"}
        subtitle={getPanelSubtitle(currentNotificationWave, 1, currentStep)}
        expanded={expanded === 1}
        onAction={(event) => {
          updateExpanded(event.expanded ? 0 : 1);
        }}
      >
        <Reveal>
          {expanded === 1 && (
            <ExpansionPanelContent>
              <NotificationWaveClients
                waveId={waveId}
                oePeriod={oePeriod}
                currentNotificationWave={currentNotificationWave}
              />
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
      <ExpansionPanel
        title={"2) Upload Welcome Letter for Selected Clients"}
        subtitle={getPanelSubtitle(currentNotificationWave, 2, currentStep)}
        expanded={expanded === 2}
        disabled={currentStep < 2}
        onAction={(event) => {
          updateExpanded(event.expanded ? 0 : 2);
        }}
      >
        <Reveal>
          {expanded === 2 && (
            <ExpansionPanelContent>
              <NotificationWelcomeLetter
                waveId={waveId}
                oePeriod={oePeriod}
                currentNotificationWave={currentNotificationWave}
                currentStep={currentStep}
              />
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
      <ExpansionPanel
        title={"3) Send Broker Notification"}
        subtitle={getPanelSubtitle(currentNotificationWave, 3, currentStep)}
        expanded={expanded === 3}
        disabled={currentStep < 3}
        onAction={(event) => {
          updateExpanded(event.expanded ? 0 : 3);
        }}
      >
        <Reveal>
          {expanded === 3 && (
            <ExpansionPanelContent>
              <NotificationBrokerMails
                waveId={waveId}
                oePeriod={oePeriod}
                currentNotificationWave={currentNotificationWave}
                currentStep={currentStep}
              />
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
      <ExpansionPanel
        title={"4) Send Client Notification"}
        subtitle={getPanelSubtitle(currentNotificationWave, 4, currentStep)}
        expanded={expanded === 4}
        disabled={currentStep < 4}
        onAction={(event) => {
          updateExpanded(event.expanded ? 0 : 4);
        }}
      >
        <Reveal>
          {expanded === 4 && (
            <ExpansionPanelContent>
              <NotificationClientMails
                waveId={waveId}
                oePeriod={oePeriod}
                currentNotificationWave={currentNotificationWave}
                currentStep={currentStep}
              />
            </ExpansionPanelContent>
          )}
        </Reveal>
      </ExpansionPanel>
    </div>
  );
};

export default NotificationWaveDetail;
