import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";

import { process } from "@progress/kendo-data-query";
import { useQueryGetImportSubmitErrors } from "../../data/services/importService";

const ImportRateSubmitError = ({ importId }) => {
  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const [errorList, setErrorList] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const { isLoading: errorListLoading, data: errorListSrc } =
    useQueryGetImportSubmitErrors(importId);

  React.useEffect(() => {
    if (!errorListLoading && errorListSrc && errorListSrc.data) {
      setErrorList(process(errorListSrc.data.slice(0), gridDataState));
    }
  }, [errorListLoading, errorListSrc, gridDataState]);

  const dataStateChange = (event) => {
    setErrorList(process(errorListSrc.data.slice(0), event.dataState));
    setGridDataState(event.dataState);
  };

  const columnMenuHeader = (field) => {
    var active = GridColumnMenuFilter.active(field, gridDataState.filter);
    return active ? "active" : "";
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={errorListSrc.data}
          expanded={true}
        />
      </div>
    );
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={errorList}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="planId"
          width={"150"}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planId")}
          title="Plan Id"
        />
        <Column
          field="planTypeCode"
          width={"150"}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planTypeCode")}
          title="Plan Type Code"
        />
        <Column
          field="rateGroup"
          width={"150"}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("rateGroup")}
          title="Rate Group"
        />
        <Column field="message" title="Error Message" />
      </Grid>
    </div>
  );
};

export default ImportRateSubmitError;
