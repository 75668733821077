import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import ErrorBanner from "../../components/ErrorBanner";

import { FormNumericTextbox } from "../../components/FormNumericTextbox";
import { FormRadioGroup } from "../../components/FormRadioGroup";
import { FormDropDownList } from "../../components/FormDropDownList";
import {
  useMutatePostImportCost,
  useQueryGetImportPlanGroups,
} from "../../data/services/importService";

const AddImportCostModel = ({
  item,
  id,
  existingItems,
  onSubmit,
  cancelEdit,
}) => {
  const [planGroupsSrc, setplanGroupsSrc] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);

  let isEdit = item && item.importBPCostId ? true : false;

  const costTypes = [
    {
      text: "MLR Credit",
      value: "M",
    },
    {
      text: "Admin Fee",
      value: "A",
    },
  ];

  const defaultCostType = {
    text: "Select Cost Type",
  };

  const costValueTypes = [
    {
      label: "Percentage",
      value: "P",
    },
    {
      label: "Fixed Amount",
      value: "F",
    },
  ];

  const { isLoading: planGroupsLoading, data: planGroups } =
    useQueryGetImportPlanGroups(id);
  const useMutatePostImportCostHandler = useMutatePostImportCost();

  React.useEffect(() => {
    if (!planGroupsLoading && planGroups && planGroups.data) {
      var result = planGroups.data.map((item) => item);
      result.unshift("@All");

      setplanGroupsSrc(result);
    }
  }, [planGroupsLoading, planGroups, existingItems]);

  const handleSubmit = async (item) => {
    var content = {
      planGroup: item.planGroup,
      costType: item.costType.value,
      costValueType: item.costValueType,
      costValue: item.costValue,
    };

    var data = {
      importId: id,
      content: content,
    };

    setSubmitted(true);

    if (isEdit) {
      data.content.importBPCostId = item.importBPCostId;
      await useMutatePostImportCostHandler.mutateAsync(data);
    } else {
      await useMutatePostImportCostHandler.mutateAsync(data);
    }

    setSubmitted(false);
    onSubmit();
  };

  return (
    <Dialog title={isEdit ? "Edit" : "Add"} onClose={cancelEdit} width={500}>
      <ErrorBanner
        handler={useMutatePostImportCostHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={item}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"costType"}
              name={"costType"}
              label={"Cost Type"}
              data={costTypes}
              component={FormDropDownList}
              required={true}
              textField={"text"}
              dataItemKey={"value"}
              defaultItem={defaultCostType}
              disabled={isEdit}
              validator={(value) =>
                value?.value ? "" : "Please select a cost type"
              }
            />
            <Field
              id={"planGroup"}
              name={"planGroup"}
              label={"Plan Group"}
              data={planGroupsSrc}
              component={FormDropDownList}
              required={true}
              defaultItem={"Select Plan Group"}
              disabled={isEdit}
              validator={(value) => (value ? "" : "Please select a plan group")}
            />
            <Field
              id={"costValueType"}
              name={"costValueType"}
              label={"Credit/Fee Type"}
              layout={"horizontal"}
              component={FormRadioGroup}
              data={costValueTypes}
              required={true}
              validator={(value) =>
                value ? "" : "Please select a credit/fee type"
              }
            />
            <Field
              id={"costValue"}
              name={"costValue"}
              label={"Cost Value"}
              format={"n2"}
              component={FormNumericTextbox}
              required={true}
              validator={(value) =>
                value >= 0 ? "" : "Please enter a cost value"
              }
            />
            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={cancelEdit}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {isEdit ? "Update Cost" : "Apply Cost"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddImportCostModel;
