export const PRIVILEGE_TYPE = {
  HasClientAppPrivilege: 10,
  HasAdminAppPrivilege: 11,
  HasDataSyncPrivilege: 12,
  HasGridUnlockPrivilege: 13,
  SuperAdminPrivilege: 14,
  BenefitAdminPrivilege: 15,
  SalesUserPrivilege: 16,
  BrokerUserPrivilege: 17,
  ContactUserPrivilege: 18,
  SubmitContributionModalPrivilege: 19,
};
