import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import BenefitPlanGrid from "./BenefitPlanGrid";

import { useQueryGetPlanDetailsList } from "../../data/services/benefitPlanService";

const BenefitPlanLayout = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const [medPlanList, setMedPlanList] = React.useState([]);
  const [denPlanList, setDenPlanList] = React.useState([]);
  const [visPlanList, setVisPlanList] = React.useState([]);

  const { isLoading: planDetailListLoading, data: planDetailList } =
    useQueryGetPlanDetailsList();

  React.useEffect(() => {
    if (!planDetailListLoading && planDetailList && planDetailList.data) {
      let dpPlanIds = planDetailList.data
        .filter((x) => x.dpPlanId && x.dpPlanId !== null)
        .map((x) => x.dpPlanId);
      let planSrc = planDetailList.data
        .filter((x) => !dpPlanIds.includes(x.planId))
        .map((x) => {
          return {
            planId: x.planId,
            planDesc: x.planDesc,
            dpPlanId: x.dpPlanId,
            dpPlanDesc: x.dpPlanId
              ? planDetailList.data.find((y) => y.planId === x.dpPlanId)
                  ?.planDesc
              : "",
            offerTypeCode: x.offerTypeCode,
          };
        });

      let medPlans = planSrc.filter((x) => x.offerTypeCode === "MED");
      let denPlans = planSrc.filter((x) => x.offerTypeCode === "DEN");
      let visPlans = planSrc.filter((x) => x.offerTypeCode === "VIS");

      setMedPlanList(medPlans);
      setDenPlanList(denPlans);
      setVisPlanList(visPlans);
    }
  }, [planDetailListLoading, planDetailList]);

  const handleTabSelect = (e) => {
    setSelectedTabIndex(e.selected);
  };

  return (
    <TabStrip
      selected={selectedTabIndex}
      onSelect={handleTabSelect}
      style={{ paddingTop: 20 }}
    >
      <TabStripTab title="Medical Plans">
        <BenefitPlanGrid title={"Medical Plans"} planList={medPlanList} />
      </TabStripTab>
      <TabStripTab title="Dental Plans">
        <BenefitPlanGrid title={"Dental Plans"} planList={denPlanList} />
      </TabStripTab>
      <TabStripTab title="Vision Plans">
        <BenefitPlanGrid title={"Vision Plans"} planList={visPlanList} />
      </TabStripTab>
    </TabStrip>
  );
};

export default BenefitPlanLayout;
