import React from "react";
import { useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";

import AdminLayout from "./layouts/AdminLayout";
import ClientLayout from "./layouts/ClientLayout";

import Login from "./pages/auth/Login";
import BrokerLogin from "./pages/auth/BrokerLogin";
import Logout from "./pages/auth/Logout";
import AuthError from "./pages/auth/AuthError";
import SessionTimeout from "./pages/auth/SessionTimeout";
import AdminDashboard from "./pages/userdashboards/AdminDashboard";
import ClientAdminLayout from "./pages/clientadmin/ClientAdminLayout";
import ClientDashboard from "./pages/userdashboards/ClientDashboard";
import RateImport from "./pages/carrieradmin/RateImport";
import ImportRateFileLayout from "./pages/carrieradmin/ImportRateFileLayout";
import OpenEnrollmentList from "./pages/openenrollment/OpenEnrollmentGridList";
import CarrierSetupLayout from "./pages/carriersetup/CarrierSetupLayout";
import BenefitPlanMappingLayout from "./pages/mapping/BenefitPlanMappingLayout";
import BenefitGroupMapLayout from "./pages/mapping/BenefitGroupMapLayout";
import ImportSummary from "./pages/carrieradmin/ImportSummary";
import BenefitPlanLayout from "./pages/benefitplan/BenefitPlanLayout";
import ClientSummary from "./pages/client/ClientSummary";
import ClientPlanLibrary from "./pages/client/ClientPlanLibrary";
import OpenEnrollmentWorkflow from "./pages/workflow/OpenEnrollmentWorkflow";
import OEWorkflowSetupList from "./pages/workflow/OEWorkflowSetupList";
import ClientRateModelLayout from "./pages/client/ClientRateModelLayout";
import NotificationWaveGrid from "./pages/notification/NotificationWaveGrid";
import NotificationWaveDetail from "./pages/notification/NotificationWaveDetail";
import BrokerUserList from "./pages/broker/BrokerUserList";
import BrokerAuthError from "./pages/auth/BrokerAuthError";
import MaintenanceError from "./pages/auth/MaintenanceError";
import ClientBenefitEmpMenu from "./pages/client/ClientBenefitEmpMenu";
import UserGuide from "./pages/client/UserGuide";
import UserGuideSetup from "./pages/clientadmin/UserGuideSetup";

import ClientContext from "./layouts/ClientContext";
import UserContext from "./layouts/UserContext";
import NotificationRedirect from "./pages/notification/NotificationRedirect";
import NotificationReminders from "./pages/notification/NotificationReminders";
import SystemMenu from "./pages/carrieradmin/SystemMenu";
import DataSyncLayout from "./pages/datasync/DataSyncLayout";

var queryClient = new QueryClient();

export default function App() {
  const [client, setClient] = React.useState(null);

  const contextValue = useMemo(() => ({ client, setClient }), [client]);

  const [loggedInUser, setLoggedInUser] = React.useState(null);

  const userContextValue = useMemo(
    () => ({ loggedInUser, setLoggedInUser }),
    [loggedInUser]
  );

  return (
    <ClientContext.Provider value={contextValue}>
      <UserContext.Provider value={userContextValue}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="ssologin" element={<Login />} />
              <Route path="login" element={<BrokerLogin />} />
              <Route path="logout" element={<Logout />} />
              <Route path="autherror" element={<AuthError />} />
              <Route path="under-maintenance" element={<MaintenanceError />} />
              <Route path="brokerautherror" element={<BrokerAuthError />} />
              <Route path="sessiontimeout" element={<SessionTimeout />} />
              <Route path="/admin" element={<AdminLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="openenrollment" element={<OpenEnrollmentList />} />
                <Route path="workflows" element={<OEWorkflowSetupList />} />
                <Route
                  path="workflow/:workflowSetupId"
                  element={<OpenEnrollmentWorkflow />}
                />
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="rateimportsummary" element={<ImportSummary />} />
                <Route path="importrate" element={<RateImport />} />
                <Route path="benefitplans" element={<BenefitPlanLayout />} />
                <Route
                  path="rateimportsummary/:id/grid"
                  element={<ImportRateFileLayout />}
                />
                <Route path="carriersetup" element={<CarrierSetupLayout />} />
                <Route
                  path="planmapping"
                  element={<BenefitPlanMappingLayout />}
                />
                <Route
                  path="benefitgroupmapping"
                  element={<BenefitGroupMapLayout />}
                />
                <Route path="systemmenu" element={<SystemMenu />} />
                <Route path="brokers" element={<BrokerUserList />} />
                <Route
                  path="clientadminsummary"
                  element={<ClientDashboard />}
                />
                <Route path="datasync" element={<DataSyncLayout />} />
                <Route
                  path="clients/:clientId/setup"
                  element={<ClientAdminLayout />}
                />
                <Route
                  path="clients/:clientId/oe/:oePeriodId/summary"
                  element={<ClientSummary />}
                />
                <Route
                  path="clients/:clientId/oe/:oePeriodId/ratemodel/:versionId"
                  element={<ClientRateModelLayout />}
                />
                <Route
                  path="notificationwaves"
                  element={<NotificationWaveGrid />}
                />
                <Route
                  path="notificationredirect"
                  element={<NotificationRedirect />}
                />
                <Route
                  path="notificationreminders"
                  element={<NotificationReminders />}
                />
                <Route
                  path="notificationwaves/:waveId"
                  element={<NotificationWaveDetail />}
                />
                <Route path="userguidesetup" element={<UserGuideSetup />} />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <h2>There's nothing here!</h2>
                    </main>
                  }
                />
              </Route>
              <Route path="/" element={<ClientLayout />}>
                <Route index element={<ClientDashboard />} />
                <Route path="dashboard" element={<ClientDashboard />} />
                <Route
                  path="/clients/:clientId/oe/:oePeriodId/summary"
                  element={<ClientSummary />}
                />
                <Route
                  path="/clients/:clientId/oe/:oePeriodId/planlibrary"
                  element={<ClientPlanLibrary />}
                />
                <Route
                  path="/clients/:clientId/oe/:oePeriodId/benefitempmenu"
                  element={<ClientBenefitEmpMenu />}
                />
                <Route
                  path="/clients/:clientId/oe/:oePeriodId/ratemodel/:versionId"
                  element={<ClientRateModelLayout />}
                />
                <Route
                  path="/clients/:clientId/oe/:oePeriodId/userguide"
                  element={<UserGuide />}
                />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: "1rem" }}>
                      <h2>There's nothing here!</h2>
                    </main>
                  }
                />
              </Route>
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </UserContext.Provider>
    </ClientContext.Provider>
  );
}
