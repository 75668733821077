import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ListView } from "@progress/kendo-react-listview";
import { Stepper } from "@progress/kendo-react-layout";
import { Card } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

import LoadingIndicator from "../../components/LoadingIndicator";

import { useQueryGetImportSummaryList } from "../../data/services/importService";

import OEContext from "../../layouts/OEContext";
import { GetCurrentSteps, ImportStatus } from "./ImportStatusHelper";

const ImportSummary = () => {
  const navigate = useNavigate();
  const oePeriod = React.useContext(OEContext);

  const [importSummaryListSrc, setImportSummaryListSrc] = React.useState([]);
  const [expanded, setExpanded] = React.useState();

  const { isLoading: importSummaryListLoading, data: importSummaryList } =
    useQueryGetImportSummaryList(oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (
      !importSummaryListLoading &&
      importSummaryList &&
      importSummaryList.data
    ) {
      var grouped = importSummaryList.data.reduce((prev, current) => {
        let found = prev.find(
          (item) => item.carrierTypeId === current.carrierTypeId
        );
        if (!found) {
          prev.push({
            carrierTypeId: current.carrierTypeId,
            carrierTypeName: current.carrierTypeName,
            items: [current],
          });
        } else {
          found.items.push(current);
        }
        return prev;
      }, []);

      if (grouped && grouped.length > 0) {
        grouped.forEach((item) => {
          let completed = item.items.filter(
            (e) => e.importStatus === ImportStatus.SubmitComplete
          );
          let notStatrted = item.items.filter((e) => !e.importStatus);
          let inProgress =
            item.items.length - completed.length - notStatrted.length;

          item.subTitle = `${completed.length} of ${item.items.length} completed (${notStatrted.length} not started, ${inProgress} in progress)`;
        });
        setExpanded(grouped[0].carrierTypeId);
      }

      setImportSummaryListSrc(grouped);
    }
  }, [importSummaryListLoading, importSummaryList]);

  if (importSummaryListLoading) {
    return <LoadingIndicator />;
  }

  const RenderList = ({ data }) => {
    const MyItemRender = (props) => {
      let data = props.dataItem;
      let stepData = GetCurrentSteps(data.importStatus);

      return (
        <Card
          style={{
            width: "100%",
            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
            marginTop: "15px",
            padding: "15px",
          }}
        >
          <div className="stepper-view-container">
            <div className="stepper-view-text">
              <span style={{ fontSize: 16, fontWeight: 600 }}>
                {data.carrierName}
              </span>
            </div>
            <div className="stepper-view-control">
              <Stepper
                value={stepData.currentStep}
                items={stepData.steps}
                linear={false}
                mode={"labels"}
                successIcon="k-icon k-i-check-circle"
                errorIcon="k-icon k-i-close-outline"
              />
            </div>
            <div className="stepper-view-action">
              {stepData.currentStep === 0 && (
                <Button
                  onClick={() => {
                    navigate(`/admin/importrate`);
                  }}
                >
                  Import File
                </Button>
              )}
              {stepData.currentStep > 0 && (
                <Button
                  onClick={() => {
                    navigate(
                      `/admin/rateimportsummary/${data.importFileQueueId}/grid`
                    );
                  }}
                >
                  View Import
                </Button>
              )}
            </div>
          </div>
        </Card>
      );
    };

    return (
      <ListView
        data={data}
        item={MyItemRender}
        style={{
          width: "100%",
        }}
      />
    );
  };

  return (
    <div>
      <h2>Rate Import Summary</h2>
      {importSummaryListSrc &&
        importSummaryListSrc.length > 0 &&
        importSummaryListSrc.map((data) => {
          return (
            <ExpansionPanel
              title={data.carrierTypeName}
              subtitle={data.subTitle}
              expanded={expanded === data.carrierTypeId}
              tabIndex={0}
              key={data.carrierTypeId}
              onAction={(event) => {
                setExpanded(event.expanded ? "" : data.carrierTypeId);
              }}
            >
              <Reveal>
                {expanded === data.carrierTypeId && (
                  <ExpansionPanelContent>
                    <RenderList data={data.items} />
                  </ExpansionPanelContent>
                )}
              </Reveal>
            </ExpansionPanel>
          );
        })}
    </div>
  );
};

export default ImportSummary;
