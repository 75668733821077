import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { formatNumber } from "@telerik/kendo-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const ClientRateModelSummary = (newModelDetailData) => {
  let gridData =
    newModelDetailData &&
    newModelDetailData.groupSummary &&
    newModelDetailData.groupSummary.map((group) => {
      let newGroup = newModelDetailData.groups.find(
        (ng) => ng.groupId === group.groupId
      );

      let projectedCostResult = 0;
      let hasPending = false;

      if (newGroup) {
        projectedCostResult = newGroup.rates.reduce(
          (projectedCost, current) => {
            let amount = 0;
            if (current.percentSelected) {
              amount =
                (current.percentValue ?? 0) *
                current.newTotalPremium *
                current.eeCount;
            } else if (current.fixedValueSelected) {
              amount = (current.fixedValue ?? 0) * current.eeCount;
            }

            return projectedCost + amount;
          },
          0
        );

        hasPending = newGroup.rates.some(
          (e) => !e.percentSelected && !e.fixedValueSelected
        );
      }

      return {
        groupId: group.groupId,
        groupName: group.groupName,
        currentERCost: group.currentTotalERCost,
        medicalEECount: group.medEECount,
        dentalEECount: group.denEECount,
        visionEECount: group.visEECount,
        projectedERCost: projectedCostResult,
        hasPending: hasPending,
      };
    });

  if (!gridData) {
    gridData = [];
  }

  const SummaryTotalHeader = (props) => {
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "left" }}>
        {"Total"}
      </td>
    );
  };

  const StatusColumn = (props) => {
    return (
      <td
        colSpan={props.colSpan}
        style={{ textAlign: "center", fontStyle: "italic" }}
      >
        {props.dataItem.hasPending ? (
          <span>
            Pending{" "}
            <FontAwesomeIcon
              icon={faCircleExclamation}
              style={{ color: "#F8D210", marginLeft: "10px" }}
            />
          </span>
        ) : (
          <span>
            Completed{" "}
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "darkgreen", marginLeft: "10px" }}
            />
          </span>
        )}
      </td>
    );
  };

  const TotalCell = (props) => {
    const field = props.field || "";
    const total = gridData.reduce((acc, current) => acc + current[field], 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "center" }}>
        {total}
      </td>
    );
  };

  const TotalDollarCell = (props) => {
    const field = props.field || "";
    const total = gridData.reduce((acc, current) => acc + current[field], 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, "c")}
      </td>
    );
  };

  const TotalStatusCell = (props) => {
    const total = gridData.reduce((total, current) => {
      if (!current.hasPending) {
        return total + 1;
      }
      return total;
    }, 0);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "center" }}>
        {`${total}/${gridData.length} Completed`}
      </td>
    );
  };

  return (
    <Grid sortable={false} data={gridData}>
      <Column
        field="groupName"
        width={"350px"}
        title="Class Name"
        sortable={false}
        footerCell={SummaryTotalHeader}
      />
      <Column
        field="medicalEECount"
        className="k-text-center"
        title="Medical Employee Count"
        sortable={false}
        footerCell={TotalCell}
      />
      <Column
        field="dentalEECount"
        className="k-text-center"
        title="Dental Employee Count"
        sortable={false}
        footerCell={TotalCell}
      />
      <Column
        field="visionEECount"
        className="k-text-center"
        title="Vision Employee Count"
        sortable={false}
        footerCell={TotalCell}
      />
      <Column
        field="currentERCost"
        format="{0:c}"
        className="k-text-right"
        title="Current Total Employer Cost"
        sortable={false}
        footerCell={TotalDollarCell}
      />
      <Column
        field="projectedERCost"
        format="{0:c}"
        className="k-text-right"
        title="Estimated Renewal Total Employer Cost"
        sortable={false}
        footerCell={TotalDollarCell}
      />
      <Column
        field="hasPending"
        className="k-text-center"
        title="Status"
        sortable={false}
        cell={StatusColumn}
        footerCell={TotalStatusCell}
      />
    </Grid>
  );
};

export default ClientRateModelSummary;
