import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { formatDate } from "@telerik/kendo-intl";
import { useQueryClient } from "react-query";

import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

import ImportRateSubmitError from "./ImportRateSubmitError";
import { QUERY_KEYS } from "../../data/helpers/queryKeys";
import { useMutatePostSubmitImportRate } from "../../data/services/importService";
import {
  RateImportReviewCompleted,
  RateImportSubmitInprogress,
  RateImportSubmitSuccess,
  RateImportSubmitError,
} from "./ImportStatusHelper";

const ImportRateSubmit = ({ id, importItem, onPrevClick }) => {
  const queryClient = useQueryClient();

  const isReviewComplete = RateImportReviewCompleted(importItem.importStatus);
  const isSubmitInProgress = RateImportSubmitInprogress(
    importItem.importStatus
  );
  const isSubmitSuccess = RateImportSubmitSuccess(importItem.importStatus);
  const isSubmitError = RateImportSubmitError(importItem.importStatus);

  const useMutatePostSubmitImportRateHandler = useMutatePostSubmitImportRate();

  const SubmitDetail = () => {
    return (
      <div>
        <div
          style={{
            padding: 20,
            marginTop: 10,
            marginBottom: 20,
            backgroundColor: "#cce5ff",
            color: "#004085",
            borderRadius: ".25rem",
          }}
        >
          Billing Rate's will be submitted with an effective date of{" "}
          {formatDate(new Date(importItem.oePeriod.effectiveDate), "d")} . Click
          on the button below to submit the Billing Rate's to PrismHR.
        </div>
        <div style={{ paddingBottom: 30, paddingTop: 30, textAlign: "center" }}>
          <Button themeColor={"primary"} size={"large"} onClick={onSubmit}>
            Publish rate to PrismHR
          </Button>
        </div>
      </div>
    );
  };

  const SubmitInProgessDetail = () => {
    return (
      <div>
        <div
          style={{
            padding: 20,
            marginTop: 10,
            marginBottom: 20,
            backgroundColor: "#fff3cd",
            color: "#856404",
            borderRadius: ".25rem",
          }}
        >
          <span
            className="k-icon k-i-loading"
            style={{ fontSize: "24px" }}
          ></span>{" "}
          The Billing Rate's are being submitted to PrismHR. Please wait for a
          few minutes.{" "}
          <Button
            themeColor="info"
            onClick={onRefreshClick}
            style={{ marginLeft: 20 }}
          >
            {" "}
            Refresh{" "}
          </Button>
        </div>
      </div>
    );
  };

  const SubmitErrorDetail = () => {
    return (
      <div>
        <div
          style={{
            padding: 20,
            marginTop: 10,
            marginBottom: 20,
            backgroundColor: "#f8d7da",
            color: "#721c24",
            borderRadius: ".25rem",
          }}
        >
          <span
            className="k-icon k-i-warning"
            style={{ fontSize: "24px" }}
          ></span>{" "}
          Error submitting the Billing Rate's to PrismHR. Please find below the
          Plan and Error details for the failed records. Note: Successfully
          submitted rates records are not listed below
        </div>
        <ImportRateSubmitError importId={id} />
      </div>
    );
  };

  const SubmitSuccessDetail = () => {
    return (
      <div>
        <div
          style={{
            padding: 20,
            marginTop: 10,
            marginBottom: 20,
            backgroundColor: "#d4edda",
            color: "#155724",
            borderRadius: ".25rem",
            textAlign: "center",
          }}
        >
          <span
            className="k-icon k-i-check"
            style={{ fontSize: "64px" }}
          ></span>{" "}
          <br />
          The Billing Rates have been successfully submitted to PrismHR on{" "}
          {formatDate(new Date(importItem.submitDate), "F")}. <br /> <br />
          <Button
            themeColor="info"
            onClick={onExportClick}
            style={{ marginLeft: 20 }}
          >
            {" "}
            Export Rate{" "}
          </Button>
        </div>
      </div>
    );
  };

  const onRefreshClick = () => {
    queryClient.invalidateQueries([QUERY_KEYS.IMPORT_ITEM, id]);
  };

  const onExportClick = async () => {
    await axiosDownload(
      SERVICE_URLS.IMPORT_FINAL_RATES_EXPORT(id),
      "Rates Export.csv"
    );
  };

  const onSubmit = async () => {
    var data = {
      importId: id,
      content: {
        importFileQueueId: id,
      },
    };

    await useMutatePostSubmitImportRateHandler.mutateAsync(data);
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"light"}
            onClick={onPrevClick}
            iconClass="k-icon k-i-arrow-left"
          >
            Review Data
          </Button>
        </div>
        <div style={{ alignSelf: "center" }}>
          <h3 style={{ marginTop: 30 }}>Submit data to PrismHR</h3>
        </div>
        <div style={{ alignSelf: "center", width: 30 }}></div>
      </div>
      {isReviewComplete && <SubmitDetail />}
      {isSubmitInProgress && <SubmitInProgessDetail />}
      {isSubmitSuccess && <SubmitSuccessDetail />}
      {isSubmitError && <SubmitErrorDetail />}
    </div>
  );
};

export default ImportRateSubmit;
