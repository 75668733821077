import * as React from "react";
import { useParams } from "react-router-dom";

import OEContext from "../../layouts/OEContext";
import {
  useQueryGetOEWorkflowSteps,
  useQueryGetOEWorkflowSetups,
} from "../../data/services/workflowService";
import LoadingIndicator from "../../components/LoadingIndicator";

import IntroductionPanel from "./Panels/IntroductionPanel";
import MappingPanel from "./Panels/MappingPanel";
import ImportRateFilePanel from "./Panels/ImportRateFilePanel";
import SyncSystemPlanRatesPanel from "./Panels/SyncSystemPlanRatesPanel";
import UploadPlanDetailsPanel from "./Panels/UploadPlanDetailsPanel";
import SyncClientDetailsPanel from "./Panels/SyncClientDetailsPanel";
import SetRateGroupPanel from "./Panels/SetRateGroupPanel";
import SendNotificationPanel from "./Panels/SendNotificationPanel";
import BuildClientModelPanel from "./Panels/BuildClientModelPanel";

const OpenEnrollmentWorkflow = () => {
  const oePeriod = React.useContext(OEContext);
  const { workflowSetupId } = useParams();

  const [currentWorkflowSetup, setCurrentWorkflowSetup] = React.useState();
  const [expanded, setExpanded] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState(0);

  const [step1Status, setStep1Status] = React.useState();
  const [step2Status, setStep2Status] = React.useState();
  const [step3Status, setStep3Status] = React.useState();
  const [step4Status, setStep4Status] = React.useState();
  const [step5Status, setStep5Status] = React.useState();
  const [step6Status, setStep6Status] = React.useState();
  const [step7Status, setStep7Status] = React.useState();
  const [step8Status, setStep8Status] = React.useState();
  const [step9Status, setStep9Status] = React.useState();

  const { isLoading: workflowStepsLoading, data: workflowSteps } =
    useQueryGetOEWorkflowSteps(oePeriod?.oePeriodId, workflowSetupId);
  const { isLoading: workflowSetupLoading, data: workflowSetups } =
    useQueryGetOEWorkflowSetups(oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (!workflowStepsLoading && workflowSteps && workflowSteps.data) {
      if (workflowSteps.data.length > 0) {
        const step1 = workflowSteps.data.find((x) => x.stepId === 1);
        const step2 = workflowSteps.data.find((x) => x.stepId === 2);
        const step3 = workflowSteps.data.find((x) => x.stepId === 3);
        const step4 = workflowSteps.data.find((x) => x.stepId === 4);
        const step5 = workflowSteps.data.find((x) => x.stepId === 5);
        const step6 = workflowSteps.data.find((x) => x.stepId === 6);
        const step7 = workflowSteps.data.find((x) => x.stepId === 7);
        const step8 = workflowSteps.data.find((x) => x.stepId === 8);
        const step9 = workflowSteps.data.find((x) => x.stepId === 9);

        setStep1Status(step1);
        setStep2Status(step2);
        setStep3Status(step3);
        setStep4Status(step4);
        setStep5Status(step5);
        setStep6Status(step6);
        setStep7Status(step7);
        setStep8Status(step8);
        setStep9Status(step9);

        let currentOEStep = workflowSteps.data.find(
          (x) => x.status !== "Completed" && x.status !== "NotStarted"
        );

        if (!currentOEStep) {
          currentOEStep = workflowSteps.data.find(
            (x) => x.status === "Completed" && x.stepId === 9
          );
        }

        setExpanded(currentOEStep?.stepId ?? 1);
        setCurrentStep(currentOEStep?.stepId ?? 1);
      } else {
        setExpanded(1);
        setCurrentStep(1);

        setStep1Status(null);
        setStep2Status(null);
        setStep3Status(null);
        setStep4Status(null);
        setStep5Status(null);
        setStep6Status(null);
        setStep7Status(null);
        setStep8Status(null);
        setStep9Status(null);
      }
    }
  }, [workflowStepsLoading, workflowSteps]);

  React.useEffect(() => {
    if (!workflowSetupLoading && workflowSetups?.data?.length > 0) {
      const currentWorkflowSetup = workflowSetups.data.find(
        (x) => x.oeWorkflowSetupId === Number(workflowSetupId)
      );

      setCurrentWorkflowSetup(currentWorkflowSetup);
    }
  }, [workflowSetupLoading, workflowSetups, workflowSetupId]);

  if (workflowStepsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <h1>
        {currentWorkflowSetup?.carrierName ?? "Other Clients"} -{" "}
        {currentWorkflowSetup?.workflowName ?? "OE Workflow"}
      </h1>
      <IntroductionPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step1Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        panelStepId={1}
      />
      <MappingPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step2Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        panelStepId={2}
      />
      <ImportRateFilePanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step3Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        currentWorkflowSetup={currentWorkflowSetup}
        panelStepId={3}
      />
      <SyncSystemPlanRatesPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step4Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        panelStepId={4}
      />
      <UploadPlanDetailsPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step5Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        panelStepId={5}
      />
      <SyncClientDetailsPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step6Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        currentWorkflowSetup={currentWorkflowSetup}
        panelStepId={6}
      />
      <SetRateGroupPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step7Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        currentWorkflowSetup={currentWorkflowSetup}
        panelStepId={7}
      />
      <BuildClientModelPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step8Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        panelStepId={8}
      />
      <SendNotificationPanel
        expanded={expanded}
        setExpanded={setExpanded}
        currentStep={currentStep}
        status={step9Status}
        oePeriod={oePeriod}
        workflowSetupId={workflowSetupId}
        panelStepId={9}
      />
    </div>
  );
};

export default OpenEnrollmentWorkflow;
