import React from "react";
import OktaSignInWidget from "./OktaSignInWidget";
import { axiosPost } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";
import { QUERY_KEYS } from "../../data/helpers/queryKeys";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";

const BrokerLogin = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const onSuccess = async (tokens) => {
    try {
      setIsAuthenticating(true);

      let entity = {
        idToken: tokens?.idToken?.idToken,
      };

      let authResponse = await axiosPost(
        SERVICE_URLS.BROKER_AUTHENTICATE,
        entity
      );

      queryClient.invalidateQueries([QUERY_KEYS.USER_GET]);

      if (authResponse.data && authResponse.data.isAuthenticated) {
        navigate("/dashboard");
      } else {
        navigate("/brokerautherror");
      }
    } catch (err) {
      queryClient.invalidateQueries([QUERY_KEYS.USER_GET]);
      navigate("/brokerautherror");
    } finally {
      setIsAuthenticating(false);
    }
  };

  const onError = (err) => {
    console.log("Sign in error:", err);
  };

  if (isAuthenticating) {
    return <LoadingIndicator />;
  }

  return <OktaSignInWidget onSuccess={onSuccess} onError={onError} />;
};

export default BrokerLogin;
