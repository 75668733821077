import * as React from "react";

const SuccessBanner = ({ handler, message }) => {

    const [successMessage, setSuccessMessage] = React.useState(null);

    React.useEffect(() => {

        if (handler.isSuccess) {
            setSuccessMessage(message);

            setTimeout(() => {
                setSuccessMessage(null);
            }, 10000);

            handler.reset();
        }

    }, [handler, message]);

    if (successMessage === null) {
        return null;
    }

    return (
        <div style={{ backgroundColor: "#d4edda", padding: 10, marginBottom: 10, borderRadius: ".25rem" }}>
            <span style={{ color: "#155724" }}>{successMessage}</span>
        </div>
    )
};

export default SuccessBanner;