import * as React from "react";
import { parseServiceValidationError } from "../data/helpers/webHelper";

const ErrorBanner = ({ handler, onError }) => {
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (handler.isError) {
      let errorMdg = parseServiceValidationError(handler.error);
      setError(errorMdg);

      setTimeout(() => {
        setError(null);
      }, 10000);

      if (onError) onError();
      handler.reset();
    }
  }, [handler, onError]);

  if (!error || !error.errors) {
    return null;
  }

  let message = error.errors.length > 0 ? error.errors[0] : error.errorTitle;

  return (
    <div
      style={{
        backgroundColor: "#f8d7da",
        padding: 10,
        marginBottom: 10,
        borderRadius: ".25rem",
      }}
    >
      <span style={{ color: "#721c24" }}>{message}</span>
    </div>
  );
};

export default ErrorBanner;
