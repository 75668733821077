import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { useQueryGetPlanRates } from "../../data/services/benefitPlanService";

const BenefitPlanRatesModel = ({ oePeriodId, planId, onClose }) => {
  const [ratesListSrc, setRatesListSrc] = React.useState([]);
  const [gridState, setGridState] = React.useState({});

  const { isLoading: ratesLoading, data: ratesList } = useQueryGetPlanRates(
    oePeriodId,
    planId
  );

  React.useEffect(() => {
    if (!ratesLoading && ratesList && ratesList.data) {
      setRatesListSrc(ratesList.data);
    }
  }, [ratesLoading, ratesList]);

  const gridDataStateChange = (event) => {
    setGridState(event.dataState);
  };

  return (
    <Dialog title={"Plan Current Rates"} onClose={onClose} width={"60vw"}>
      <Grid
        data={process(ratesListSrc, gridState)}
        style={{ maxHeight: "50vh" }}
        sortable={true}
        onDataStateChange={gridDataStateChange}
        {...gridState}
      >
        <GridColumn field="rateGroup" title="Rate Group" />
        <GridColumn
          field="eeAmount"
          title="Employee Rate"
          className="k-text-right"
          format="{0:c}"
        />
        <GridColumn
          field="esAmount"
          title="Spouse Rate"
          className="k-text-right"
          format="{0:c}"
        />
        <GridColumn
          field="ecAmount"
          title="Single Parent Rate"
          className="k-text-right"
          format="{0:c}"
        />
        <GridColumn
          field="famAmount"
          title="Family Rate"
          className="k-text-right"
          format="{0:c}"
        />
        <GridColumn
          field="dpAmount"
          title="DP Rate"
          className="k-text-right"
          format="{0:c}"
        />
        <GridColumn
          field="dpfAmount"
          title="DPF Rate"
          className="k-text-right"
          format="{0:c}"
        />
      </Grid>
      <DialogActionsBar layout={"end"}>
        <div style={{ paddingRight: "8px", paddingBottom: "8px" }}>
          <Button onClick={onClose} style={{ marginRight: "10px" }}>
            {"Close"}
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default BenefitPlanRatesModel;
