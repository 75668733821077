import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormInput } from "../../components/FormInput";
import { FormDropDownList } from "../../components/FormDropDownList";
import ErrorBanner from "../../components/ErrorBanner";
import { filterBy } from "@progress/kendo-data-query";

import {
  useMutatePostBenefitGroupMapping,
  useQueryGetPlanListForMapping,
} from "../../data/services/benefitPlanService";

const AddEditBenefitGroupModel = (props) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [planList, setPlanList] = React.useState([]);
  const [planListFiltered, setPlanListFiltered] = React.useState([]);
  const [formInitValue, setFormInitValue] = React.useState(null);

  let isEdit = props.item.bpBenefitGroupMappingId ? true : false;

  const { isLoading: planListLoading, data: planListResp } =
    useQueryGetPlanListForMapping(props.offerType);

  const useMutatePostBenefitGroupMappingHandler =
    useMutatePostBenefitGroupMapping();

  React.useEffect(() => {
    if (!planListLoading && planListResp && planListResp.data) {
      let mapped = planListResp.data.map((e) => {
        e.PlanDisName = e.planId + " - " + e.planDesc;
        return e;
      });

      if (props.lookup && props.lookup.length > 0) {
        mapped = mapped.filter((e) => {
          return (
            props.lookup.find((l) => l.planId === e.planId) === undefined ||
            (isEdit && e.planId === props.item.planId)
          );
        });
      }

      setPlanList(mapped);
      setPlanListFiltered(mapped);
      setFormInitValue({
        plan: isEdit
          ? mapped.find((e) => e.planId === props.item.planId)
          : null,
        benefitGroup: props.item.benefitGroup,
      });
    }
  }, [planListLoading, planListResp]);

  const handleSubmit = async (item) => {
    var data = {
      planId: item.plan.planId,
      benefitGroup: item.benefitGroup,
      offerType: props.offerType,
    };

    setSubmitted(true);

    await useMutatePostBenefitGroupMappingHandler.mutateAsync(data);

    setSubmitted(false);
    props.onSubmit();
  };

  const filterChange = (event) => {
    setPlanListFiltered(filterBy(planList.slice(), event.filter));
  };

  if (!formInitValue) {
    return null;
  }

  return (
    <Dialog
      title={isEdit ? `Edit Mapping` : "Add Mapping"}
      onClose={props.cancelEdit}
      width={600}
    >
      <ErrorBanner
        handler={useMutatePostBenefitGroupMappingHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={formInitValue}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"plan"}
              name={"plan"}
              label={"Plan"}
              data={planListFiltered}
              component={FormDropDownList}
              required={true}
              autoFocus={true}
              textField={"PlanDisName"}
              filterable={true}
              onFilterChange={filterChange}
              dataItemKey={"planId"}
              disabled={isEdit}
              loading={planListLoading}
              validator={(value) =>
                value?.planId ? "" : "Please select a plan"
              }
            />

            <Field
              id={"benefitGroup"}
              name={"benefitGroup"}
              label={"Benefit Group"}
              component={FormInput}
              required={true}
              validator={(value) =>
                value ? "" : "Please enter a benefit group"
              }
            />
            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={props.cancelEdit}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddEditBenefitGroupModel;
