import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import OEContext from "../../layouts/OEContext";

//import { useMutateDeleteBenefitGroupMapping } from "../../data/services/benefitPlanService";

import HideDialog from "../../components/HideDialog";
//import ErrorBanner from '../../components/ErrorBanner';
import BenefitPlanRatesModel from "./BenefitPlanRatesModel";

const BenefitPlanGrid = ({ title, planList }) => {
  const oePeriod = React.useContext(OEContext);

  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const [showRatesModel, setShowRatesModel] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [currentViewItem, setCurrentViewItem] = React.useState();

  const [planListInternal, setPlanListInternal] = React.useState([]);

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  React.useEffect(() => {
    setPlanListInternal(process(planList.slice(0), gridDataState));
  }, [planList, gridDataState]);

  //const useMutateDeleteBenefitGroupMappingHandler = useMutateDeleteBenefitGroupMapping();

  const handleCancelView = () => {
    setShowRatesModel(false);
  };

  const handleOpenViewRateWindow = (item) => {
    setCurrentViewItem(item);
    setShowRatesModel(true);
  };

  // const handleOpenDeleteWindow = (item) => {
  //   setCurrentViewItem(item);
  //   setShowDeleteDialog(true);
  // };

  const handleDelete = async (item) => {
    // let data = {
    //     bpBenefitGroupMappingId: item.bpBenefitGroupMappingId,
    //     offerType: offerType,
    // };

    // await useMutateDeleteBenefitGroupMappingHandler.mutateAsync(data);

    setShowDeleteDialog(false);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="info"
            onClick={() => handleOpenViewRateWindow(props.dataItem)}
          >
            View Rates
          </Button>
          {/* <Button themeColor="light" style={{ marginLeft: 10 }} onClick={() => handleOpenDeleteWindow(props.dataItem)}>
                        Hide Plan
                    </Button> */}
        </td>
      );
    };

    const dataStateChange = (event) => {
      setPlanListInternal(process(planList.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={planListInternal}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="planId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planId")}
          title="Plan Id"
        />
        <Column
          field="planDesc"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planDesc")}
          title="Plan Desc"
        />
        <Column
          field="dpPlanId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("dpPlanId")}
          title="DP Plan Id"
        />
        <Column
          field="dpPlanDesc"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("dpPlanDesc")}
          title="DP Plan Desc"
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={planList}
          expanded={true}
        />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>{title}</h2>
        </div>
      </div>
      {/* <ErrorBanner handler={useMutateDeleteBenefitGroupMappingHandler} onError={() => setShowDeleteDialog(false)} /> */}
      <RenderGrid />
      {showRatesModel && (
        <BenefitPlanRatesModel
          onClose={handleCancelView}
          planId={currentViewItem.planId}
          oePeriodId={oePeriod?.oePeriodId}
        />
      )}
      {showDeleteDialog && (
        <HideDialog
          onHide={handleDelete}
          onCancel={() => setShowDeleteDialog(false)}
          item={currentViewItem}
        />
      )}
    </div>
  );
};

export default BenefitPlanGrid;
