import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

import { useQueryGetSystemMenuFilters } from "../../data/services/benefitPlanService";
import OEContext from "../../layouts/OEContext";

import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

const SystemMenu = () => {
  const oePeriod = React.useContext(OEContext);

  const offerTypes = [
    {
      code: "MED",
      name: "Medical",
    },
    {
      code: "DEN",
      name: "Dental",
    },
    {
      code: "VIS",
      name: "Vision",
    },
  ];

  const fileTypes = [
    {
      label: "Pdf",
      value: "Pdf",
    },
    {
      label: "Excel",
      value: "Excel",
    },
  ];

  const [benefitGroupList, setBenefitGroupList] = React.useState([]);
  const [rateGroupList, setRateGroupList] = React.useState([]);

  const [selectedOfferType, setSelectedOfferType] = React.useState(null);
  const [selectedBenefitGroup, setSelectedBenefitGroup] = React.useState(null);
  const [selectedRateGroup, setSelectedRateGroup] = React.useState(null);
  const [selectedFileType, setSelectedFileType] = React.useState("Pdf");
  const [groupedItems, setGroupedItems] = React.useState([]);
  const [disabledButtonIndexes, setDisabledButtonIndexes] = React.useState([]);

  const [systemMenuFiltersListSrc, setSystemMenuFiltersListSrc] =
    React.useState([]);

  const [isDownloading, setIsDownloading] = React.useState(false);

  const { isLoading: systemMenuFiltersLoading, data: systemMenuFilters } =
    useQueryGetSystemMenuFilters(oePeriod?.oePeriodId);

    React.useEffect(() => {
      const groupItems = () => {
        const groups = [];
        for (let i = 0; i < rateGroupList.length; i += 8) {
          groups.push(rateGroupList.slice(i, i + 8));
        }
        setGroupedItems(groups);
      };
  
      groupItems();
    }, [rateGroupList]);
    
    React.useEffect(() => {
      if(rateGroupList.length === 1){
        setSelectedRateGroup(rateGroupList[0])
      }
    },[rateGroupList])

  React.useEffect(() => {
    if (
      !systemMenuFiltersLoading &&
      systemMenuFilters &&
      systemMenuFilters.data &&
      systemMenuFilters.data.length > 0
    ) {
      setSystemMenuFiltersListSrc(systemMenuFilters.data);
    }
  }, [systemMenuFiltersLoading, systemMenuFilters]);

  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const handleOfferTypeChange = (event) => {
    setSelectedOfferType(event.target.value);

    let filteredItems = systemMenuFiltersListSrc.filter(
      (e) => e.offerTypeCode === event.target.value.code
    );

    if (filteredItems.length > 0) {
      let benGroups = filteredItems.reduce((result, current) => {
        if (!result.find((e) => e.code === current.benefitGroup)) {
          result.push({
            code: current.benefitGroup,
            name: current.benefitGroup,
          });
        }
        return result;
      }, []);
      setBenefitGroupList(benGroups.sort(compare));
    } else {
      setBenefitGroupList([]);
    }

    setRateGroupList([]);
    setSelectedBenefitGroup(null);
    setSelectedRateGroup(null);
  };

  const handleBenefitGroupChange = (event) => {
    setSelectedBenefitGroup(event.target.value);

    let filteredItems = systemMenuFiltersListSrc.filter(
      (e) =>
        e.offerTypeCode === selectedOfferType.code &&
        e.benefitGroup === event.target.value.code
    );

    if (filteredItems.length > 0) {
      let rateGroups = filteredItems.reduce((result, current) => {
        if (!result.find((e) => e.code === current.rateGroup)) {
          result.push({
            code: current.rateGroup,
            name: current.rateGroup,
          });
        }
        return result;
      }, []);
      setRateGroupList(rateGroups.sort(compare));
    } else {
      setRateGroupList([]);
    }

    setSelectedRateGroup(null);
  };

  const handleRateGroupChange = (event) => {
    setSelectedRateGroup(event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setSelectedFileType(event.value);
  };

  const handleSubmit = async (groupIndex) => {
    setIsDownloading(true);
    setDisabledButtonIndexes(prevIndexes => {
      const newIndexes = [...prevIndexes];
      const index = newIndexes.indexOf(groupIndex);

      if (index === -1) {
        // Add the button to the disabled list
        newIndexes.push(groupIndex);
      } else {
        // Remove the button from the disabled list
        newIndexes.splice(index, 1);
      }

      return newIndexes;
    });
    let isExcel = selectedFileType === "Excel";
    let fileName = `PrestigePEO_${selectedOfferType.name}_${
      selectedBenefitGroup.code
    }_${selectedRateGroup?.code || "All"}${groupIndex !=null ? "_"+groupIndex :""}.${!selectedRateGroup?.code ? "zip" : (isExcel ? "xlsx" : "pdf")}`;
    await axiosDownload(
      SERVICE_URLS.SYSTEM_MENU_DOWNLAOD(
        oePeriod?.oePeriodId,
        selectedOfferType.code,
        selectedBenefitGroup.code,
        selectedRateGroup?.code || null,
        isExcel ? "true" : "false",
        groupIndex
      ),
      fileName
    );
    setIsDownloading(false);
    setDisabledButtonIndexes(prevIndexes => prevIndexes.filter(index => index !== groupIndex));
    // setSelectedOfferType(null);
    // setBenefitGroupList([]);
    // setSelectedBenefitGroup(null);
    // setRateGroupList([]);
    // setSelectedRateGroup(null);
  };

  return (
    <div>
      <h2>System Menu</h2>
      <div style={{ paddingLeft: "20px" }}>
        <div>
          <span>Offer Type: </span> <br />
          <DropDownList
            name="offerType"
            id="offerType"
            data={offerTypes}
            style={{
              width: "400px",
            }}
            textField="name"
            dataItemKey="code"
            value={selectedOfferType}
            onChange={handleOfferTypeChange}
          />
        </div>
        <div style={{ paddingTop: "20px" }}>
          <span>Benefit Group: </span> <br />
          <DropDownList
            name="benefitGroup"
            id="benefitGroup"
            data={benefitGroupList}
            style={{
              width: "400px",
            }}
            textField="name"
            dataItemKey="code"
            value={selectedBenefitGroup}
            onChange={handleBenefitGroupChange}
            disabled={benefitGroupList.length === 0}
          />
        </div>
        <div style={{ paddingTop: "20px" }}>
          <span>Rate Group: </span> <br />
          <DropDownList
            name="rateGroup"
            id="rateGroup"
            data={rateGroupList}
            style={{
              width: "400px",
            }}
            textField="name"
            dataItemKey="code"
            value={selectedRateGroup}
            onChange={handleRateGroupChange}
            disabled={rateGroupList.length === 0}
          />
        </div>
        <div style={{ paddingTop: "20px" }}>
          <span>Output File Type: </span> <br />
          <RadioGroup
            data={fileTypes}
            disabled={!selectedBenefitGroup}
            value={selectedFileType}
            onChange={handleFileTypeChange}
          />
        </div>
          <div style={{flex:1,flexDirection:'row'}}>
        {!selectedRateGroup && groupedItems.map((group, groupIndex) => (
           <div style={{ padding: "10px" }}>
        <Button
          key={groupIndex}
          type="submit"
          themeColor="success"
          disabled={disabledButtonIndexes.includes(groupIndex) }
          onClick={() => handleSubmit(groupIndex)}
        >
          {disabledButtonIndexes.includes(groupIndex) ?  <Loader style={{marginBottom:2,marginLeft:5}} size="small" type={'infinite-spinner'} themeColor="light"/> : null}
          {`Bundle ${groupIndex + 1}`}
        </Button>
        </div>
      ))}</div>
       {selectedRateGroup && 
        <div style={{ paddingTop: "20px" }}>
          <Button
            type={"submit"}
            themeColor={"success"}
            disabled={!selectedBenefitGroup || isDownloading}
            onClick={() => handleSubmit(null)}
          >
            {"Download Menu"}
          </Button>
        </div>
        }
      </div>
    </div>
  );
};

export default SystemMenu;
