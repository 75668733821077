import * as React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { formatNumber } from "@telerik/kendo-intl";
import OEContext from "../../layouts/OEContext";
import { formatDate } from "@telerik/kendo-intl";

import {
  getPercentFromAmount,
  getAmountFromPercentUI,
  formatGridAmount,
} from "./ClientRateModelHelper";

const initialGroup = [{ field: "planName" }];

const ClientRateModelGrid = ({
  data,
  maxContribution,
  dataChange,
  isRateSubmitted,
  hasDentalNewOffering,
  hasVisionNewOffering,
}) => {
  const oePeriod = React.useContext(OEContext);

  const [internalData, setInternalData] = React.useState([]);
  const [resultState, setResultState] = React.useState([]);

  const [collapsedState, setCollapsedState] = React.useState([]);
  const [groupCheckboxState, setGroupCheckboxState] = React.useState([]);
  const [currentFocusId, setCurrentFocusId] = React.useState(null);

  React.useEffect(() => {
    setInternalData(data.rates);
    let groupedResult = processWithGroups(data.rates, initialGroup);

    let mapCheckState = groupedResult.map((group) => {
      let percentSelected = group.items.every((item) => item.percentSelected);
      let fixedValueSelected = group.items.every(
        (item) => item.fixedValueSelected
      );

      return {
        planName: group.value,
        percentChecked: percentSelected,
        fixedChecked: fixedValueSelected,
      };
    });

    setGroupCheckboxState(mapCheckState);

    setResultState(groupedResult);
  }, [data]);

  React.useEffect(() => {
    let groupedResult = processWithGroups(internalData, initialGroup);
    let mapCheckState = groupedResult.map((group) => {
      let percentSelected = group.items.every((item) => item.percentSelected);
      let fixedValueSelected = group.items.every(
        (item) => item.fixedValueSelected
      );

      return {
        planName: group.value,
        percentChecked: percentSelected,
        fixedChecked: fixedValueSelected,
      };
    });

    setGroupCheckboxState(mapCheckState);
    if (dataChange) {
      dataChange(internalData, data.groupId);
    }
  }, [internalData, dataChange, data.groupId]);

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
      data: newDataState,
      group: group,
    });
    return newDataState;
  };

  const itemChange = (e) => {
    let newData = internalData.map((item) => {
      if (item.clientRateModelId === e.dataItem.clientRateModelId) {
        item[e.field || ""] = e.value;
      }

      return item;
    });
    setInternalData(newData);
  };

  const PercentCopyChangeEvent = (event, dataItem) => {
    if (event.value) {
      let firstItem = null;
      let newData = internalData.map((item) => {
        if (item.planName === dataItem.value) {
          if (!firstItem) {
            firstItem = item;
          }
          item.percentSelected = true;
          item.fixedValueSelected = false;
        }

        return item;
      });
      if (firstItem) {
        setCurrentFocusId(firstItem.clientRateModelId + "pt");
      }
      setInternalData(newData);
      updateGroupCheckboxState(dataItem.value, true, false);
    } else {
      let newData = internalData.map((item) => {
        if (item.planName === dataItem.value) {
          item.percentSelected = false;
          item.fixedValueSelected = false;
          item.percentValue = null;
          item.fixedValue = null;
          item.newCompanyContribution = null;
          item.newEmployeeContribution = null;
        }

        return item;
      });
      setInternalData(newData);
      setCurrentFocusId(null);
      setGroupCheckboxState(
        groupCheckboxState.filter((item) => item.planName !== dataItem.value)
      );
    }
  };

  const AmountCopyChangeEvent = (event, dataItem) => {
    if (event.value) {
      let firstItem = null;
      let newData = internalData.map((item) => {
        if (item.planName === dataItem.value) {
          if (!firstItem) {
            firstItem = item;
          }
          item.percentSelected = false;
          item.fixedValueSelected = true;
        }

        return item;
      });
      if (firstItem) {
        setCurrentFocusId(firstItem.clientRateModelId + "at");
      }
      setInternalData(newData);
      updateGroupCheckboxState(dataItem.value, false, true);
    } else {
      let newData = internalData.map((item) => {
        if (item.planName === dataItem.value) {
          item.percentSelected = false;
          item.fixedValueSelected = false;
          item.percentValue = null;
          item.fixedValue = null;
          item.newCompanyContribution = null;
          item.newEmployeeContribution = null;
        }

        return item;
      });
      setInternalData(newData);
      setCurrentFocusId(null);
      setGroupCheckboxState(
        groupCheckboxState.filter((item) => item.planName !== dataItem.value)
      );
    }
  };

  const updateGroupCheckboxState = (planName, percentChecked, fixedChecked) => {
    let found = groupCheckboxState.find((item) => item.planName === planName);
    if (found) {
      found.percentChecked = percentChecked;
      found.fixedChecked = fixedChecked;
      setGroupCheckboxState([
        ...groupCheckboxState.filter((item) => item.planName !== planName),
        found,
      ]);
    } else {
      setGroupCheckboxState([
        ...groupCheckboxState,
        {
          planName: planName,
          percentChecked: percentChecked,
          fixedChecked: fixedChecked,
        },
      ]);
    }
  };

  const cellRender = (td, props) => {
    //https://www.telerik.com/kendo-react-ui/knowledge-base/render-a-custom-content-inside-the-grid-group-header/
    if (td && td.props.children && props.rowType === "groupHeader") {
      let children = <span>{td.props.children.props.children}</span>;

      const applyNewOfferingStyle =
        (hasDentalNewOffering &&
          props.dataItem.items.some((item) => item.planOfferType === "DEN")) ||
        (hasVisionNewOffering &&
          props.dataItem.items.some((item) => item.planOfferType === "VIS"));

      let percentStateSelected = groupCheckboxState.find(
        (e) => e.planName === props.dataItem.value
      )?.percentChecked
        ? true
        : false;
      let fixedValueStateSelected = groupCheckboxState.find(
        (e) => e.planName === props.dataItem.value
      )?.fixedChecked
        ? true
        : false;

      let percentCopy = (
        <span style={{ float: "right" }}>
          <Checkbox
            value={percentStateSelected}
            onChange={(e) => PercentCopyChangeEvent(e, props.dataItem)}
            disabled={isRateSubmitted}
          ></Checkbox>
        </span>
      );

      let amountCopy = (
        <span style={{ float: "right" }}>
          <Checkbox
            value={fixedValueStateSelected}
            onChange={(e) => AmountCopyChangeEvent(e, props.dataItem)}
            disabled={isRateSubmitted}
          ></Checkbox>
        </span>
      );

      let { colSpan, ...otherProps } = td.props;
      return (
        <>
          <td
            colSpan={8}
            {...otherProps}
            style={{ color: applyNewOfferingStyle ? "#f34704" : "inherit" }}
          >
            {children}
          </td>
          <td colSpan={1}>{percentCopy}</td>
          <td colSpan={1}>{amountCopy}</td>
          <td colSpan={3}></td>
        </>
      );
    }
    return td;
  };

  const PercentCell = (props) => {
    const [isEnabled, setIsEnabled] = React.useState(
      props.dataItem.percentSelected
    );
    const textboxRef = React.useRef();

    React.useEffect(() => {
      if (currentFocusId === props.dataItem.clientRateModelId + "pt") {
        textboxRef.current.focus();
      }
    }, [props.dataItem.clientRateModelId]);

    if (props.rowType === "groupHeader") {
      return null;
    }

    const handleChange = (e) => {
      setCurrentFocusId(props.dataItem.clientRateModelId + "pt");

      let currentValue = e.target.value ? e.target.value / 100 : 0;
      if (currentValue > 1) {
        currentValue = 1;
      } else if (currentValue < 0) {
        currentValue = 0;
      }

      let maxContrib = getMaxContrib(props.dataItem);
      if (props.dataItem.newTotalPremium > 0 && maxContrib > 0) {
        let maxPercent =
          Math.floor((maxContrib / dataItem.newTotalPremium) * 100) / 100;

        if (currentValue > maxPercent) {
          currentValue = maxPercent;
        }
      }

      if (props.dataItem.percentValue === currentValue) {
        return;
      }

      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: currentValue,
        });

        let erContrib = getAmountFromPercentUI(
          currentValue,
          props.dataItem.newTotalPremium
        );
        let eeContrib = props.dataItem.newTotalPremium - erContrib;

        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "fixedValue",
          syntheticEvent: e.syntheticEvent,
          value: erContrib,
        });

        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "newCompanyContribution",
          syntheticEvent: e.syntheticEvent,
          value: erContrib,
        });

        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "newEmployeeContribution",
          syntheticEvent: e.syntheticEvent,
          value: eeContrib,
        });
      }
    };

    const checkBoxChange = (e) => {
      let newData = !isEnabled;
      setIsEnabled(newData);

      if (newData) {
        setCurrentFocusId(props.dataItem.clientRateModelId + "pt");
      } else {
        setCurrentFocusId(null);
      }

      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "percentSelected",
          syntheticEvent: e.syntheticEvent,
          value: newData,
        });

        if (newData) {
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "fixedValueSelected",
            syntheticEvent: e.syntheticEvent,
            value: false,
          });
        } else {
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "fixedValue",
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "newCompanyContribution",
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "newEmployeeContribution",
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
        }
      }
    };

    const { dataItem } = props;

    const field = props.field || "";
    const dataValue = dataItem[field] >= 0 ? dataItem[field] * 100 : null;

    let maxPercent = 100;
    let maxContrib = getMaxContrib(dataItem);
    if (dataItem.newTotalPremium > 0 && maxContrib > 0) {
      maxPercent = Math.floor((maxContrib / dataItem.newTotalPremium) * 100);
    }

    return (
      <td colSpan={"1"} role={"gridcell"}>
        {dataItem.inEdit ? (
          <div style={{ display: "flex" }}>
            <NumericTextBox
              defaultValue={dataValue}
              spinners={false}
              onChange={handleChange}
              min={0}
              format={"n0"}
              max={maxPercent}
              step={1}
              disabled={!isEnabled || isRateSubmitted}
              ref={textboxRef}
            ></NumericTextBox>
            <Checkbox
              style={{ marginLeft: "10px", marginTop: "5px" }}
              checked={isEnabled}
              onChange={checkBoxChange}
              disabled={isRateSubmitted}
            />
          </div>
        ) : (
          dataValue
        )}
      </td>
    );
  };

  const AmountCell = (props) => {
    const [isEnabled, setIsEnabled] = React.useState(
      props.dataItem.fixedValueSelected
    );
    const textboxRef = React.useRef();

    React.useEffect(() => {
      if (currentFocusId === props.dataItem.clientRateModelId + "at") {
        textboxRef.current.focus();
      }
    }, [props.dataItem.clientRateModelId]);

    if (props.rowType === "groupHeader") {
      return null;
    }

    const handleChange = (e) => {
      setCurrentFocusId(props.dataItem.clientRateModelId + "at");

      let currentValue = e.target.value ? e.target.value : 0;
      let maxVal =
        props.dataItem.newTotalPremium === null
          ? 0
          : props.dataItem.newTotalPremium;

      let maxContrib = getMaxContrib(props.dataItem);
      if (maxContrib > 0 && maxVal > maxContrib) {
        maxVal = maxContrib;
      }

      if (currentValue > maxVal) {
        currentValue = maxVal;
      } else if (currentValue < 0) {
        currentValue = 0;
      }

      currentValue = formatGridAmount(currentValue);

      if (props.dataItem.fixedValue === currentValue) {
        return;
      }

      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: currentValue,
        });

        let contribPercent = getPercentFromAmount(
          currentValue,
          props.dataItem.newTotalPremium
        );
        let eeContrib = props.dataItem.newTotalPremium - currentValue;

        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "percentValue",
          syntheticEvent: e.syntheticEvent,
          value: contribPercent,
        });

        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "newCompanyContribution",
          syntheticEvent: e.syntheticEvent,
          value: currentValue,
        });

        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "newEmployeeContribution",
          syntheticEvent: e.syntheticEvent,
          value: eeContrib,
        });
      }
    };

    const checkBoxChange = (e) => {
      let newData = !isEnabled;
      setIsEnabled(newData);

      if (newData) {
        setCurrentFocusId(props.dataItem.clientRateModelId + "at");
      } else {
        setCurrentFocusId(null);
      }

      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: "fixedValueSelected",
          syntheticEvent: e.syntheticEvent,
          value: newData,
        });

        if (newData) {
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "percentSelected",
            syntheticEvent: e.syntheticEvent,
            value: false,
          });
        } else {
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "percentValue",
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "newCompanyContribution",
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: "newEmployeeContribution",
            syntheticEvent: e.syntheticEvent,
            value: null,
          });
        }
      }
    };

    const { dataItem } = props;

    const field = props.field || "";
    const dataValue = dataItem[field] >= 0 ? dataItem[field] : null;
    let maxValue =
      dataItem["newTotalPremium"] === null ? 0 : dataItem["newTotalPremium"];

    let maxContrib = getMaxContrib(dataItem);
    if (maxContrib && maxContrib > 0 && maxValue > maxContrib) {
      maxValue = maxContrib;
    }

    return (
      <td colSpan={"1"} role={"gridcell"}>
        {dataItem.inEdit ? (
          <div style={{ display: "flex" }}>
            <NumericTextBox
              defaultValue={dataValue}
              spinners={false}
              onChange={handleChange}
              min={0}
              max={maxValue}
              step={1}
              disabled={!isEnabled || isRateSubmitted}
              ref={textboxRef}
            ></NumericTextBox>
            <Checkbox
              style={{ marginLeft: "10px", marginTop: "5px" }}
              checked={isEnabled}
              onChange={checkBoxChange}
              disabled={isRateSubmitted}
            />
          </div>
        ) : (
          dataValue
        )}
      </td>
    );
  };

  const getMaxContrib = (dataItem) => {
    let maxContrib = 0;
    if (dataItem.planOfferType === "MED" && maxContribution) {
      if (
        dataItem.planType === "EE" &&
        maxContribution.eeContrib &&
        maxContribution.eeContrib > 0
      ) {
        maxContrib = maxContribution.eeContrib;
      } else if (
        dataItem.planType === "ES" &&
        maxContribution.esContrib &&
        maxContribution.esContrib > 0
      ) {
        maxContrib = maxContribution.esContrib;
      } else if (
        dataItem.planType === "EC" &&
        maxContribution.ecContrib &&
        maxContribution.ecContrib > 0
      ) {
        maxContrib = maxContribution.ecContrib;
      } else if (
        dataItem.planType === "FAM" &&
        maxContribution.famContrib &&
        maxContribution.famContrib > 0
      ) {
        maxContrib = maxContribution.famContrib;
      }
    }
    return maxContrib;
  };

  const currentAmountCell = (props) => {
    if (props.rowType === "groupHeader") {
      return null;
    }

    if (props.dataItem.currentMethod === "F") {
      return (
        <td className="k-text-center dark-bg-column highlight-coulmn">
          {formatNumber(props.dataItem.currentAmount, "c2")}
        </td>
      );
    } else {
      return (
        <td className="k-text-center dark-bg-column">
          {formatNumber(props.dataItem.currentAmount, "c2")}
        </td>
      );
    }
  };

  const currentPercentCell = (props) => {
    if (props.rowType === "groupHeader") {
      return null;
    }

    if (props.dataItem.currentMethod === "P") {
      return (
        <td className="k-text-center dark-bg-column highlight-coulmn">
          {formatNumber(props.dataItem.currentFmtPercentage, "p")}
        </td>
      );
    } else {
      return (
        <td className="k-text-center dark-bg-column">
          {formatNumber(props.dataItem.currentFmtPercentage, "p")}
        </td>
      );
    }
  };

  const totalChangeCell = (props) => {
    if (props.rowType === "groupHeader") {
      return null;
    }

    const currentContrib = props.dataItem.currentTotalPremium;
    const newContrib = props.dataItem.newTotalPremium;

    if (currentContrib === 0 && newContrib === 0) {
      return <td className="k-text-center dark-bg-column">0%</td>;
    } else if (currentContrib === 0 && newContrib !== 0) {
      return <td className="k-text-center dark-bg-column">100%</td>;
    }

    const totalChangePercent = (newContrib - currentContrib) / currentContrib;

    const formattedChange = totalChangePercent.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      style: "percent",
    });
    const iconClass = totalChangePercent > 0 ? "red-icon" : "green-icon";
    const arrowIcon = totalChangePercent > 0 ? "▲" : "▼";

    return (
      <td colSpan={props.colSpan} className="k-text-center dark-bg-column">
        <span className={iconClass}>
          {arrowIcon}
          {formattedChange}
        </span>
      </td>
    );
  };

  const TotalTextFooterCell = (props) => {
    return (
      <td colSpan={props.colSpan} className="k-text-center">
        {"Total"}
      </td>
    );
  };

  const EmployerContribFooterCell = (props) => {
    const total = internalData.reduce((total, current) => {
      if (current.newCompanyContribution) {
        return (
          total +
          Number(current.newCompanyContribution) * Number(current.eeCount)
        );
      }
      return total;
    }, 0);
    return (
      <td colSpan={props.colSpan} className="k-text-right">
        {`${formatNumber(total, "c2")}`}
      </td>
    );
  };

  const EmployeeContribFooterCell = (props) => {
    const total = internalData.reduce((total, current) => {
      if (current.newEmployeeContribution) {
        return (
          total +
          Number(current.newEmployeeContribution) * Number(current.eeCount)
        );
      }
      return total;
    }, 0);
    return (
      <td colSpan={props.colSpan} className="k-text-right">
        {`${formatNumber(total, "c2")}`}
      </td>
    );
  };

  const groupedGridData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });

  return (
    <Grid
      sortable={false}
      editField="inEdit"
      onItemChange={itemChange}
      onExpandChange={onExpandChange}
      expandField="expanded"
      cellRender={cellRender}
      groupable={true}
      group={initialGroup}
      data={groupedGridData}
    >
      <Column
        field="planTypeDescription"
        title="Tier"
        headerClassName="grid-bold-header"
        className="dark-bg-column"
        width={"120px"}
        sortable={false}
        editable={false}
      />
      <Column title="Last Year Rate" headerClassName="grid-bold-header">
        <Column
          field="currentTotalPremium"
          title="Rate"
          headerClassName="grid-bold-header"
          className="k-text-right dark-bg-column"
          format="{0:c}"
          sortable={false}
          editable={false}
        />
        <Column
          title="Monthly Contributions"
          headerClassName="grid-bold-header"
        >
          <Column
            field="currentFmtPercentage"
            title="%"
            headerClassName="grid-bold-header"
            className="k-text-center dark-bg-column"
            format="{0:p}"
            sortable={false}
            editable={false}
            cell={currentPercentCell}
          />
          <Column
            field="currentAmount"
            title="$"
            headerClassName="grid-bold-header"
            className="k-text-right dark-bg-column"
            format="{0:c}"
            sortable={false}
            editable={false}
            cell={currentAmountCell}
          />
        </Column>
        <Column
          field="eeCount"
          title="Employee Count"
          headerClassName="grid-bold-header"
          className="k-text-center dark-bg-column"
          sortable={false}
          editable={false}
        />
        <Column
          field="currentTotalAmount"
          title="Total"
          headerClassName="grid-bold-header"
          className="k-text-right dark-bg-column"
          format="{0:c}"
          sortable={false}
          editable={false}
        />
      </Column>
      <Column title="Next Year Rate" headerClassName="grid-bold-header">
        <Column
          field="newTotalPremium"
          title={`Rate Effective ${formatDate(
            new Date(oePeriod.effectiveDate),
            "d"
          )}`}
          headerClassName="grid-bold-header"
          className="k-text-right bold-font-coulmn"
          format="{0:c}"
          sortable={false}
          editable={false}
        />
        <Column
          title="Monthly Contributions"
          headerClassName="grid-bold-header"
        >
          <Column
            field="percentValue"
            title="%"
            headerClassName="grid-bold-header"
            className="k-text-center bold-font-coulmn"
            sortable={false}
            editable={true}
            width={"120px"}
            editor={"numeric"}
            cell={PercentCell}
          />
          <Column
            field="fixedValue"
            title="$"
            headerClassName="grid-bold-header"
            className="k-text-right bold-font-coulmn"
            format="{0:c}"
            sortable={false}
            width={"140px"}
            editor={"numeric"}
            cell={AmountCell}
            footerCell={TotalTextFooterCell}
          />
        </Column>
        <Column title="Final Contribution" headerClassName="grid-bold-header">
          <Column
            field="newCompanyContribution"
            title="Employer"
            headerClassName="grid-bold-header"
            className="k-text-right dark-bg-column"
            format="{0:c}"
            sortable={false}
            editable={false}
            footerCell={EmployerContribFooterCell}
          />
          <Column
            field="newEmployeeContribution"
            title="Employee"
            headerClassName="grid-bold-header"
            className="k-text-right dark-bg-column"
            format="{0:c}"
            sortable={false}
            editable={false}
            footerCell={EmployeeContribFooterCell}
          />
          <Column
            field=""
            title="Rate Change (%)"
            headerClassName="grid-bold-header"
            className="k-text-right dark-bg-column"
            format="{0:c}"
            sortable={false}
            editable={false}
            cell={totalChangeCell}
          />
        </Column>
      </Column>
    </Grid>
  );
};

export default ClientRateModelGrid;
