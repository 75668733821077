import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useQueryGetImportErrors } from "../../data/services/importService";

const ImportErrorModel = (props) => {
  const { isLoading: errorsLoading, data: errorsList } =
    useQueryGetImportErrors(props.importId);

  const DialogBody = () => {
    if (errorsLoading) {
      return <div>Loading...</div>;
    }

    if (!errorsList || !errorsList.data) {
      return <div>No error details found</div>;
    }

    return (
      <ul>
        {errorsList.data.map((error, index) => {
          return <li key={index}>{error}</li>;
        })}
      </ul>
    );
  };

  return (
    props.visible && (
      <Dialog
        title={"Import Error Details"}
        onClose={props.toggleDialog}
        width={600}
        height={350}
      >
        <DialogBody />
        <DialogActionsBar layout={"end"}>
          <Button onClick={props.toggleDialog}>{"Close"}</Button>
        </DialogActionsBar>
      </Dialog>
    )
  );
};

export default ImportErrorModel;
