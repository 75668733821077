import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormInput } from "../../components/FormInput";
import { FormDatePicker } from "../../components/FormDatePicker";
import ErrorBanner from "../../components/ErrorBanner";

import {
  useMutatePostOEPeriod,
  useMutatePutOEPeriod,
} from "../../data/services/openEnrollmentService";

const AddEditOEModel = (props) => {
  const [submitted, setSubmitted] = React.useState(false);

  let isEdit = props.item.oePeriodId ? true : false;

  const useMutatePostOEPeriodHandler = useMutatePostOEPeriod();
  const useMutatePutOEPeriodHandler = useMutatePutOEPeriod();

  const ToUTCDate = (date) => {
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let year = date.getFullYear();

    return `${year}-${month}-${day}T00:00:00.000Z`;
  };

  const handleSubmit = async (item) => {
    var data = {
      oePeriodName: item.oePeriodName,
      effectiveDate: ToUTCDate(item.effectiveDate),
    };

    setSubmitted(true);

    if (isEdit) {
      data.oePeriodId = props.item.oePeriodId;
      await useMutatePutOEPeriodHandler.mutateAsync(data);
    } else {
      await useMutatePostOEPeriodHandler.mutateAsync(data);
    }

    setSubmitted(false);
    props.onSubmit();
  };

  return (
    <Dialog
      title={isEdit ? `Edit ${props.item.oePeriodName}` : "Add"}
      onClose={props.cancelEdit}
      width={400}
    >
      <ErrorBanner
        handler={useMutatePutOEPeriodHandler}
        onError={() => setSubmitted(false)}
      />
      <ErrorBanner
        handler={useMutatePostOEPeriodHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"oePeriodName"}
              name={"oePeriodName"}
              label={"Name"}
              autoFocus={true}
              component={FormInput}
              required={true}
              validator={(value) =>
                value ? "" : "Please enter a OE period name"
              }
            />
            <Field
              id={"effectiveDate"}
              name={"effectiveDate"}
              label={"Effective Date"}
              required={true}
              component={FormDatePicker}
              validator={(value) =>
                value ? "" : "Please enter a effective date"
              }
            />

            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={props.cancelEdit}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"primary"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddEditOEModel;
