import * as React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import LoadingIndicator from "../../components/LoadingIndicator";
import ErrorBanner from "../../components/ErrorBanner";
import {
  useQueryGetNotificationRedirects,
  useMutatePostNotificationRedirect,
} from "../../data/services/notificationService";
import SuccessBanner from "../../components/SuccessBanner";

const NotificationRedirect = () => {
  const [blockAlways, setBlockAlways] = React.useState(false);
  const [blockEmails, setBlockEmails] = React.useState(false);
  const [redirectMailTo, setRedirectMailTo] = React.useState("");

  const {
    isLoading: notificationRedirectLoading,
    data: notificationRedirectData,
  } = useQueryGetNotificationRedirects();
  const useMutatePostNotificationRedirectHandler =
    useMutatePostNotificationRedirect();

  React.useEffect(() => {
    if (
      !notificationRedirectLoading &&
      notificationRedirectData &&
      notificationRedirectData.data
    ) {
      setBlockAlways(notificationRedirectData.data.blockAlways);
      setBlockEmails(
        notificationRedirectData.data.redirectMail ||
          notificationRedirectData.data.blockAlways
      );
      setRedirectMailTo(notificationRedirectData.data.redirectTo);
    }
  }, [notificationRedirectLoading, notificationRedirectData]);

  if (notificationRedirectLoading) {
    return <LoadingIndicator />;
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSave = async () => {
    if (blockEmails) {
      if (!redirectMailTo) {
        alert("Email address is required");
        return;
      } else if (
        redirectMailTo.split(",").find((email) => !validateEmail(email))
      ) {
        alert("Please enter a valid email address");
        return;
      }
    }

    const notificationRedirect = {
      blockAlways: blockAlways,
      redirectMail: blockEmails,
      redirectTo: redirectMailTo,
    };
    await useMutatePostNotificationRedirectHandler.mutateAsync(
      notificationRedirect
    );
  };

  return (
    <div>
      <h2>NotificationRedirect</h2>
      <ErrorBanner
        handler={useMutatePostNotificationRedirectHandler}
        onError={() => {}}
      />
      <SuccessBanner
        handler={useMutatePostNotificationRedirectHandler}
        message={"Successfully saved."}
      />
      <div>
        <Checkbox
          label={`Block emails to Contacts and redirect to below users`}
          checked={blockEmails}
          disabled={blockAlways}
          onChange={() => setBlockEmails(!blockEmails)}
        />
      </div>
      <div style={{ marginTop: "30px" }}>
        <label>Redirect to (Enter comma separated emails)</label>
        <TextArea
          id={"RedirectTo"}
          name={"RedirectTo"}
          style={{ marginTop: "10px" }}
          label={`Redirect to users`}
          value={redirectMailTo}
          disabled={!blockEmails}
          onChange={(event) => setRedirectMailTo(event.value)}
        />
      </div>
      <div style={{ marginTop: "30px" }}>
        <Button type={"submit"} themeColor={"success"} onClick={onSave}>
          {"Save"}
        </Button>
      </div>
    </div>
  );
};

export default NotificationRedirect;
