import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";

import ClientBenefitPlanGrid from "./ClientBenefitPlanGrid";
import ClientContactsGrid from "./ClientContactsGrid";
import ClientNotificationHistoryGrid from "./ClientNotificationHistoryGrid";
import ClientRateMenuGrid from "./ClientRateMenuGrid";
import ClientEmpMenuGrid from "./ClientEmpMenuGrid";
import OEContext from "../../layouts/OEContext";

import { useQueryGetClients } from "../../data/services/clientService";

const ClientAdminLayout = () => {
  const { clientId } = useParams();
  const oePeriod = React.useContext(OEContext);

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [client, setClient] = React.useState({});

  const { isLoading: clientListLoading, data: clientList } = useQueryGetClients(
    oePeriod?.oePeriodId
  );

  React.useEffect(() => {
    if (!clientListLoading && clientList && clientList.data) {
      let client = clientList.data.find((e) => e.clientId === clientId);
      setClient(client);
    }
  }, [clientListLoading, clientList, clientId]);

  const handleTabSelect = (e) => {
    setSelectedTabIndex(e.selected);
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2>{`${client?.clientId} - ${client?.clientName}`}</h2>
        </div>
        <div></div>
      </div>
      <TabStrip selected={selectedTabIndex} onSelect={handleTabSelect}>
        <TabStripTab title="Benefit Plans">
          <ClientBenefitPlanGrid oePeriod={oePeriod} />
        </TabStripTab>
        <TabStripTab title="Contacts">
          <ClientContactsGrid />
        </TabStripTab>
        <TabStripTab title="Notification History">
          <ClientNotificationHistoryGrid />
        </TabStripTab>
        <TabStripTab title="Rate Menus">
          <ClientRateMenuGrid />
        </TabStripTab>
        <TabStripTab title="Rate Employee Menus">
          <ClientEmpMenuGrid />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default ClientAdminLayout;
