import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

const UserGuide = () => {
  return (
    <div>
      <h2>User Guide</h2>
      <iframe
        src={SERVICE_URLS.USER_GUIDE_DOWNLOAD}
        style={{ border: "none" }}
        width={"100%"}
        height={"800px"}
        title="User Guide"
      ></iframe>
    </div>
  );
};

export default UserGuide;
