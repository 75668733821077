import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import { useQueryClient } from "react-query";

import { QUERY_KEYS } from "../../data/helpers/queryKeys";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  PlanMappingSuccess,
  PlanMappingInProgress,
} from "./ImportStatusHelper";
import {
  useQueryGetImportPlanMapping,
  useMutatePostRetryMapping,
} from "../../data/services/importService";

const ImportPlanMapping = ({ id, importItem, onNextClick, onPrevClick }) => {
  const [importPlanMappingSrc, setImportPlanMappingSrc] = React.useState([]);
  const [importPlanMappingMaster, setImportPlanMappingMaster] = React.useState(
    []
  );
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const [nextEnabled, setNextEnabled] = React.useState(false);
  const [isMappingInProgress, setIsMappingInProgress] = React.useState(false);
  const queryClient = useQueryClient();

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: importPlanMappingLoading, data: importPlanMapping } =
    useQueryGetImportPlanMapping(id);
  const useMutatePostRetryMappingHandler = useMutatePostRetryMapping();

  React.useEffect(() => {
    let plaMappingSuccess =
      importItem && PlanMappingSuccess(importItem.importStatus);
    let plaMappingInProgress =
      importItem && PlanMappingInProgress(importItem.importStatus);

    if (
      !importPlanMappingLoading &&
      importPlanMapping &&
      importPlanMapping.data
    ) {
      if (plaMappingSuccess) {
        setImportPlanMappingMaster(importPlanMapping.data);
        setImportPlanMappingSrc(
          process(importPlanMapping.data.slice(0), gridDataState)
        );
      } else {
        let filtered = importPlanMapping.data.filter((x) => !x.prismPlanId);
        setImportPlanMappingMaster(filtered);
        setImportPlanMappingSrc(process(filtered.slice(0), gridDataState));
      }
    }

    setNextEnabled(plaMappingSuccess);
    setIsMappingInProgress(plaMappingInProgress);
  }, [importPlanMappingLoading, importPlanMapping, gridDataState, importItem]);

  const onRetryClick = async () => {
    var data = {
      importId: id,
      content: {
        importFileQueueId: id,
        requestType: "PlanMapping",
      },
    };

    await useMutatePostRetryMappingHandler.mutateAsync(data);
  };

  const onRefreshClick = () => {
    queryClient.invalidateQueries([QUERY_KEYS.IMPORT_ITEM, id]);
  };

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setImportPlanMappingSrc(
        process(importPlanMappingMaster.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    return (
      <Grid
        pageable={pagerSetting}
        pageSize={20}
        sortable={true}
        data={importPlanMappingSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="planGroup"
          title="Plan Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planGroup")}
        />
        <Column
          field="planName"
          title="Plan Name"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planName")}
        />
        {nextEnabled && (
          <Column
            field="prismPlanName"
            title="Prism Plan"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("prismPlanName")}
          />
        )}
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={importPlanMappingMaster}
          expanded={true}
        />
      </div>
    );
  };

  if (importPlanMappingLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"light"}
            onClick={onPrevClick}
            iconClass="k-icon k-i-arrow-left"
          >
            Import Data
          </Button>
        </div>
        <div style={{ alignSelf: "center" }}>
          <h3 style={{ marginTop: 30 }}>PrismHR Plan Mapping</h3>
        </div>
        <div style={{ alignSelf: "center" }}>
          {nextEnabled && (
            <Button
              themeColor={"primary"}
              onClick={onNextClick}
              iconClass="k-icon k-i-arrow-right"
            >
              Rate Group Mapping
            </Button>
          )}
          {!nextEnabled && (
            <Button
              themeColor={"primary"}
              onClick={onRetryClick}
              disabled={isMappingInProgress}
            >
              {" "}
              Retry{" "}
            </Button>
          )}
        </div>
      </div>
      {!nextEnabled && !isMappingInProgress && (
        <div
          style={{
            padding: 20,
            marginTop: 10,
            marginBottom: 20,
            backgroundColor: "#f8d7da",
            color: "#721c24",
            borderRadius: ".25rem",
          }}
        >
          Error mapping to PrismHR Plans. Below is the list of plans that could
          not be mapped. Please setup the mapping in "PrismHR Mapping" tab and
          retry.
        </div>
      )}
      {isMappingInProgress && (
        <>
          <div
            style={{
              padding: 20,
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: "#fff3cd",
              color: "#856404",
              borderRadius: ".25rem",
            }}
          >
            Mapping to PrismHR Plans is in progress.{" "}
            <Button
              themeColor="info"
              onClick={onRefreshClick}
              style={{ marginLeft: 20 }}
            >
              {" "}
              Refresh{" "}
            </Button>
          </div>
        </>
      )}
      {!isMappingInProgress && <RenderGrid />}
    </div>
  );
};

export default ImportPlanMapping;
