import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosGet,
  axiosPost,
  axiosFormPost,
  axiosPut,
  axiosDelete,
  reactQueryDefaultOptions,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetPlanMappingList = () => {
  const getPlanMappingList = async () => {
    return await axiosGet(SERVICE_URLS.PLAN_MAPPING_LIST);
  };
  return useQuery(
    [QUERY_KEYS.PLAN_MAPPING_LIST],
    getPlanMappingList,
    reactQueryDefaultOptions
  );
};

export const useQueryGetPlanList = () => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.PLAN_LIST);
  };
  return useQuery(
    [QUERY_KEYS.PLAN_LIST],
    getPlanList,
    reactQueryDefaultOptions
  );
};

export const useQueryGetPlanDetailsList = () => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.PLAN_DETAIL_LIST);
  };
  return useQuery(
    [QUERY_KEYS.PLAN_DETAIL_LIST],
    getPlanList,
    reactQueryDefaultOptions
  );
};

export const useQueryGetPlanMappingStatus = () => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.PLAN_MAPPING_STATUS);
  };

  let option = {
    staleTime: 1000 * 60,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 60000,
  };

  return useQuery([QUERY_KEYS.PLAN_MAPPING_STATUS], getPlanList, option);
};

export const useQueryGetPlanRates = (oePeriodId, planId) => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.PLAN_RATES_GET(oePeriodId, planId));
  };
  return useQuery(
    [QUERY_KEYS.PLAN_RATES_GET, oePeriodId, planId],
    getPlanList,
    reactQueryDefaultOptions
  );
};

export const useQueryGetPlanListForMapping = (offerType) => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.PLAN_LIST_FOR_MAPPING(offerType));
  };
  return useQuery(
    [QUERY_KEYS.PLAN_LIST_FOR_MAPPING, offerType],
    getPlanList,
    reactQueryDefaultOptions
  );
};

export const useMutatePostPlanMapping = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.PLAN_MAPPING_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PLAN_MAPPING_LIST]);
    },
  });
};

export const useMutatePutPlanMapping = () => {
  const pushData = async (data) => {
    return await axiosPut(SERVICE_URLS.PLAN_MAPPING_PUT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(pushData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PLAN_MAPPING_LIST]);
    },
  });
};

export const useMutateDeletePlanMapping = () => {
  const deleteData = async (data) => {
    return await axiosDelete(SERVICE_URLS.PLAN_MAPPING_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(deleteData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.PLAN_MAPPING_LIST]);
    },
  });
};

export const useQueryGetRateGroupMappingList = () => {
  const getList = async () => {
    return await axiosGet(SERVICE_URLS.RATE_GROUP_MAP_LIST);
  };
  return useQuery(
    [QUERY_KEYS.RATE_GROUP_MAP_LIST],
    getList,
    reactQueryDefaultOptions
  );
};

export const useMutatePostRateGroupMapping = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.RATE_GROUP_MAP_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.RATE_GROUP_MAP_LIST]);
    },
  });
};

export const useMutateImportRateGroupMapping = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.RATE_GROUP_MAP_IMPORT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.RATE_GROUP_MAP_LIST]);
    },
  });
};

export const useMutatePutRateGroupMapping = () => {
  const pushData = async (data) => {
    return await axiosPut(SERVICE_URLS.RATE_GROUP_MAP_PUT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(pushData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.RATE_GROUP_MAP_LIST]);
    },
  });
};

export const useMutateDeleteRateGroupMapping = () => {
  const deleteData = async (data) => {
    return await axiosDelete(SERVICE_URLS.RATE_GROUP_MAP_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(deleteData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.RATE_GROUP_MAP_LIST]);
    },
  });
};

export const useQueryGetBenefitGroupMapping = (offerType) => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.RATE_GROUP_MAPPING_LIST(offerType));
  };
  return useQuery(
    [QUERY_KEYS.RATE_GROUP_MAPPING_LIST, offerType],
    getPlanList,
    reactQueryDefaultOptions
  );
};

export const useMutatePostBenefitGroupMapping = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.RATE_GROUP_MAPPING_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.RATE_GROUP_MAPPING_LIST,
        variables.offerType,
      ]);
    },
  });
};

export const useMutateDeleteBenefitGroupMapping = () => {
  const deleteData = async (data) => {
    return await axiosDelete(SERVICE_URLS.RATE_GROUP_MAPPING_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(deleteData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.RATE_GROUP_MAPPING_LIST,
        variables.offerType,
      ]);
    },
  });
};

export const useMutatePostPlanDetailImport = () => {
  const queueImport = async (data) => {
    var formData = new FormData();
    formData.append("medical", data.medFile, data.medFileName);
    formData.append("dental", data.denFile, data.denFileName);

    return await axiosFormPost(
      SERVICE_URLS.BENEFIT_PLAN_DETAIL_IMPORT(
        data.oePeriodId,
        data.oeWorkflowId
      ),
      formData
    );
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_GET,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useQueryGetSystemMenuFilters = (oePeriodId) => {
  const getPlanList = async () => {
    return await axiosGet(SERVICE_URLS.SYSTEM_MENU_FILTERS(oePeriodId));
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    enabled: enabled,
  };

  return useQuery([QUERY_KEYS.SYSTEM_MENU_FILTERS], getPlanList, option);
};
