import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { useMutatePutOEPeriodStatus } from "../../data/services/openEnrollmentService";

const ConfirmPublishOEModel = (props) => {
  const useMutatePutOEPeriodStatusHandler = useMutatePutOEPeriodStatus();

  const handleSubmit = async () => {
    var data = {
      oePeriodId: props.item.oePeriodId,
      status: props.isPublish ? "Published" : "Unpublished",
    };

    await useMutatePutOEPeriodStatusHandler.mutateAsync(data);
    props.onClose();
  };

  return (
    <Dialog
      title={props.isPublish ? "Confirm Publish" : "Confirm Unpublish"}
      onClose={props.onClose}
      width={"600px"}
    >
      <p
        style={{
          margin: "25px",
          textAlign: "left",
        }}
      >
        {props.isPublish
          ? "Are you sure you want to publish this Open Enrollment Period?"
          : "Are you sure you want to unpublish this Open Enrollment Period?"}
      </p>
      <p
        style={{
          margin: "25px",
          textAlign: "left",
        }}
      >
        <b>
          {props.isPublish
            ? "Note: Publishing will make the Open Enrollment Renewal Grid available to contact users. It will unpublish other published open enrollments."
            : "Note: Unpublishing will make the Open Enrollment Renewal Grid unavailable to contact users."}
        </b>
      </p>
      <DialogActionsBar layout="end">
        <Button onClick={props.onClose}>{"Cancel"}</Button>
        <Button type={"submit"} themeColor={"primary"} onClick={handleSubmit}>
          {"Confirm"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ConfirmPublishOEModel;
