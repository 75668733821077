import * as React from "react";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";

import ErrorBanner from "../../components/ErrorBanner";
import DeleteDialog from "../../components/DeleteDialog";

import {
  useQueryGetRateGroupMappingList,
  useMutatePostRateGroupMapping,
  useMutateDeleteRateGroupMapping,
  useMutatePutRateGroupMapping,
} from "../../data/services/benefitPlanService";
import { useQueryGetCarrierList } from "../../data/services/carrierService";
import ImportRateGroupMappingModel from "./ImportRateGroupMappingModel";

const RateGroupMapping = () => {
  const editField = "inEdit";
  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const [gridDataState, setGridDataState] = React.useState({
    take: 10,
    skip: 0,
  });
  const [data, setData] = React.useState([]);
  const [masterMappingData, setMasterMappingData] = React.useState([]);
  const [carrierList, setCarrierList] = React.useState([]);
  const [isInAddMode, setIsInAddMode] = React.useState(false);

  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState();

  const [showImportModel, setShowImportModel] = React.useState(false);

  const { isLoading: rateGroupMappingLoading, data: rateGroupMappings } =
    useQueryGetRateGroupMappingList();
  const { isLoading: carrierListLoading, data: carrierListResp } =
    useQueryGetCarrierList();

  const useMutatePostRateGroupMappingHandler = useMutatePostRateGroupMapping();
  const useMutatePutRateGroupMappingHandler = useMutatePutRateGroupMapping();
  const useMutateDeleteRateGroupMappingHandler =
    useMutateDeleteRateGroupMapping();

  React.useEffect(() => {
    if (
      !rateGroupMappingLoading &&
      rateGroupMappings &&
      rateGroupMappings.data
    ) {
      setData(rateGroupMappings.data);
      setMasterMappingData(rateGroupMappings.data);
    }

    if (!carrierListLoading && carrierListResp && carrierListResp.data) {
      setCarrierList(carrierListResp.data);
    }
  }, [
    rateGroupMappingLoading,
    rateGroupMappings,
    carrierListLoading,
    carrierListResp,
  ]);

  const CarrierCell = (props) => {
    const handleChange = (e) => {
      if (props.onChange) {
        props.onChange({
          dataIndex: 0,
          dataItem: props.dataItem,
          field: props.field,
          syntheticEvent: e.syntheticEvent,
          value: e.target.value.carrierName,
        });
      }
    };

    const { dataItem } = props;

    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const dataEntity = carrierList.find((x) => x.carrierName === dataValue);
    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            onChange={handleChange}
            value={dataEntity}
            data={carrierList}
            textField="carrierName"
          />
        ) : (
          dataValue
        )}
      </td>
    );
  };

  const CommandCell = (props) => {
    const { dataItem } = props;

    const inEdit = dataItem[editField];
    const isNewItem = dataItem.bpRateGroupMappingId === undefined;

    return inEdit ? (
      <td className="k-command-cell">
        <Button onClick={() => (isNewItem ? add(dataItem) : update(dataItem))}>
          {isNewItem ? "Add" : "Update"}
        </Button>
        <Button
          onClick={() => (isNewItem ? discard(dataItem) : cancel(dataItem))}
        >
          {isNewItem ? "Discard" : "Cancel"}
        </Button>
      </td>
    ) : (
      <td className="k-command-cell">
        <Button onClick={() => enterEdit(dataItem)}>Edit</Button>
        <Button onClick={() => remove(dataItem)}>Remove</Button>
      </td>
    );
  };

  const remove = async (dataItem) => {
    setDeleteItem(dataItem);
    setShowDeleteModel(true);
  };

  const handleDeleteConfirm = async (data) => {
    var entity = {
      bpRateGroupMappingId: data.bpRateGroupMappingId,
    };

    await useMutateDeleteRateGroupMappingHandler.mutateAsync(entity);

    setShowDeleteModel(false);
    setDeleteItem(null);
    setIsInAddMode(false);
  };

  const add = async (dataItem) => {
    dataItem.inEdit = true;

    let carrierId = carrierList.find(
      (e) => e.carrierName === dataItem.carrierName
    ).carrierId;

    var entity = {
      carrierId: carrierId,
      planGroup: dataItem.planGroup,
      rateFactorName: dataItem.rateFactorName,
      rateGroup: dataItem.rateGroup,
    };

    await useMutatePostRateGroupMappingHandler.mutateAsync(entity);

    setIsInAddMode(false);
  };

  const update = async (dataItem) => {
    let carrierId = carrierList.find(
      (e) => e.carrierName === dataItem.carrierName
    ).carrierId;

    var entity = {
      bpRateGroupMappingId: dataItem.bpRateGroupMappingId,
      carrierId: carrierId,
      planGroup: dataItem.planGroup,
      rateFactorName: dataItem.rateFactorName,
      rateGroup: dataItem.rateGroup,
    };

    await useMutatePutRateGroupMappingHandler.mutateAsync(entity);

    dataItem.inEdit = false;
    setIsInAddMode(false);
  };

  const discard = (dataItem) => {
    const newData = [...data];
    newData.splice(0, 1);
    setData(newData);
    setIsInAddMode(false);
  };

  const cancel = (dataItem) => {
    const originalItem = masterMappingData.find(
      (p) => p.bpRateGroupMappingId === dataItem.bpRateGroupMappingId
    );
    const newData = data.map((item) =>
      item.bpRateGroupMappingId === originalItem.bpRateGroupMappingId
        ? originalItem
        : item
    );
    setData(newData);
  };

  const enterEdit = (dataItem) => {
    let newData = data.map((item) =>
      item.bpRateGroupMappingId === dataItem.bpRateGroupMappingId
        ? {
            ...item,
            inEdit: true,
          }
        : item
    );
    setData(newData);
  };

  const itemChange = (event) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item.bpRateGroupMappingId === event.dataItem.bpRateGroupMappingId
        ? {
            ...item,
            [field]: event.value,
          }
        : item
    );
    setData(newData);
  };

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
    };
    setData([newDataItem, ...data]);
    setIsInAddMode(true);
    setGridDataState({
      take: 10,
      skip: 0,
    });
  };

  const dataStateChange = (event) => {
    setGridDataState(event.dataState);
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={masterMappingData}
          expanded={true}
        />
      </div>
    );
  };

  const columnMenuHeader = (field) => {
    var active = GridColumnMenuFilter.active(field, gridDataState.filter);
    return active ? "active" : "";
  };

  const rowRender = (trElement, props) => {
    const inEdit = props.dataItem.inEdit;
    const green = {
      backgroundColor: "#fffdaf",
    };
    const trProps = {
      style: inEdit ? green : {},
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <>
      <ErrorBanner handler={useMutatePostRateGroupMappingHandler} />
      <ErrorBanner handler={useMutatePutRateGroupMappingHandler} />
      <ErrorBanner handler={useMutateDeleteRateGroupMappingHandler} />
      <Grid
        data={process(data.slice(0), gridDataState)}
        onItemChange={itemChange}
        sortable={true}
        pageable={pagerSetting}
        pageSize={10}
        editField={editField}
        dataItemKey={"bpRateGroupMappingId"}
        rowRender={rowRender}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <GridToolbar>
          <Button themeColor="primary" onClick={addNew} disabled={isInAddMode}>
            Add new
          </Button>
          <Button
            themeColor="info"
            onClick={() => setShowImportModel(true)}
            style={{ marginLeft: "10px" }}
            disabled={isInAddMode}
          >
            Import Mapping
          </Button>
        </GridToolbar>
        <Column
          field="carrierName"
          title="Carrier"
          width={"150px"}
          cell={CarrierCell}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("carrierName")}
        />
        <Column
          field="planGroup"
          title="Plan Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planGroup")}
        />
        <Column
          field="rateFactorName"
          title="Rate Factor Name"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("rateFactorName")}
        />
        <Column
          field="rateGroup"
          title="Prism Billing Rate Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("rateGroup")}
        />
        <Column cell={CommandCell} width="160px" />
      </Grid>
      {showDeleteModel && (
        <DeleteDialog
          onDelete={handleDeleteConfirm}
          onCancel={() => setShowDeleteModel(false)}
          item={deleteItem}
        />
      )}
      {showImportModel && (
        <ImportRateGroupMappingModel
          onClose={() => setShowImportModel(false)}
          existingMappings={masterMappingData}
          carrierList={carrierList}
        />
      )}
    </>
  );
};

export default RateGroupMapping;
