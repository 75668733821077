import { Loader } from "@progress/kendo-react-indicators";
import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import { axiosPost } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";
import { QUERY_KEYS } from "../../data/helpers/queryKeys";
import { useQueryClient } from "react-query";

const Login = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isAuthenticated, setIsAuthenticated] = React.useState(1);

  React.useEffect(() => {
    let searchParams = new URLSearchParams(search);

    let entity = {
      token: searchParams.get("key"),
    };

    if (!entity.token) {
      setIsAuthenticated(3);
    } else {
      axiosPost(SERVICE_URLS.USER_AUTHENTICATE, entity)
        .then((resp) => {
          queryClient.invalidateQueries([QUERY_KEYS.USER_GET]);

          if (resp.data && resp.data.isAuthenticated) {
            setIsAuthenticated(2);
            if (resp.data.hasAdminAppPrivilege) {
              navigate("/admin/dashboard");
            } else if (resp.data.hasClientAppPrivilege) {
              navigate("/dashboard");
            } else {
              setIsAuthenticated(3);
            }
          } else {
            setIsAuthenticated(3);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsAuthenticated(3);
          queryClient.invalidateQueries([QUERY_KEYS.USER_GET]);
        });
    }
  }, [search, navigate, queryClient]);

  const RenderMessage = () => {
    if (isAuthenticated === 2) {
      return (
        <span>
          Successfully logged-in. Click <a href="/admin/dashboard">here</a> to
          navigate to dashboard.
        </span>
      );
    } else {
      return (
        <span>Failed to login. Please try navigating again from PrismHR.</span>
      );
    }
  };

  if (isAuthenticated === 1) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader size="large" type={"infinite-spinner"} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#EEF2F7",
        }}
      >
        <div
          style={{
            width: "400px",
            textAlign: "center",
            height: "400px",
            backgroundColor: "#ffff",
          }}
          className={"login-form"}
        >
          <p
            style={{
              backgroundColor: "#192853",
              marginTop: "0px",
              padding: "10px",
            }}
          >
            <img
              src={"/Logo_white.svg"}
              alt={"PrestigePEO logo"}
              style={{ height: 45 }}
            />
          </p>
          <h2>SSO Login</h2>
          <p style={{ padding: "40px" }}>
            <RenderMessage />
          </p>
        </div>
      </div>
    );
  }
};

export default Login;
