import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import ErrorBanner from "../../components/ErrorBanner";
import { process } from "@progress/kendo-data-query";
import AlertDialog from "../../components/AlertDialog";

import { Loader } from "@progress/kendo-react-indicators";

import {
  useQueryGetModelAvailablePlans,
  useMutateModelAddPlan,
} from "../../data/services/clientService";
import ClientRMDeletePlanModal from "./ClientRMDeletePlanModal";

const ClientRateModelAddPlan = ({
  oePeriodId,
  clientId,
  versionId,
  groupId,
  onClose,
}) => {
  const DATA_ITEM_KEY = "planId";
  const SELECTED_FIELD = "isAlreadyMapped";
  const gridSelectableSetting = {
    enabled: true,
    drag: false,
    cell: false,
    mode: "multiple",
  };

  const [selected, setSelected] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  const [hasNewDentalPlanOffering, setHasNewDentalPlanOffering] =
    React.useState(false);
  const [hasNewVisionPlanOffering, setHasNewVisionPlanOffering] =
    React.useState(false);

  const [medicalPlansSrc, setMedicalPlansSrc] = React.useState([]);
  const [dentalPlansSrc, setDentalPlansSrc] = React.useState([]);
  const [visionPlansSrc, setVisionPlansSrc] = React.useState([]);

  const [medicalGridState, setMedicalGridState] = React.useState({});
  const [dentalGridState, setDentalGridState] = React.useState({});
  const [visionGridState, setVisionGridState] = React.useState({});

  const [showDeletePlanModal, setShowDeletePlanModal] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [plansToMapTo, setPlansToMapTo] = React.useState([]);
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState(null);
  const [deletedPlans, setDeletedPlans] = React.useState([]);

  const { isLoading: planListLoading, data: plansList } =
    useQueryGetModelAvailablePlans(oePeriodId, clientId, versionId, groupId);
  const useMutateModelAddPlanHandler = useMutateModelAddPlan();

  React.useEffect(() => {
    if (!planListLoading && plansList && plansList.data) {
      plansList.data.plans.map((e) => {
        e.originalMapped = e.isAlreadyMapped;
        e.planDispName = `${e.planId} - ${e.planName}`;
        return e;
      });

      let medicalPlans = plansList.data.plans.filter(
        (plan) => plan.offerTypeCode === "MED"
      );
      let dentalPlans = plansList.data.plans.filter(
        (plan) => plan.offerTypeCode === "DEN"
      );
      let visionPlans = plansList.data.plans.filter(
        (plan) => plan.offerTypeCode === "VIS"
      );

      setMedicalPlansSrc(medicalPlans);
      setDentalPlansSrc(dentalPlans);
      setVisionPlansSrc(visionPlans);

      setHasNewDentalPlanOffering(plansList.data.hasDentalNewOffering);
      setHasNewVisionPlanOffering(plansList.data.hasVisionNewOffering);
    }
  }, [planListLoading, plansList]);

  const handleSubmit = async () => {
    let medPlans = medicalPlansSrc
      .filter((plan) => plan.isAlreadyMapped === true)
      .map((plan) => plan.planId);
    let denPlans = dentalPlansSrc
      .filter((plan) => plan.isAlreadyMapped === true)
      .map((plan) => plan.planId);
    let visPlans = visionPlansSrc
      .filter((plan) => plan.isAlreadyMapped === true)
      .map((plan) => plan.planId);

    let plans = [...medPlans, ...denPlans, ...visPlans];

    let data = {
      oePeriodId: oePeriodId,
      clientId: clientId,
      modelVersionId: versionId,
      groupId: groupId,
      planIds: plans,
      deleteMovePlans: deletedPlans,
    };

    await useMutateModelAddPlanHandler.mutateAsync(data);

    onClose();
  };

  const handleTabSelect = (e) => {
    setSelected(e.selected);
  };

  const onMedicalSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;

    if (
      checked ||
      !event.dataItem.originalMapped ||
      (event.dataItem.eeCount === 0 &&
        !deletedPlans.find((e) => e.moveToPlanId === event.dataItem.planId))
    ) {
      if (event.dataItem.offerTypeCode === "MED") {
        const medicalPlans = medicalPlansSrc.map((plan) => {
          if (plan.planId === event.dataItem.planId) {
            plan.isAlreadyMapped = checked;
          }
          return plan;
        });

        setMedicalPlansSrc(medicalPlans);
      } else if (event.dataItem.offerTypeCode === "DEN") {
        const dentalPlans = dentalPlansSrc.map((plan) => {
          if (plan.planId === event.dataItem.planId) {
            plan.isAlreadyMapped = checked;
          }
          return plan;
        });

        setDentalPlansSrc(dentalPlans);
      } else if (event.dataItem.offerTypeCode === "VIS") {
        const visionPlans = visionPlansSrc.map((plan) => {
          if (plan.planId === event.dataItem.planId) {
            plan.isAlreadyMapped = checked;
          }
          return plan;
        });

        setVisionPlansSrc(visionPlans);
      }
    } else {
      let availablePlans = [];
      if (event.dataItem.offerTypeCode === "MED") {
        availablePlans = medicalPlansSrc.filter(
          (e) => e.planId !== event.dataItem.planId && e.isAlreadyMapped
        );
      } else if (event.dataItem.offerTypeCode === "DEN") {
        availablePlans = dentalPlansSrc.filter(
          (e) => e.planId !== event.dataItem.planId && e.isAlreadyMapped
        );
      } else if (event.dataItem.offerTypeCode === "VIS") {
        availablePlans = visionPlansSrc.filter(
          (e) => e.planId !== event.dataItem.planId && e.isAlreadyMapped
        );
      }

      if (availablePlans.length === 0) {
        setShowAlertDialog(true);
        return;
      }

      setPlansToMapTo(availablePlans);
      setCurrentDeleteItem(event.dataItem);
      setShowDeletePlanModal(true);
    }
  };

  const handleDeleteConfirm = (item) => {
    if (currentDeleteItem.offerTypeCode === "MED") {
      const medicalPlans = medicalPlansSrc.map((plan) => {
        if (plan.planId === currentDeleteItem.planId) {
          plan.isAlreadyMapped = false;
        }
        return plan;
      });

      setMedicalPlansSrc(medicalPlans);
    } else if (currentDeleteItem.offerTypeCode === "DEN") {
      const dentalPlans = dentalPlansSrc.map((plan) => {
        if (plan.planId === currentDeleteItem.planId) {
          plan.isAlreadyMapped = false;
        }
        return plan;
      });

      setDentalPlansSrc(dentalPlans);
    } else if (currentDeleteItem.offerTypeCode === "VIS") {
      const visionPlans = visionPlansSrc.map((plan) => {
        if (plan.planId === currentDeleteItem.planId) {
          plan.isAlreadyMapped = false;
        }
        return plan;
      });

      setVisionPlansSrc(visionPlans);
    }

    let tempDelPlans = [...deletedPlans];
    let findDelItem = tempDelPlans.find(
      (e) => e.planId === currentDeleteItem.planId
    );
    if (findDelItem) {
      findDelItem.moveToPlanId = item.planId;
    } else {
      tempDelPlans.push({
        planId: currentDeleteItem.planId,
        moveToPlanId: item.planId,
      });
    }

    tempDelPlans = tempDelPlans.map((e) => {
      if (e.moveToPlanId === currentDeleteItem.planId) {
        e.moveToPlanId = item.planId;
      }
      return e;
    });

    setDeletedPlans(tempDelPlans);
    setShowDeletePlanModal(false);
    setPlansToMapTo([]);
    setCurrentDeleteItem(null);
  };

  const medicalGridDataStateChange = (event) => {
    setMedicalGridState(event.dataState);
  };

  const dentalGridDataStateChange = (event) => {
    setDentalGridState(event.dataState);
  };

  const visionGridDataStateChange = (event) => {
    setVisionGridState(event.dataState);
  };

  return (
    <Dialog title={"Manage Plans"} onClose={onClose} width={"90vw"}>
      <ErrorBanner
        handler={useMutateModelAddPlanHandler}
        onError={() => setSubmitted(false)}
      />
      {showDeletePlanModal && (
        <ClientRMDeletePlanModal
          plans={plansToMapTo}
          handleSubmit={handleDeleteConfirm}
          onClose={() => setShowDeletePlanModal(false)}
        />
      )}
      {showAlertDialog && (
        <AlertDialog
          title={"Can't delete"}
          message={
            "We can't delete last plan in this class. Please add plans before removing."
          }
          onClose={() => setShowAlertDialog(false)}
        />
      )}
      <TabStrip selected={selected} onSelect={handleTabSelect}>
        <TabStripTab title="Medical">
          {planListLoading ? (
            <Loader type={"infinite-spinner"} />
          ) : (
            <Grid
              data={process(medicalPlansSrc, medicalGridState)}
              dataItemKey={DATA_ITEM_KEY}
              selectedField={SELECTED_FIELD}
              selectable={gridSelectableSetting}
              onSelectionChange={onMedicalSelectionChange}
              style={{ maxHeight: "50vh" }}
              sortable={true}
              onDataStateChange={medicalGridDataStateChange}
              {...medicalGridState}
            >
              <GridColumn
                field={SELECTED_FIELD}
                width="50px"
                headerCell={() => ""}
                headerSelectionValue={false}
              />
              <GridColumn field="planDispName" title="Plan Name" />
              <GridColumn
                field="eeRate"
                title="Employee Rate"
                className="k-text-center"
                format="{0:c}"
              />
              <GridColumn
                field="esRate"
                title="Spouse Rate"
                className="k-text-center"
                format="{0:c}"
              />
              <GridColumn
                field="ecRate"
                title="Single Parent Rate"
                className="k-text-center"
                format="{0:c}"
              />
              <GridColumn
                field="famRate"
                title="Family Rate"
                className="k-text-center"
                format="{0:c}"
              />
            </Grid>
          )}
        </TabStripTab>
        <TabStripTab title="Dental">
          {planListLoading ? (
            <Loader type={"infinite-spinner"} />
          ) : (
            <>
              {hasNewDentalPlanOffering && (
                <p
                  style={{
                    paddingLeft: 10,
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >

                </p>
              )}
              <Grid
                data={process(dentalPlansSrc, dentalGridState)}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={gridSelectableSetting}
                onSelectionChange={onMedicalSelectionChange}
                style={{ maxHeight: "50vh" }}
                sortable={true}
                onDataStateChange={dentalGridDataStateChange}
                {...dentalGridState}
              >
                <GridColumn
                  field={SELECTED_FIELD}
                  width="50px"
                  headerCell={() => ""}
                  headerSelectionValue={false}
                />
                <GridColumn field="planDispName" title="Plan Name" />
                <GridColumn
                  field="eeRate"
                  title="Employee Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
                <GridColumn
                  field="esRate"
                  title="Spouse Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
                <GridColumn
                  field="ecRate"
                  title="Single Parent Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
                <GridColumn
                  field="famRate"
                  title="Family Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
              </Grid>
            </>
          )}
        </TabStripTab>
        <TabStripTab title="Vision">
          {planListLoading ? (
            <Loader type={"infinite-spinner"} />
          ) : (
            <>
              {hasNewVisionPlanOffering && (
                <p
                  style={{
                    paddingLeft: 10,
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >

                </p>
              )}
              <Grid
                data={process(visionPlansSrc, visionGridState)}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={gridSelectableSetting}
                onSelectionChange={onMedicalSelectionChange}
                style={{ maxHeight: "50vh" }}
                sortable={true}
                onDataStateChange={visionGridDataStateChange}
                {...visionGridState}
              >
                <GridColumn
                  field={SELECTED_FIELD}
                  width="50px"
                  headerCell={() => ""}
                  headerSelectionValue={false}
                />
                <GridColumn field="planDispName" title="Plan Name" />
                <GridColumn
                  field="eeRate"
                  title="Employee Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
                <GridColumn
                  field="esRate"
                  title="Spouse Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
                <GridColumn
                  field="ecRate"
                  title="Single Parent Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
                <GridColumn
                  field="famRate"
                  title="Family Rate"
                  className="k-text-center"
                  format="{0:c}"
                />
              </Grid>
            </>
          )}
        </TabStripTab>
      </TabStrip>
      <DialogActionsBar layout={"end"}>
        <div style={{ paddingRight: "8px", paddingBottom: "8px" }}>
          <Button onClick={onClose} style={{ marginRight: "10px" }}>
            {"Cancel"}
          </Button>
          <Button
            type={"submit"}
            themeColor={"primary"}
            disabled={submitted}
            onClick={() => handleSubmit()}
          >
            {"Save Changes"}
          </Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ClientRateModelAddPlan;
