import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { axiosDownload } from "../../data/helpers/baseApi";
import { SERVICE_URLS } from "../../data/helpers/serviceUrls";

import {
  useQueryGetNotificationWaveBenefitGroups,
  useMutateContinueNotificationWaveStep,
} from "../../data/services/notificationService";

import LoadingIndicator from "../../components/LoadingIndicator";
import UploadLetterModel from "./UploadLetterModel";
import ErrorBanner from "../../components/ErrorBanner";
import { DocUploadInProgress } from "./NotificationWaveDetailHelper";

const NotificationWelcomeLetter = ({
  waveId,
  oePeriod,
  currentNotificationWave,
  currentStep,
}) => {
  const isDocUploadStep = React.useMemo(
    () => currentNotificationWave?.status === DocUploadInProgress,
    [currentNotificationWave]
  );

  const [showUploadDialog, setShowUploadDialog] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [notificationBenefitGroupsList, SetNotificationBenefitGroups] =
    React.useState([]);

  const {
    isLoading: notificationBenefitGroupsLoading,
    data: notificationBenefitGroups,
  } = useQueryGetNotificationWaveBenefitGroups(waveId);
  const useMutateContinueNotificationWaveStepHandler =
    useMutateContinueNotificationWaveStep();

  React.useEffect(() => {
    if (
      !notificationBenefitGroupsLoading &&
      notificationBenefitGroups &&
      notificationBenefitGroups.data
    ) {
      SetNotificationBenefitGroups(notificationBenefitGroups.data);
    }
  }, [notificationBenefitGroupsLoading, notificationBenefitGroups]);

  const isAllDocumentsUploaded = React.useMemo(() => {
    return notificationBenefitGroupsList.every((item) => item.hasDoc);
  }, [notificationBenefitGroupsList]);
  const totalDocumentsUploaded = React.useMemo(() => {
    return notificationBenefitGroupsList.filter((item) => item.hasDoc).length;
  }, [notificationBenefitGroupsList]);

  const handleUploadClick = (item) => {
    setCurrentItem(item);
    setShowUploadDialog(true);
  };

  const handleDownloadCLick = async (item) => {
    await axiosDownload(
      SERVICE_URLS.NOTIFICATION_DOWNLOAD_WELCOME_LETTER(
        waveId,
        item.notificationWaveDocId
      ),
      item.fileName
    );
  };

  const handleContinueStep3 = async (data) => {
    let reqBody = {
      notificationWaveId: waveId,
      status: "ReadyToSendNotification",
      oePeriodId: oePeriod?.oePeriodId,
    };

    await useMutateContinueNotificationWaveStepHandler.mutateAsync(reqBody);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="light"
            onClick={() => handleUploadClick(props.dataItem)}
            disabled={!isDocUploadStep}
          >
            {props.dataItem.hasDoc ? "Replace Document" : "Upload Document"}
          </Button>
          {props.dataItem.hasDoc && (
            <Button
              themeColor="success"
              style={{ marginLeft: 10 }}
              onClick={() => handleDownloadCLick(props.dataItem)}
            >
              {"Download"}
            </Button>
          )}
        </td>
      );
    };

    return (
      <Grid data={notificationBenefitGroupsList}>
        <Column field="groupBenefits" filter="text" title="Benefit Groups" />
        <Column field="fileName" filter="text" title="Document" />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  if (notificationBenefitGroupsLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h3 style={{ margin: "10px" }}>
            Please upload all documents before moving to next step.
          </h3>
        </div>
      </div>
      <ErrorBanner handler={useMutateContinueNotificationWaveStepHandler} />
      <RenderGrid />
      <div
        style={{
          justifyContent: "space-around",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div style={{ marginTop: "30px" }}>
          <Button
            onClick={() => handleContinueStep3()}
            themeColor={"success"}
            disabled={!isAllDocumentsUploaded || currentStep > 2}
          >
            {"Continue to Step 3"}
          </Button>
          {!isAllDocumentsUploaded && (
            <span style={{ marginLeft: "10px" }}>
              {totalDocumentsUploaded} of {notificationBenefitGroupsList.length}{" "}
              documents uploaded
            </span>
          )}
          {currentStep > 2 && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ color: "darkgreen", marginLeft: "10px" }}
            />
          )}
        </div>
      </div>
      {showUploadDialog && (
        <UploadLetterModel
          onClose={() => setShowUploadDialog(false)}
          waveId={waveId}
          bgLookupId={currentItem.globalBGLookupId}
        />
      )}
    </div>
  );
};

export default NotificationWelcomeLetter;
