import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosGet, axiosPost } from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetDashboardSummary = (oePeriodId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_WORKFLOW_DAHSBAORD_SUMMARY(oePeriodId)
    );
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.OE_WORKFLOW_DASHBAORD_SUMMARY, oePeriodId],
    getData,
    option
  );
};

export const useQueryGetDashboardExport = (oePeriodId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_WORKFLOW_DAHSBAORD_EXPORT(oePeriodId)
    );
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  return useQuery(
    [QUERY_KEYS.OE_WORKFLOW_DASHBAORD_EXPORT, oePeriodId],
    getData
  );
};

export const useMutateCreateWorkflowSetup = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.OE_WORKFLOW_SETUP_CREATE(data.oePeriodId),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([QUERY_KEYS.OE_WORKFLOW_SETUP_GET]);
    },
  });
};

export const useQueryGetOEWorkflowSetups = (oePeriodId) => {
  const getData = async () => {
    return await axiosGet(SERVICE_URLS.OE_WORKFLOW_SETUP_LIST(oePeriodId));
  };

  let enabled = false;
  if (oePeriodId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.OE_WORKFLOW_SETUP_GET, oePeriodId],
    getData,
    option
  );
};

export const useQueryGetOEWorkflowSteps = (oePeriodId, workflowSetupId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_WORKFLOW_GET(oePeriodId, workflowSetupId)
    );
  };

  let enabled = false;
  if (oePeriodId && workflowSetupId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.OE_WORKFLOW_GET, oePeriodId, workflowSetupId],
    getData,
    option
  );
};

export const useQueryGetOEWorkflowClients = (oePeriodId, workflowSetupId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_WORKFLOW_CLIENTS_GET(oePeriodId, workflowSetupId)
    );
  };

  let enabled = false;
  if (oePeriodId && workflowSetupId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.OE_WORKFLOW_CLIENTS_GET, oePeriodId, workflowSetupId],
    getData,
    option
  );
};

export const useMutateAddOEWorkflowClient = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.OE_WORKFLOW_CLIENTS_POST(data.oePeriodId),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_CLIENTS_GET,
        variables.oePeriodId,
        variables.workflowSetupId,
      ]);
    },
  });
};

export const useMutateAddOEWorkflowClientBulk = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.OE_WORKFLOW_CLIENTS_POST_BULK(data.oePeriodId),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_CLIENTS_GET,
        variables.oePeriodId,
        variables.workflowSetupId,
      ]);
    },
  });
};

export const useMutateStartWorkflow = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.OE_WORKFLOW_START(data.oePeriodId, data.workflowSetupId),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_GET,
        variables.oePeriodId,
        variables.workflowSetupId,
      ]);
    },
  });
};

export const useMutateContinueWorkflow = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.OE_WORKFLOW_CONTINUE(
        data.oePeriodId,
        data.stepId,
        data.workflowSetupId,
        data.isSkipped ?? false
      ),
      data
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_GET,
        variables.oePeriodId,
        variables.workflowSetupId,
      ]);
    },
  });
};

export const useMutatePostClientRateGroupsAndContinueWorkflow = () => {
  const postData = async (data) => {
    return await axiosPost(
      SERVICE_URLS.OE_UPDATE_RATE_GROUPS_WORKFLOW_CONTINUE(
        data.oePeriodId,
        data.stepId,
        data.workflowSetupId
      ),
      data.rateGroups
    );
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_GET,
        variables.oePeriodId,
        variables.workflowSetupId,
      ]);
    },
  });
};

export const useMutateDataSync = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.OE_DATA_SYNC, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_WORKFLOW_GET,
        variables.oePeriodId,
      ]);
    },
  });
};

export const useMutateBulkDataSync = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.OE_DATA_SYNC_BULK, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        QUERY_KEYS.OE_DATE_SYNC_HISTORY,
        variables[0].dataType,
        variables[0].oePeriodId,
      ]);
    },
  });
};

export const useQueryGetDataSyncErrorLogs = (oePeriodId, workflowId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_DATA_SYNC_ERROR_LOGS(oePeriodId, workflowId)
    );
  };

  let enabled = false;
  if (oePeriodId && workflowId) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 120000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.OE_DATA_SYNC_ERROR_LOGS, oePeriodId, workflowId],
    getData,
    option
  );
};

export const useQueryGetDataSyncHistory = (dataType, oePeriodId) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_DATA_SYNC_HISTORY(dataType, oePeriodId)
    );
  };

  let enabled = false;
  if (oePeriodId && dataType) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [QUERY_KEYS.OE_DATE_SYNC_HISTORY, dataType, oePeriodId],
    getData,
    option
  );
};

export const useQueryGetOEWorkflowClientRateGroups = (
  oePeriodId,
  workflowSetupId,
  loadingEnabled
) => {
  const getData = async () => {
    return await axiosGet(
      SERVICE_URLS.OE_WORKFLOW_CLIENT_RATE_GROUPS_GET(
        oePeriodId,
        workflowSetupId
      )
    );
  };

  let enabled = false;
  if (oePeriodId && workflowSetupId && loadingEnabled) {
    enabled = true;
  }

  let option = {
    staleTime: 1000 * 30,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    retry: true,
    refetchInterval: 30000,
    enabled: enabled,
  };

  return useQuery(
    [
      QUERY_KEYS.OE_WORKFLOW_CLIENT_RATE_GROUPS_GET,
      oePeriodId,
      workflowSetupId,
    ],
    getData,
    option
  );
};
