import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormInput } from "../../components/FormInput";
import ErrorBanner from "../../components/ErrorBanner";

import { useMutateCreateNotificationWave } from "../../data/services/notificationService";

const AddNotificationWaveModel = ({ onSubmit, onClose, oePeriod }) => {
  const [submitted, setSubmitted] = React.useState(false);

  const useMutateCreateNotificationWaveHandler =
    useMutateCreateNotificationWave();

  const handleSubmit = async (item) => {
    var data = {
      oePeriodId: oePeriod.oePeriodId,
      name: item.name,
      description: item.description,
    };

    setSubmitted(true);

    await useMutateCreateNotificationWaveHandler.mutateAsync(data);

    setSubmitted(false);
    onSubmit();
  };

  return (
    <Dialog title={"Add Wave"} onClose={onClose} width={400}>
      <ErrorBanner
        handler={useMutateCreateNotificationWaveHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <Field
              id={"name"}
              name={"name"}
              label={"Wave Name"}
              autoFocus={true}
              component={FormInput}
              required={true}
              validator={(value) =>
                value ? "" : "Please enter valid Wave name"
              }
            />

            <Field
              id={"description"}
              name={"description"}
              label={"Description"}
              component={FormInput}
            />

            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={onClose}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"success"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {"Add Wave"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddNotificationWaveModel;
