import ClientRateMenuGrid from "../clientadmin/ClientRateMenuGrid";

const ClientPlanLibrary = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      {/* <h2>Plan Library</h2> */}
      <ClientRateMenuGrid />
    </div>
  );
};

export default ClientPlanLibrary;
