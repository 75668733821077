import * as React from "react";

const SessionTimeout = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#EEF2F7",
      }}
    >
      <div
        style={{
          width: "400px",
          textAlign: "center",
          height: "400px",
          backgroundColor: "#ffff",
        }}
        className={"login-form"}
      >
        <p
          style={{
            backgroundColor: "#192853",
            marginTop: "0px",
            padding: "10px",
          }}
        >
          <img
            src={"/Logo_white.svg"}
            alt={"PrestigePEO logo"}
            style={{ height: 45 }}
          />
        </p>
        <h2>Session Timeout</h2>
        <p style={{ padding: "40px" }}>
          User session timed out. Please login again.
        </p>
      </div>
    </div>
  );
};

export default SessionTimeout;
