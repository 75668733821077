import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { useParams } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  useQueryGetClientBenefitGroups,
  useQueryGetClientBenefitPlans,
} from "../../data/services/clientAdminService";
import { formatDate } from "@telerik/kendo-intl";

import CLientBPRulesModel from "./CLientBPRulesModel";

const ClientBenefitPlanGrid = ({ oePeriod }) => {
  const { clientId } = useParams();

  const [gridDataMasterSrc, SetGridDataMasterSrc] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({});

  const [planListSrc, setPlanListSrc] = React.useState([]);

  const [currentViewPlan, setCurrentViewPlan] = React.useState(null);
  const [showViewRulesModel, setShowViewRulesModel] = React.useState(false);

  const { isLoading: planGroupsLoading, data: planGroups } =
    useQueryGetClientBenefitGroups(clientId);
  const { isLoading: planListLoading, data: planList } =
    useQueryGetClientBenefitPlans(clientId, oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (!planGroupsLoading && planGroups && planGroups.data) {
      SetGridDataMasterSrc(planGroups.data);
    }
  }, [planGroupsLoading, planGroups, gridDataState]);

  React.useEffect(() => {
    if (!planListLoading && planList && planList.data) {
      setPlanListSrc(planList.data);
    }
  }, [planListLoading, planList]);

  if (planGroupsLoading || planListLoading) {
    return "Loading ...";
  }

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setGridDataState(event.dataState);
    };

    const expandChange = (event) => {
      event.dataItem.expanded = event.value;

      var item = gridDataMasterSrc.find(
        (e) => e.groupId === event.dataItem.groupId
      );
      if (item) {
        item.expanded = event.value;
        SetGridDataMasterSrc([...gridDataMasterSrc]);
      }
    };

    const DetailComponent = (props) => {
      const planIds = props.dataItem.planIds || [];
      const planList = planListSrc.filter((e) => planIds.includes(e.planId));

      const handleOpenRulesWindow = (data) => {
        setCurrentViewPlan({
          planId: data.planId,
          groupId: props.dataItem.groupId,
        });
        setShowViewRulesModel(true);
      };

      const actionCell = (acProps) => {
        return (
          <td>
            <Button
              themeColor="light"
              onClick={() => handleOpenRulesWindow(acProps.dataItem)}
            >
              View Rules
            </Button>
          </td>
        );
      };

      if (planList) {
        return (
          <Grid data={planList}>
            <Column field="planId" title="Plan Id" />
            <Column field="planDescription" title="Plan Description" />
            <Column field="rateGroup" title="Rate Group" />
            <Column field="newRateGroup" title="Pending Rate Group" />
            <Column
              field="newRateGroupEffectiveDate"
              title="Pending Rate Group Effective"
            />
            <Column
              field="Action"
              title="Action"
              cell={actionCell}
              sortable={false}
            />
          </Grid>
        );
      }

      return (
        <div
          style={{
            height: "50px",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
            }}
          >
            <div className="k-loading-image" />
          </div>
        </div>
      );
    };

    const dateCell = (props) => {
      let currData = props.dataItem[props.field];

      if (!currData) {
        return <td></td>;
      }

      return <td>{formatDate(new Date(props.dataItem[props.field]), "d")}</td>;
    };

    return (
      <Grid
        sortable={true}
        data={process(gridDataMasterSrc.slice(0), gridDataState)}
        {...gridDataState}
        onDataStateChange={dataStateChange}
        detail={DetailComponent}
        expandField="expanded"
        onExpandChange={expandChange}
      >
        <Column field="groupId" filter="text" title="Group Id" />
        <Column field="groupDesc" filter="text" title="Group Description" />
        <Column
          field="stopAllUseOn"
          filter="text"
          cell={dateCell}
          title="Stop All Use On"
        />
      </Grid>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Client Benefit Groups & Plans</h2>
        </div>
      </div>
      <RenderGrid />
      {showViewRulesModel && (
        <CLientBPRulesModel
          clientId={clientId}
          planId={currentViewPlan.planId}
          groupId={currentViewPlan.groupId}
          onClose={() => setShowViewRulesModel(false)}
        />
      )}
    </div>
  );
};

export default ClientBenefitPlanGrid;
