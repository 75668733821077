export const ImportStatus = {
  ParsingQueued: "FPQ",
  ParsingRunning: "FPR",
  ParsingComplete: "FPC",
  ParsingError: "FPE",

  PlanMappingQueued: "PMQ",
  PlanMappingRunning: "PMR",
  PlanMappingComplete: "PMC",
  PlanMappingError: "PME",

  RateMappingQueued: "RMQ",
  RateMappingRunning: "RMR",
  RateMappingComplete: "RMC",
  RateMappingError: "RME",

  ApplyCostComplete: "ACC",
  ReviewCompleted: "SRC",

  SubmitQueued: "PSQ",
  SubmitRunning: "PSR",
  SubmitComplete: "PSC",
  SubmitError: "PSE",

  Cancelled: "FIC",
};

export const IsImportAutoStepsComplete = (importStatus) => {
  return (
    importStatus !== ImportStatus.ParsingQueued &&
    importStatus !== ImportStatus.ParsingRunning &&
    importStatus !== ImportStatus.ParsingComplete &&
    importStatus !== ImportStatus.PlanMappingQueued &&
    importStatus !== ImportStatus.PlanMappingRunning &&
    importStatus !== ImportStatus.PlanMappingComplete &&
    importStatus !== ImportStatus.RateMappingQueued &&
    importStatus !== ImportStatus.RateMappingRunning
  );
};

export const ImportSuccess = (status) => {
  return (
    (status !== ImportStatus.ParsingError &&
      status !== ImportStatus.ParsingQueued &&
      status !== ImportStatus.ParsingRunning) ||
    status === ImportStatus.ParsingComplete
  );
};

export const PlanMappingSuccess = (status) => {
  return (
    (status !== ImportStatus.ParsingError &&
      status !== ImportStatus.ParsingQueued &&
      status !== ImportStatus.ParsingRunning &&
      status !== ImportStatus.ParsingComplete &&
      status !== ImportStatus.PlanMappingError &&
      status !== ImportStatus.PlanMappingQueued &&
      status !== ImportStatus.PlanMappingRunning) ||
    status === ImportStatus.PlanMappingComplete
  );
};

export const PlanMappingInProgress = (status) => {
  return (
    status === ImportStatus.PlanMappingQueued ||
    status === ImportStatus.PlanMappingRunning ||
    status === ImportStatus.ParsingComplete
  );
};

export const RateGroupMappingSuccess = (status) => {
  return (
    (status !== ImportStatus.ParsingError &&
      status !== ImportStatus.ParsingQueued &&
      status !== ImportStatus.ParsingRunning &&
      status !== ImportStatus.ParsingComplete &&
      status !== ImportStatus.PlanMappingError &&
      status !== ImportStatus.PlanMappingQueued &&
      status !== ImportStatus.PlanMappingRunning &&
      status !== ImportStatus.PlanMappingComplete &&
      status !== ImportStatus.RateMappingError &&
      status !== ImportStatus.RateMappingQueued &&
      status !== ImportStatus.RateMappingRunning) ||
    status === ImportStatus.RateMappingComplete
  );
};

export const RateGroupMappingInProgress = (status) => {
  return (
    status === ImportStatus.RateMappingQueued ||
    status === ImportStatus.RateMappingRunning ||
    status === ImportStatus.PlanMappingComplete
  );
};

export const ApplyCostSuccess = (status) => {
  return (
    (status !== ImportStatus.ParsingError &&
      status !== ImportStatus.ParsingQueued &&
      status !== ImportStatus.ParsingRunning &&
      status !== ImportStatus.ParsingComplete &&
      status !== ImportStatus.PlanMappingError &&
      status !== ImportStatus.PlanMappingQueued &&
      status !== ImportStatus.PlanMappingRunning &&
      status !== ImportStatus.PlanMappingComplete &&
      status !== ImportStatus.RateMappingError &&
      status !== ImportStatus.RateMappingQueued &&
      status !== ImportStatus.RateMappingRunning &&
      status !== ImportStatus.RateMappingComplete) ||
    status === ImportStatus.ApplyCostComplete
  );
};

export const RateImportInApplyCostSuccess = (status) => {
  return status === ImportStatus.ApplyCostComplete;
};

export const RateImportReviewCompleted = (status) => {
  return status === ImportStatus.ReviewCompleted;
};

export const RateImportSubmitInprogress = (status) => {
  return (
    status === ImportStatus.SubmitQueued ||
    status === ImportStatus.SubmitRunning
  );
};

export const RateImportSubmitSuccess = (status) => {
  return status === ImportStatus.SubmitComplete;
};

export const RateImportSubmitError = (status) => {
  return status === ImportStatus.SubmitError;
};

export const IsRateEditable = (status) => {
  return (
    status === ImportStatus.SubmitQueued ||
    status === ImportStatus.SubmitRunning ||
    status === ImportStatus.SubmitComplete ||
    status === ImportStatus.SubmitError
  );
};

export const BaseSteps = [
  {
    label: "Import File",
    isValid: true,
    id: 1,
  },
  {
    label: "Plan Mapping",
    isValid: true,
    id: 2,
  },
  {
    label: "Apply Costs",
    isValid: undefined,
    id: 3,
  },
  {
    label: "Review",
    isValid: undefined,
    id: 4,
  },
  {
    label: "Submit",
    isValid: undefined,
    id: 5,
  },
];

export const GetCurrentSteps = (importStatus) => {
  let stepNumber = 0;
  let isValid = true;
  let disableFrom = 0;
  let allComplete = false;

  switch (importStatus) {
    case ImportStatus.ParsingQueued:
    case ImportStatus.ParsingRunning: {
      stepNumber = 0;
      isValid = true;
      disableFrom = 1;
      break;
    }
    case ImportStatus.ParsingError: {
      stepNumber = 0;
      isValid = false;
      disableFrom = 1;
      break;
    }
    case ImportStatus.ParsingComplete:
    case ImportStatus.PlanMappingQueued:
    case ImportStatus.PlanMappingRunning: {
      stepNumber = 1;
      isValid = true;
      disableFrom = 2;
      break;
    }
    case ImportStatus.PlanMappingError: {
      stepNumber = 1;
      isValid = false;
      disableFrom = 2;
      break;
    }
    case ImportStatus.PlanMappingComplete:
    case ImportStatus.RateMappingQueued:
    case ImportStatus.RateMappingRunning: {
      stepNumber = 1;
      isValid = true;
      disableFrom = 2;
      break;
    }
    case ImportStatus.RateMappingError: {
      stepNumber = 1;
      isValid = false;
      disableFrom = 2;
      break;
    }
    case ImportStatus.RateMappingComplete: {
      stepNumber = 2;
      isValid = true;
      disableFrom = 3;
      break;
    }
    case ImportStatus.ApplyCostComplete: {
      stepNumber = 3;
      isValid = true;
      disableFrom = 4;
      break;
    }
    case ImportStatus.ReviewCompleted:
    case ImportStatus.SubmitQueued:
    case ImportStatus.SubmitRunning: {
      stepNumber = 4;
      isValid = true;
      disableFrom = 5;
      break;
    }
    case ImportStatus.SubmitComplete: {
      stepNumber = 4;
      isValid = true;
      disableFrom = 5;
      allComplete = true;
      break;
    }
    case ImportStatus.SubmitError: {
      stepNumber = 4;
      isValid = false;
      disableFrom = 5;
      break;
    }
    default: {
      stepNumber = 0;
      disableFrom = 1;
      break;
    }
  }

  var steps = BaseSteps.map((step) => {
    if (step.id > disableFrom) {
      return {
        ...step,
        isValid: undefined,
        disabled: true,
      };
    } else if (step.id < disableFrom) {
      return {
        ...step,
        disabled: false,
        isValid: true,
      };
    } else {
      return {
        ...step,
        disabled: false,
        isValid: !isValid ? false : allComplete ? true : undefined,
      };
    }
  });

  return { steps, currentStep: stepNumber };
};
