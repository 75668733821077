import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PlanMapping from "./PlanMapping";
import RateGroupMapping from "./RateGroupMapping";

const BenefitPlanMappingLayout = () => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleTabSelect = (e) => {
    setSelectedTabIndex(e.selected);
  };

  return (
    <TabStrip
      selected={selectedTabIndex}
      onSelect={handleTabSelect}
      style={{ paddingTop: 20 }}
    >
      <TabStripTab title="Benefit Plan Mapping">
        <div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h2 style={{ margin: "10px", marginBottom: 20 }}>
                Benefit Plan Mapping
              </h2>
            </div>
          </div>
          <PlanMapping />
        </div>
      </TabStripTab>
      <TabStripTab title="Rate Group Mapping">
        <div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <h2 style={{ margin: "10px", marginBottom: 20 }}>
                Rate Group Mapping
              </h2>
            </div>
          </div>
          <RateGroupMapping />
        </div>
      </TabStripTab>
    </TabStrip>
  );
};

export default BenefitPlanMappingLayout;
