import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridToolbar,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";

import LoadingIndicator from "../../components/LoadingIndicator";
import AddImportCostModel from "./AddImportCostModel";
import DeleteDialog from "../../components/DeleteDialog";
import ErrorBanner from "../../components/ErrorBanner";

import { ApplyCostSuccess, IsRateEditable } from "./ImportStatusHelper";

import {
  useQueryGetImportCosts,
  useMutateDeleteImportCost,
} from "../../data/services/importService";

const ImportApplyCost = ({ id, importItem, onNextClick, onPrevClick }) => {
  const [importApplyCostSrc, setImportApplyCostSrc] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const [showModel, setShowModel] = React.useState(false);
  const [showDeleteModel, setShowDeleteModel] = React.useState(false);
  const [editItem, setEditItem] = React.useState();
  const [nextEnabled, setNextEnabled] = React.useState(false);
  const [isRateSubmitted, setIsRateSubmitted] = React.useState(false);

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: importApplyCostLoading, data: importApplyCost } =
    useQueryGetImportCosts(id);
  const useMutateDeleteImportCostHandler = useMutateDeleteImportCost();

  React.useEffect(() => {
    if (!importApplyCostLoading && importApplyCost && importApplyCost.data) {
      setImportApplyCostSrc(
        process(importApplyCost.data.slice(0), gridDataState)
      );
    }

    if (importItem && ApplyCostSuccess(importItem.importStatus)) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }

    if (importItem && IsRateEditable(importItem.importStatus)) {
      setIsRateSubmitted(true);
    } else {
      setIsRateSubmitted(false);
    }
  }, [importApplyCostLoading, importApplyCost, gridDataState, importItem]);

  const handleDeleteConfirm = async (data) => {
    let item = {
      importId: id,
      content: {
        importBPCostId: data.importBPCostId,
      },
    };

    await useMutateDeleteImportCostHandler.mutateAsync(item);

    setShowDeleteModel(false);
  };

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setImportApplyCostSrc(
        process(importApplyCost.data.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="primary"
            onClick={() => onEditClick(props.dataItem)}
          >
            Edit
          </Button>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            onClick={() => onDeleteClick(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const dateCell = (props) => {
      if (props.dataItem.costValueType === "P") {
        return <td>{props.dataItem.costValue} %</td>;
      } else {
        return <td>${props.dataItem.costValue}</td>;
      }
    };

    const onAddClick = () => {
      setEditItem(null);
      setShowModel(true);
    };

    const onEditClick = (data) => {
      let editData = {
        ...data,
        costType: {
          text: data.costType === "M" ? "MLR Credit" : "Admin Fee",
          value: data.costType,
        },
      };
      setEditItem(editData);
      setShowModel(true);
    };

    const onDeleteClick = (data) => {
      setEditItem(data);
      setShowDeleteModel(true);
    };

    return (
      <Grid
        pageable={pagerSetting}
        pageSize={20}
        dataItemKey={"importBPCostId"}
        sortable={true}
        data={importApplyCostSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <GridToolbar>
          <Button
            themeColor={"primary"}
            onClick={onAddClick}
            disabled={nextEnabled || isRateSubmitted}
          >
            Add Cost
          </Button>
          {nextEnabled && (
            <div style={{ color: "green" }}>
              <span
                class="k-icon k-i-check"
                style={{ marginRight: "10px" }}
              ></span>
              <span>All Costs are applied</span>
            </div>
          )}
        </GridToolbar>
        <Column
          field="planGroup"
          title="Plan Group"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("planGroup")}
        />
        <Column
          field="costTypeDesc"
          title="Cost Type"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("costTypeDesc")}
        />
        <Column
          field="costValue"
          title="Cost"
          cell={dateCell}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("costValue")}
        />
        {!isRateSubmitted && (
          <Column
            field="Action"
            title="Action"
            cell={actionCell}
            sortable={false}
          />
        )}
      </Grid>
    );
  };

  const ColumnCheckboxMenu = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={importApplyCost.data}
          expanded={true}
        />
      </div>
    );
  };

  if (importApplyCostLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"light"}
            onClick={onPrevClick}
            iconClass="k-icon k-i-arrow-left"
          >
            Rate Group Mapping
          </Button>
        </div>
        <div style={{ alignSelf: "center" }}>
          <h3 style={{ marginTop: 30 }}>Apply Cost</h3>
        </div>
        <div style={{ alignSelf: "center" }}>
          <Button
            themeColor={"primary"}
            onClick={onNextClick}
            iconClass="k-icon k-i-arrow-right"
            disabled={!nextEnabled}
          >
            Review
          </Button>
        </div>
      </div>
      <ErrorBanner
        handler={useMutateDeleteImportCostHandler}
        onError={() => setShowDeleteModel(false)}
      />
      <RenderGrid />
      {showModel && (
        <AddImportCostModel
          cancelEdit={() => setShowModel(false)}
          onSubmit={() => setShowModel(false)}
          id={id}
          item={editItem}
          existingItems={importApplyCost.data}
        />
      )}
      {showDeleteModel && (
        <DeleteDialog
          onDelete={handleDeleteConfirm}
          onCancel={() => setShowDeleteModel(false)}
          item={editItem}
        />
      )}
    </div>
  );
};

export default ImportApplyCost;
