import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete,
  reactQueryDefaultOptions,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetBrokerUsersList = () => {
  const getList = async () => {
    return await axiosGet(SERVICE_URLS.BROKER_USER_LIST);
  };
  return useQuery(
    [QUERY_KEYS.BROKER_USER_LIST],
    getList,
    reactQueryDefaultOptions
  );
};

export const useQueryGetBrokerUserClients = (userId) => {
  const getList = async () => {
    return await axiosGet(SERVICE_URLS.BROKER_USER_CLIENTS(userId));
  };
  return useQuery(
    [QUERY_KEYS.BROKER_USER_CLIENTS, userId],
    getList,
    reactQueryDefaultOptions
  );
};

export const useMutatePostBrokerUser = () => {
  const postData = async (data) => {
    return await axiosPost(SERVICE_URLS.BROKER_USER_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.BROKER_USER_LIST]);
    },
  });
};

export const useMutateDeleteBrokerUser = () => {
  const deleteData = async (data) => {
    return await axiosDelete(SERVICE_URLS.BROKER_USER_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(deleteData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.BROKER_USER_LIST]);
    },
  });
};

export const useMutatePutBrokerUser = () => {
  const putData = async (data) => {
    return await axiosPut(SERVICE_URLS.BROKER_USER_PUT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(putData, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.BROKER_USER_LIST]);
    },
  });
};
