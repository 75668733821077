import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useMutateLogout } from "../../data/services/authService";
import { Button } from "@progress/kendo-react-buttons";

const MaintenanceError = () => {
  const useMutateLogoutHandler = useMutateLogout();
  const navigate = useNavigate();

  const onLogoutClick = async () => {
    await useMutateLogoutHandler.mutateAsync();
    navigate("/logout");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#EEF2F7",
      }}
    >
      <div
        style={{
          width: "400px",
          textAlign: "center",
          height: "400px",
          backgroundColor: "#ffff",
        }}
        className={"login-form"}
      >
        <p
          style={{
            backgroundColor: "#192853",
            marginTop: "0px",
            padding: "10px",
          }}
        >
          <img
            src={"/Logo_white.svg"}
            alt={"PrestigePEO logo"}
            style={{ height: 45 }}
          />
        </p>
        <h2>Closed for Maintenance</h2>
        <p style={{ padding: "40px" }}>
          The portal is temporarily down for maintenance.
        </p>
        <div style={{ padding: "10px" }}>
          <Button
            themeColor={"primary"}
            style={{ width: "100px" }}
            fillMode={"outline"}
            onClick={onLogoutClick}
          >
            {"Logout"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceError;
