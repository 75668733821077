export const getPercentFromAmount = (amount, total) => {
  amount = amount || 0;
  total = total || 0;

  if (total === 0) {
    return 0;
  }

  return amount / total;
};

export const getAmountFromPercent = (percent, total) => {
  percent = percent || 0;
  total = total || 0;

  return (percent * total) / 100;
};

export const getAmountFromPercentUI = (percent, total) => {
  percent = percent || 0;
  total = total || 0;

  return formatGridAmount(percent * total);
};

export const formatGridAmount = (amount) => {
  if (!amount) {
    return 0;
  }

  if (countDecimals(amount) > 2) {
    return amount.toFixed(2);
  }

  return amount;
};

export const countDecimals = function (value) {
  if (value % 1 !== 0) return value.toString().split(".")[1].length;
  return 0;
};

export const setRateGridFields = (newModelDetail) => {
  newModelDetail.data.groups = newModelDetail.data.groups.map((group) => {
    group.rates.map((item) => {
      item.inEdit = true;
      item.maxContribution = 0;
      item.currentFmtPercentage = item.currentPercentage / 100;
      item.percentSelected = item.newMethod === "P";
      item.fixedValueSelected = item.newMethod === "F";
      if (item.percentSelected === true || item.fixedValueSelected === true) {
        item.percentValue =
          item.newMethod === "P"
            ? (item.newContributionValue ?? 0) / 100
            : getPercentFromAmount(
                item.newContributionValue,
                item.newTotalPremium
              );
        item.fixedValue =
          item.newMethod === "F"
            ? item.newContributionValue ?? 0
            : getAmountFromPercent(
                item.newContributionValue,
                item.newTotalPremium
              );
      }
      return item;
    });
    return group;
  });
};
