import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const NotStarted = "NotStarted";
export const DocUploadInProgress = "DocUploadInProgress";
export const ReadyToSendNotification = "ReadyToSendNotification";
export const SendNotificationInProgress = "SendNotificationInProgress";
export const SendNotificationCompleted = "SendNotificationCompleted";
export const SendNotificationError = "SendNotificationError";
export const ReadyToSendClientNotification = "ReadyToSendClientNotification";
export const SendClientNotificationInProgress =
  "SendClientNotificationInProgress";
export const SendClientNotificationError = "SendClientNotificationError";
export const SendClientNotificationCompleted = "Completed";

export const getCurrentStep = (currentWave) => {
  if (!currentWave) {
    return 0;
  }

  if (currentWave.status === NotStarted) {
    return 1;
  } else if (currentWave.status === DocUploadInProgress) {
    return 2;
  } else if (
    currentWave.status === ReadyToSendNotification ||
    currentWave.status === SendNotificationInProgress ||
    currentWave.status === SendNotificationError ||
    currentWave.status === SendNotificationCompleted
  ) {
    return 3;
  } else if (
    currentWave.status === ReadyToSendClientNotification ||
    currentWave.status === SendClientNotificationInProgress ||
    currentWave.status === SendClientNotificationError ||
    currentWave.status === SendClientNotificationCompleted
  ) {
    return 4;
  } else {
    return 0;
  }
};

export const getPanelSubtitle = (currentWave, step, currentStep) => {
  if (!currentWave || !currentWave.status) {
    return "Not Started";
  } else if (step < currentStep) {
    return (
      <span>
        Completed{" "}
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ color: "darkgreen", marginLeft: "10px" }}
        />
      </span>
    );
  } else if (step > currentStep) {
    return "Not Started";
  } else {
    return "In Progress";
  }
};

export const SummaryBaseSteps = [
  {
    label: "Map Clients",
    isValid: undefined,
    id: 1,
  },
  {
    label: "Welcome Letters",
    isValid: undefined,
    id: 2,
  },
  {
    label: "Broker Notification",
    isValid: undefined,
    id: 3,
  },
  {
    label: "Client Notification",
    isValid: undefined,
    id: 4,
  },
];

export const getSummaryCurrentSteps = (currentWave) => {
  const stepNumber = getCurrentStep(currentWave) - 1;
  const disableFrom = stepNumber + 1;
  const isValid =
    currentWave &&
    currentWave.status !== SendNotificationError &&
    currentWave.status !== SendClientNotificationError;
  const allComplete =
    currentWave && currentWave.status === SendClientNotificationCompleted;

  var steps = SummaryBaseSteps.map((step) => {
    if (step.id > disableFrom) {
      return {
        ...step,
        isValid: undefined,
        disabled: true,
      };
    } else if (step.id < disableFrom) {
      return {
        ...step,
        disabled: false,
        isValid: true,
      };
    } else {
      return {
        ...step,
        disabled: false,
        isValid: !isValid ? false : allComplete ? true : undefined,
      };
    }
  });

  return { steps, currentStep: stepNumber };
};
