import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FormDropDownList } from "../../components/FormDropDownList";
import { FormInput } from "../../components/FormInput";
import ErrorBanner from "../../components/ErrorBanner";

import { useQueryGetCarrierList } from "../../data/services/carrierService";
import { useMutateCreateWorkflowSetup } from "../../data/services/workflowService";

const AddWorkflowModel = ({ onSubmit, onClose, oePeriod }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [carrierListSrc, setCarrierListSrc] = React.useState([]);

  const defaultCarrier = {
    carrierName: "Select Carrier",
  };

  const { isLoading: carrierListLoading, data: carrierList } =
    useQueryGetCarrierList();
  const useMutateCreateWorkflowSetupHandler = useMutateCreateWorkflowSetup();

  React.useEffect(() => {
    if (!carrierListLoading && carrierList && carrierList.data) {
      const carriers = [...carrierList.data];
      carriers.push({
        carrierId: 999999,
        carrierName: "Other Clients",
      });
      setCarrierListSrc(carriers);
    }
  }, [carrierListLoading, carrierList]);

  const handleSubmit = async (item) => {
    var data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowName: item.workflowName,
      carrierId:
        item.carrier?.carrierId === 999999 ? null : item.carrier?.carrierId,
    };

    setSubmitted(true);
    var response = await useMutateCreateWorkflowSetupHandler.mutateAsync(data);

    setSubmitted(false);
    onSubmit(response.data);
  };

  return (
    <Dialog title={"Add Workflow"} onClose={onClose} width={400}>
      <ErrorBanner
        handler={useMutateCreateWorkflowSetupHandler}
        onError={() => setSubmitted(false)}
      />
      <Form
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement horizontal={false} style={{ maxWidth: 600 }}>
            <p>Please select a carrier to add a workflow for.</p>
            <Field
              id={"carrier"}
              name={"carrier"}
              label={"Carrier"}
              data={carrierListSrc}
              component={FormDropDownList}
              required={true}
              textField={"carrierName"}
              dataItemKey={"carrierId"}
              defaultItem={defaultCarrier}
              loading={carrierListLoading}
              validator={(value) => {
                return value?.carrierId ? "" : "Please select a carrier type";
              }}
            />
            <Field
              id={"workflowName"}
              name={"workflowName"}
              label={"Workflow Name"}
              autoFocus={true}
              component={FormInput}
              maxLength={50}
              required={true}
              validator={(value) =>
                value ? "" : "Please enter valid Workflow name"
              }
            />

            <div style={{ paddingTop: 40 }}></div>
            <DialogActionsBar layout={"end"}>
              <Button onClick={onClose}>{"Cancel"}</Button>
              <Button
                type={"submit"}
                themeColor={"success"}
                disabled={!formRenderProps.allowSubmit || submitted}
              >
                {"Add Workflow"}
              </Button>
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AddWorkflowModel;
