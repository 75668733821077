export const QUERY_KEYS = {
  OE_PERIOD_LIST: "OE_PERIOD_LIST",
  CARRIER_LIST: "carrierList",
  CARRIER_TYPE_LIST: "carrierTypeList",
  IMPORT_LIST: "importList",
  IMPORT_SUMMARY_LIST: "importSummaryList",
  IMPORT_ITEM: "importItem",
  IMPORT_ERRORS: "importErrors",
  IMPORT_SUBMIT_ERRORS: "importSubmitErrors",
  IMPORT_RATES: "importRates",
  IMPORT_FINAL_RATES: "importFinalRates",
  IMPORT_FINAL_RATES_EXPORT: "importFinalRatesExport",
  IMPORT_PLANMAPPING_LIST: "importPlanMapping",
  IMPORT_RATEGROUPMAPPING_LIST: "importRateGroupMapping",
  IMPORT_COSTS_LIST: "importCosts",
  IMPORT_PLANGROUPS_LIST: "importPlanGroups",
  PLAN_MAPPING_LIST: "planMappingList",
  RATE_GROUP_MAP_LIST: "rateGroupMapList",
  PLAN_LIST: "planList",
  PLAN_DETAIL_LIST: "planDetailList",
  SYSTEM_MENU_FILTERS: "systemMenuFilters",
  PLAN_MAPPING_STATUS: "planMappingStatus",
  PLAN_RATES_GET: "PlanRateGet",
  PLAN_LIST_FOR_MAPPING: "planListForMapping",
  GET_USER: "getUser",
  GET_CLIENTS: "getClients",
  GET_CLIENT_BENEFIT_REP: "getClientBenefitRep",
  GET_CLIENT_SALES_REP: "getClientSalesRep",
  GET_CLIENTS_ACTIVE_OE: "getClientsActiveOE",
  GET_CLIENT_MODEL_VERSIONS: "getClientModelVersions",
  GET_CLIENT_MODEL_SUMMARY: "getClientModelSummary",
  GET_CLIENT_MODEL_AVAILABLE_PLANS: "getClientModelAvailablePlans",
  GET_CLIENT_MODEL_DETAIL: "getClientModelDetail",
  GET_CLIENT_MODEL_ERRORS: "getClientModelErrors",
  RATE_GROUP_MAPPING_LIST: "rateGroupMappingList",
  GET_CLIENT_MODEL_MAXCONTRIBS: "getClientModelMaxContribs",
  GET_CLIENT_AUDIT_RECORDS: "getClientModelAuditRecords",

  OE_WORKFLOW_GET: "OEWorkFlowGet",
  OE_WORKFLOW_CLIENTS_GET: "OEWorkFlowClientsGet",
  OE_WORKFLOW_CLIENT_RATE_GROUPS_GET: "OEWorkFlowClientRateGroupsGet",
  OE_WORKFLOW_SETUP_GET: "OEWorkFlowSetupGet",
  OE_DATA_SYNC_ERROR_LOGS: "OEDataSyncErrorLogs",
  OE_DATE_SYNC_HISTORY: "OEDataSyncHistory",
  OE_WORKFLOW_DASHBAORD_SUMMARY: "OEWorkFlowDashboardSummary",
  OE_WORKFLOW_DASHBAORD_EXPORT: "OEWorkFlowDashboardExport",
  CLIENT_ADMIN_CONTACT_LIST: "clientAdminContactList",
  CLIENT_ADMIN_BENEFITGROUP_LIST: "clientAdminBenefitGroupList",
  CLIENT_ADMIN_BENEFITPLAN_LIST: "clientAdminBenefitPlanList",
  CLIENT_ADMIN_BENEFITRULES_GET: "clientAdminBenefitRulesList",
  CLIENT_MASTER_LIST: "clientMasterList",

  CLIENT_ADMIN_NOTIFICATION_HISTORY: "clientAdminNotificationHistory",
  CLIENT_ADMIN_MENU_DOCUMENT: "clientAdminMenuDocument",
  CLIENT_ADMIN_MENU_All: "clientAdminMenuAll",

  CLIENT_ADMIN_EMP_MENU_DOCUMENT: "clientAdminEmpMenuDocument",
  
  CLIENT_ADMIN_MAINTENANCE: "clientAdminMaintenance",
  CLIENT_ADMIN_GET_LATEST_MAINTENANCE: "clientAdminGetLatestMaintenance",

  NOTIFICATION_WAVES_LIST: "notificationWavesList",
  NOTIFICATION_WAVE_CLIENTS: "notificationWaveClients",
  NOTIFICATION_WAVE_MAIL_DETAILS: "notificationWaveMailDetails",
  NOTIFICATION_WAVE_AVAILABLE_CLIENTS: "notificationWaveAvailableClients",
  NOTIFICATION_WAVE_BENEFIT_GROUPS: "notificationWaveBenefitGroups",
  NOTIFICATION_WAVE_ERRORS: "notificationWaveErrors",

  NOTIFICATION_REDIRECT_LIST: "notificationRedirectList",

  NOTIFICATION_REMINDER_LIST: "notificationReminderList",

  BROKER_USER_LIST: "BrokerUserList",
  BROKER_USER_CLIENTS: "BrokerUserClients",
};
