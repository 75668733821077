import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Upload as KendoUpload } from "@progress/kendo-react-upload";
import Papa from "papaparse";
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import { process } from "@progress/kendo-data-query";

import { getPanelSubtitle } from "./panelHelper";
import {
  useMutatePostClientRateGroupsAndContinueWorkflow,
  useMutateContinueWorkflow,
  useQueryGetOEWorkflowClients,
  useQueryGetOEWorkflowClientRateGroups,
} from "../../../data/services/workflowService";

const SetRateGroupPanel = ({
  expanded,
  setExpanded,
  currentStep,
  status,
  oePeriod,
  workflowSetupId,
  currentWorkflowSetup,
  panelStepId,
}) => {
  const stepStatus = status?.status;
  const isStepCompleted = stepStatus === "Completed";

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50],
    previousNext: true,
  };
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const [workflowClientsList, setWorkflowClientsList] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [parsedRateGroupData, setParsedRateGroupData] = React.useState([]);
  const [parsedRateGroupDataPaginated, setParsedRateGroupDataPaginated] =
    React.useState([]);

  const { isLoading: workflowClientsLoading, data: workflowClientsSrc } =
    useQueryGetOEWorkflowClients(oePeriod?.oePeriodId, workflowSetupId);
  const useMutateContinueWorkflowHandler = useMutateContinueWorkflow();
  const useMutatePostClientRateGroupsAndContinueWorkflowHandler =
    useMutatePostClientRateGroupsAndContinueWorkflow();
  const {
    isLoading: workflowRateGroupsLoading,
    data: workflowClientRateGroupsSrc,
  } = useQueryGetOEWorkflowClientRateGroups(
    oePeriod?.oePeriodId,
    workflowSetupId,
    isStepCompleted
  );

  React.useEffect(() => {
    if (
      !workflowClientsLoading &&
      workflowClientsSrc &&
      workflowClientsSrc.data
    ) {
      setWorkflowClientsList(workflowClientsSrc.data);
    }
  }, [workflowClientsLoading, workflowClientsSrc]);

  React.useEffect(() => {
    if (
      workflowRateGroupsLoading ||
      !workflowClientRateGroupsSrc ||
      !workflowClientRateGroupsSrc.data ||
      workflowClientRateGroupsSrc.data.length === 0
    )
      return;

    const mapped = workflowClientRateGroupsSrc.data.map((item) => {
      item.ClientId = item.clientId.padStart(5, "0");
      item.RateGroup = item.rateGroup;
      item.BenefitGroup = item.benefitGroup;
      item.EffectiveDate = new Date(item.effectiveDate);
      item.Included = true;
      return item;
    });

    setParsedRateGroupData(mapped);
    setParsedRateGroupDataPaginated(process(mapped.slice(0), gridDataState));
  }, [workflowRateGroupsLoading, workflowClientRateGroupsSrc, gridDataState]);

  const onDownloadTemplate = async (event) => {
    const csvData = Papa.unparse([
      {
        ClientId: "",
        BenefitGroup: "",
        RateGroup: "",
        EffectiveDate: "",
      },
    ]);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a download link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Rate Group Upload Template.csv";

    // Simulate a click on the download link to trigger the download
    link.click();
  };

  const onAddFileHandler = (event) => {
    setFiles(event.newState);
    Papa.parse(event.newState[0].getRawFile(), {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const filteredData = results.data
          .map((item) => {
            item.ClientId = item.ClientId.padStart(5, "0");
            item.Included = workflowClientsList.some(
              (client) => client.clientId === item.ClientId
            );
            item.EffectiveDate = new Date(item.EffectiveDate);
            return item;
          })
          .filter(
            (item) =>
              item.ClientId &&
              item.RateGroup &&
              item.BenefitGroup &&
              item.EffectiveDate
          );
        setParsedRateGroupData(filteredData);
        setParsedRateGroupDataPaginated(
          process(filteredData.slice(0), gridDataState)
        );
      },
    });
  };

  const onRemoveHandler = (event) => {
    setFiles(event.newState);
    setParsedRateGroupData([]);
    setParsedRateGroupDataPaginated([]);
  };

  const handleSubmit = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      stepId: panelStepId,
      rateGroups: parsedRateGroupData.filter((item) => item.Included),
    };

    await useMutatePostClientRateGroupsAndContinueWorkflowHandler.mutateAsync(
      data
    );
  };

  const handleContinue = async (isSkipped) => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      workflowSetupId: workflowSetupId,
      stepId: panelStepId,
      isSkipped: isSkipped,
    };

    await useMutateContinueWorkflowHandler.mutateAsync(data);
  };

  const ClientsGrid = () => {
    const dataStateChange = (event) => {
      setParsedRateGroupDataPaginated(
        process(parsedRateGroupData.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={workflowClientsSrc.data}
            expanded={true}
          />
        </div>
      );
    };

    const dateCell = (props) => {
      return <td>{formatDate(props.dataItem.EffectiveDate, "MM/dd/yyyy")}</td>;
    };

    const includedCell = (props) => {
      return (
        <td style={{ color: !props.dataItem.Included ? "red" : "" }}>
          {props.dataItem.Included ? "Included" : "Skipped - Not in Workflow"}
        </td>
      );
    };

    return (
      <div>
        <Grid
          sortable={true}
          pageable={pagerSetting}
          pageSize={20}
          style={{ maxHeight: "400px" }}
          data={parsedRateGroupDataPaginated}
          {...gridDataState}
          onDataStateChange={dataStateChange}
        >
          <Column
            field="ClientId"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("ClientId")}
            title="Client Id"
          />
          <Column
            field="BenefitGroup"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("BenefitGroup")}
            title="Benefit Group"
          />
          <Column
            field="RateGroup"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("RateGroup")}
            title="New Rate Group"
          />
          <Column
            field="EffectiveDate"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("EffectiveDate")}
            title="Effective Date (MM/dd/yyyy)"
            cell={dateCell}
          />
          <Column
            field="Included"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("Included")}
            title="Status"
            cell={includedCell}
          />
        </Grid>
      </div>
    );
  };

  const hasValidRateGroupData = React.useMemo(() => {
    return (
      parsedRateGroupData.length > 0 &&
      parsedRateGroupData.some((item) => item.Included)
    );
  }, [parsedRateGroupData]);

  return (
    <ExpansionPanel
      title={`${panelStepId}) Upload new client rate groups`}
      subtitle={getPanelSubtitle(status)}
      expanded={expanded === panelStepId}
      disabled={currentStep < panelStepId}
      onAction={(event) => {
        setExpanded(event.expanded ? 0 : panelStepId);
      }}
    >
      <Reveal>
        {expanded === panelStepId && (
          <ExpansionPanelContent>
            <p>
              If there are any Rate Group changes for the clients, you can
              upload the new rate groups here. Include only the clients that are
              in the current workflow.
            </p>
            <p>
              Please use this template to upload rate groups.{" "}
              <Button
                themeColor={"info"}
                fillMode="flat"
                onClick={onDownloadTemplate}
                disabled={isStepCompleted}
              >
                Download Template
              </Button>
            </p>
            <KendoUpload
              autoUpload={false}
              showActionButtons={false}
              files={files}
              multiple={false}
              onAdd={onAddFileHandler}
              onRemove={onRemoveHandler}
              disabled={isStepCompleted}
              restrictions={{
                allowedExtensions: [".csv"],
              }}
            />
            <ClientsGrid />
            <div
              style={{
                marginTop: "40px",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                themeColor={"success"}
                onClick={handleSubmit}
                disabled={
                  !hasValidRateGroupData ||
                  useMutatePostClientRateGroupsAndContinueWorkflowHandler.isLoading ||
                  isStepCompleted
                }
              >
                Save & Continue{" "}
                {useMutatePostClientRateGroupsAndContinueWorkflowHandler.isLoading && (
                  <span className="k-icon k-i-loading"></span>
                )}
              </Button>
              <Button
                themeColor={"warning"}
                onClick={() => handleContinue(true)}
                style={{ marginLeft: "10px" }}
                disabled={isStepCompleted}
              >
                Skip & Continue
              </Button>
            </div>
          </ExpansionPanelContent>
        )}
      </Reveal>
    </ExpansionPanel>
  );
};

export default SetRateGroupPanel;
