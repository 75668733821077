import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
  reactQueryDefaultOptions,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetOEPeriodList = () => {
  const getList = async () => {
    return await axiosGet(SERVICE_URLS.OE_PERIOD_LIST);
  };
  return useQuery(
    [QUERY_KEYS.OE_PERIOD_LIST],
    getList,
    reactQueryDefaultOptions
  );
};

export const useMutatePostOEPeriod = () => {
  const queueImport = async (data) => {
    return await axiosPost(SERVICE_URLS.OE_PERIOD_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.OE_PERIOD_LIST]);
    },
  });
};

export const useMutatePutOEPeriod = () => {
  const queueImport = async (data) => {
    return await axiosPut(SERVICE_URLS.OE_PERIOD_PUT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.OE_PERIOD_LIST]);
    },
  });
};

export const useMutatePutOEPeriodStatus = () => {
  const queueImport = async (data) => {
    return await axiosPut(SERVICE_URLS.OE_PERIOD_PUT_STATUS, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.OE_PERIOD_LIST]);
    },
  });
};

export const useMutateDeleteOEPeriod = () => {
  const queueImport = async (data) => {
    return await axiosDelete(SERVICE_URLS.OE_PERIOD_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.OE_PERIOD_LIST]);
    },
  });
};
