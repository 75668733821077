export let STANDARD_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  pragma: "no-cache",
  "cache-control": "no-cache",
};

let FORM_DATA_HEADERS = {
  Accept: "application/json, text/javascript, */*",
  pragma: "no-cache",
  "cache-control": "no-cache",
  "content-type": "multipart/form-data",
};

export const METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const HTTP_HEADERS = (type = "standard") => {
  let _headers = type === "form" ? FORM_DATA_HEADERS : STANDARD_HEADERS;
  _headers = Object.assign(_headers, {});

  return {
    GET: {
      method: METHODS.GET,
      mode: "cors",
      headers: _headers,
      credentials: "include",
      withCredentials: true,
    },
    GET_FILE: {
      method: METHODS.GET,
      mode: "cors",
      headers: _headers,
      credentials: "include",
      withCredentials: true,
      responseType: "blob",
    },
    POST: {
      method: METHODS.POST,
      mode: "cors",
      headers: _headers,
      credentials: "include",
      withCredentials: true,
    },
    PUT: {
      method: METHODS.PUT,
      mode: "cors",
      headers: _headers,
      credentials: "include",
      withCredentials: true,
    },
    DELETE: {
      method: METHODS.DELETE,
      mode: "cors",
      headers: _headers,
      credentials: "include",
      withCredentials: true,
    },
  };
};
