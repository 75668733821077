const serviceBaseUrl = "/api";

export const SERVICE_URLS = {
  OE_PERIOD_LIST: `${serviceBaseUrl}/OEPeriod`,
  OE_PERIOD_POST: `${serviceBaseUrl}/OEPeriod`,
  OE_PERIOD_PUT: `${serviceBaseUrl}/OEPeriod`,
  OE_PERIOD_PUT_STATUS: `${serviceBaseUrl}/OEPeriod/status`,
  OE_PERIOD_DELETE: `${serviceBaseUrl}/OEPeriod`,
  CARRIER_LIST: `${serviceBaseUrl}/carrier`,
  CARRIER_POST: `${serviceBaseUrl}/carrier`,
  CARRIER_PUT: `${serviceBaseUrl}/carrier`,
  CARRIER_DELETE: `${serviceBaseUrl}/carrier`,
  CARRIER_TYPE_LIST: `${serviceBaseUrl}/carrier/types`,
  CARRIER_TYPE_POST: `${serviceBaseUrl}/carrier/types`,
  CARRIER_TYPE_DELETE: `${serviceBaseUrl}/carrier/types`,
  IMPORT_LIST: (oePeriodId) =>
    `${serviceBaseUrl}/import?oePeriod=${oePeriodId}`,
  IMPORT_SUMMARY_LIST: (oePeriodId) =>
    `${serviceBaseUrl}/import/oeperiods/${oePeriodId}/summary`,
  IMPORT_LIST_All: `${serviceBaseUrl}/import`,
  IMPORT_QUEUE: `${serviceBaseUrl}/import`,
  IMPORT_ITEM: (importId) => `${serviceBaseUrl}/import/${importId}`,
  IMPORT_ERRORS: (importId) => `${serviceBaseUrl}/import/${importId}/errors`,
  IMPORT_SUBMIT_ERRORS: (importId) =>
    `${serviceBaseUrl}/import/${importId}/submitErrors`,
  IMPORT_RATES: (importId) => `${serviceBaseUrl}/import/${importId}/rates`,
  IMPORT_FINAL_RATES_LIST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/finalrates`,
  IMPORT_FINAL_RATES_EXPORT: (importId) =>
    `${serviceBaseUrl}/import/${importId}/finalrates/export`,
  IMPORT_PLANMAPPING_LIST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/planmapping`,
  IMPORT_RATEGROUPMAPPING_LIST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/rategroupmapping`,
  IMPORT_PLANGROUPS_LIST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/plangroups`,
  IMPORT_COSTS_LIST: (importId) => `${serviceBaseUrl}/import/${importId}/costs`,
  IMPORT_COSTS_POST: (importId) => `${serviceBaseUrl}/import/${importId}/costs`,
  IMPORT_RETRY_MAPPING_POST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/retrymapping`,
  IMPORT_APPROVE_RATES_POST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/approverate`,
  IMPORT_SUBMIT_RATES_POST: (importId) =>
    `${serviceBaseUrl}/import/${importId}/submitrate`,
  IMPORT_COSTS_DELETE: (importId) =>
    `${serviceBaseUrl}/import/${importId}/costs`,

  BENEFIT_PLAN_DETAIL_IMPORT: (oePeriodId, oeWorkflowId) =>
    `${serviceBaseUrl}/benefitplan/detailfile?oePeriodId=${oePeriodId}&oeWorkflowId=${oeWorkflowId}`,
  PLAN_MAPPING_LIST: `${serviceBaseUrl}/benefitplanmapping`,
  PLAN_MAPPING_POST: `${serviceBaseUrl}/benefitplanmapping`,
  PLAN_MAPPING_PUT: `${serviceBaseUrl}/benefitplanmapping`,
  PLAN_MAPPING_DELETE: `${serviceBaseUrl}/benefitplanmapping`,
  PLAN_LIST: `${serviceBaseUrl}/benefitplan`,
  PLAN_DETAIL_LIST: `${serviceBaseUrl}/benefitplan/details`,
  PLAN_MAPPING_STATUS: `${serviceBaseUrl}/BenefitPlanMapping/mappingstatus`,
  PLAN_RATES_GET: (oePeriodId, planId) =>
    `${serviceBaseUrl}/benefitplan/rates?oePeriodId=${oePeriodId}&planId=${planId}`,
  PLAN_LIST_FOR_MAPPING: (offerType) =>
    `${serviceBaseUrl}/benefitplan/mapping?offerType=${offerType}`,

  SYSTEM_MENU_FILTERS: (oePeriodId) =>
    `${serviceBaseUrl}/benefitplan/systemmenu/filters?oePeriodId=${oePeriodId}`,
  SYSTEM_MENU_DOWNLAOD: (
    oePeriodId,
    offerTypeCode,
    benefitGroup,
    rateGroup,
    isExcel,
    bundleIndex
  ) =>
    `${serviceBaseUrl}/benefitplan/systemmenu?oePeriodId=${oePeriodId}&offerTypeCode=${offerTypeCode}&benefitGroup=${benefitGroup}&rateGroup=${rateGroup}&isExcel=${isExcel}&bundleIndex=${bundleIndex}`,
  USER_GUIDE_DOWNLOAD: `${serviceBaseUrl}/userguide`,

  RATE_GROUP_MAPPING_LIST: (offerType) =>
    `${serviceBaseUrl}/BenefitGroupMapping?offerType=${offerType}`,
  RATE_GROUP_MAPPING_POST: `${serviceBaseUrl}/BenefitGroupMapping`,
  RATE_GROUP_MAPPING_DELETE: `${serviceBaseUrl}/BenefitGroupMapping`,

  RATE_GROUP_MAP_LIST: `${serviceBaseUrl}/rategroupmapping`,
  RATE_GROUP_MAP_POST: `${serviceBaseUrl}/rategroupmapping`,
  RATE_GROUP_MAP_IMPORT: `${serviceBaseUrl}/rategroupmapping/bulk`,
  RATE_GROUP_MAP_PUT: `${serviceBaseUrl}/rategroupmapping`,
  RATE_GROUP_MAP_DELETE: `${serviceBaseUrl}/rategroupmapping`,

  USER_AUTHENTICATE: `${serviceBaseUrl}/Authentication/login`,
  BROKER_AUTHENTICATE: `${serviceBaseUrl}/Authentication/brokerlogin`,
  USER_LOGOUT: `${serviceBaseUrl}/Authentication/logout`,
  USER_GET: `${serviceBaseUrl}/Authentication/user/me`,

  CLIENT_LIST: (oePeriodId, showAllClients) =>
    `${serviceBaseUrl}/client/openenrollmentstatus?oePeriodId=${oePeriodId}&showAllClients=${showAllClients}`,
  GET_CLIENT_BENEFIT_REP: (clientId) =>
    `${serviceBaseUrl}/client/benefitrep?clientId=${clientId}`,
  GET_CLIENT_SALES_REP: (clientId) =>
    `${serviceBaseUrl}/client/salesrep?clientId=${clientId}`,  
  CLIENT_ACTIVE_OE: `${serviceBaseUrl}/client/openenrollment/active`,
  CLIENT_UPDATE_OESUMMARY_STATAS: `${serviceBaseUrl}/client/openenrollmentstatus`,
  CLIENT_CREATE_MODEL: `${serviceBaseUrl}/ClientRateModel/Create`,
  CLIENT_MODEL_ADD_PLAN: `${serviceBaseUrl}/ClientRateModel/addplan`,
  CLIENT_MODEL_SET_MAX_CONTRIBUTION: `${serviceBaseUrl}/ClientRateModel/Updatemaxcontribution`,
  CLIENT_MODEL_COPY_CONTRIBUTION: `${serviceBaseUrl}/ClientRateModel/copycontribution`,
  CLIENT_MODEL_SUMIT_RATE: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/Submitrate?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_UNLOCK_RATE: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/unlockrate?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_PUBLISH_RATE: (oePeriodId, clientId, versionid, isRetry) =>
    `${serviceBaseUrl}/ClientRateModel/publishrate?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}&isRetry=${isRetry}`,
  CLIENT_MODEL_DELETE_PLAN: `${serviceBaseUrl}/ClientRateModel/deleteplan`,
  CLIENT_MODEL_VERSIONS: (oePeriodId, clientId) =>
    `${serviceBaseUrl}/ClientRateModel/modelversions?oePeriod=${oePeriodId}&clientId=${clientId}`,
  CLIENT_MODEL_SUMMARY_GET: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/summary?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_AVAILABLE_PLANS: (oePeriodId, clientId, versionid, groupId) =>
    `${serviceBaseUrl}/ClientRateModel/availableplans?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}&groupId=${groupId}`,
  CLIENT_MODEL_GET: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_EXCEL_DOWNLOAD: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/excel?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_MAXCONTRIBS: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/maxcontribs?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_ERRORS_GET: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/errors?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_UPDATE_RATE: (oePeriodId, clientId, versionid) =>
    `${serviceBaseUrl}/ClientRateModel/update?oePeriod=${oePeriodId}&clientId=${clientId}&versionId=${versionid}`,
  CLIENT_MODEL_AUDIT_RECORD: (clientId) =>
    `${serviceBaseUrl}/ClientRateModel/auditRecords?clientId=${clientId}`,

  OE_WORKFLOW_GET: (oePeriodId, workflowSetupId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}?workflowSetupId=${workflowSetupId}`,
  OE_WORKFLOW_CLIENTS_GET: (oePeriodId, workflowSetupId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/clients?workflowSetupId=${workflowSetupId}`,
  OE_WORKFLOW_CLIENT_RATE_GROUPS_GET: (oePeriodId, workflowSetupId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/clientrategroups?workflowSetupId=${workflowSetupId}`,
  OE_WORKFLOW_CLIENTS_POST: (oePeriodId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/clients`,
  OE_WORKFLOW_CLIENTS_POST_BULK: (oePeriodId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/clients/bulk`,
  OE_WORKFLOW_SETUP_LIST: (oePeriodId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/setups`,
  OE_WORKFLOW_SETUP_CREATE: (oePeriodId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/setup`,
  OE_WORKFLOW_START: (oePeriodId, workflowSetupId) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}?workflowSetupId=${workflowSetupId}`,
  OE_WORKFLOW_CONTINUE: (oePeriodId, stepId, workflowSetupId, isSkipped) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/continue?stepId=${stepId}&workflowSetupId=${workflowSetupId}&isSkipped=${isSkipped}`,
  OE_UPDATE_RATE_GROUPS_WORKFLOW_CONTINUE: (
    oePeriodId,
    stepId,
    workflowSetupId
  ) =>
    `${serviceBaseUrl}/OEWorkflow/${oePeriodId}/clientrategroups?stepId=${stepId}&workflowSetupId=${workflowSetupId}`,
  OE_DATA_SYNC: `${serviceBaseUrl}/datasync`,
  OE_DATA_SYNC_BULK: `${serviceBaseUrl}/datasync/bulk`,
  OE_DATA_SYNC_ERROR_LOGS: (oePeriodId, workflowId) =>
    `${serviceBaseUrl}/datasync/logs?oePeriodId=${oePeriodId}&workflowId=${workflowId}`,
  OE_DATA_SYNC_HISTORY: (dataType, oePeriodId) =>
    `${serviceBaseUrl}/datasync?dataType=${dataType}&oePeriodId=${oePeriodId}`,
  OE_WORKFLOW_DAHSBAORD_SUMMARY: (oePeriodId) =>
    `${serviceBaseUrl}/OEWorkflow/dashboard?id=${oePeriodId}`,
    OE_WORKFLOW_DAHSBAORD_EXPORT: (oePeriodId) =>
    `${serviceBaseUrl}/OEWorkflow/export/clientSummery?id=${oePeriodId}`,  

  CLIENT_MASTER_LIST: `${serviceBaseUrl}/clientadmin/allclients`,
  CLIENT_ADMIN_CONTACT_LIST: (clientId) =>
    `${serviceBaseUrl}/clientadmin/contacts?clientId=${clientId}`,
  CLIENT_ADMIN_BENEFITGROUOP_LIST: (clientId) =>
    `${serviceBaseUrl}/clientadmin/benefitgroups?clientId=${clientId}`,
  CLIENT_ADMIN_BENEFITPLANS_LIST: (clientId, oePeriodId) =>
    `${serviceBaseUrl}/clientadmin/benefitplans?clientId=${clientId}&oePeriodId=${oePeriodId}`,
  CLIENT_ADMIN_BENEFITRULES_LIST: (clientId, planId) =>
    `${serviceBaseUrl}/clientadmin/benefitrules?clientId=${clientId}&planId=${planId}`,

  CLIENT_ADMIN_NOTIFICATION_HISTORY: (oePeriodId, clientId) =>
    `${serviceBaseUrl}/notification/history?oePeriodId=${oePeriodId}&clientId=${clientId}`,
  CLIENT_ADMIN_MENU_DOCUMENT: (oePeriodId, clientId) =>
    `${serviceBaseUrl}/client/menudocuments?oePeriodId=${oePeriodId}&clientId=${clientId}`,
  CLIENT_ADMIN_MENU_ALL: (oePeriodId, clientId) =>
  `${serviceBaseUrl}/client/menudocuments/all?oePeriodId=${oePeriodId}&clientId=${clientId}`,
  CLIENT_ADMIN_EMP_MENU_DOCUMENT: (oePeriodId, clientId) =>
    `${serviceBaseUrl}/client/empmenudocuments?oePeriodId=${oePeriodId}&clientId=${clientId}`,
  CLIENT_ADMIN_MENU_DOCUMENT_DOWNLOAD: (oePeriodId, clientId, docId, docType,filteredPlans,offerTypeCode) =>
    `${serviceBaseUrl}/client/menudocuments/download?oePeriodId=${oePeriodId}&clientId=${clientId}&docId=${docId}&docType=${docType}&filteredPlans=${filteredPlans}&offerTypeCode=${offerTypeCode}`,
  CLIENT_ADMIN_EMP_MENU_DOCUMENT_DOWNLOAD: (
    oePeriodId,
    clientId,
    docId,
    docType
  ) =>
    `${serviceBaseUrl}/client/empmenudocuments/download?oePeriodId=${oePeriodId}&clientId=${clientId}&docId=${docId}&docType=${docType}`,
  CLIENT_ADMIN_MAINTENANCE: () => `${serviceBaseUrl}/clientadmin/maintenance`,
  CLIENT_ADMIN_GET_LATEST_MAINTENANCE: () => `${serviceBaseUrl}/clientadmin/latestmaintenance`,

  NOTIFICATION_WAVES_LIST: (oePeriodId) =>
    `${serviceBaseUrl}/notification/waves?oePeriodId=${oePeriodId}`,
  NOTIFICATION_WAVES_POST: `${serviceBaseUrl}/notification/waves`,
  NOTIFICATION_WAVES_DELETE: `${serviceBaseUrl}/notification/waves`,
  NOTIFICATION_WAVES_UPDATE_STATUS: `${serviceBaseUrl}/notification/waves/status`,
  NOTIFICATION_WAVES_RESEND: `${serviceBaseUrl}/notification/waves/resend`,
  NOTIFICATION_WAVE_CLIENTS: (oePeriodId, waveId) =>
    `${serviceBaseUrl}/notification/waveclients?oePeriodId=${oePeriodId}&waveId=${waveId}`,
  NOTIFICATION_WAVE_MAIL_DETAILS: (oePeriodId, waveId, isBroker) =>
    `${serviceBaseUrl}/notification/wavemaildetails?oePeriodId=${oePeriodId}&waveId=${waveId}&isBroker=${isBroker}`,
  NOTIFICATION_WAVE_CLIENTS_POST: `${serviceBaseUrl}/notification/waveclients`,
  NOTIFICATION_WAVE_CLIENTS_DELETE: `${serviceBaseUrl}/notification/waveclients`,
  NOTIFICATION_WAVE_ERRORS: (waveId, brokerNotifications) =>
    `${serviceBaseUrl}/notification/waves/errors?notificationWaveId=${waveId}&brokerNotifications=${brokerNotifications}`,

  NOTIFICATION_REDIRECT: `${serviceBaseUrl}/notification/redirectsetup`,

  NOTIFICATION_REMINDER: `${serviceBaseUrl}/notification/remindersetup`,

  NOTIFICATION_WAVE_AVAILABLE_CLIENTS: (oePeriodId) =>
    `${serviceBaseUrl}/notification/availableclients?oePeriodId=${oePeriodId}`,
  NOTIFICATION_WAVE_BENEFIT_GROUPS: (waveId) =>
    `${serviceBaseUrl}/notification/waves/${waveId}/benefitgroup`,

  NOTIFICATION_UPLOAD_WELCOME_LETTER: (waveId, bgLookupId) =>
    `${serviceBaseUrl}/notification/welcomeletter?waveId=${waveId}&bgLookupId=${bgLookupId}`,
  NOTIFICATION_DOWNLOAD_WELCOME_LETTER: (waveId, docId) =>
    `${serviceBaseUrl}/notification/welcomeletter?waveId=${waveId}&docId=${docId}`,

  BROKER_USER_LIST: `${serviceBaseUrl}/brokeruser`,
  BROKER_USER_POST: `${serviceBaseUrl}/brokeruser`,
  BROKER_USER_PUT: `${serviceBaseUrl}/brokeruser`,
  BROKER_USER_DELETE: `${serviceBaseUrl}/brokeruser`,
  BROKER_USER_CLIENTS: (oePeriodId) =>
    `${serviceBaseUrl}/brokeruser/clients?userId=${oePeriodId}`,
};
