import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import ClientContext from "../../layouts/ClientContext";
import LoadingIndicator from "../../components/LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useQueryGetClients } from "../../data/services/clientService";
import UserContext from "../../layouts/UserContext";
import OEContext from "../../layouts/OEContext";
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import AuditTrailModal from "../client/AuditTrailModal";

const ClientDashboard = () => {
  const navigate = useNavigate();
  const { setClient } = React.useContext(ClientContext);
  const { loggedInUser } = React.useContext(UserContext);
  const oePeriod = React.useContext(OEContext);

  const [showAllClients, setShowAllClients] = React.useState("Yes");
  const [allClientOption, setAllClientOption] = React.useState("All Clients");
  const [clientListSrc, setClientListSrc] = React.useState([]);
  const [clientMasterList, setClientMasterList] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });
  const [showAuditTrailModelDialog, setShowAuditTrailModelDialog] =
    React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState({});

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const { isLoading: clientListLoading, data: clientList } = useQueryGetClients(
    oePeriod?.oePeriodId,
    showAllClients
  );

  React.useEffect(() => {
    if (!clientListLoading && clientList && clientList.data) {
      setClientMasterList(clientList.data);
      setClientListSrc(process(clientList.data.slice(0), gridDataState));
    }
  }, [clientListLoading, clientList, gridDataState]);

  React.useEffect(() => {
    if (loggedInUser && !loggedInUser.isWhiteListed) {
      navigate("/under-maintenance");
    }
  }, [loggedInUser]);

  if (clientListLoading) {
    return <LoadingIndicator />;
  }

  const onClientFilterChange = (event) => {
    setShowAllClients(event.value === "All Clients" ? "Yes" : "No");
    setAllClientOption(event.value);
  };

  const handleOpenClient = (item) => {
    setClient(item);
    if (loggedInUser.hasAdminAppPrivilege) {
      navigate(`/admin/clients/${item.clientId}/oe/${item.oePeriodId}/summary`);
    } else {
      navigate(`/clients/${item.clientId}/oe/${item.oePeriodId}/summary`);
    }
  };

  const handleOpenClientSetup = (item) => {
    setClient(item);
    navigate(`/admin/clients/${item.clientId}/setup`);
  };

  const handleAuditTrailModel = async (props) => {
    setSelectedClient(props);
    setShowAuditTrailModelDialog(true);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      if (loggedInUser.hasAdminAppPrivilege) {
        return (
          <td>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "stretch",
              }}
            >
              <Button onClick={() => handleOpenClientSetup(props.dataItem)}>
                View Setup
              </Button>
              <Button
                themeColor="info"
                onClick={() => handleOpenClient(props.dataItem)}
                style={{ marginLeft: "10px" }}
              >
                View Rates
              </Button>
              <div
                style={{ paddingTop: "5px", marginLeft: "10px", width: "20px" }}
              >
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  onClick={() => handleAuditTrailModel(props.dataItem)}
                  style={{
                    color: "grey",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </td>
        );
      } else {
        return (
          <td>
            <Button
              themeColor="info"
              onClick={() => handleOpenClient(props.dataItem)}
            >
              Review
            </Button>
          </td>
        );
      }
    };

    const statusCell = (props) => {
      if (loggedInUser.hasAdminAppPrivilege) {
        if (props.dataItem.oeStatus === "NotStarted") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "grey",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else if (props.dataItem.oeStatus === "InProgress") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "orange",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else if (props.dataItem.oeStatus === "Submitted") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "green",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else if (props.dataItem.oeStatus === "PublishError") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "red",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else if (props.dataItem.oeStatus === "PublishInProgress") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "yellow",
                  borderRadius: "15px",
                  color: "grey",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "darkgreen",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        }
      } else {
        if (props.dataItem.oeStatus === "NotStarted") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "grey",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else if (props.dataItem.oeStatus === "InProgress") {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "orange",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {props.dataItem.oeStatusDesc}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <span
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  paddingTop: "1px",
                  paddingBottom: "1px",
                  backgroundColor: "green",
                  borderRadius: "15px",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {"Submitted"}
              </span>
            </td>
          );
        }
      }
    };

    const dataStateChange = (event) => {
      setClientListSrc(process(clientMasterList.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={clientMasterList}
            expanded={true}
          />
        </div>
      );
    };

    const ColumnMenu = (props) => {
      return (
        <div>
          <GridColumnMenuFilter
            {...props}
            expanded={true}
            hideSecondFilter={true}
          />
        </div>
      );
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={clientListSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="clientId"
          filter="text"
          columnMenu={ColumnMenu}
          headerClassName={columnMenuHeader("clientId")}
          title="Company Id"
        />
        <Column
          field="clientName"
          filter="text"
          columnMenu={ColumnMenu}
          headerClassName={columnMenuHeader("clientName")}
          title="Company Name"
        />
        {loggedInUser.hasAdminAppPrivilege && (
          <Column
            field="benefitRepName"
            filter="text"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("benefitRep")}
            title="Benefit Representative"
          />
        )}
        {loggedInUser.hasAdminAppPrivilege && (
          <Column
            field="salesRepName"
            filter="text"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("salesRepName")}
            title="Sales Representative"
          />
        )}
        {loggedInUser.hasAdminAppPrivilege && (
          <Column
            field="wave"
            filter="text"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("wave")}
            title="Notification Wave"
          />
        )}
        {loggedInUser.hasAdminAppPrivilege && (
          <Column
            field="notificationSent"
            filter="text"
            columnMenu={ColumnCheckboxMenu}
            headerClassName={columnMenuHeader("notificationSent")}
            title="Notification Sent"
          />
        )}
        <Column
          field="oeStatusDesc"
          filter="text"
          cell={statusCell}
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("oeStatusDesc")}
          title="Status"
        />
        <Column
          field="Action"
          width={"240"}
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  const RenderSearchBox = () => {
    const onChange = (event) => {
      let filters =
        gridDataState.filter && gridDataState.filter.filters.length > 0
          ? gridDataState.filter.filters
          : [];

      var clientNameField = filters.find((x) => x.field === "clientName");
      if (clientNameField) {
        clientNameField.value = event.target.value;
      } else {
        filters.push({
          field: "clientName",
          operator: "startswith",
          value: event.target.value,
        });
      }

      var clientIdField = filters.find((x) => x.field === "clientId");
      if (clientIdField) {
        clientIdField.value = event.target.value;
      } else {
        filters.push({
          field: "clientId",
          operator: "startswith",
          value: event.target.value,
        });
      }

      setGridDataState({
        ...gridDataState,
        filter: { logic: "or", filters: filters },
      });
    };

    return (
      <Input
        autoFocus
        type="search"
        key={"clientSearchBox"}
        onChange={onChange}
        placeholder="Search..."
        style={{ height: 40 }}
      />
    );
  };

  if (clientMasterList.length === 0 && showAllClients !== "No") {
    return (
      <div
        style={{
          backgroundColor: "#cce5ff",
          marginTop: 20,
          padding: 10,
          borderRadius: ".25rem",
          textAlign: "center",
        }}
      >
        <span style={{ color: "#004085" }}>
          {loggedInUser?.hasAdminAppPrivilege
            ? "Client Rate Model is not created yet. Please navigate to Workflow screen to create Client Rate Model."
            : "Currently there is no active Open Enrollment. Please reach out to Specialist for any additional info."}
        </span>
      </div>
    );
  }

  return (
    <div>
      {!loggedInUser.hasAdminAppPrivilege && (
        <div
          style={{
            backgroundColor: "#cce5ff",
            padding: "10px",
            marginTop: 20,
            borderRadius: ".25rem",
            textAlign: "center",
          }}
        >
          <span style={{ color: "#004085" }}>
            Welcome to {oePeriod.oePeriodName}. Please select your company below
            to review your current renewal and create comparison models with new
            contributions.
          </span>
        </div>
      )}
      <div
        style={{
          justifyContent: "space-around",
          display: "flex",
          paddingBottom: 20,
          marginTop: "20px",
        }}
      >
        <div style={{ width: "50%", display: "flex", flexDirection: "row" }}>
          {RenderSearchBox()}
          {loggedInUser.benefitAdminPrivilege && (
            <DropDownList
              style={{
                width: "150px",
                marginLeft: "10px",
              }}
              data={["All Clients", "My Clients"]}
              defaultValue={allClientOption}
              onChange={onClientFilterChange}
            />
          )}
        </div>
      </div>
      {RenderGrid()}
      {showAuditTrailModelDialog && (
        <AuditTrailModal
          client={selectedClient}
          onModelClose={() => setShowAuditTrailModelDialog(false)}
        />
      )}
    </div>
  );
};

export default ClientDashboard;
