import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete,
  reactQueryDefaultOptions,
} from "../helpers/baseApi";
import { SERVICE_URLS } from "../helpers/serviceUrls";
import { QUERY_KEYS } from "../helpers/queryKeys";

export const useQueryGetCarrierList = () => {
  const getCarrierList = async () => {
    return await axiosGet(SERVICE_URLS.CARRIER_LIST);
  };
  return useQuery(
    [QUERY_KEYS.CARRIER_LIST],
    getCarrierList,
    reactQueryDefaultOptions
  );
};

export const useQueryGetCarrierTypeList = () => {
  const getCarrierTypeList = async () => {
    return await axiosGet(SERVICE_URLS.CARRIER_TYPE_LIST);
  };

  return useQuery(
    [QUERY_KEYS.CARRIER_TYPE_LIST],
    getCarrierTypeList,
    reactQueryDefaultOptions
  );
};

export const useMutatePostCarrierType = () => {
  const postCarrierType = async (data) => {
    return await axiosPost(SERVICE_URLS.CARRIER_TYPE_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postCarrierType, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CARRIER_TYPE_LIST]);
    },
  });
};

export const useMutateDeleteCarrierType = () => {
  const queueImport = async (data) => {
    return await axiosDelete(SERVICE_URLS.CARRIER_TYPE_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CARRIER_TYPE_LIST]);
    },
  });
};

export const useMutatePostCarrier = () => {
  const postCarrierType = async (data) => {
    return await axiosPost(SERVICE_URLS.CARRIER_POST, data);
  };

  var queryClient = useQueryClient();

  return useMutation(postCarrierType, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CARRIER_LIST]);
    },
  });
};

export const useMutatePutCarrier = () => {
  const queueImport = async (data) => {
    return await axiosPut(SERVICE_URLS.CARRIER_PUT, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CARRIER_LIST]);
    },
  });
};

export const useMutateDeleteCarrier = () => {
  const queueImport = async (data) => {
    return await axiosDelete(SERVICE_URLS.CARRIER_DELETE, data);
  };

  var queryClient = useQueryClient();

  return useMutation(queueImport, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CARRIER_LIST]);
    },
  });
};
