import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { ListView } from "@progress/kendo-react-listview";
import { Card } from "@progress/kendo-react-layout";
import { formatDate } from "@telerik/kendo-intl";

import LoadingIndicator from "../../components/LoadingIndicator";
import AddWorkflowModel from "./AddWorkflowModel";
import OEContext from "../../layouts/OEContext";

import { useQueryGetOEWorkflowSetups } from "../../data/services/workflowService";

const OEWorkflowSetupList = () => {
  const navigate = useNavigate();
  const oePeriod = React.useContext(OEContext);

  const [createWorkflowModelOpen, setCreateWorkflowModelOpen] =
    React.useState(false);
  const [workflowsSrc, setWorkflowsSrc] = React.useState([]);

  const { isLoading: workflowSetupLoading, data: workflowSetups } =
    useQueryGetOEWorkflowSetups(oePeriod?.oePeriodId);

  React.useEffect(() => {
    if (!workflowSetupLoading && workflowSetups && workflowSetups.data) {
      setWorkflowsSrc(workflowSetups.data);
    }
  }, [workflowSetupLoading, workflowSetups]);

  if (workflowSetupLoading) {
    return <LoadingIndicator />;
  }

  const handleOpenAddWindow = () => {
    setCreateWorkflowModelOpen(true);
  };

  const getStepDescription = (stepId) => {
    switch (stepId) {
      case 1:
        return "System plan data sync step in progress. (Step 1 of 9)";
      case 2:
        return "Prism hr benefit plan and rate group mapping step in progress (Step 2 of 9)";
      case 3:
        return "Import rate file step in progress (Step 3 of 9)";
      case 4:
        return "Sync and validate system plan rates step in progress (Step 4 of 9)";
      case 5:
        return "Upload benefit plan details step in progress (Step 5 of 9)";
      case 6:
        return "Workflow Client setup step in progress (Step 6 of 9)";
      case 7:
        return "Client Rate group setup in progress (Step 7 of 9)";
      case 8:
        return "Client model building step in progress (Step 8 of 9)";
      case 9:
        return "Send Notification step in progress (Step 9 of 9)";
      default:
        return "System plan data sync step in progress (Step 1 of 9)";
    }
  };

  const MyItemRender = (props) => {
    const data = props.dataItem;
    let stepDescription = getStepDescription(data.currentStep);

    return (
      <Card
        style={{
          width: "100%",
          boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
          marginTop: "15px",
          padding: "15px",
        }}
      >
        <div className="stepper-view-container">
          <div
            className="stepper-view-text"
            style={{
              fontSize: 16,
              fontWeight: 600,
              paddingTop: 5,
              minWidth: 300,
            }}
          >
            <span>
              {data.carrierId === null ? "Other Clients" : data.carrierName}{" "}
              <br />
              <span style={{ fontWeight: 400, fontSize: 12, opacity: 0.8 }}>
                {data.workflowName}
              </span>
            </span>
          </div>
          <div
            className="stepper-view-control"
            style={{
              paddingLeft: 10,
              paddingTop: 15,
            }}
          >
            <span>
              <b>Status:</b> {stepDescription}
            </span>
          </div>
          <div
            className="stepper-view-control"
            style={{
              paddingLeft: 10,
              paddingTop: 15,
            }}
          >
            <span>
              <b>Created On:</b>{" "}
              {formatDate(new Date(data.dateCreated + "Z"), "G")}
            </span>
          </div>
          <div className="stepper-view-action" style={{ paddingTop: 10 }}>
            <Button
              onClick={() => {
                navigate(`/admin/workflow/${data.oeWorkflowSetupId}`);
              }}
            >
              View
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
          marginTop: 20,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Workflows</h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          {workflowsSrc && workflowsSrc.length > 0 && (
            <Button onClick={handleOpenAddWindow} themeColor={"success"}>
              Start New Workflow
            </Button>
          )}
        </div>
      </div>
      {(!workflowsSrc || workflowsSrc.length === 0) && (
        <div
          style={{ textAlign: "center", margin: "200px", marginTop: "100px" }}
        >
          <p>
            Welcome to <b>{oePeriod?.oePeriodName}</b>. This workflow will guide
            to import the rate files, sync client data, and send notification.
            We can have workflow created for each carrier.
          </p>
          <p>To get started please click the Start Workflow button.</p>
          <Button onClick={handleOpenAddWindow} themeColor={"success"}>
            Start New Workflow
          </Button>
        </div>
      )}
      {workflowsSrc && workflowsSrc.length > 0 && (
        <ListView
          data={workflowsSrc}
          item={MyItemRender}
          style={{
            width: "100%",
          }}
        />
      )}
      {createWorkflowModelOpen && (
        <AddWorkflowModel
          onSubmit={(data) => {
            navigate(`/admin/workflow/${data.oeWorkflowSetupId}`);
          }}
          onClose={() => setCreateWorkflowModelOpen(false)}
          oePeriod={oePeriod}
        />
      )}
    </div>
  );
};

export default OEWorkflowSetupList;
