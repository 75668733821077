import * as React from "react";
import { useNavigate, useLocation, Outlet, useParams } from "react-router-dom";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from "@progress/kendo-react-layout";
import { Popup } from "@progress/kendo-react-popup";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import ErrorBoundary from "../components/ErrorBoundary";
import LoadingIndicator from "../components/LoadingIndicator";
import UserContext from "./UserContext";
import ClientHeader from "./ClientHeader";
import config from "../config";
import { PRIVILEGE_TYPE } from "../constants/index";

import { useQueryGetUser, useMutateLogout } from "../data/services/authService";
import { useQueryGetActiveOEPeriod } from "../data/services/clientService";

import OEContext from "./OEContext";

const ClientLayout = (props) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { setLoggedInUser } = React.useContext(UserContext);

  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const avatarCompRef = React.useRef(null);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [selectedOEPeriod, setSelectedOEPeriod] = React.useState(null);

  const [items, setItems] = React.useState([
    {
      text: "Dashboard",
      id: 1,
      selected: true,
    },
    {
      separator: true,
    },
    {
      text: "Full Rate Benefit Menus",
      id: 2,
    },
    {
      separator: true,
    },
    {
      text: "Benefit Employee Menus",
      id: 3,
    },
    {
      separator: true,
    },
    {
      text: "User Guide",
      id: 4,
    },
    {
      separator: true,
    },
  ]);

  const { data: userData, isLoading: userDataLoading } = useQueryGetUser();
  const { data: activeOEPeriodData, isLoading: activeOEPeriodDataLoading } =
    useQueryGetActiveOEPeriod();
  const useMutateLogoutHandler = useMutateLogout();

  React.useEffect(() => {
    if (!userDataLoading) {
      if (!userData || !userData.data) {
        navigate("/autherror");
      }

      let userEntity = { ...userData.data };
      if (userEntity.privileges && userEntity.privileges.length > 0) {
        userEntity.hasDataSyncPriv = userEntity.privileges.some(
          (e) => e === PRIVILEGE_TYPE.HasDataSyncPrivilege
        );
        userEntity.hasUnlockPriv = userEntity.privileges.some(
          (e) => e === PRIVILEGE_TYPE.HasGridUnlockPrivilege
        );
        userEntity.submitContributionModalPrivilege =
          userEntity.privileges.some(
            (e) => e === PRIVILEGE_TYPE.SubmitContributionModalPrivilege
          );
        userEntity.brokerUserPrivilege = userEntity.privileges.some(
          (e) => e === PRIVILEGE_TYPE.BrokerUserPrivilege
        );
      }

      setUser(userEntity);
      setLoggedInUser(userEntity);

      if (userEntity.hasAdminAppPrivilege) {
        navigate("/admin/dashboard");
      } else if (!userEntity.hasClientAppPrivilege) {
        navigate("/autherror");
      }
    }
  }, [userData, userDataLoading, navigate, setLoggedInUser]);

  React.useEffect(() => {
    if (
      !activeOEPeriodDataLoading &&
      activeOEPeriodData &&
      activeOEPeriodData.data
    ) {
      setSelectedOEPeriod(activeOEPeriodData.data);
    }
  }, [activeOEPeriodData, activeOEPeriodDataLoading]);

  if (userDataLoading || activeOEPeriodDataLoading) {
    return <LoadingIndicator />;
  }

  const onSelect = (ev) => {
    const currentItem = ev.itemTarget.props;

    const newData = items.map((item) => {
      item.selected = item.id === currentItem.id;
      return item;
    });

    if (currentItem.route) {
      navigate(currentItem.route);
    }

    setItems(newData);
  };

  const onLogoutClick = async () => {
    let response = await useMutateLogoutHandler.mutateAsync();
    if (user.brokerUserPrivilege) {
      window.location.replace(
        `${config.widget.issuer}/v1/logout?id_token_hint=${response.data.idToken}&post_logout_redirect_uri=${window.location.origin}/login`
      );
    } else {
      navigate("/logout");
    }
  };

  let selectedMenuItem = items.find(
    (e) => e.route === pathName || pathName.indexOf(e.route) > -1
  );
  let menuItems = [...items];
  if (selectedMenuItem) {
    menuItems = items.map((e) => {
      if (e.id === selectedMenuItem.id) {
        e.selected = true;
      } else {
        e.selected = false;
      }

      return e;
    });
  }

  if (selectedOEPeriod && clientId) {
    menuItems = menuItems.map((e) => {
      if (e.text === "Dashboard") {
        e.route = `/clients/${clientId}/oe/${selectedOEPeriod.oePeriodId}/summary`;
      } else if (e.text === "Full Rate Benefit Menus") {
        e.route = `/clients/${clientId}/oe/${selectedOEPeriod.oePeriodId}/planlibrary`;
      } else if (e.text === "Benefit Employee Menus") {
        e.route = `/clients/${clientId}/oe/${selectedOEPeriod.oePeriodId}/benefitempmenu`;
      } else if (e.text === "User Guide") {
        e.route = `/clients/${clientId}/oe/${selectedOEPeriod.oePeriodId}/userguide`;
      }
      return e;
    });
  }

  return (
    <React.Fragment>
      <AppBar
        themeColor={"light"}
        style={{ backgroundColor: "#192853", padding: 0 }}
      >
        <AppBarSection style={{ padding: "8px" }}>
          <img
            src={"/Logo_white.svg"}
            alt={"PrestigePEO logo"}
            style={{ height: 35, cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          />
        </AppBarSection>

        <AppBarSpacer />

        <AppBarSection>
          <div
            onClick={() => setIsProfilePopupOpen(!isProfilePopupOpen)}
            ref={avatarCompRef}
            className={
              isProfilePopupOpen ? "profile-avatar" : "profile-avatar-default"
            }
          >
            <span
              style={{
                paddingRight: "10px",
                paddingLeft: "8px",
                color: "#fff",
              }}
            >
              Welcome {user.userName}
            </span>
            <Avatar type="text" style={{ marginRight: "8px" }}>
              <span>{user.userInitial}</span>
            </Avatar>
          </div>
          <Popup
            anchor={avatarCompRef.current}
            show={isProfilePopupOpen}
            style={{ width: "180px" }}
          >
            <div style={{ padding: "10px" }}>
              <Button
                themeColor={"primary"}
                style={{ width: "100%" }}
                fillMode={"outline"}
                onClick={onLogoutClick}
              >
                {"Logout"}
              </Button>
            </div>
          </Popup>
        </AppBarSection>
      </AppBar>
      {clientId && (
        <OEContext.Provider value={selectedOEPeriod}>
          <ClientHeader menuIconCLick={() => setDrawerOpen(!drawerOpen)} />
          <Drawer
            expanded={drawerOpen}
            mode="push"
            width={220}
            items={menuItems}
            onSelect={onSelect}
          >
            <DrawerContent
              style={{
                overflow: "auto",
                backgroundColor: "#EEF2F7",
                minHeight: "90vh",
              }}
            >
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
              <div style={{ height: "10px" }}></div>
            </DrawerContent>
          </Drawer>
        </OEContext.Provider>
      )}
      {!clientId && (
        <OEContext.Provider value={selectedOEPeriod}>
          <ErrorBoundary>
            <div style={{ margin: "15px" }}>
              <Outlet />
            </div>
          </ErrorBoundary>
          <div style={{ height: "10px" }}></div>
        </OEContext.Provider>
      )}
    </React.Fragment>
  );
};

export default ClientLayout;
