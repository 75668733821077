import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import {
  useQueryGetNotificationWaveClients,
  useMutateDeleteNotificationWaveClient,
  useMutateContinueNotificationWaveStep,
} from "../../data/services/notificationService";

import DeleteDialog from "../../components/DeleteDialog";
import ErrorBanner from "../../components/ErrorBanner";
import LoadingIndicator from "../../components/LoadingIndicator";
import AddWaveClientModel from "./AddWaveClientModel";
import { NotStarted } from "./NotificationWaveDetailHelper";

const NotificationWaveClients = ({
  waveId,
  oePeriod,
  currentNotificationWave,
}) => {
  const isAddClientsStepCompleted = React.useMemo(
    () => currentNotificationWave?.status !== NotStarted,
    [currentNotificationWave]
  );

  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [editItem, setEditItem] = React.useState();

  const [notificationWaveClientsList, SetNotificationWaveClients] =
    React.useState([]);
  const [notificationWaveClientsMasterData, SetNotificationWaveClientsMaster] =
    React.useState([]);

  const [gridDataState, setGridDataState] = React.useState({
    take: 10,
    skip: 0,
  });

  const pagerSetting = {
    buttonCount: 5,
    info: true,
    type: "numeric",
    pageSizes: [10, 20, 50, 100, 200, 500],
    previousNext: true,
  };

  const {
    isLoading: notificationWaveClientsLoading,
    data: notificationWaveClients,
  } = useQueryGetNotificationWaveClients(oePeriod?.oePeriodId, waveId);
  const useMutateDeleteNotificationWaveClientHandler =
    useMutateDeleteNotificationWaveClient();
  const useMutateContinueNotificationWaveStepHandler =
    useMutateContinueNotificationWaveStep();

  React.useEffect(() => {
    if (
      !notificationWaveClientsLoading &&
      notificationWaveClients &&
      notificationWaveClients.data
    ) {
      SetNotificationWaveClientsMaster(notificationWaveClients.data);
      SetNotificationWaveClients(
        process(notificationWaveClients.data.slice(0), gridDataState)
      );
    }
  }, [notificationWaveClientsLoading, notificationWaveClients, gridDataState]);

  if (notificationWaveClientsLoading) {
    return <LoadingIndicator />;
  }

  const handleCancelEdit = () => {
    setOpenEditForm(false);
  };

  const handleSubmit = () => {
    setOpenEditForm(false);
  };

  const handleOpenDeleteWindow = (item) => {
    setEditItem(item);
    setShowDeleteDialog(true);
  };

  const handleOpenAddWindow = (item) => {
    setEditItem({});
    setOpenEditForm(true);
  };

  const handleContinueStep2 = async () => {
    let reqBody = {
      notificationWaveId: waveId,
      status: "DocUploadInProgress",
      oePeriodId: oePeriod?.oePeriodId,
    };

    await useMutateContinueNotificationWaveStepHandler.mutateAsync(reqBody);
  };

  const handleDelete = async (item) => {
    let data = {
      notificationWaveId: item.notificationWaveId,
      oePeriodId: oePeriod?.oePeriodId,
      clientId: item.clientId,
    };

    await useMutateDeleteNotificationWaveClientHandler.mutateAsync(data);

    setShowDeleteDialog(false);
  };

  const RenderGrid = () => {
    const actionCell = (props) => {
      return (
        <td>
          <Button
            themeColor="light"
            style={{ marginLeft: 10 }}
            disabled={isAddClientsStepCompleted}
            onClick={() => handleOpenDeleteWindow(props.dataItem)}
          >
            Delete
          </Button>
        </td>
      );
    };

    const dataStateChange = (event) => {
      SetNotificationWaveClients(
        process(notificationWaveClientsMasterData.slice(0), event.dataState)
      );
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={notificationWaveClientsMasterData}
            expanded={true}
          />
        </div>
      );
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={notificationWaveClientsList}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="clientId"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("clientId")}
          title="Client Id"
        />
        <Column
          field="clientName"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("clientName")}
          title="Client Name"
        />
        <Column
          field="Action"
          title="Action"
          cell={actionCell}
          sortable={false}
        />
      </Grid>
    );
  };

  return (
    <div>
      {!notificationWaveClientsMasterData?.length && (
        <div>
          <div
            style={{
              justifyContent: "space-around",
              display: "flex",
              paddingBottom: 10,
            }}
          >
            <h3 style={{ margin: "10px" }}>
              Click on <b>Add Clients</b> to map clients to this wave.
            </h3>
          </div>
          <div
            style={{
              justifyContent: "space-around",
              display: "flex",
              paddingBottom: 10,
            }}
          >
            <Button
              onClick={handleOpenAddWindow}
              themeColor={"success"}
              disabled={isAddClientsStepCompleted}
            >
              Add Clients
            </Button>
          </div>
        </div>
      )}
      {notificationWaveClientsMasterData?.length > 0 && (
        <>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              paddingBottom: 10,
            }}
          >
            <div>
              <h3 style={{ margin: "10px" }}>
                There are{" "}
                <b>{notificationWaveClientsMasterData?.length} clients</b>{" "}
                mapped to this wave.
              </h3>
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button
                onClick={handleOpenAddWindow}
                themeColor={"success"}
                disabled={isAddClientsStepCompleted}
              >
                Add Clients
              </Button>
            </div>
          </div>
          <ErrorBanner handler={useMutateContinueNotificationWaveStepHandler} />
          <ErrorBanner
            handler={useMutateDeleteNotificationWaveClientHandler}
            onError={() => setShowDeleteDialog(false)}
          />
          <RenderGrid />
          <div
            style={{
              marginTop: "20px",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            Note: We can't add clients once we move to step. Please make sure to
            add clients before continuing.
          </div>
          <div
            style={{
              justifyContent: "space-around",
              display: "flex",
              paddingBottom: 10,
            }}
          >
            <div style={{ marginTop: "30px" }}>
              <Button
                onClick={() => handleContinueStep2()}
                themeColor={"success"}
                disabled={isAddClientsStepCompleted}
              >
                {"Continue to Step 2"}
              </Button>
              {isAddClientsStepCompleted && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "darkgreen", marginLeft: "10px" }}
                />
              )}
            </div>
          </div>
          {showDeleteDialog && (
            <DeleteDialog
              onDelete={handleDelete}
              onCancel={() => setShowDeleteDialog(false)}
              item={editItem}
            />
          )}
        </>
      )}
      {openEditForm && (
        <AddWaveClientModel
          onClose={handleCancelEdit}
          onSubmit={handleSubmit}
          oePeriod={oePeriod}
          currentWave={currentNotificationWave}
        />
      )}
    </div>
  );
};

export default NotificationWaveClients;
