import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input as KendoInput } from "@progress/kendo-react-inputs";

import ErrorBanner from "../../components/ErrorBanner";

import {
  useMutatePostCarrier,
  useMutatePutCarrier,
} from "../../data/services/carrierService";

const CarrierAddModel = ({ item, cancelEdit, onSubmit, carrierTypes }) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [mappings, setMappings] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);

  const [carrierNameState, setCarrierNameState] = React.useState("");
  const [carrierTypeAddState, setCarrierTypeAddState] = React.useState();
  const [rateFileParserAddState, setRateFileParserAddState] = React.useState();
  const [isAddValid, setIsAddValid] = React.useState(false);
  const [filteredCarrierTypes, setFilteredCarrierTypes] = React.useState([]);

  const rateParsers = [
    {
      text: "OXFORD_RATE_PARSER",
      value: "OXFORD_RATE_PARSER",
    },
    {
      text: "AETNA_RATE_PARSER",
      value: "AETNA_RATE_PARSER",
    },
  ];

  const defaultRateParser = {
    text: "Select Rate Parser",
  };

  const defaultCarrierType = {
    carrierTypeDesc: "Select Carrier Type",
  };

  React.useEffect(() => {
    setIsEdit(item.carrierId ? true : false);
    setCarrierNameState(item.carrierName);
    setMappings(item.carrierTypes || []);

    if (carrierTypes.length > 0) {
      if (item.carrierTypes && item.carrierTypes.length > 0) {
        setFilteredCarrierTypes(
          carrierTypes.filter(
            (x) =>
              !item.carrierTypes.find(
                (y) => y.carrierTypeId === x.carrierTypeId
              )
          )
        );
      } else {
        setFilteredCarrierTypes(carrierTypes);
      }
    }
  }, [item, carrierTypes]);

  const useMutatePostCarrierHandler = useMutatePostCarrier();
  const useMutatePutCarrierHandler = useMutatePutCarrier();

  const handleSubmit = async () => {
    let data = {
      carrierName: carrierNameState,
      carrierTypes: mappings.map((x) => {
        return {
          carrierTypeId: x.carrierTypeId,
          rateFileParser: x.rateFileParser,
        };
      }),
    };

    // if(data.carrierTypes.length === 0) {
    //     alert("Please add at least one carrier type");
    //     return;
    // }

    setSubmitted(true);

    if (isEdit) {
      data.carrierId = item.carrierId;
      await useMutatePutCarrierHandler.mutateAsync(data);
    } else {
      await useMutatePostCarrierHandler.mutateAsync(data);
    }

    setSubmitted(false);
    onSubmit();
  };

  const onCarrierTypeChange = (event) => {
    let carrierType = event.target.value;
    setCarrierTypeAddState(carrierType);

    setIsAddValid(
      carrierType &&
        carrierType.carrierTypeId &&
        rateFileParserAddState &&
        rateFileParserAddState.value
    );
  };

  const onRateFileParserChange = (event) => {
    let rateFileParser = event.target.value;
    setRateFileParserAddState(rateFileParser);

    setIsAddValid(
      carrierTypeAddState &&
        carrierTypeAddState.carrierTypeId &&
        rateFileParser &&
        rateFileParser.value
    );
  };

  const onAdd = () => {
    var found = mappings.find(
      (e) => e.carrierTypeId === carrierTypeAddState.carrierTypeId
    );
    if (found) {
      found.rateFileParser = rateFileParserAddState.value;

      setMappings([...mappings]);
      setRateFileParserAddState(null);
      setCarrierTypeAddState(null);
      setIsAddValid(false);
    } else {
      var typeDetail = carrierTypes.find(
        (c) => c.carrierTypeId === carrierTypeAddState.carrierTypeId
      );
      var newMapping = {
        carrierTypeId: typeDetail.carrierTypeId,
        carrierTypeDesc: typeDetail.carrierTypeDesc,
        carrierTypeCode: typeDetail.carrierTypeCode,
        rateFileParser: rateFileParserAddState.value,
      };
      setMappings([...mappings, newMapping]);

      setRateFileParserAddState(null);
      setCarrierTypeAddState(null);
      setIsAddValid(false);
    }

    setFilteredCarrierTypes(
      carrierTypes.filter(
        (x) =>
          x.carrierTypeId !== carrierTypeAddState.carrierTypeId &&
          !mappings.find((y) => y.carrierTypeId === x.carrierTypeId)
      )
    );
  };

  const onRemove = (item) => {
    setMappings(mappings.filter((x) => x.carrierTypeId !== item.carrierTypeId));
    setFilteredCarrierTypes(
      carrierTypes.filter(
        (x) =>
          x.carrierTypeId === item.carrierTypeId ||
          !mappings.find((y) => y.carrierTypeId === x.carrierTypeId)
      )
    );
  };

  const actionCell = (props) => {
    return (
      <td>
        <Button themeColor="light" onClick={() => onRemove(props.dataItem)}>
          Delete
        </Button>
      </td>
    );
  };

  return (
    <Dialog
      title={isEdit ? `Edit ${item.carrierName}` : "Add"}
      onClose={cancelEdit}
      width={600}
    >
      <ErrorBanner
        handler={useMutatePostCarrierHandler}
        onError={() => setSubmitted(false)}
      />
      <ErrorBanner
        handler={useMutatePutCarrierHandler}
        onError={() => setSubmitted(false)}
      />
      <KendoInput
        id={"carrierName"}
        name={"carrierName"}
        label={"Carrier Name"}
        style={{ width: "100%" }}
        value={carrierNameState}
        autoFocus={true}
        onChange={(e) => setCarrierNameState(e.target.value)}
      />
      <h3 style={{ marginBottom: 15 }}>Add Carrier Type Mapping</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DropDownList
          id={"CarrierType"}
          name={"CarrierType"}
          data={filteredCarrierTypes}
          textField={"carrierTypeDesc"}
          dataItemKey={"carrierTypeId"}
          onChange={onCarrierTypeChange}
          value={carrierTypeAddState}
          defaultItem={defaultCarrierType}
          disabled={filteredCarrierTypes.length <= 0}
          style={{ width: "220px" }}
        />

        <DropDownList
          id={"ratefileParser"}
          name={"ratefileParser"}
          data={rateParsers}
          textField={"text"}
          dataItemKey={"value"}
          onChange={onRateFileParserChange}
          value={rateFileParserAddState}
          defaultItem={defaultRateParser}
          disabled={filteredCarrierTypes.length <= 0}
          style={{ width: "220px" }}
        />
        <div>
          <Button
            type="button"
            themeColor={"primary"}
            style={{ width: "80px" }}
            onClick={() => onAdd()}
            disabled={!isAddValid}
          >
            {"Add"}
          </Button>
        </div>
      </div>
      <div style={{ paddingTop: 10 }}></div>
      <Grid
        style={{
          height: "150px",
        }}
        data={mappings}
      >
        <GridColumn field="carrierTypeDesc" title="Carrier Type" />
        <GridColumn field="rateFileParser" title="Rate File Parser" />
        <GridColumn title="Action" cell={actionCell} />
      </Grid>

      <div style={{ paddingTop: 40 }}></div>
      <DialogActionsBar layout={"end"}>
        <Button onClick={cancelEdit}>{"Cancel"}</Button>
        <Button
          type={"submit"}
          themeColor={"primary"}
          disabled={submitted}
          onClick={() => handleSubmit()}
        >
          {isEdit ? "Update" : "Save"}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default CarrierAddModel;
