import ClientEmpMenuGrid from "../clientadmin/ClientEmpMenuGrid";

const ClientBenefitEmpMenu = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      <ClientEmpMenuGrid />
    </div>
  );
};

export default ClientBenefitEmpMenu;
