import {
  Grid,
  GridColumn as Column,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";

import UserContext from "../../layouts/UserContext";
import OEContext from "../../layouts/OEContext";

import {
  useQueryGetDataSyncHistory,
  useMutateDataSync,
} from "../../data/services/workflowService";

const pagerSetting = {
  buttonCount: 5,
  info: true,
  type: "numeric",
  pageSizes: [10, 20, 50, 100, 200, 500],
  previousNext: true,
};

const ClientMasterSyncGrid = () => {
  const oePeriod = React.useContext(OEContext);
  const { loggedInUser } = React.useContext(UserContext);

  const [gridDataSrc, setGridDataSrc] = React.useState([]);
  const [gridDataMasterSrc, SetGridDataMasterSrc] = React.useState([]);
  const [gridDataState, setGridDataState] = React.useState({
    take: 20,
    skip: 0,
  });

  const {
    isLoading: historyLoading,
    data: historyList,
    refetch,
  } = useQueryGetDataSyncHistory("ClientsMasterList", oePeriod?.oePeriodId);
  const useMutateDataSyncHandler = useMutateDataSync();

  React.useEffect(() => {
    if (!historyLoading && historyList && historyList.data) {
      SetGridDataMasterSrc(historyList.data);
      setGridDataSrc(process(historyList.data.slice(0), gridDataState));
    }
  }, [historyLoading, historyList, gridDataState]);

  if (historyLoading) {
    return "Loading ...";
  }

  const handleDataResync = async () => {
    let data = {
      oePeriodId: oePeriod.oePeriodId,
      dataType: "ClientsMasterList",
    };

    await useMutateDataSyncHandler.mutateAsync(data);
    refetch();
  };

  const RenderGrid = () => {
    const dataStateChange = (event) => {
      setGridDataSrc(process(gridDataMasterSrc.slice(0), event.dataState));
      setGridDataState(event.dataState);
    };

    const columnMenuHeader = (field) => {
      var active = GridColumnMenuFilter.active(field, gridDataState.filter);
      return active ? "active" : "";
    };

    const ColumnCheckboxMenu = (props) => {
      return (
        <div>
          <GridColumnMenuCheckboxFilter
            {...props}
            data={gridDataMasterSrc}
            expanded={true}
          />
        </div>
      );
    };

    const DateCell = (props) => {
      return (
        <td>
          {props.dataItem[props.field] &&
            new Date(props.dataItem[props.field] + "Z").toLocaleString("en-US")}
        </td>
      );
    };

    return (
      <Grid
        sortable={true}
        pageable={pagerSetting}
        pageSize={20}
        data={gridDataSrc}
        {...gridDataState}
        onDataStateChange={dataStateChange}
      >
        <Column
          field="startDate"
          filter="text"
          title="Start Date"
          cell={DateCell}
        />
        <Column
          field="endDate"
          filter="text"
          title="End Date"
          cell={DateCell}
        />
        <Column
          field="syncStatusDesc"
          filter="text"
          columnMenu={ColumnCheckboxMenu}
          headerClassName={columnMenuHeader("syncStatusDesc")}
          title="Sync Status"
        />
        <Column field="errorMessage" filter="text" title="Error Message" />
      </Grid>
    );
  };

  return (
    <div>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          paddingBottom: 10,
        }}
      >
        <div>
          <h2 style={{ margin: "10px" }}>Client Master Sync History</h2>
        </div>
        <div style={{ alignSelf: "center" }}>
          {loggedInUser && loggedInUser.hasDataSyncPriv && (
            <Button
              onClick={handleDataResync}
              themeColor={"success"}
              disabled={gridDataMasterSrc.some((e) => e.syncStatus === "Q")}
            >
              Resync Clients Master
            </Button>
          )}
        </div>
      </div>
      <RenderGrid />
    </div>
  );
};

export default ClientMasterSyncGrid;
