import React from "react";
import * as PropTypes from "prop-types";

import { Upload as KendoUpload } from "@progress/kendo-react-upload";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error, Hint } from "@progress/kendo-react-labels";

export const FormUploadWithoutDZ = (fieldRenderProps) => {
  const {
    valid,
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;

  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  const onChangeHandler = (event) => {
    fieldRenderProps.onChange({ value: event.newState });
  };
  const onRemoveHandler = (event) => {
    fieldRenderProps.onChange({ value: event.newState });
  };

  return (
    <FieldWrapper>
      <Label id={labelId} editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <div
          style={{
            height: "5px",
          }}
        />
        <KendoUpload
          id={id}
          valid={valid}
          autoUpload={false}
          showActionButtons={false}
          multiple={false}
          files={value}
          onAdd={onChangeHandler}
          onRemove={onRemoveHandler}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ariaLabelledBy={labelId}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

FormUploadWithoutDZ.displayName = "FormUploadWithoutDZ";
FormUploadWithoutDZ.propTypes = {
  valid: PropTypes.bool,
  value: PropTypes.array,
  id: PropTypes.string,
  optional: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  validationMessage: PropTypes.string,
  visited: PropTypes.bool,
};
