import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import ErrorBanner from "../../components/ErrorBanner";
import { filterBy } from "@progress/kendo-data-query";

import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../../components/FormInput";
import { FormMultiSelect } from "../../components/FormMultiSelect";

import {
  useMutatePostBrokerUser,
  useMutatePutBrokerUser,
} from "../../data/services/brokerUserService";
import { useQueryGetClientsMasterList } from "../../data/services/clientAdminService";

const AddBrokerUser = ({ onSubmit, onClose, item }) => {
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const isEdit = item && item.userId ? true : false;

  const [submitted, setSubmitted] = React.useState(false);
  const [clients, setClients] = React.useState([]);
  const [clientsDataSource, setClientsDataSource] = React.useState([]);
  const [selectedClients, setSelectedClients] = React.useState([]);
  const [initialValue, setInitialValue] = React.useState({});

  const { isLoading: availableClientsLoading, data: availableClients } =
    useQueryGetClientsMasterList();
  const useMutatePostBrokerUserHandler = useMutatePostBrokerUser();
  const useMutatePutBrokerUserHandler = useMutatePutBrokerUser();

  React.useEffect(() => {
    if (!availableClientsLoading && availableClients && availableClients.data) {
      let tempAvailableClients = availableClients.data.map((client) => {
        client.clientDispName = `${client.clientId} - ${client.clientName}`;
        return client;
      });

      if (tempAvailableClients.length > 0) {
        setClients(tempAvailableClients);
        setClientsDataSource(tempAvailableClients.slice());

        if (isEdit) {
          let filteredClients = tempAvailableClients.filter((client) =>
            item.clients.find((t) => t.clientId === client.clientId)
          );
          setSelectedClients(filteredClients);

          setInitialValue({
            firstName: item.firstName,
            lastName: item.lastName,
            userEmail: item.userEmail,
            clientIds: filteredClients,
          });
        }
      } else {
        setClients([]);
        setClientsDataSource([]);
      }
    }
  }, [availableClientsLoading, availableClients, isEdit, item]);

  const handleSubmit = async (data) => {
    let entity = {
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
      clients: selectedClients.map((e) => e.clientId),
    };

    setSubmitted(true);

    if (isEdit) {
      entity.userId = item.userId;
      await useMutatePutBrokerUserHandler.mutateAsync(entity);
    } else {
      await useMutatePostBrokerUserHandler.mutateAsync(entity);
    }

    setSubmitted(false);
    onSubmit();
  };

  const handleChange = (event) => {
    setSelectedClients(event.value);
  };

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span>
        <input
          type="checkbox"
          name={itemProps.dataItem}
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
        />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const filterChange = (event) => {
    setClientsDataSource(filterBy(clients.slice(), event.filter));
  };

  const emailValidator = (value) =>
    emailRegex.test(value) ? "" : "Please enter a valid email.";
  const selectedClientsValidator = (value) =>
    selectedClients.length > 0 ? "" : "Please select at least one client.";

  const selected = selectedClients.length;

  return (
    <Dialog
      title={isEdit ? "Edit Broker User" : "Add Broker User"}
      onClose={onClose}
      width={400}
    >
      <ErrorBanner
        handler={useMutatePostBrokerUserHandler}
        onError={() => setSubmitted(false)}
      />
      <ErrorBanner
        handler={useMutatePutBrokerUserHandler}
        onError={() => setSubmitted(false)}
      />
      {clients && clients.length > 0 && (
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValue}
          render={(formRenderProps) => (
            <FormElement horizontal={false} style={{ maxWidth: 400 }}>
              <Field
                id={"firstName"}
                name={"firstName"}
                label={"First Name"}
                component={FormInput}
                required={true}
                validator={(value) => (value ? "" : "Please enter first name")}
              />
              <Field
                id={"lastName"}
                name={"lastName"}
                label={"Last Name"}
                component={FormInput}
                required={true}
                validator={(value) => (value ? "" : "Please enter last name")}
              />
              <Field
                id={"userEmail"}
                name={"userEmail"}
                label={"User Email"}
                component={FormInput}
                required={true}
                disabled={isEdit}
                validator={emailValidator}
              />
              <Field
                id={"clientIds"}
                name={"clientIds"}
                label={"Assigned Clients"}
                component={FormMultiSelect}
                required={true}
                validator={selectedClientsValidator}
                data={clientsDataSource}
                itemRender={itemRender}
                textField={"clientDispName"}
                dataItemKey={"clientId"}
                onChange={handleChange}
                autoClose={false}
                value={selectedClients}
                filterable={true}
                onFilterChange={filterChange}
                tags={
                  selected > 0
                    ? [
                        {
                          text: `${selected} clients selected`,
                          data: [...selectedClients],
                        },
                      ]
                    : []
                }
              />
              <div style={{ paddingTop: 40 }}></div>
              <DialogActionsBar layout={"end"}>
                <Button onClick={onClose}>{"Cancel"}</Button>
                <Button
                  type={"submit"}
                  themeColor={"primary"}
                  disabled={!formRenderProps.allowSubmit || submitted}
                >
                  {isEdit ? "Update User" : "Add Broker User & Send Invitation"}
                </Button>
              </DialogActionsBar>
            </FormElement>
          )}
        />
      )}
      {(!clients || clients.length === 0) && <span>Loading... </span>}
    </Dialog>
  );
};

export default AddBrokerUser;
